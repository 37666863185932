import { Button, Modal, Select } from 'antd'
import { DialogKeys } from 'components/modals/types'
import { OverlayActions } from 'data/actions'
import { BoxLabelEnum, prettyBoxLabel } from 'data/box'
import React, { useState } from 'react'
import { useDispatch } from 'react-redux'

interface Props {
  isTotalMatch?: boolean
  incompleteLineItems: any[][] // pages
  incompleteReceiptFields: string[]
  onOk: (bypassCheck: SubmitBypassList) => Promise<void>
}

type SubmitBypassList = { field: any; reason?: string }[]

const bypassReasons = ['NOT_PRINTED', 'NOT_CAPTURED']

const ValidateModal = ({
  isTotalMatch,
  incompleteLineItems,
  incompleteReceiptFields,
  onOk
}: Props) => {
  const dispatch = useDispatch()

  const formatToReason = (data): SubmitBypassList =>
    data.map(i => ({ field: i, reason: null }))

  const [missingFieldsState, setMissingFieldsState] = useState({
    receipt: formatToReason(incompleteReceiptFields),
    lines: incompleteLineItems
      .map(i =>
        i.map(
          j =>
            `Line ${j.lineNumber} - ${j?.errors
              .map(i => prettyBoxLabel[i])
              ?.join(',')}`
        )
      )
      .map(formatToReason),
    general: isTotalMatch
      ? []
      : [{ field: BoxLabelEnum.RECEIPT_TOTAL_VALUE, reason: 'NOT_MATCH' }]
  })

  const handleSelectLineReason = (type, index, pageIndex = null) => value => {
    const newReasonList = missingFieldsState[type].map((j, prevPageIdx) =>
      prevPageIdx === pageIndex
        ? j.map((k, prevIdx) =>
            prevIdx === index ? { ...k, reason: value } : k
          )
        : j
    )
    setMissingFieldsState(prev => ({
      ...prev,
      [type]: newReasonList
    }))
  }
  const handleSelectReceiptReason = (type, index) => value => {
    const newReasonList = missingFieldsState[type].map((i, prevIdx) =>
      prevIdx === index ? { ...i, reason: value } : i
    )
    setMissingFieldsState(prev => ({
      ...prev,
      [type]: newReasonList
    }))
  }
  const flatData = data => [
    ...data.general,
    ...data.receipt,
    ...data.lines.map((page, index) =>
      page.map(i => ({
        ...i,
        field: `Page ${index + 1} - ${i.field}`
      }))
    )
  ]

  const handleCancel = () =>
    dispatch(OverlayActions.hideDialog({ key: DialogKeys.VALIDATE_RECEIPT }))

  const reasonList = flatData(missingFieldsState).flat()

  const handleSubmit = async () => {
    await onOk(reasonList)
    handleCancel()
  }

  const canSubmit = reasonList.every(item => !!item.reason)

  return (
    <Modal
      title="Please review the following errors before approving"
      visible
      onOk={handleSubmit}
      onCancel={handleCancel}
      footer={[
        <Button key="back" type="primary" onClick={handleCancel}>
          Review Again
        </Button>,
        <Button
          key="submit"
          type="ghost"
          danger
          onClick={handleSubmit}
          disabled={!canSubmit}
        >
          Confirm & Approve
        </Button>
      ]}
    >
      <div className="h-5/6 overflow-scroll pb-14">
        {!isTotalMatch ? (
          <div
            className="w-full self-center text-red-600 mb-0.5 pb-2 border"
            style={{ borderBottomWidth: 0.5 }}
          >
            The total’s don’t match
          </div>
        ) : null}

        {missingFieldsState.receipt.map(({ field, reason }, index) => (
          <div
            key={field}
            className="flex w-full align-center justify-between mb-0.5 pb-2 pt-2 border"
            style={{ borderBottomWidth: 0.5 }}
          >
            <div className="self-center text-red-600">{field}</div>
            <Select
              placeholder={'Choose a reason'}
              value={reason}
              style={{ width: 200 }}
              getPopupContainer={triggerNode => triggerNode.parentNode}
              onChange={handleSelectReceiptReason('receipt', index)}
            >
              {bypassReasons.map((option, index) => (
                <Select.Option
                  key={`${index}_${option}_${field}`}
                  value={option}
                >
                  {option}
                </Select.Option>
              ))}
            </Select>
          </div>
        ))}

        <div>
          {missingFieldsState.lines.map((page, pageIdx) =>
            page.length ? (
              <div key={pageIdx}>
                <span>Page {pageIdx + 1}</span>
                {page.map(({ field, reason }, lineIdx) => (
                  <div
                    key={lineIdx}
                    className="flex w-full align-center justify-between mb-0.5 pb-2 border"
                    style={{ borderBottomWidth: 0.5 }}
                  >
                    <div className="self-center text-red-600">{field}</div>
                    <div>
                      <Select
                        placeholder="Choose a reason"
                        value={reason}
                        style={{
                          width: 200
                        }}
                        getPopupContainer={triggerNode =>
                          triggerNode.parentNode
                        }
                        onChange={handleSelectLineReason(
                          'lines',
                          lineIdx,
                          pageIdx
                        )}
                      >
                        {bypassReasons.map(option => (
                          <Select.Option key={option} value={option}>
                            {option}{' '}
                          </Select.Option>
                        ))}{' '}
                      </Select>
                    </div>
                  </div>
                ))}{' '}
              </div>
            ) : null
          )}
        </div>
      </div>
    </Modal>
  )
}

export default ValidateModal
