import moment from 'moment'
import { PendingReceiptType } from 'services/api/requests/notua-ii/approval'
import handleActionsImmer from 'services/integrations/immer'
import { RecordActions } from './actions'

export interface CombinedStates {
  record?: PendingReceiptType
}

export const initialState: CombinedStates = {
  record: null
}

export const combinedReducers = handleActionsImmer<CombinedStates>(
  {
    // record reducer
    [RecordActions.SET_RECORD]: (
      state: CombinedStates,
      newRecord: PendingReceiptType
    ) => {
      state.record = newRecord
    },
    [RecordActions.EDIT_PURCHASE_TIME]: (
      state: CombinedStates,
      newPurchaseTime: string | null
    ) => {
      // format date
      const timeReg = /(\d{2}:\d{2}:\d{2})/g

      state.record.receiptDateTime =
        state.record.receiptDateTime?.replace(timeReg, newPurchaseTime) ||
        moment(newPurchaseTime).format('YYYY-MM-DD HH:mm:ss')
    },
    [RecordActions.EDIT_PURCHASE_DATE]: (
      state: CombinedStates,
      newPurchaseDate: string | null
    ) => {
      // format time
      const dateReg = /\d{4}\-\d{2}\-\d{2}/g

      state.record.receiptDateTime =
        state.record.receiptDateTime?.replace(dateReg, newPurchaseDate) ||
        moment(newPurchaseDate).format('YYYY-MM-DD HH:mm:ss')
    },
    [RecordActions.EDIT_TOTAL]: (
      state: CombinedStates,
      newTotal: number | null
    ) => {
      state.record.total = newTotal
    },
    [RecordActions.EDIT_REJECTION_REASON]: (
      state: CombinedStates,
      newReason: string | null
    ) => {
      state.record.rejectionReason = newReason
    },
    [RecordActions.EDIT_ORDER_ID]: (
      state: CombinedStates,
      newOrderId: string | null
    ) => {
      state.record.orderId = newOrderId
    },
    [RecordActions.EDIT_USER_EMAIL]: (
      state: CombinedStates,
      newUserEmail: string | null
    ) => {
      state.record.messageTo = newUserEmail
    },
    [RecordActions.EDIT_RETAILER_EMAIL]: (
      state: CombinedStates,
      newRetailerEmail: string | null
    ) => {
      state.record.email = newRetailerEmail
    },
    [RecordActions.EDIT_RETAILER_NAME]: (
      state: CombinedStates,
      newRetailerValue: string | null
    ) => {
      state.record.name = newRetailerValue
    },
    //  reset
    [RecordActions.RESET_WORKING_STATE]: (state: CombinedStates) => {
      state.record = null
    }
  },
  initialState
)
