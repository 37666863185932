import { Modal } from 'antd'
import React from 'react'
import { useMutation } from 'react-query'
import { Noctua2ReceiptApi } from 'services/api/requests/notua-ii'

export const useHandleSubmitAnnotation = (): [
  boolean,
  (id, putBody) => Promise<any>
] => {
  const { mutateAsync, isLoading } = useMutation(
    Noctua2ReceiptApi.updateReceiptAnnotation
  )

  const handleSubmit = async (id, putBody) => {
    if (id) {
      await mutateAsync(
        { id, putBody },
        {
          onError: () => {
            Modal.error({
              title: 'Submission Error!',
              content: (
                <>
                  <p>
                    Something went wrong while submitting extracted receipt.
                  </p>
                  <p>Please contact support.</p>
                  <p>#Receipt Id: {id}</p>
                </>
              )
            })
          }
        }
      )
    } else {
      Modal.error({
        title: 'Error!',
        content: (
          <>
            <p>Something went wrong while trying to submit this receipt.</p>
            <p>Receipt not found.</p>
            <p>Please contact support.</p>
          </>
        )
      })
    }
  }

  return [isLoading, handleSubmit]
}

export const useHandleSkipAnnotation = (): [boolean, (id) => Promise<any>] => {
  const { mutateAsync, isLoading } = useMutation(
    Noctua2ReceiptApi.skipReceiptAnnotation
  )

  const handleSkip = async id => {
    if (id) {
      await mutateAsync(id, {
        onError: () => {
          Modal.error({
            title: 'Skip Error!',
            content: (
              <>
                <p>Something went wrong while trying to skip this receipt.</p>
                <p>Please contact support.</p>
                <p>#Receipt Id: {id}</p>
              </>
            )
          })
        }
      })
    } else {
      Modal.error({
        title: 'Error!',
        content: (
          <>
            <p>Something went wrong while trying to skip this receipt.</p>
            <p>Receipt not found.</p>
            <p>Please contact support.</p>
          </>
        )
      })
    }
  }

  return [isLoading, handleSkip]
}

export const useHandleRejectAnnotation = (): [
  boolean,
  (id, reason) => Promise<any>
] => {
  const { mutateAsync, isLoading } = useMutation(
    Noctua2ReceiptApi.rejectReceiptAnnotation
  )

  const handleReject = async (id, reason) => {
    if (id) {
      await mutateAsync(
        { id, reason },
        {
          onError: () => {
            Modal.error({
              title: 'Reject Error!',
              content: (
                <>
                  <p>Something went wrong while rejecting this receipt.</p>
                  <p>Please contact support.</p>
                  <p>#Receipt Id: {id}</p>
                </>
              )
            })
          }
        }
      )
    } else {
      Modal.error({
        title: 'Error!',
        content: (
          <>
            <p>Something went wrong while trying to reject this receipt.</p>
            <p>Receipt not found.</p>
            <p>Please contact support.</p>
          </>
        )
      })
    }
  }

  return [isLoading, handleReject]
}
