import axios from 'axios'
import {
  DBReceiptAnnotation,
  DBReceiptOverview
} from 'services/api/requests/types'
import config from 'services/config'
import {
  axiosReqInterceptor,
  axiosResInterceptor
} from 'services/integrations/axios'

const ReceiptApiNoctua2Client = axios.create({
  baseURL: config.baseUrl + 'noctua-ii/receipt/'
})

ReceiptApiNoctua2Client.interceptors.request.use(axiosReqInterceptor)
ReceiptApiNoctua2Client.interceptors.response.use(axiosResInterceptor)

export const getReceiptAnnotation = async (
  country: string,
  retailerFilter?: string
) => {
  const { data } = await ReceiptApiNoctua2Client.get<DBReceiptAnnotation>(
    `annotate/${country}${
      retailerFilter ? `/?merchant=${encodeURIComponent(retailerFilter)}` : ''
    }`
  )
  return data
}

export const getReceiptOverview = async () => {
  const { data } = await ReceiptApiNoctua2Client.get<DBReceiptOverview>(
    'overview'
  )
  return data
}

type updateProps = {
  id: string | number
  putBody: DBReceiptAnnotation
}
export const updateReceiptAnnotation = async ({ id, putBody }: updateProps) => {
  const { data } = await ReceiptApiNoctua2Client.put<DBReceiptAnnotation>(
    `approve/${id}`,
    putBody
  )
  return data
}

export const skipReceiptAnnotation = async (id: string | number) => {
  const { data } = await ReceiptApiNoctua2Client.get<DBReceiptAnnotation>(
    `skip/${id}`
  )
  return data
}

type rejectProps = {
  id: string | number
  reason: string
}
export const rejectReceiptAnnotation = async ({ id, reason }: rejectProps) => {
  const { data } = await ReceiptApiNoctua2Client.post<DBReceiptAnnotation>(
    `reject/${id}`,
    { reason }
  )
  return data
}

export const unlockReceipt = async () => {
  const { data } = await ReceiptApiNoctua2Client.post('unlock')
  return data
}
