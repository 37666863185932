enum BoxConstants {
  ADD_BOX = 'BOX/ADD_BOX',
  EDIT_LABEL = 'BOX/EDIT_LABEL',
  EDIT_TEXT = 'BOX/EDIT_TEXT',
  ADD_LINK = 'BOX/ADD_LINK',
  REMOVE_LINK = 'BOX/REMOVE_LINK',
  REMOVE_BOX = 'BOX/REMOVE_BOX',
  SET_ACTIVE_ID = 'BOX/SET_ACTIVE_ID',
  SET_TARGET_ID = 'BOX/SET_TARGET_ID',
  SET_RECEIPT_ID = 'BOX/SET_RECEIPT_ID',
  RESET_BOX_STATE = 'BOX/RESET_BOX_STATE'
}

export default BoxConstants
