import { QuestionCircleOutlined } from '@ant-design/icons'
import { Tooltip } from 'antd'
import BigNumber from 'bignumber.js'
import { BoxLabelEnum } from 'data/box'
import HotkeysTooltip from 'pages/app/extraction/version-2/task/footer/tooltips/hotkeys'
import ShortcutsTooltip from 'pages/app/extraction/version-2/task/footer/tooltips/shortcut'
import {
  getReceiptFields,
  getSummedTotal
} from 'pages/app/extraction/version-2/task/helpers'
import React from 'react'
import { useParams } from 'react-router'
import { DBReceiptAnnotation } from 'services/api/requests/types'
import { parseCurrency } from 'services/helpers/number'
import { Color } from 'services/styles/color'

interface TaskDetailsProps {
  data: DBReceiptAnnotation
}

const TaskDetails = ({ data }: TaskDetailsProps) => {
  const { countryCode } = useParams<{ countryCode: string }>()
  const minervaId = data.metadata.minerva_id
  const receiptInQueue =
    data.metadata[countryCode === 'AU' ? 'AU_pending' : 'US_pending']

  const receiptFields = getReceiptFields(data.pages)
  const receiptTotal = parseCurrency(
    receiptFields[BoxLabelEnum.RECEIPT_TOTAL_VALUE]?.text
  )
  const summedTotal = getSummedTotal(data.pages)

  const isTotalMatch = new BigNumber(receiptTotal).isEqualTo(
    new BigNumber(summedTotal)
  )

  let receiptTotalText = receiptTotal.toString()
  if (!isTotalMatch) {
    const receiptDifference = new BigNumber(receiptTotal)
      .minus(new BigNumber(summedTotal))
      .toNumber()
    receiptTotalText = `${summedTotal} (${-receiptDifference})`
  }

  return (
    <div className="flex items-center justify-between w-full mr-2">
      <div className="flex items-center">
        <Tooltip title={ShortcutsTooltip}>
          <QuestionCircleOutlined className="text-lg mr-4" />
        </Tooltip>
        <Tooltip title={HotkeysTooltip} overlayStyle={{ maxWidth: '500px' }}>
          <span className="underline">Hotkeys</span>
        </Tooltip>{' '}
        <span className="mx-2">|</span>
        <span>
          Receipt ID: <span className="font-bold">{minervaId}</span>
        </span>
        <span className="mx-2">|</span>
        <span>
          <span className="font-bold">{receiptInQueue}</span> more in queue
        </span>
        <span className="mx-2">|</span>
        <span>
          Receipt Total: <span className="font-bold">{receiptTotal}</span>
        </span>
      </div>
      <span className="text-right">
        Extracted Total:{' '}
        <span
          className="font-bold"
          style={{ color: isTotalMatch ? Color.SUCCESS : '#EF4444' }}
        >
          {receiptTotalText}
        </span>
      </span>
    </div>
  )
}
export default TaskDetails
