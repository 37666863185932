import { Button, message } from 'antd'
import React, { useContext } from 'react'
import { useMutation } from 'react-query'
import { Noctua2ApprovalApi } from 'services/api/requests/notua-ii'
import { ReceiptStatusEnum } from 'services/api/requests/notua-ii/approval'
import { useEventListener } from 'services/hooks/use-event-listener'
import { TaskContext } from '../context'
import { RecordActions } from '../reducers/actions'

const TaskFooter = () => {
  const { state: parentState, dispatchTask: parentDispatcher } = useContext(
    TaskContext
  )

  const handleDispatchTask = (action, value = null) =>
    parentDispatcher({ type: action, payload: value })

  // fetch next receipt
  const resetWorkingState = () =>
    handleDispatchTask(RecordActions.RESET_WORKING_STATE)

  const { record } = parentState

  const {
    total,
    name,
    orderId,
    messageTo,
    email,
    rejectionReason,
    status,
    id,
    receiptId,
    notes,
    receiptDateTime
    // vendorName,
    // purchaseTime
  } = record

  const {
    mutateAsync: mutateReceipt,
    isLoading: isPatchingReceipt
  } = useMutation(Noctua2ApprovalApi.updateEmailReceipt)

  // add validation like validateModal
  const handleApproveClick = async () => {
    if (!total && total !== 0) return message.error('Missing total')
    if (!receiptDateTime) return message.error('Missing receipt date time')
    if (!name) return message.error('Missing retailer name')
    if (!email) return message.error('Missing retailer email')
    if (!messageTo) return message.error('Missing user email')

    const updateRecordStatus = {
      ...record,
      reviewed: true,
      status: ReceiptStatusEnum.APPROVED
    }
    await mutateReceipt(updateRecordStatus)
    resetWorkingState()
  }
  const handleRejectClick = async () => {
    if (!rejectionReason) {
      return message.error('Missing rejection reason')
    }

    const updateRecordStatus = {
      ...record,
      reviewed: true,
      status: ReceiptStatusEnum.REJECTED
    }
    await mutateReceipt(updateRecordStatus)
    resetWorkingState()
  }

  const handleDeleteClick = async () => {
    const updateRecordStatus = {
      ...record,
      reviewed: true,
      status: ReceiptStatusEnum.DELETED
    }
    await mutateReceipt(updateRecordStatus)
    resetWorkingState()
  }

  const handleMissingUserClick = async () => {
    const updateRecordStatus = {
      ...record,
      reviewed: true,
      status: ReceiptStatusEnum.USER_EMAIL_MISSING
    }
    await mutateReceipt(updateRecordStatus)
    resetWorkingState()
  }

  // "Enter" to approve receipt
  const handleEventChange = async ({ key }) => {
    if (key === 'Enter') {
      await handleApproveClick()
    }
  }

  useEventListener('keydown', handleEventChange)

  return (
    <div className="flex items-center self-end">
      <CTAButtons
        isApproving={isPatchingReceipt}
        isRejecting={isPatchingReceipt}
        onApproveClick={handleApproveClick}
        onRejectClick={handleRejectClick}
      />
      <SkipButtons
        isSkipping={isPatchingReceipt}
        isSubmittingMissingUser={isPatchingReceipt}
        onSkipClick={handleDeleteClick}
        onMissingUserClick={handleMissingUserClick}
      />
    </div>
  )
}

const CTAButtons = ({
  isApproving,
  isRejecting,
  onApproveClick,
  onRejectClick
}) => (
  <>
    <Button
      className="w-56 mx-2"
      loading={isApproving}
      type="primary"
      onClick={async () => await onApproveClick()}
      block={true}
      size="large"
    >
      Approve
    </Button>

    <Button
      className="w-56 mx-2"
      loading={isRejecting}
      type="ghost"
      danger
      onClick={async () => await onRejectClick()}
      block={true}
      size="large"
    >
      Reject
    </Button>
  </>
)

const SkipButtons = ({
  isSkipping,
  isSubmittingMissingUser,
  onSkipClick,
  onMissingUserClick
}) => (
  <>
    <Button
      className="w-56 mx-2"
      loading={isSkipping}
      type="ghost"
      onClick={async () => await onSkipClick()}
      block
      size="large"
    >
      Delete (not receipt)
    </Button>
    <Button
      className="w-56 mx-2"
      loading={isSubmittingMissingUser}
      type="ghost"
      onClick={async () => await onMissingUserClick()}
      block
      size="large"
    >
      Missing user email
    </Button>
  </>
)

export default TaskFooter
