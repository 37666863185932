import WoolworthsAU from 'pages/app/extraction/version-2/task/definitions/au/woolworths'
import { BaseMerchantDefinition } from 'pages/app/extraction/version-2/task/definitions/types'

const MerchantDefinitions = {
  AU: {
    WOOLWORTHS: WoolworthsAU
  }
}

const getMerchantDefinition = (countryCode: string, merchantName: string) => {
  return (
    MerchantDefinitions?.[countryCode]?.[merchantName] || BaseMerchantDefinition
  )
}

export default getMerchantDefinition
