import { PermissionEnum } from 'data/reducers'
import { ForbiddenPage } from 'pages/app'
import { AuditHomePage, AuditUserPage } from 'pages/app/audit'
import React from 'react'
import { AppSubRoutes } from 'routes/app/routes'
import useHandleRequirePermissions from 'services/hooks/use-handle-require-permission'
import SentryRoute from 'services/sentry/sentry-route'

const AuditRoutesNavigator = () => {
  const hasPermission = useHandleRequirePermissions([PermissionEnum.ADMIN])
  return (
    <>
      <SentryRoute
        exact
        path={AppSubRoutes.AUDIT.HOME.pathname}
        component={hasPermission ? AuditHomePage : ForbiddenPage}
      />
      <SentryRoute
        exact
        path={AppSubRoutes.AUDIT.USER.pathname}
        component={hasPermission ? AuditUserPage : ForbiddenPage}
      />
    </>
  )
}

export default AuditRoutesNavigator
