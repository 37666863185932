import { Button, Tag } from 'antd'
import { AuditActions } from 'data/actions'
import { AuditSelectors } from 'data/selectors'
import React from 'react'
import { useDispatch, useSelector } from 'react-redux'

const AuditFooter = ({ type, setType, isLoading }) => {
  const dispatch = useDispatch()
  const receiptFilter = useSelector(AuditSelectors.receiptFilter)
  const receiptsList = useSelector(AuditSelectors.receiptIdsList)
  const labelsList = useSelector(AuditSelectors.labelsIdsList)

  const currentList = type === 'LABEL' ? labelsList : receiptsList

  const currentIndex = useSelector(AuditSelectors.currentIndex)

  const handleGoToIndex = (index: number) => () => {
    if (!isLoading && !receiptFilter) {
      dispatch(AuditActions.setCurrentIndex(index))
    }
  }

  return (
    <div className="flex justify-between w-full items-center justify-center">
      <div>
        {currentList.map((id, index) => (
          <Tag
            key={id}
            className="cursor-pointer"
            color={
              !receiptFilter && currentList?.[currentIndex] === id
                ? 'purple'
                : 'blue'
            }
            onClick={handleGoToIndex(index)}
          >
            #{id}
          </Tag>
        ))}
      </div>
      <div className="flex flex-row">
        <Button
          loading={isLoading}
          disabled={!receiptsList.length || type === 'EXTRACTION'}
          onClick={() => {
            if (!receiptFilter) {
              setType('EXTRACTION')
              handleGoToIndex(0)
            }
          }}
          size="large"
          className="text-white w-32 rounded bg-blue-400 flex items-center justify-center"
        >
          Extraction
        </Button>
        <Button
          loading={isLoading}
          disabled={!labelsList.length || type === 'LABEL'}
          onClick={() => {
            if (!receiptFilter) {
              setType('LABEL')
              handleGoToIndex(0)
            }
          }}
          size="large"
          className="text-white w-32 rounded bg-blue-400 flex items-center justify-center"
        >
          Annotation
        </Button>
      </div>
    </div>
  )
}

export default AuditFooter
