import { message } from 'antd'
import { AppSelectors } from 'data/selectors'
import { TaskContext } from 'pages/app/annotation-tool/task/context'
import React, {
  Dispatch,
  SetStateAction,
  useContext,
  useEffect,
  useState
} from 'react'
import { useSelector } from 'react-redux'
import Selecto, { OnSelect } from 'react-selecto'
import { DBReceiptAnnotation } from 'services/api/requests/types'
import { useEventListener } from 'services/hooks/use-event-listener'
import { HEADER_HEIGHT, SIDER_WIDTH } from 'services/styles/layout'
import { MouseEventActions } from '../../../../reducers/mouse-event/actions'
import { RecordActions } from '../../../../reducers/record/actions'
import { verticesToId } from '../../../helpers'
import { SelectableAreaEnum } from '../../../types'

type ZoomState = {
  positionX?: number
  positionY?: number
  previousScale?: number
  scale?: number
}

interface Props {
  zoomState?: ZoomState
  dragContainer: string
  selectableTargets: SelectableAreaEnum[]
  setSelectableTargets: Dispatch<SetStateAction<SelectableAreaEnum[]>>
}

const SelectableArea = ({
  dragContainer,
  zoomState,
  selectableTargets,
  setSelectableTargets
}: Props) => {
  const isSiderOpen = useSelector(AppSelectors.siderState)

  const { state: parentState, dispatchTask: parentDispatcher } = useContext(
    TaskContext
  )

  const { record, currentPage, mode } = parentState
  const { editingText } = mode

  const handleDispatchTask = (action, value) =>
    parentDispatcher({ type: action, payload: value })

  const [draggable, setDraggable] = useState(true)
  const [createMode, setCreateMode] = useState(false)

  const _handleDataChange = newData =>
    handleDispatchTask(RecordActions.SET_RECORD, newData)

  const _handleUpdatePopupList = newList =>
    handleDispatchTask(MouseEventActions.SET_POPUP_LIST, newList)

  const _formPopupList = (elements: Element[]) =>
    elements.map(item => {
      if (
        item.classList.contains('last-box') ||
        item.classList.contains('invalid-box')
      ) {
        return true
      }
      if (item.classList.contains('selected')) {
        return false
      }
      return null
    })

  const handleUpdateInvalidList = () => {
    const textBoxList = Array.from(
      document.querySelectorAll(SelectableAreaEnum.TOKEN)
    )

    const newPopupList = _formPopupList(textBoxList)

    _handleUpdatePopupList(newPopupList)
  }

  const handleUpdateSelectedList = () => {
    const textBoxList = Array.from(
      document.querySelectorAll(selectableTargets[0])
    )

    const newPopupList = _formPopupList(textBoxList)

    _handleUpdatePopupList(newPopupList)
  }

  const handleOnStart = (e: OnSelect) =>
    e.currentTarget.getSelectableElements().forEach(el => {
      el.classList.remove('last-box')
    })

  const handleOnEnd = (e: OnSelect) => {
    const { rect } = e
    const { positionX, positionY, scale } = zoomState
    const accountForSider = isSiderOpen ? SIDER_WIDTH : 0

    const newBoxVertices = [
      {
        x: (rect.left - positionX - accountForSider) / scale,
        y: (rect.top - positionY - HEADER_HEIGHT) / scale
      },
      {
        x: (rect.right - positionX - accountForSider) / scale,
        y: (rect.top - positionY - HEADER_HEIGHT) / scale
      },
      {
        x: (rect.right - positionX - accountForSider) / scale,
        y: (rect.bottom - positionY - HEADER_HEIGHT) / scale
      },
      {
        x: (rect.left - positionX - accountForSider) / scale,
        y: (rect.bottom - positionY - HEADER_HEIGHT) / scale
      }
    ]
    const newBox = {
      id: verticesToId(newBoxVertices),
      boundingPoly: {
        vertices: newBoxVertices
      },
      text: ''
    }
    if (createMode) {
      const updatedPage = record.pages.map((page, index) =>
        index === currentPage
          ? {
              ...page,
              words: [...page.words, newBox]
            }
          : page
      )

      const currentExtractedData = {
        ...record,
        pages: updatedPage
      } as DBReceiptAnnotation

      _handleDataChange(currentExtractedData)

      handleUpdateInvalidList()
    } else {
      e.added.forEach((el, index) => {
        if (index === e.added.length - 1) {
          el.classList.add('last-box')
        }
      })

      handleUpdateSelectedList()
    }
  }

  // add selected class which change color of boxes
  const handleOnSelect = (e: OnSelect) => {
    e.added.forEach(el => {
      el.classList.add('selected')
    })
    e.removed.forEach(el => {
      el.classList.remove('selected')
    })
  }

  // prevent drag when in move cursor mode.
  const keydownEventHandler = ({ key }: { key: string }) => {
    const keydown = String(key)
    if (keydown === '+' || keydown === '-' || keydown === ' ') {
      setDraggable(false)
    }

    if (keydown === '`' && !editingText) {
      setCreateMode(true)
      setSelectableTargets([])
    }

    if (keydown.toLowerCase() === 'o' && !editingText) {
      setSelectableTargets([SelectableAreaEnum.HIGHLIGHT])
    }
  }

  const invalidBoxes = Array.from(
    document.querySelectorAll(SelectableAreaEnum.TOKEN)
  ).find(i => i.classList.contains('invalid-box'))

  const keyupEventHandler = () => {
    !invalidBoxes && setDraggable(true)
    setCreateMode(false)
    setSelectableTargets([SelectableAreaEnum.TOKEN])
  }

  useEffect(() => {
    if (createMode) {
      message.open({
        key: 'create-mode',
        type: 'info',
        content: 'Drag your mouse to create a new box',
        duration: 0
      })
    } else {
      message.destroy('create-mode')
    }
  }, [createMode])

  useEffect(() => {
    if (invalidBoxes && draggable) {
      setDraggable(false)
    }

    if (!invalidBoxes && !draggable) {
      setDraggable(true)
    }
  }, [invalidBoxes])

  useEventListener('keydown', keydownEventHandler)
  useEventListener('keyup', keyupEventHandler)

  return (
    <Selecto
      dragCondition={() => draggable}
      dragContainer={dragContainer}
      selectableTargets={selectableTargets}
      hitRate={2}
      selectByClick
      selectFromInside
      toggleContinueSelect={'shift'}
      ratio={0}
      onSelectStart={handleOnStart}
      onSelect={handleOnSelect}
      onSelectEnd={handleOnEnd}
    />
  )
}

export default SelectableArea
