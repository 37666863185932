import { FilterOutlined, SortAscendingOutlined } from '@ant-design/icons'
import { Button, Dropdown, Input } from 'antd'
import Table from 'components/table'
import React, { ReactNode, useState } from 'react'
import { DBReceiptOverview } from 'services/api/requests/notua-ii/types'
import { Color } from 'services/styles/color'

interface Props {
  data: DBReceiptOverview
}

interface MenuItemProps {
  actionComponent: ReactNode | string
}

enum SortEnum {
  COUNTRY_CODE = 'COUNTRY_CODE',
  RETAILER = 'RETAILER',
  COUNTRY_CODE_DESC = 'COUNTRY_CODE_DESC',
  RETAILER_DESC = 'RETAILER_DESC'
}

const SortEnumToField = {
  [SortEnum.COUNTRY_CODE]: 'countryCode',
  [SortEnum.COUNTRY_CODE_DESC]: 'countryCode',
  [SortEnum.RETAILER]: 'merchant',
  [SortEnum.RETAILER_DESC]: 'merchant'
}

const MenuItem = ({ actionComponent }: MenuItemProps) => (
  <div className={'my-1'}>{actionComponent}</div>
)

const AnnotationOverviewTable = ({ data }: Props) => {
  const [filterProps, setFilterProps] = useState({
    countryCode: '',
    merchant: ''
  })

  const [sort, setSort] = useState(SortEnum.RETAILER)

  const CountryFilterMenu = (
    <div className={'rounded-lg bg-white border border-gray-400 border-solid'}>
      <div className={'p-2'}>
        <MenuItem
          actionComponent={
            <Input
              onClick={e => {
                e.preventDefault()
                e.stopPropagation()
              }}
              placeholder="Filter country..."
              onChange={e =>
                setFilterProps(prev => ({
                  ...prev,
                  countryCode: e.target.value
                }))
              }
            />
          }
        />
      </div>
    </div>
  )

  const RetailersFilterMenu = (
    <div className={'rounded-lg bg-white border border-gray-400 border-solid'}>
      <div className={'p-2'}>
        <MenuItem
          actionComponent={
            <Input
              onClick={e => {
                e.preventDefault()
                e.stopPropagation()
              }}
              placeholder="Filter retailer..."
              onChange={e =>
                setFilterProps(prev => ({
                  ...prev,
                  merchant: e.target.value
                }))
              }
            />
          }
        />
      </div>
    </div>
  )

  const filteredData = data.filter(
    i =>
      i.countryCode
        .toLowerCase()
        .includes(filterProps.countryCode.toLowerCase()) &&
      i.merchant.toLowerCase().includes(filterProps.merchant.toLowerCase())
  )

  const sortedData =
    sort !== null
      ? filteredData.sort((a, b) => {
          let subject = a
          let object = b
          if (sort.includes('_DESC')) {
            subject = b
            object = a
          }
          return subject[SortEnumToField[sort]].localeCompare(
            object[SortEnumToField[sort]]
          )
        })
      : filteredData

  const handleToggleSort = sortKey =>
    setSort(prev => {
      if (prev === sortKey) {
        return `${prev}_DESC`
      }
      const cleanKey = prev?.replace(/(_DESC)/g, '')
      if (cleanKey === sortKey) {
        return null
      }

      return sortKey
    })

  return (
    <Table>
      <Table.Header
        titles={[
          <div>
            Country
            <Dropdown
              overlay={CountryFilterMenu}
              trigger={['click']}
              className="cursor-pointer"
              overlayStyle={{ width: 200 }}
            >
              <FilterOutlined className="px-2" />
            </Dropdown>
            <SortAscendingOutlined
              className={`cursor-pointer ${
                sort === SortEnum.COUNTRY_CODE ? 'text-blue-400' : 'text-black'
              } ${
                sort === SortEnum.COUNTRY_CODE_DESC
                  ? 'text-red-400'
                  : 'text-black'
              }`}
              onClick={() => handleToggleSort(SortEnum.COUNTRY_CODE)}
            />
          </div>,
          <div>
            Retailer
            <Dropdown
              overlay={RetailersFilterMenu}
              trigger={['click']}
              className="cursor-pointer"
              overlayStyle={{ width: 200 }}
            >
              <FilterOutlined className="px-2" />
            </Dropdown>
            <SortAscendingOutlined
              className={`cursor-pointer ${
                sort === SortEnum.RETAILER ? 'text-blue-400' : 'text-black'
              } ${
                sort === SortEnum.RETAILER_DESC ? 'text-red-400' : 'text-black'
              }`}
              onClick={() => handleToggleSort(SortEnum.RETAILER)}
            />
          </div>,
          'Completed / Target',
          'Mapping'
        ]}
      />
      <tbody>
        {(filterProps.countryCode || filterProps.merchant) && (
          <Table.Row rowStyle={{ backgroundColor: 'transparent' }}>
            <Table.Cell
              label={filterProps.countryCode}
              cellStyle={{ color: Color.GREY_400 }}
            />
            <Table.Cell
              label={filterProps.merchant}
              cellStyle={{ color: Color.GREY_400 }}
            />
            <Table.Cell label={' '} />
            <Table.Cell label={' '} />
            <Table.Cell label={' '} />
          </Table.Row>
        )}
        {sortedData.map(
          ({
            countryCode,
            merchant,
            complete,
            target,
            tutorial_url,
            pending
          }) => {
            const isCompleted = complete >= target
            const isNotReady = pending === 0
            const completeColor =
              isCompleted || isNotReady ? 'text-green-400' : 'text-black'

            const handleReviewClick = url => () => window.open(url, '_blank')

            return (
              <Table.Row key={`${countryCode}_${merchant}`}>
                <Table.Cell label={countryCode} />
                <Table.Cell label={merchant} />
                <Table.Cell
                  render={
                    <span>
                      <span className={completeColor}>{complete}</span> /{' '}
                      <span className="text-gray-400">{target}</span>
                    </span>
                  }
                />
                <Table.Cell
                  render={
                    tutorial_url ? (
                      <Button
                        type="link"
                        onClick={handleReviewClick(tutorial_url)}
                      >
                        Review
                      </Button>
                    ) : null
                  }
                />
              </Table.Row>
            )
          }
        )}
      </tbody>
    </Table>
  )
}

export default AnnotationOverviewTable
