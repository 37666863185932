export const cleanTrailingComma = (tex: string): string =>
  tex
    .split(',')
    .reduce((acc, curr) => {
      const trimmedItem = curr.trim()
      return trimmedItem.length ? [...acc, trimmedItem] : acc
    }, [])
    .toString()

// Search substring in a string
// e.g stringIsEqual("FullText","lte") -> true
export const isSubStringOf = (target: string, searchText: string): boolean =>
  target.toUpperCase().indexOf(String(searchText).toUpperCase()) !== -1
export const compareCaseInsensitive = (string1: string, string2: string) =>
  string1.toUpperCase().trim() === string2.toUpperCase().trim()
