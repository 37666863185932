import * as Sentry from '@sentry/react'
import { ConfigProvider } from 'antd'
import AuthIsLoaded from 'components/auth'
import SplashScreen from 'components/splash'
import { persistor, store } from 'data'
import { createBrowserHistory } from 'history'
import React from 'react'
import { QueryClientProvider } from 'react-query'
import { Provider } from 'react-redux'
import { ReactReduxFirebaseProvider } from 'react-redux-firebase'
import { Router } from 'react-router-dom'
import { PersistGate } from 'redux-persist/integration/react'
import MainRoutesNavigator from 'routes'
import queryClient from 'services/react-query'
import rrfProps from 'services/redux-firebase'
import { initSentry } from 'services/sentry'
import './App.less'

export const browserHistory = createBrowserHistory()

initSentry(browserHistory)

const App = () => (
  <QueryClientProvider client={queryClient}>
    <Router history={browserHistory}>
      <Provider store={store}>
        <PersistGate loading={<SplashScreen />} persistor={persistor}>
          <ReactReduxFirebaseProvider {...rrfProps}>
            <ConfigProvider //  @ts-ignore
              getPopupContainer={_ => document.getElementById('popup')}
            >
              <div
                className="sl"
                id="popup"
                style={{ position: 'absolute', zIndex: 999999 }}
              />
              <AuthIsLoaded>
                <MainRoutesNavigator />
              </AuthIsLoaded>
            </ConfigProvider>
          </ReactReduxFirebaseProvider>
        </PersistGate>
      </Provider>
    </Router>
  </QueryClientProvider>
)

export default Sentry.withErrorBoundary(App, {
  fallback: 'An error has occurred'
})
