import { Dropdown, Select } from 'antd'
import TextArea from 'antd/lib/input/TextArea'
import { BoxLabelEnum, boxLabelsList } from 'data/box'
import React, { ReactNode } from 'react'
import { Field, Word } from 'services/api/requests/types'
import {
  getBoxColor,
  getBoxColorDarker,
  getBoxColorLight
} from 'services/styles/color'

interface MenuItemProps {
  title: string
  actionComponent?: ReactNode | string
  valueComponent?: ReactNode | string
}

interface ILabelSelectMenu {
  value: string
  handleOnChange?: () => void
}

const LabelSelectMenu = ({ value, handleOnChange }: ILabelSelectMenu) => {
  const formatText = (label: string) =>
    label === BoxLabelEnum.ITEM_CODE ? 'Barcode/SKU' : label

  return (
    <Select
      style={{ borderRadius: 99 }}
      className="w-full"
      value={value}
      onChange={handleOnChange}
      onClick={event => event.stopPropagation()}
    >
      {boxLabelsList.map(label => (
        <Select.Option key={label} value={label}>
          {formatText(label)}
        </Select.Option>
      ))}
    </Select>
  )
}

const MenuItem = ({
  title,
  actionComponent,
  valueComponent
}: MenuItemProps) => (
  <div className={'my-1'}>
    <div className={'flex flex-row justify-between items-center'}>
      <span className={'px-4 font-semibold text-gray-500'}>{title}</span>
      {actionComponent}
    </div>
    <div className={'px-4'}>{valueComponent}</div>
  </div>
)

interface FieldBoxProps {
  fields: Field[]
  words: Word[]
  field: Field
}

const FieldBox = ({ fields, words, field }: FieldBoxProps) => {
  const { label, boundingPoly, fieldElements, text, links, id } = field

  const menu = (
    <div className={'rounded-lg bg-white'}>
      <div className={'py-2'}>
        <MenuItem
          title={'LABEL'}
          valueComponent={<LabelSelectMenu value={label} />}
        />{' '}
        <MenuItem
          title={'TEXT'}
          valueComponent={
            <TextArea
              onClick={event => event.stopPropagation()}
              className={'rounded-sm'}
              value={text}
              autoSize={{ minRows: 1 }}
            />
          }
        />{' '}
        <MenuItem
          title={'LINKS'}
          valueComponent={links.map((link, index) => {
            const linkedBox = fields.find(item => item.id === link)
            const borderColor = getBoxColor(linkedBox?.label)
            const backgroundColor = getBoxColorLight(linkedBox?.label)
            return (
              <div
                key={`${link}_${index}`}
                className="rounded-sm my-0.5 px-3"
                style={{
                  border: `1px solid
  ${borderColor}`,
                  backgroundColor
                }}
              >
                {' '}
                {linkedBox?.label}:{linkedBox?.text}{' '}
              </div>
            )
          })}
        />
      </div>
    </div>
  )

  const renderLabeledWords = () =>
    fieldElements.map((elementId, index) => {
      const wordBox = words.find(word => word.id === elementId)
      if (!wordBox) return null
      const [
        { x: x0, y: y0 },
        { x: x1, y: y1 },
        { x: x2, y: y2 },
        { x: x3, y: y3 }
      ] = wordBox.boundingPoly.vertices

      return (
        <path
          key={`${elementId}_${index}`}
          className="z-20 cursor-pointer opacity-50 absolute"
          d={`M${x0},${y0}
       L${x1},${y1}
       L${x2},${y2} 
       L${x3},${y3}z`}
          fill={getBoxColorLight(label)}
        />
      )
    })

  return (
    <Dropdown
      placement="bottomCenter"
      overlay={menu}
      overlayStyle={{ width: '12rem', minWidth: 'min-content' }}
      trigger={['contextMenu']}
    >
      <g className="highlight" stroke={getBoxColorDarker(label)}>
        {renderLabeledWords()}
      </g>
    </Dropdown>
  )
}

export default FieldBox
