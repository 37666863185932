import React from 'react'

const Hotkeys = () => {
  // from the injected scripts
  const labelMap = {
    1: 'Name',
    2: 'Address',
    q: 'Membership id',
    w: 'Phone',
    e: 'Email',
    r: 'Username'
  }

  return (
    <>
      <p style={{ borderBottom: '1px solid grey' }}>HotKeys</p>
      <table className="table-auto tooltip-table">
        <thead>
          <tr>
            {Object.values(labelMap).map(label => (
              <th key={label}>{label}</th>
            ))}
          </tr>
        </thead>
        <tbody>
          <tr>
            {Object.keys(labelMap).map(hotkey => (
              <td key={hotkey}>{hotkey}</td>
            ))}
          </tr>
        </tbody>
      </table>
    </>
  )
}

export default Hotkeys
