import { BoxActions, BoxLabelEnum } from 'data/box'
import { BoxSelectors, ExtractionSelectors } from 'data/selectors'
import LineItemsSection from 'pages/app/extraction/version-1/task/components/line-items-section'
import MerchantSection from 'pages/app/extraction/version-1/task/components/merchant-section'
import OtherInfoSection from 'pages/app/extraction/version-1/task/components/other-info-section'
import {
  filterHighLightsProperties,
  sortHighLightsByPosition
} from 'pages/app/extraction/version-1/task/helpers'
import React, { useEffect } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { scrollIntoView } from 'services/helpers/dom'

const TaskContent = () => {
  const dispatch = useDispatch()
  const boxes = useSelector(BoxSelectors.boxes)
  const targetBoxId = useSelector(BoxSelectors.targetId)
  const currentPage = String(useSelector(ExtractionSelectors.currentPage))
  const settings = useSelector(ExtractionSelectors.settings)
  const sumTax = useSelector(BoxSelectors.sumTax)
  const summary = useSelector(BoxSelectors.summary)

  // for total and subtotal
  const summaryOfPages = useSelector(
    ExtractionSelectors.summaryOfPages(currentPage, summary)
  )

  // sum tax of pages
  const sumTaxOfPages = useSelector(
    ExtractionSelectors.sumTaxOfPages(currentPage, sumTax)
  )

  const importantFields = useSelector(ExtractionSelectors.importantFields) // final
  const extractedImportantFields = useSelector(BoxSelectors.importantFields) // editing

  // TODO clean all these to summaryOfPages
  const mergedImportantFields = {
    ...importantFields,
    ...extractedImportantFields,

    [BoxLabelEnum.RECEIPT_TOTAL_VALUE]: {
      text: summaryOfPages?.[BoxLabelEnum.RECEIPT_TOTAL_VALUE] || 0,
      id:
        extractedImportantFields?.[BoxLabelEnum.RECEIPT_TOTAL_VALUE]?.id ||
        importantFields?.[BoxLabelEnum.RECEIPT_TOTAL_VALUE]?.id
    },
    [BoxLabelEnum.RECEIPT_TOTAL_TAX]: {
      text: sumTaxOfPages,
      id: undefined
    }
  }

  const handleSetTargetId = (id: string) => dispatch(BoxActions.setTargetId(id))

  // need separate line and promo to remove duplication based on first condition
  const lineItemEntries = filterHighLightsProperties(boxes, [
    BoxLabelEnum.ITEM_DESCRIPTION,
    BoxLabelEnum.ITEM_VALUE,
    BoxLabelEnum.ITEM_QUANTITY,
    BoxLabelEnum.ITEM_CODE
  ])

  const promoEntries = filterHighLightsProperties(boxes, [
    BoxLabelEnum.ITEM_PROMO_NAME,
    BoxLabelEnum.ITEM_PROMO_PRICE
  ])

  const mergeLineItems = sortHighLightsByPosition([
    ...lineItemEntries,
    ...promoEntries
  ])

  const missingLineItems = mergeLineItems.filter(
    item =>
      !(
        ((settings.quantity ? BoxLabelEnum.ITEM_QUANTITY in item : true) &&
          (settings.barcode ? BoxLabelEnum.ITEM_CODE in item : true) &&
          BoxLabelEnum.ITEM_DESCRIPTION in item &&
          (settings.price ? BoxLabelEnum.ITEM_VALUE in item : true)) ||
        BoxLabelEnum.ITEM_PROMO_NAME in item
      )
  )

  // // auto select missing item
  useEffect(() => {
    // set first line in missing line as active id
    if (settings.autoFocus) {
      const newTargetId = missingLineItems?.[0]?.key[0]
      if (targetBoxId !== newTargetId) {
        !settings.linkMode && handleSetTargetId(newTargetId)
      }
    } else if (!settings.linkMode) {
      if (targetBoxId) {
        handleSetTargetId(undefined)
      }
    }
  }, [missingLineItems.length, settings.autoFocus])

  useEffect(() => {
    if (targetBoxId) {
      scrollIntoView(String(targetBoxId))
    }
  }, [targetBoxId])

  return (
    <div className="px-4 pb-4">
      <MerchantSection mergedImportantFields={mergedImportantFields} />
      <LineItemsSection
        missingLineItems={missingLineItems}
        mergeLineItems={mergeLineItems}
      />
      <OtherInfoSection mergedImportantFields={mergedImportantFields} />
    </div>
  )
}

export default TaskContent
