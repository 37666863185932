import { LockFilled } from '@ant-design/icons'
import { Button, Tooltip } from 'antd'
import Table from 'components/table'
import { AppActions } from 'data/actions'
import { AppSelectors, AuthSelectors } from 'data/selectors'
import React from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { useHistory } from 'react-router'
import { AppSubRoutes } from 'routes/app/routes'
import { DBReceiptOverview } from 'services/api/requests/types'
import { checkAllPermissions } from 'services/hooks/use-handle-require-permission'

interface Props {
  data: DBReceiptOverview
}

const TasksTable = ({ data }: Props) => {
  const dispatch = useDispatch()
  const history = useHistory()
  const isSiderOpen = useSelector(AppSelectors.siderState)
  const profile = useSelector(AuthSelectors.firebaseProfile)

  const formattedData = data
    ? Object.entries(data).map(([country, value]) => ({
        country,
        ...value
      }))
    : []

  const handleNavigationClick = (country: string) => () => {
    if (isSiderOpen) dispatch(AppActions.toggleSider())
    history.push(AppSubRoutes.RECEIPT_REVIEW.HOME.pathname + `/${country}`)
  }

  return (
    <Table>
      <Table.Header titles={['Country', '']} />
      <tbody>
        {formattedData.map(({ country, requirePermissions }) => {
          const isBlocked = !checkAllPermissions(
            profile.permissions,
            requirePermissions
          )
          return (
            <Table.Row key={country}>
              <Table.Cell
                label={country}
                render={
                  isBlocked ? (
                    <span className="flex items-center flex-row text-gray-400">
                      {country} <LockFilled className="ml-1" />
                    </span>
                  ) : (
                    country
                  )
                }
              />
              <Table.Cell
                cellStyle={{ textAlign: 'right' }}
                render={
                  <Tooltip
                    title={
                      isBlocked && "You don't have permission for this workflow"
                    }
                  >
                    <Button
                      type="primary"
                      disabled={isBlocked}
                      onClick={handleNavigationClick(country)}
                      size="large"
                      className={'text-white rounded mr-2'}
                    >
                      Start
                    </Button>
                  </Tooltip>
                }
              />
            </Table.Row>
          )
        })}
      </tbody>
    </Table>
  )
}

export default TasksTable
