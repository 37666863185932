import { PermissionEnum } from 'data/reducers'
import { ForbiddenPage } from 'pages/app'
import {
  ReceiptReviewEmailTaskPage,
  ReceiptReviewHomePage,
  ReceiptReviewTaskPage
} from 'pages/app/receipt-review'
import React from 'react'
import { Switch } from 'react-router'
import { AppSubRoutes } from 'routes/app/routes'
import useHandleRequirePermissions from 'services/hooks/use-handle-require-permission'
import SentryRoute from 'services/sentry/sentry-route'

const ReceiptReviewRoutesNavigator = () => {
  const hasOfflinePermission = useHandleRequirePermissions([
    PermissionEnum.OFFLINE
  ])

  const hasEmailPermission = useHandleRequirePermissions([PermissionEnum.EMAIL])

  return (
    <Switch>
      <SentryRoute
        exact
        path={AppSubRoutes.RECEIPT_REVIEW.HOME.pathname}
        component={
          hasEmailPermission || hasOfflinePermission
            ? ReceiptReviewHomePage
            : ForbiddenPage
        }
      />
      <SentryRoute
        exact
        path={AppSubRoutes.RECEIPT_REVIEW.EMAIL.pathname}
        component={
          hasEmailPermission ? ReceiptReviewEmailTaskPage : ForbiddenPage
        }
      />
      <SentryRoute
        exact
        path={AppSubRoutes.RECEIPT_REVIEW.TASK.pathname}
        component={hasOfflinePermission ? ReceiptReviewTaskPage : ForbiddenPage}
      />
    </Switch>
  )
}

export default ReceiptReviewRoutesNavigator
