import Divider from 'components/divider'
import {
  AnnotationHotkeys,
  AnnotationLabelEnum
} from 'pages/app/annotation-tool/task/types'
import React from 'react'

const AnnotationHotkeysTooltip = () => (
  <>
    <p style={{ borderBottom: '1px solid grey' }}>AnnotationHotkeys</p>
    <Divider text1={'Merchant'} />
    <table className="table-auto tooltip-table">
      <thead>
        <tr>
          <th>Store Name</th>
        </tr>
      </thead>
      <tbody>
        <tr>
          <td>{AnnotationHotkeys[AnnotationLabelEnum.RECEIPT_STORE_NAME]}</td>
        </tr>
      </tbody>
    </table>
    <Divider text1={'Line'} />

    <table className="table-auto tooltip-table">
      <thead>
        <tr>
          <th>Name</th>
          <th>Quantity</th>
          <th>Unit Price</th>
          <th>Total Price</th>
          <th>Product ID</th>
          <th>Reg Price</th>
        </tr>
      </thead>
      <tbody>
        <tr>
          <td>{AnnotationHotkeys[AnnotationLabelEnum.ITEM_DESCRIPTION]}</td>
          <td>{AnnotationHotkeys[AnnotationLabelEnum.ITEM_QUANTITY]}</td>
          <td>{AnnotationHotkeys[AnnotationLabelEnum.ITEM_UNIT_PRICE]}</td>
          <td>{AnnotationHotkeys[AnnotationLabelEnum.ITEM_PRICE]}</td>
          <td>{AnnotationHotkeys[AnnotationLabelEnum.ITEM_CODE]}</td>
          <td>{AnnotationHotkeys[AnnotationLabelEnum.ITEM_REG_PRICE]}</td>
        </tr>
      </tbody>
    </table>

    <Divider text1={'Total'} />

    <table className="table-auto tooltip-table">
      <thead>
        <tr>
          <th>Total Discount</th>
          <th>Tax</th>
          <th>Summed tax</th>
        </tr>
      </thead>
      <tbody>
        <tr>
          <td>
            {AnnotationHotkeys[AnnotationLabelEnum.RECEIPT_TOTAL_DISCOUNT]}
          </td>
          <td>{AnnotationHotkeys[AnnotationLabelEnum.RECEIPT_TOTAL_TAX]}</td>
          <td>
            {AnnotationHotkeys[AnnotationLabelEnum.RECEIPT_SUMMED_TOTAL_TAX]}
          </td>
        </tr>
      </tbody>
    </table>

    <Divider text1={'Promo'} />

    <table className="table-auto tooltip-table">
      <thead>
        <tr>
          <th>Promo Name</th>
          <th>Promo Unit Price</th>
          <th>Promo Qty</th>
          <th>Promo Price</th>
          <th>Promo Code</th>
        </tr>
      </thead>
      <tbody>
        <tr>
          <td>{AnnotationHotkeys[AnnotationLabelEnum.ITEM_PROMO_NAME]}</td>
          <td>
            {AnnotationHotkeys[AnnotationLabelEnum.ITEM_PROMO_UNIT_PRICE]}
          </td>
          <td>{AnnotationHotkeys[AnnotationLabelEnum.ITEM_PROMO_QUANTITY]}</td>
          <td>{AnnotationHotkeys[AnnotationLabelEnum.ITEM_PROMO_PRICE]}</td>
          <td>{AnnotationHotkeys[AnnotationLabelEnum.ITEM_PROMO_CODE]}</td>
        </tr>
      </tbody>
    </table>

    <Divider text1={'Date / Time'} />
    <table className="table-auto tooltip-table">
      <thead>
        <tr>
          <th>Purchase Date</th>
          <th>Eftpos Date</th>
          <th>Purchase Time</th>
          <th>Eftpos Time</th>
        </tr>
      </thead>
      <tbody>
        <tr>
          <td>
            {AnnotationHotkeys[AnnotationLabelEnum.RECEIPT_PURCHASE_DATE]}
          </td>
          <td>{AnnotationHotkeys[AnnotationLabelEnum.EFT_PURCHASE_DATE]}</td>
          <td>
            {AnnotationHotkeys[AnnotationLabelEnum.RECEIPT_PURCHASE_TIME]}
          </td>
          <td>{AnnotationHotkeys[AnnotationLabelEnum.EFT_PURCHASE_TIME]}</td>
        </tr>
      </tbody>
    </table>

    <Divider text1={'Other'} />
    <table className="table-auto tooltip-table">
      <thead>
        <tr>
          <th>Cash Register / POS ID</th>
          <th>Eftpos Register ID</th>
        </tr>
      </thead>
      <tbody>
        <tr>
          <td>
            {AnnotationHotkeys[AnnotationLabelEnum.RECEIPT_CASH_REGISTER_ID]}
          </td>
          <td>{AnnotationHotkeys[AnnotationLabelEnum.EFT_REGISTER_ID]}</td>
        </tr>
      </tbody>
    </table>
    <table className="table-auto tooltip-table">
      <thead>
        <tr>
          <th>Transaction ID</th>
          <th>Eftpos Transaction ID</th>
          <th>Bottom Transaction ID</th>
        </tr>
      </thead>

      <tbody>
        <tr>
          <td>
            {AnnotationHotkeys[AnnotationLabelEnum.RECEIPT_TRANSACTION_ID]}
          </td>
          <td>{AnnotationHotkeys[AnnotationLabelEnum.EFT_TRANSACTION_ID]}</td>
          <td>
            {
              AnnotationHotkeys[
                AnnotationLabelEnum.RECEIPT_BOTTOM_TRANSACTION_ID
              ]
            }
          </td>
        </tr>
      </tbody>
    </table>
    <table className="table-auto tooltip-table">
      <thead>
        <tr>
          <th>Bottom Barcode</th>
          <th>Eftpos RRN</th>
        </tr>
      </thead>
      <tbody>
        <tr>
          <td>
            {AnnotationHotkeys[AnnotationLabelEnum.RECEIPT_BOTTOM_BARCODE]}
          </td>
          <td>{AnnotationHotkeys[AnnotationLabelEnum.EFT_RRN]}</td>
        </tr>
      </tbody>
    </table>
  </>
)

export default AnnotationHotkeysTooltip
