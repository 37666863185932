import Icon from '@ant-design/icons'
import React from 'react'

const PromoSvg = () => (
  <svg
    width="23px"
    height="23px"
    viewBox="0 0 23 23"
    version="1.1"
    xmlns="http://www.w3.org/2000/svg"
  >
    <title>tag-svgrepo-com</title>
    <g
      id="Platform-3.0"
      stroke="none"
      strokeWidth="1"
      fill="none"
      fillRule="evenodd"
    >
      <g
        id="Desktop-HD"
        transform="translate(-903.000000, -826.000000)"
        fill="#0E9D00"
        fillRule="nonzero"
      >
        <g id="Group-9-Copy" transform="translate(903.000000, 812.000000)">
          <g id="tag-svgrepo-com" transform="translate(0.000000, 14.000000)">
            <path
              d="M22.9994029,9.2907636 L22.7913637,1.75319303 C22.7681385,0.910374201 22.0897249,0.231993889 21.2468126,0.20866596 L13.7087675,0.000636951275 C13.2684247,-0.0116764541 12.8518266,0.154944183 12.5413785,0.465377083 L0.46465907,12.5415546 C-0.154886357,13.1610695 -0.154886357,14.1690543 0.46465907,14.7885692 L8.21069151,22.5341688 C8.8316398,23.1551905 9.83676444,23.1553463 10.4578686,22.5342208 L22.53464,10.4580952 C22.8419707,10.1507277 23.0114052,9.7252138 22.9994029,9.2907636 Z M19.5479468,7.272664 C18.4920073,8.32849955 16.7830097,8.32860346 15.7270183,7.272664 C14.6735728,6.21927036 14.6735728,4.50526511 15.7270183,3.45192342 C16.7829577,2.39603592 18.4919554,2.39598396 19.5479468,3.45192342 C20.6013402,4.50526511 20.6013402,6.21927036 19.5479468,7.272664 Z"
              id="Shape"
            ></path>
          </g>
        </g>
      </g>
    </g>
  </svg>
)

const PromoIcon = props => <Icon component={PromoSvg} {...props} />

export default PromoIcon
