import { ExtractionColor } from 'pages/app/visualizer/version-1/types'
import React from 'react'

interface Props {
  x: number
  y: number
  boxWidth: number
  boxHeight: number
  label: string
  text: string
  color?: string
}

const Highlight = ({
  x,
  y,
  boxWidth,
  boxHeight,
  label,
  text,
  color
}: Props) => {
  const boxColor = color || ExtractionColor[label]
  return (
    <div
      className={'z-50 cursor-pointer absolute'}
      style={{
        top: y,
        left: x,
        width: boxWidth,
        height: boxHeight,
        opacity: 0.5,
        border: `4px solid ${boxColor}`,
        backgroundColor: boxColor
      }}
    />
  )
}

export default Highlight
