import { PermissionEnum } from 'data/reducers'
import { AuthSelectors } from 'data/selectors'
import { useSelector } from 'react-redux'

export const checkAllPermissions = (
  userPermissions: PermissionEnum[] = [],
  requirePermissions
) =>
  !requirePermissions.length ||
  requirePermissions.every(ai => userPermissions.includes(ai))

export const checkOnePermission = (
  userPermissions: PermissionEnum[] = [],
  requirePermissions
) =>
  !requirePermissions.length ||
  userPermissions.some(ai => requirePermissions.includes(ai))

const useHandleRequirePermissions = (
  requirePermissions: PermissionEnum[]
): boolean => {
  const profile = useSelector(AuthSelectors.firebaseProfile)

  return checkAllPermissions(profile?.permissions, requirePermissions)
}

export default useHandleRequirePermissions
