import { combineReducers } from 'redux'
import { persistReducer } from 'redux-persist'
import { PersistPartial } from 'redux-persist/es/persistReducer'
import autoMergeLevel1 from 'redux-persist/lib/stateReconciler/autoMergeLevel1'
import hardSet from 'redux-persist/lib/stateReconciler/hardSet'
import storage from 'redux-persist/lib/storage'
import { AppReducer as app, AppState } from './app'
import { AuditReducer as audit, AuditState } from './audit'
import { AuthReducers as auth, FirebaseReducer, FirestoreReducer } from './auth'
import { BoxReducer as box, BoxState } from './box'
import { ExtractionReducer as extraction, ExtractionState } from './extraction'
import { HistoryReducer as history, HistoryState } from './history'
import { OverlayReducer as overlay, OverlayState } from './overlay'
import { VisualizerReducer as visualizer, VisualizerState } from './visualizer'

export enum PermissionEnum {
  'OFFLINE' = 'OFFLINE',
  'EMAIL' = 'EMAIL',
  'ANNOTATION' = 'ANNOTATION',
  'REDACTION' = 'REDACTION',
  'SUBJECT_WHITELIST' = 'SUBJECT_WHITELIST',
  'ADMIN' = 'ADMIN'
}

export type profileSchema = {
  username?: string
  email?: string
  role?: string
  permissions?: PermissionEnum[]
}

const rootPersistConfig = {
  key: 'ROOT',
  storage,
  blacklist: [
    'firebase',
    'box',
    'extraction',
    'audit',
    'overlay',
    'visualizer'
  ],
  stateReconciler: autoMergeLevel1
}

const auditPersistConfig = {
  key: 'AUDIT',
  storage,
  blacklist: ['data'],
  stateReconciler: autoMergeLevel1
}

const extractionPersistConfig = {
  key: 'EXTRACTION',
  storage,
  blacklist: ['receiptId', 'rawData', 'isMissingFieldsOpen'],
  stateReconciler: autoMergeLevel1
}

const firebasePersistConfig = {
  key: 'FIREBASE',
  storage,
  stateReconciler: hardSet
}

export interface ApplicationReducerState {
  extraction: ExtractionState
  box: BoxState
  app: AppState
  overlay: OverlayState
  audit: AuditState
  visualizer: VisualizerState
  history: HistoryState
  firebase: FirebaseReducer.Reducer<profileSchema, any>
  firestore: FirestoreReducer.Reducer<any>
}

export const state = combineReducers<ApplicationReducerState>({
  extraction: persistReducer<ExtractionState & any, any>(
    extractionPersistConfig,
    extraction
  ),
  box,
  app,
  overlay,
  audit: persistReducer<AuditState & any, any>(auditPersistConfig, audit),
  visualizer,
  history,
  firebase: persistReducer<profileSchema & any, any>(
    firebasePersistConfig,
    auth.firebase
  ),
  firestore: auth.firestore
})

export default persistReducer(rootPersistConfig, state)
