import { LeftCircleOutlined, RightCircleOutlined } from '@ant-design/icons'
import { AppSelectors } from 'data/selectors'
import React, { CSSProperties } from 'react'
import { useSelector } from 'react-redux'
import {
  EXTRACTION_SIDER_WIDTH,
  FOOTER_HEIGHT,
  SIDER_WIDTH
} from 'services/styles/layout'
import { Space } from 'services/styles/spacing'

interface ButtonProp {
  disabled?: boolean
  onClick: () => void
  style?: CSSProperties
}

const LeftArrow = ({ onClick, disabled, style }: ButtonProp) => (
  <div
    className={`absolute top-1/2 left-4 cursor-${
      !disabled ? 'pointer' : 'not-allowed'
    }`}
    style={style}
    onClick={!disabled ? onClick : () => {}}
  >
    <LeftCircleOutlined
      style={{ color: !disabled ? 'white' : 'grey', fontSize: 30 }}
    />
  </div>
)

const RightArrow = ({ onClick, disabled, style }: ButtonProp) => (
  <div
    className={`absolute top-1/2  right-4 cursor-${
      !disabled ? 'pointer' : 'not-allowed'
    }`}
    style={style}
    onClick={!disabled ? onClick : () => {}}
  >
    <RightCircleOutlined
      style={{ color: !disabled ? 'white' : 'grey', fontSize: 30 }}
    />
  </div>
)

interface PageIndicatorProps {
  current: string | number
  total: string | number
  style?: CSSProperties
}

const PageIndicator = ({ current, total, style }: PageIndicatorProps) => {
  const isSiderOpen = useSelector(AppSelectors.siderState)

  return (
    <div
      className={'absolute inset-x-0'}
      style={{
        marginLeft: isSiderOpen ? SIDER_WIDTH : 0,
        bottom: FOOTER_HEIGHT + Space.SMALL,
        marginRight: EXTRACTION_SIDER_WIDTH,
        ...style
      }}
    >
      <div className={'flex justify-center text-white'}>
        <span className=" rounded w-12 bg-opacity-50 bg-black flex justify-center">
          {`${current} / ${total}`}
        </span>
      </div>
    </div>
  )
}
const Pagination = ({ children }) => children

Pagination.LeftArrow = LeftArrow
Pagination.RightArrow = RightArrow
Pagination.PageIndicator = PageIndicator

export default Pagination
