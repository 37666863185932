import { BoxLabelEnum } from 'data/box'
import { getBoxColorLight } from 'services/styles/color'

export const FieldsKeyDictionary = [
  'receipt_id',
  'user_id',
  'receipt_store_id',
  'receipt_transaction_id',
  'receipt_source',
  'receipt_retailer',
  'receipt_store_address',
  'receipt_store_zip_code',
  'receipt_store_city',
  'receipt_store_phone',
  'receipt_purchase_date',
  'receipt_purchase_time',
  'receipt_order_total_without_tax',
  'receipt_total_value',
  'receipt_total_tax',
  'receipt_tip',
  'receipt_currency',
  'receipt_number_of_items',
  'receipt_upload_date',
  'receipt_upload_time',
  'receipt_image_parts_num',
  'receipt_image_link'
]

// msft annotation
export const FixedExtractionList = [
  'Items',
  'MerchantName',
  'MerchantPhoneNumber',
  'MerchantAddress',
  'TransactionDate',
  'TransactionTime',
  'Total',
  'Subtotal',
  'Tax',
  'Tip',
  'CashRegisterId',
  'ReceiptId'
]

export const ExtractionColor = {
  Items: '#B4FEB6',
  MerchantName: getBoxColorLight(BoxLabelEnum.RECEIPT_MERCHANT_NAME),
  MerchantPhoneNumber: getBoxColorLight(BoxLabelEnum.RECEIPT_MERCHANT_NAME),
  MerchantAddress: getBoxColorLight(BoxLabelEnum.RECEIPT_MERCHANT_NAME),
  TransactionDate: getBoxColorLight(BoxLabelEnum.RECEIPT_PURCHASE_DATE),
  TransactionTime: getBoxColorLight(BoxLabelEnum.RECEIPT_PURCHASE_TIME),
  Total: getBoxColorLight(BoxLabelEnum.RECEIPT_TOTAL_VALUE),
  Subtotal: getBoxColorLight(BoxLabelEnum.RECEIPT_SUBTOTAL_VALUE),
  Tax: getBoxColorLight(BoxLabelEnum.RECEIPT_TOTAL_TAX),
  Tip: getBoxColorLight(BoxLabelEnum.RECEIPT_TOTAL_TAX),
  CashRegisterId: getBoxColorLight(BoxLabelEnum.RECEIPT_MERCHANT_NAME),
  ReceiptId: getBoxColorLight(BoxLabelEnum.RECEIPT_MERCHANT_NAME)
}
