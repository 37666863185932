export enum RecordActions {
  SET_RECORD = 'SET_RECORD',
  EDIT_OCR_TEXT = 'EDIT_OCR_TEXT',
  REMOVE_OCR = 'REMOVE_OCR',
  EDIT_TEXT = 'EDIT_TEXT',
  EDIT_LABEL = 'EDIT_LABEL',
  ADD_FIELD = 'ADD_FIELD',
  REMOVE_FIELD = 'REMOVE_FIELD',
  ADD_LINK = 'ADD_LINK',
  REMOVE_LINK = 'REMOVE_LINK',
  RESET_WORKING_STATE = 'RESET_WORKING_STATE'
}
