import { WorkflowActions } from './actions'

// TODO typing
export interface CombinedStates {
  data: any | null // raw db data
  record: any | null // workflow record
  localeOptions: { value: string }[]
}

export const baseSurveyDetails = {
  // locale: 'en',
  title: '',
  description: '',
  surveyUrl: '',
  timeEstimate: '',
  tip: '',
  notificationText: '',
  maxNumberOfResponses: null,
  earlyExitNotificationText: null
}
/**
 * surveyCreation: data for survey creations process
 * surveyRequest: data for survey request process
 * */
export const initialData = {
  surveyCreation: {
    type: 'SURVEY',
    imageUrl: null,
    isActive: false,
    isPublic: false,
    isUnlisted: false,
    points: null, // incentive
    globalMaxNumberOfResponses: null,
    earlyExitShakes: null,
    earlyExitPoints: null,
    surveyDetails: [
      {
        locale: 'en',
        ...baseSurveyDetails
      }
    ]
  },
  surveyRequest: {
    countryCode: 'US',
    expiryDate: new Date().toISOString(),
    releaseDate: new Date().toISOString(),
    targetUsers: 'All active users'
  }
}

export const initialState: CombinedStates = {
  record: initialData,
  data: initialData,
  localeOptions: [{ value: 'en' }, { value: 'es' }, { value: 'fr' }]
}

export const combinedReducers = {
  // data reducer
  [WorkflowActions.SET_DATA]: (state: any, newData: any) => {
    return {
      ...state,
      data: newData
    }
  },
  [WorkflowActions.SET_SURVEY_DETAILS]: (state: any, newData: any) => {
    return {
      ...state,
      data: {
        ...state.data,
        surveyCreation: {
          ...state.data.surveyCreation,
          surveyDetails: newData
        }
      }
    }
  }
}

export function reducer(state, action) {
  return combinedReducers[action.type](state, action.payload)
}
