export enum AnnotationLabelEnum {
  ITEM_DESCRIPTION = 'item_line_item_description',
  ITEM_PRICE = 'item_line_item_price',
  ITEM_UNIT_PRICE = 'item_line_item_unit_price',
  ITEM_QUANTITY = 'item_line_item_quantity',
  ITEM_CODE = 'item_line_item_code',
  ITEM_CODE_2 = 'item_line_item_code_2',
  ITEM_ADDRESS = 'item_line_item_address',
  ITEM_REG_PRICE = 'item_line_item_reg_price',
  ITEM_LINE_TAX = 'item_line_item_tax',

  ITEM_PROMO_NAME = 'item_promo_description',
  ITEM_PROMO_PRICE = 'item_promo_price',
  ITEM_PROMO_UNIT_PRICE = 'item_promo_unit_price',
  ITEM_PROMO_QUANTITY = 'item_promo_quantity',
  ITEM_PROMO_CODE = 'item_promo_code',

  ITEM_UNIT_TYPE = 'item_line_item_unit_type',
  ITEM_SELLER = 'item_seller',
  ITEM_ORDER_ID = 'item_order_id',
  ITEM_DELIVERY_DATE = 'item_delivery_date',
  ITEM_DESCRIPTION_EXTRA = 'item_line_item_description_extra',
  ITEM_OTHER_DESCRIPTION = 'item_other_description',
  ITEM_OTHER_PRICE = 'item_other_price',

  ITEM_CATEGORY = 'item_category_name',

  ITEM_SUBTOTAL = 'item_subtotal',

  SUB_ITEM_DESCRIPTION = 'item_sub_item_description',
  SUB_ITEM_PRICE = 'item_sub_item_price',

  ITEM_TAX_DESCRIPTION = 'item_tax_description',
  ITEM_TAX_PRICE = 'item_tax_price',

  LOYALTY_EARNED_DESCRIPTION = 'loyalty_earned_description',
  LOYALTY_EARNED_POINTS = 'loyalty_earned_points',

  LOYALTY_REDEEMED_DESCRIPTION = 'loyalty_redeemed_description',
  LOYALTY_REDEEMED_POINTS = 'loyalty_redeemed_points',

  LOYALTY_BALANCE_DESCRIPTION = 'loyalty_balance_description',
  LOYALTY_BALANCE_POINTS = 'loyalty_balance_points',

  RECEIPT_SUBTOTAL_VALUE = 'receipt_subtotal',
  RECEIPT_TOTAL_VALUE = 'receipt_total',
  RECEIPT_TOTAL_TAX = 'receipt_total_tax',
  RECEIPT_SUMMED_TOTAL_TAX = 'receipt_summed_total_tax',
  RECEIPT_TIP = 'receipt_total_tip',

  RECEIPT_MERCHANT_LOGO_NAME = 'receipt_merchant_logo_name',
  EFT_MERCHANT_NAME = 'eft_merchant_name',

  RECEIPT_STORE_ADDRESS = 'receipt_store_address',
  RECEIPT_STORE_PHONE = 'receipt_store_phone',
  RECEIPT_STORE_ID = 'receipt_store_id',
  RECEIPT_STORE_NAME = 'receipt_store_name',
  EFT_STORE_NAME = 'eft_store_name',
  EFT_STORE_ID = 'eft_store_id',
  RECEIPT_BOTTOM_STORE_ID = 'receipt_bottom_store_id',

  RECEIPT_PURCHASE_DATE = 'receipt_purchase_date',
  RECEIPT_PURCHASE_TIME = 'receipt_purchase_time',
  EFT_PURCHASE_DATE = 'eft_purchase_date',
  EFT_PURCHASE_TIME = 'eft_purchase_time',

  RECEIPT_CASH_REGISTER_ID = 'receipt_register_id',
  RECEIPT_TRANSACTION_ID = 'receipt_transaction_id',
  EFT_REGISTER_ID = 'eft_register_id',
  EFT_TRANSACTION_ID = 'eft_transaction_id',
  EFT_RRN = 'efr_rrn',
  RECEIPT_BOTTOM_REGISTER_ID = 'receipt_bottom_register_id',
  RECEIPT_BOTTOM_TRANSACTION_ID = 'receipt_bottom_transaction_id',
  RECEIPT_BOTTOM_BARCODE = 'receipt_bottom_barcode',

  RECEIPT_TOTAL_DISCOUNT = 'receipt_total_discount',
  RECEIPT_MEMBERSHIP_DISCOUNT = 'receipt_membership_discount',

  ITEM_MEMBERSHIP_TYPE = 'item_membership_type',

  OTHER = 'Other'
}

export const prettyAnnotationLabel = {
  [AnnotationLabelEnum.ITEM_DESCRIPTION]: 'Name',
  [AnnotationLabelEnum.ITEM_PRICE]: 'Total Price',
  [AnnotationLabelEnum.ITEM_UNIT_PRICE]: 'Unit Price',
  [AnnotationLabelEnum.ITEM_QUANTITY]: 'Qty',
  [AnnotationLabelEnum.ITEM_CODE]: 'Product ID',
  [AnnotationLabelEnum.ITEM_CODE_2]: 'Product ID 2',
  [AnnotationLabelEnum.ITEM_SELLER]: 'Seller',
  [AnnotationLabelEnum.ITEM_ORDER_ID]: 'Order ID',
  [AnnotationLabelEnum.ITEM_DELIVERY_DATE]: 'Date',
  [AnnotationLabelEnum.ITEM_DESCRIPTION_EXTRA]: 'extra',
  [AnnotationLabelEnum.ITEM_ADDRESS]: 'Address',
  [AnnotationLabelEnum.ITEM_REG_PRICE]: 'Reg Price',
  [AnnotationLabelEnum.ITEM_LINE_TAX]: 'Tax',

  [AnnotationLabelEnum.ITEM_OTHER_DESCRIPTION]: 'Item Other Description',
  [AnnotationLabelEnum.ITEM_OTHER_PRICE]: 'Item Other Price',

  [AnnotationLabelEnum.SUB_ITEM_DESCRIPTION]: 'Sub-item Name',
  [AnnotationLabelEnum.SUB_ITEM_PRICE]: 'Sub-item Price',

  [AnnotationLabelEnum.ITEM_PROMO_NAME]: 'PromoName',
  [AnnotationLabelEnum.ITEM_PROMO_PRICE]: 'PromoPrice',
  [AnnotationLabelEnum.ITEM_PROMO_UNIT_PRICE]: 'PromoUnitPrice',
  [AnnotationLabelEnum.ITEM_PROMO_QUANTITY]: 'PromoQty',
  [AnnotationLabelEnum.ITEM_PROMO_CODE]: 'PromoCode',

  [AnnotationLabelEnum.LOYALTY_EARNED_DESCRIPTION]: 'LoyaltyEarnedDesc',
  [AnnotationLabelEnum.LOYALTY_EARNED_POINTS]: 'LoyaltyEarnedPoints',
  [AnnotationLabelEnum.LOYALTY_REDEEMED_DESCRIPTION]:
    'LoyaltyRedeemedDescription',
  [AnnotationLabelEnum.LOYALTY_REDEEMED_POINTS]: 'LoyaltyRedeemedPoints',
  [AnnotationLabelEnum.LOYALTY_BALANCE_DESCRIPTION]:
    'LoyaltyBalanceDescription',
  [AnnotationLabelEnum.LOYALTY_BALANCE_POINTS]: 'LoyaltyBalancePoints',

  [AnnotationLabelEnum.ITEM_TAX_DESCRIPTION]: 'Tax Name',
  [AnnotationLabelEnum.ITEM_TAX_PRICE]: 'Tax Price',

  [AnnotationLabelEnum.ITEM_CATEGORY]: 'Category',

  [AnnotationLabelEnum.ITEM_SUBTOTAL]: 'Item Subtotal',

  [AnnotationLabelEnum.RECEIPT_SUBTOTAL_VALUE]: 'Subtotal',
  [AnnotationLabelEnum.RECEIPT_TOTAL_VALUE]: 'Total',
  [AnnotationLabelEnum.RECEIPT_TOTAL_TAX]: 'Tax',
  [AnnotationLabelEnum.RECEIPT_SUMMED_TOTAL_TAX]: 'Summed Tax',
  [AnnotationLabelEnum.RECEIPT_TIP]: 'Tip',
  [AnnotationLabelEnum.RECEIPT_MERCHANT_LOGO_NAME]: 'Logo Name',
  [AnnotationLabelEnum.RECEIPT_STORE_ADDRESS]: 'Address',
  [AnnotationLabelEnum.RECEIPT_STORE_PHONE]: 'Phone',
  [AnnotationLabelEnum.RECEIPT_PURCHASE_DATE]: 'Date',
  [AnnotationLabelEnum.RECEIPT_PURCHASE_TIME]: 'Time',
  [AnnotationLabelEnum.OTHER]: 'Other',

  [AnnotationLabelEnum.ITEM_UNIT_TYPE]: 'UnitType',
  [AnnotationLabelEnum.RECEIPT_STORE_ID]: 'Store Number',
  [AnnotationLabelEnum.RECEIPT_STORE_NAME]: 'Store Name',
  [AnnotationLabelEnum.RECEIPT_TOTAL_DISCOUNT]: 'Total Discount',
  [AnnotationLabelEnum.RECEIPT_CASH_REGISTER_ID]: 'Register Id',
  [AnnotationLabelEnum.RECEIPT_TRANSACTION_ID]: 'Transaction Number'
}

export enum SectionEnum {
  MERCHANT = 'Merchant Info',
  LINE_ITEM = 'Line Items',
  PROMOTION = 'Promotion',
  LOYALTY_EARNED = 'Loyalty Earned',
  LOYALTY_REDEEMED = 'Loyalty Redeemed',
  LOYALTY_BALANCE = 'Loyalty Balance',
  TOTAL = 'Total & Tax',
  TIME = 'Date & Time',
  RECEIPT_DISCOUNT = 'Receipt Discount',
  TRANSACTION = 'Receipt Transaction',
  SUMMARY = 'Summary',
  ITEM_OTHER = 'Item Other',
  ITEM_CATEGORY = 'Item Category',
  ITEM_SUBTOTAL = 'Item Subtotal',
  SUB_ITEM = 'Sub Item',
  ITEM_TAX = 'Item Tax'
}

// fields to display
export const LineItemHeader = {
  [SectionEnum.MERCHANT]: [
    AnnotationLabelEnum.RECEIPT_MERCHANT_LOGO_NAME,
    AnnotationLabelEnum.RECEIPT_STORE_PHONE,
    AnnotationLabelEnum.RECEIPT_STORE_ID,
    AnnotationLabelEnum.RECEIPT_STORE_ADDRESS,
    AnnotationLabelEnum.RECEIPT_STORE_NAME
  ],
  [SectionEnum.LINE_ITEM]: [
    AnnotationLabelEnum.ITEM_DESCRIPTION,
    AnnotationLabelEnum.ITEM_DESCRIPTION_EXTRA,
    AnnotationLabelEnum.ITEM_ORDER_ID,
    AnnotationLabelEnum.ITEM_CODE,
    AnnotationLabelEnum.ITEM_CODE_2,
    AnnotationLabelEnum.ITEM_SELLER,
    AnnotationLabelEnum.ITEM_DELIVERY_DATE,
    AnnotationLabelEnum.ITEM_ADDRESS,
    AnnotationLabelEnum.ITEM_UNIT_PRICE,
    AnnotationLabelEnum.ITEM_QUANTITY,
    AnnotationLabelEnum.ITEM_REG_PRICE,
    AnnotationLabelEnum.ITEM_PRICE,
    AnnotationLabelEnum.ITEM_LINE_TAX
  ],
  [SectionEnum.SUB_ITEM]: [
    AnnotationLabelEnum.SUB_ITEM_DESCRIPTION,
    AnnotationLabelEnum.SUB_ITEM_PRICE
  ],
  [SectionEnum.PROMOTION]: [
    AnnotationLabelEnum.ITEM_PROMO_NAME,
    AnnotationLabelEnum.ITEM_PROMO_CODE,
    AnnotationLabelEnum.ITEM_PROMO_UNIT_PRICE,
    AnnotationLabelEnum.ITEM_PROMO_QUANTITY,
    AnnotationLabelEnum.ITEM_PROMO_PRICE
  ],
  [SectionEnum.ITEM_OTHER]: [
    AnnotationLabelEnum.ITEM_OTHER_DESCRIPTION,
    AnnotationLabelEnum.ITEM_OTHER_PRICE
  ],
  [SectionEnum.ITEM_TAX]: [
    AnnotationLabelEnum.ITEM_TAX_DESCRIPTION,
    AnnotationLabelEnum.ITEM_TAX_PRICE
  ],
  [SectionEnum.ITEM_CATEGORY]: [AnnotationLabelEnum.ITEM_CATEGORY],
  [SectionEnum.ITEM_SUBTOTAL]: [AnnotationLabelEnum.ITEM_SUBTOTAL],
  [SectionEnum.RECEIPT_DISCOUNT]: [
    AnnotationLabelEnum.RECEIPT_TOTAL_DISCOUNT,
    AnnotationLabelEnum.RECEIPT_TIP
  ],
  [SectionEnum.LOYALTY_EARNED]: [
    AnnotationLabelEnum.LOYALTY_EARNED_DESCRIPTION,
    AnnotationLabelEnum.LOYALTY_EARNED_POINTS
  ],
  [SectionEnum.LOYALTY_REDEEMED]: [
    AnnotationLabelEnum.LOYALTY_REDEEMED_DESCRIPTION,
    AnnotationLabelEnum.LOYALTY_REDEEMED_POINTS
  ],
  [SectionEnum.LOYALTY_BALANCE]: [
    AnnotationLabelEnum.LOYALTY_BALANCE_DESCRIPTION,
    AnnotationLabelEnum.LOYALTY_BALANCE_POINTS
  ],
  [SectionEnum.TOTAL]: [
    AnnotationLabelEnum.RECEIPT_SUBTOTAL_VALUE,
    AnnotationLabelEnum.RECEIPT_TOTAL_TAX,
    AnnotationLabelEnum.RECEIPT_SUMMED_TOTAL_TAX,
    AnnotationLabelEnum.RECEIPT_TOTAL_VALUE
  ],
  [SectionEnum.TRANSACTION]: [
    AnnotationLabelEnum.RECEIPT_TRANSACTION_ID,
    AnnotationLabelEnum.RECEIPT_CASH_REGISTER_ID
  ],
  [SectionEnum.TIME]: [
    AnnotationLabelEnum.RECEIPT_PURCHASE_DATE,
    AnnotationLabelEnum.RECEIPT_PURCHASE_TIME
  ],
  [SectionEnum.SUMMARY]: ['', '', 'Calculated Total']
}

export const LabelToSectionTitle = {
  [AnnotationLabelEnum.RECEIPT_MERCHANT_LOGO_NAME]: SectionEnum.MERCHANT,
  [AnnotationLabelEnum.RECEIPT_STORE_ADDRESS]: SectionEnum.MERCHANT,
  [AnnotationLabelEnum.RECEIPT_STORE_PHONE]: SectionEnum.MERCHANT,
  [AnnotationLabelEnum.ITEM_DESCRIPTION]: SectionEnum.LINE_ITEM,
  [AnnotationLabelEnum.ITEM_QUANTITY]: SectionEnum.LINE_ITEM,
  [AnnotationLabelEnum.ITEM_UNIT_PRICE]: SectionEnum.LINE_ITEM,
  [AnnotationLabelEnum.ITEM_PRICE]: SectionEnum.LINE_ITEM,
  [AnnotationLabelEnum.ITEM_CODE]: SectionEnum.LINE_ITEM,
  [AnnotationLabelEnum.ITEM_CODE_2]: SectionEnum.LINE_ITEM,
  [AnnotationLabelEnum.ITEM_SELLER]: SectionEnum.LINE_ITEM,
  [AnnotationLabelEnum.ITEM_ORDER_ID]: SectionEnum.LINE_ITEM,
  [AnnotationLabelEnum.ITEM_DELIVERY_DATE]: SectionEnum.LINE_ITEM,
  [AnnotationLabelEnum.ITEM_DESCRIPTION_EXTRA]: SectionEnum.LINE_ITEM,
  [AnnotationLabelEnum.ITEM_UNIT_TYPE]: SectionEnum.LINE_ITEM,
  [AnnotationLabelEnum.ITEM_ADDRESS]: SectionEnum.LINE_ITEM,
  [AnnotationLabelEnum.ITEM_REG_PRICE]: SectionEnum.LINE_ITEM,
  [AnnotationLabelEnum.ITEM_LINE_TAX]: SectionEnum.LINE_ITEM,

  [AnnotationLabelEnum.ITEM_PROMO_NAME]: SectionEnum.PROMOTION,
  [AnnotationLabelEnum.ITEM_PROMO_CODE]: SectionEnum.PROMOTION,
  [AnnotationLabelEnum.ITEM_PROMO_UNIT_PRICE]: SectionEnum.PROMOTION,
  [AnnotationLabelEnum.ITEM_PROMO_QUANTITY]: SectionEnum.PROMOTION,
  [AnnotationLabelEnum.ITEM_PROMO_PRICE]: SectionEnum.PROMOTION,

  [AnnotationLabelEnum.LOYALTY_EARNED_DESCRIPTION]: SectionEnum.LOYALTY_EARNED,
  [AnnotationLabelEnum.LOYALTY_EARNED_POINTS]: SectionEnum.LOYALTY_EARNED,
  [AnnotationLabelEnum.LOYALTY_REDEEMED_DESCRIPTION]:
    SectionEnum.LOYALTY_REDEEMED,
  [AnnotationLabelEnum.LOYALTY_REDEEMED_POINTS]: SectionEnum.LOYALTY_REDEEMED,
  [AnnotationLabelEnum.LOYALTY_BALANCE_DESCRIPTION]:
    SectionEnum.LOYALTY_BALANCE,
  [AnnotationLabelEnum.LOYALTY_BALANCE_POINTS]: SectionEnum.LOYALTY_BALANCE,

  [AnnotationLabelEnum.RECEIPT_SUBTOTAL_VALUE]: SectionEnum.TOTAL,
  [AnnotationLabelEnum.RECEIPT_TOTAL_TAX]: SectionEnum.TOTAL,
  [AnnotationLabelEnum.RECEIPT_SUMMED_TOTAL_TAX]: SectionEnum.TOTAL,
  [AnnotationLabelEnum.RECEIPT_TOTAL_VALUE]: SectionEnum.TOTAL,
  [AnnotationLabelEnum.RECEIPT_PURCHASE_DATE]: SectionEnum.TIME,
  [AnnotationLabelEnum.RECEIPT_PURCHASE_TIME]: SectionEnum.TIME,

  [AnnotationLabelEnum.RECEIPT_STORE_ID]: SectionEnum.MERCHANT,
  [AnnotationLabelEnum.RECEIPT_STORE_NAME]: SectionEnum.MERCHANT,

  [AnnotationLabelEnum.RECEIPT_TOTAL_DISCOUNT]: SectionEnum.RECEIPT_DISCOUNT,
  [AnnotationLabelEnum.RECEIPT_TIP]: SectionEnum.RECEIPT_DISCOUNT,

  [AnnotationLabelEnum.RECEIPT_TRANSACTION_ID]: SectionEnum.TRANSACTION,
  [AnnotationLabelEnum.RECEIPT_CASH_REGISTER_ID]: SectionEnum.TRANSACTION,

  [AnnotationLabelEnum.ITEM_OTHER_DESCRIPTION]: SectionEnum.ITEM_OTHER,
  [AnnotationLabelEnum.ITEM_OTHER_PRICE]: SectionEnum.ITEM_OTHER,

  [AnnotationLabelEnum.SUB_ITEM_DESCRIPTION]: SectionEnum.SUB_ITEM,
  [AnnotationLabelEnum.SUB_ITEM_PRICE]: SectionEnum.SUB_ITEM,

  [AnnotationLabelEnum.ITEM_CATEGORY]: SectionEnum.ITEM_CATEGORY,

  [AnnotationLabelEnum.ITEM_SUBTOTAL]: SectionEnum.ITEM_SUBTOTAL,

  [AnnotationLabelEnum.ITEM_TAX_DESCRIPTION]: SectionEnum.ITEM_TAX,
  [AnnotationLabelEnum.ITEM_TAX_PRICE]: SectionEnum.ITEM_TAX
}

// fields to filter
export const ITEM_TYPE_LINKABLE = [
  AnnotationLabelEnum.ITEM_DESCRIPTION, // must: desc is 1st in array
  AnnotationLabelEnum.ITEM_PRICE,
  AnnotationLabelEnum.ITEM_UNIT_PRICE,
  AnnotationLabelEnum.ITEM_QUANTITY,
  AnnotationLabelEnum.ITEM_CODE,
  AnnotationLabelEnum.ITEM_CODE_2,
  AnnotationLabelEnum.ITEM_UNIT_TYPE,
  AnnotationLabelEnum.ITEM_SELLER,
  AnnotationLabelEnum.ITEM_ORDER_ID,
  AnnotationLabelEnum.ITEM_DELIVERY_DATE,
  AnnotationLabelEnum.ITEM_DESCRIPTION_EXTRA,
  AnnotationLabelEnum.ITEM_ADDRESS,
  AnnotationLabelEnum.ITEM_REG_PRICE,
  AnnotationLabelEnum.ITEM_LINE_TAX
]

export const SUB_ITEM_TYPE_LINKABLE = [
  AnnotationLabelEnum.SUB_ITEM_DESCRIPTION,
  AnnotationLabelEnum.SUB_ITEM_PRICE
]

export const PROMO_TYPE_LINKABLE = [
  AnnotationLabelEnum.ITEM_PROMO_NAME,
  AnnotationLabelEnum.ITEM_PROMO_CODE,
  AnnotationLabelEnum.ITEM_PROMO_UNIT_PRICE,
  AnnotationLabelEnum.ITEM_PROMO_QUANTITY,
  AnnotationLabelEnum.ITEM_PROMO_PRICE
]

export const OTHER_TYPE_LINKABLE = [
  AnnotationLabelEnum.ITEM_OTHER_PRICE,
  AnnotationLabelEnum.ITEM_OTHER_DESCRIPTION
]

export const CATEGORY_TYPE_LINKABLE = [AnnotationLabelEnum.ITEM_CATEGORY]

export const SUBTOTAL_TYPE_LINKABLE = [AnnotationLabelEnum.ITEM_SUBTOTAL]

export const ITEM_TAX_TYPE_LINKABLE = [
  AnnotationLabelEnum.ITEM_TAX_DESCRIPTION,
  AnnotationLabelEnum.ITEM_TAX_PRICE
]

export const LOYALTY_EARNED_TYPE_LINKABLE = [
  AnnotationLabelEnum.LOYALTY_EARNED_DESCRIPTION,
  AnnotationLabelEnum.LOYALTY_EARNED_POINTS
]
export const LOYALTY_REDEEMED_TYPE_LINKABLE = [
  AnnotationLabelEnum.LOYALTY_REDEEMED_DESCRIPTION,
  AnnotationLabelEnum.LOYALTY_REDEEMED_POINTS
]
export const LOYALTY_BALANCE_TYPE_LINKABLE = [
  AnnotationLabelEnum.LOYALTY_BALANCE_DESCRIPTION,
  AnnotationLabelEnum.LOYALTY_BALANCE_POINTS
]

export const ITEM_FIELDS = [
  AnnotationLabelEnum.ITEM_DESCRIPTION,
  AnnotationLabelEnum.ITEM_PRICE,
  AnnotationLabelEnum.ITEM_UNIT_PRICE,
  AnnotationLabelEnum.ITEM_QUANTITY,
  AnnotationLabelEnum.ITEM_CODE,
  AnnotationLabelEnum.ITEM_CODE_2,
  AnnotationLabelEnum.ITEM_UNIT_TYPE,
  AnnotationLabelEnum.ITEM_SELLER,
  AnnotationLabelEnum.ITEM_ORDER_ID,
  AnnotationLabelEnum.ITEM_DELIVERY_DATE,
  AnnotationLabelEnum.ITEM_DESCRIPTION_EXTRA,
  AnnotationLabelEnum.ITEM_ADDRESS,
  AnnotationLabelEnum.ITEM_REG_PRICE,
  AnnotationLabelEnum.ITEM_LINE_TAX,

  AnnotationLabelEnum.ITEM_PROMO_NAME,
  AnnotationLabelEnum.ITEM_PROMO_CODE,
  AnnotationLabelEnum.ITEM_PROMO_UNIT_PRICE,
  AnnotationLabelEnum.ITEM_PROMO_QUANTITY,
  AnnotationLabelEnum.ITEM_PROMO_PRICE,

  AnnotationLabelEnum.ITEM_OTHER_PRICE,
  AnnotationLabelEnum.ITEM_OTHER_DESCRIPTION,

  AnnotationLabelEnum.SUB_ITEM_DESCRIPTION,
  AnnotationLabelEnum.SUB_ITEM_PRICE,

  AnnotationLabelEnum.ITEM_TAX_DESCRIPTION,
  AnnotationLabelEnum.ITEM_TAX_PRICE,

  AnnotationLabelEnum.ITEM_CATEGORY,

  AnnotationLabelEnum.ITEM_SUBTOTAL,

  AnnotationLabelEnum.LOYALTY_EARNED_DESCRIPTION,
  AnnotationLabelEnum.LOYALTY_EARNED_POINTS,
  AnnotationLabelEnum.LOYALTY_REDEEMED_DESCRIPTION,
  AnnotationLabelEnum.LOYALTY_REDEEMED_POINTS,
  AnnotationLabelEnum.LOYALTY_BALANCE_DESCRIPTION,
  AnnotationLabelEnum.LOYALTY_BALANCE_POINTS
]

export const LINKABLE_FIELDS = {
  [AnnotationLabelEnum.ITEM_DESCRIPTION]: ITEM_TYPE_LINKABLE,
  [AnnotationLabelEnum.ITEM_PRICE]: ITEM_TYPE_LINKABLE,
  [AnnotationLabelEnum.ITEM_UNIT_PRICE]: ITEM_TYPE_LINKABLE,
  [AnnotationLabelEnum.ITEM_QUANTITY]: ITEM_TYPE_LINKABLE,
  [AnnotationLabelEnum.ITEM_CODE]: ITEM_TYPE_LINKABLE,
  [AnnotationLabelEnum.ITEM_CODE_2]: ITEM_TYPE_LINKABLE,
  [AnnotationLabelEnum.ITEM_UNIT_TYPE]: ITEM_TYPE_LINKABLE,
  [AnnotationLabelEnum.ITEM_SELLER]: ITEM_TYPE_LINKABLE,
  [AnnotationLabelEnum.ITEM_ORDER_ID]: ITEM_TYPE_LINKABLE,
  [AnnotationLabelEnum.ITEM_DELIVERY_DATE]: ITEM_TYPE_LINKABLE,
  [AnnotationLabelEnum.ITEM_DESCRIPTION_EXTRA]: ITEM_TYPE_LINKABLE,
  [AnnotationLabelEnum.ITEM_ADDRESS]: ITEM_TYPE_LINKABLE,
  [AnnotationLabelEnum.ITEM_REG_PRICE]: ITEM_TYPE_LINKABLE,
  [AnnotationLabelEnum.ITEM_LINE_TAX]: ITEM_TYPE_LINKABLE,

  [AnnotationLabelEnum.ITEM_PROMO_NAME]: PROMO_TYPE_LINKABLE,
  [AnnotationLabelEnum.ITEM_PROMO_CODE]: PROMO_TYPE_LINKABLE,
  [AnnotationLabelEnum.ITEM_PROMO_UNIT_PRICE]: PROMO_TYPE_LINKABLE,
  [AnnotationLabelEnum.ITEM_PROMO_QUANTITY]: PROMO_TYPE_LINKABLE,
  [AnnotationLabelEnum.ITEM_PROMO_PRICE]: PROMO_TYPE_LINKABLE,

  [AnnotationLabelEnum.ITEM_OTHER_PRICE]: OTHER_TYPE_LINKABLE,
  [AnnotationLabelEnum.ITEM_OTHER_DESCRIPTION]: OTHER_TYPE_LINKABLE,

  [AnnotationLabelEnum.SUB_ITEM_DESCRIPTION]: SUB_ITEM_TYPE_LINKABLE,
  [AnnotationLabelEnum.SUB_ITEM_PRICE]: SUB_ITEM_TYPE_LINKABLE,

  [AnnotationLabelEnum.ITEM_TAX_DESCRIPTION]: ITEM_TAX_TYPE_LINKABLE,
  [AnnotationLabelEnum.ITEM_TAX_PRICE]: ITEM_TAX_TYPE_LINKABLE,

  [AnnotationLabelEnum.LOYALTY_EARNED_DESCRIPTION]: LOYALTY_EARNED_TYPE_LINKABLE,
  [AnnotationLabelEnum.LOYALTY_EARNED_POINTS]: LOYALTY_EARNED_TYPE_LINKABLE,

  [AnnotationLabelEnum.LOYALTY_REDEEMED_DESCRIPTION]: LOYALTY_REDEEMED_TYPE_LINKABLE,
  [AnnotationLabelEnum.LOYALTY_REDEEMED_POINTS]: LOYALTY_REDEEMED_TYPE_LINKABLE,

  [AnnotationLabelEnum.LOYALTY_BALANCE_DESCRIPTION]: LOYALTY_BALANCE_TYPE_LINKABLE,
  [AnnotationLabelEnum.LOYALTY_BALANCE_POINTS]: LOYALTY_BALANCE_TYPE_LINKABLE
}

// prevent link directly: ignore nested case
export const getLinkableFields = (sourceLabel, targetLabel) =>
  (LINKABLE_FIELDS?.[sourceLabel] || [])
    .filter(i => i !== sourceLabel)
    .includes(targetLabel)

export enum SelectableAreaEnum {
  TOKEN = '.text-box',
  HIGHLIGHT = '.highlight'
}

interface MerchantDefinitionRequirement {
  label: string
  required?: boolean
  disabled?: boolean
}

export type MerchantDefinition = {
  definition: MerchantDefinitionRequirement[]
}

const merchant = {
  // [AnnotationLabelEnum.RECEIPT_MERCHANT_LOGO_NAME]: 'E',  // old hotkey
  [AnnotationLabelEnum.EFT_MERCHANT_NAME]: 'D',

  // [AnnotationLabelEnum.RECEIPT_STORE_ADDRESS]: 'R', // old hotkey
  [AnnotationLabelEnum.RECEIPT_STORE_NAME]: 'U',
  // [AnnotationLabelEnum.RECEIPT_STORE_PHONE]: 'T', // old hotkey
  // [AnnotationLabelEnum.RECEIPT_STORE_ID]: 'Y', // old hotkey

  [AnnotationLabelEnum.EFT_STORE_NAME]: 'F',
  [AnnotationLabelEnum.EFT_STORE_ID]: 'G'
}

const lineItem = {
  [AnnotationLabelEnum.ITEM_DESCRIPTION]: '1',
  [AnnotationLabelEnum.ITEM_QUANTITY]: '2',
  [AnnotationLabelEnum.ITEM_UNIT_PRICE]: '3',
  [AnnotationLabelEnum.ITEM_PRICE]: '4',
  [AnnotationLabelEnum.ITEM_CODE]: '5',
  [AnnotationLabelEnum.ITEM_SELLER]: '8',
  [AnnotationLabelEnum.ITEM_ORDER_ID]: '9',
  [AnnotationLabelEnum.ITEM_DELIVERY_DATE]: '0',
  [AnnotationLabelEnum.ITEM_LINE_TAX]: 'T',

  [AnnotationLabelEnum.ITEM_REG_PRICE]: 'S'
}

const subItem = {
  [AnnotationLabelEnum.SUB_ITEM_DESCRIPTION]: '6',
  [AnnotationLabelEnum.SUB_ITEM_PRICE]: '7'
}

const promotion = {
  [AnnotationLabelEnum.ITEM_PROMO_NAME]: 'Q',
  [AnnotationLabelEnum.ITEM_PROMO_PRICE]: 'W',
  [AnnotationLabelEnum.ITEM_PROMO_UNIT_PRICE]: 'E',
  [AnnotationLabelEnum.ITEM_PROMO_QUANTITY]: 'R',
  [AnnotationLabelEnum.ITEM_PROMO_CODE]: 'Y'
}

const other = {
  [AnnotationLabelEnum.RECEIPT_CASH_REGISTER_ID]: 'M',
  [AnnotationLabelEnum.EFT_REGISTER_ID]: 'H',
  [AnnotationLabelEnum.RECEIPT_TRANSACTION_ID]: 'N',
  [AnnotationLabelEnum.EFT_TRANSACTION_ID]: 'J',
  [AnnotationLabelEnum.RECEIPT_BOTTOM_TRANSACTION_ID]: 'I',
  [AnnotationLabelEnum.RECEIPT_BOTTOM_BARCODE]: 'P'
}

const total = {
  [AnnotationLabelEnum.RECEIPT_TOTAL_DISCOUNT]: 'A',
  [AnnotationLabelEnum.RECEIPT_TOTAL_TAX]: 'V',
  [AnnotationLabelEnum.RECEIPT_SUMMED_TOTAL_TAX]: 'B'
}

const time = {
  [AnnotationLabelEnum.RECEIPT_PURCHASE_DATE]: 'Z',
  [AnnotationLabelEnum.EFT_PURCHASE_DATE]: 'K',
  [AnnotationLabelEnum.RECEIPT_PURCHASE_TIME]: 'X',
  [AnnotationLabelEnum.EFT_PURCHASE_TIME]: 'L'
}

export const AnnotationHotkeys = {
  ...merchant,
  ...lineItem,
  ...subItem,
  ...promotion,
  ...total,
  ...time,
  ...other
}

export const HotKeyToLabelEnum = (
  merchantDefinition: MerchantDefinitionRequirement[]
) =>
  Object.entries(AnnotationHotkeys).reduce((acc, [key, value]) => {
    if (merchantDefinition.find(i => i?.label === key)) {
      acc[value.toLowerCase()] = key
    }
    return acc
  }, {})
