import { FilterOutlined, SortAscendingOutlined } from '@ant-design/icons'
import { Button, Dropdown, Input, Modal } from 'antd'
import { DialogKeys } from 'components/modals/types'
import Table from 'components/table'
import { OverlayActions } from 'data/actions'
import useDeleteDefinition from 'pages/app/admin/merchant-definition/definitions-table/hooks'
import EditDefinitionModal from 'pages/app/admin/merchant-definition/modals/edit-definition'
import React, { ReactNode, useState } from 'react'
import { useDispatch } from 'react-redux'
import {
  DBDefinitionOverview,
  DefinitionType
} from 'services/api/requests/notua-ii/types'
import { Space } from 'services/styles/spacing'

enum SortEnum {
  COUNTRY_CODE = 'COUNTRY_CODE',
  RETAILER = 'RETAILER',
  STATUS = 'STATUS',
  TARGET = 'TARGET',
  COUNTRY_CODE_DESC = 'COUNTRY_CODE_DESC',
  RETAILER_DESC = 'RETAILER_DESC',
  STATUS_DESC = 'STATUS_DESC',
  TARGET_DESC = 'TARGET_DESC'
}

const SortEnumToField = {
  [SortEnum.COUNTRY_CODE]: 'country_code',
  [SortEnum.COUNTRY_CODE_DESC]: 'country_code',
  [SortEnum.RETAILER]: 'merchant',
  [SortEnum.RETAILER_DESC]: 'merchant',
  [SortEnum.STATUS]: 'active',
  [SortEnum.STATUS_DESC]: 'active',
  [SortEnum.TARGET]: 'target',
  [SortEnum.TARGET_DESC]: 'target'
}

interface MenuItemProps {
  actionComponent: ReactNode | string
}

const MenuItem = ({ actionComponent }: MenuItemProps) => (
  <div className={'rounded-lg bg-white border border-gray-400 border-solid'}>
    <div className={'p-2'}>
      <div className={'my-1'}>{actionComponent}</div>
    </div>
  </div>
)

interface Props {
  data: DBDefinitionOverview
}

const DefinitionsTable = ({ data }: Props) => {
  const dispatch = useDispatch()

  const [isLoading, handleDeleteDefinition] = useDeleteDefinition()
  const [filterProps, setFilterProps] = useState({
    countryCode: '',
    merchant: '',
    status: ''
  })

  const [sort, setSort] = useState(SortEnum.RETAILER)

  const handleEditClick = (
    index: number,
    definition?: DefinitionType
  ) => () => {
    dispatch(
      OverlayActions.showDialog({
        key: DialogKeys.EDIT_DEFINITION,
        component: <EditDefinitionModal data={definition} />
      })
    )
  }

  const handleDeleteClick = (id: string, definition?: DefinitionType) => () => {
    Modal.confirm({
      title: 'Are you sure?',
      onOk: async () => await handleDeleteDefinition(id),
      onCancel: () => {},
      content: (
        <>
          <p>Confirming you are going to delete</p>
          <p>
            Definition: {definition.merchant} @ {definition.country_code}
          </p>
        </>
      )
    })
  }

  const handleToggleSort = sortKey =>
    setSort(prev => {
      if (prev === sortKey) {
        return `${prev}_DESC`
      }
      const cleanKey = prev?.replace(/(_DESC)/g, '')
      if (cleanKey === sortKey) {
        return null
      }

      return sortKey
    })

  const renderMenu = (field: string) => (
    <MenuItem
      actionComponent={
        <Input
          onClick={e => {
            e.preventDefault()
            e.stopPropagation()
          }}
          placeholder={`Filter ${field}...`}
          onChange={e =>
            setFilterProps(prev => ({
              ...prev,
              [field]: e.target.value
            }))
          }
        />
      }
    />
  )

  const filteredData = data.definitions.filter(
    i =>
      i.country_code
        .toLowerCase()
        .includes(filterProps.countryCode.toLowerCase()) &&
      i.merchant.toLowerCase().includes(filterProps.merchant.toLowerCase())
  )

  const sortedData =
    sort !== null
      ? filteredData.sort((a, b) => {
          let subject = a
          let object = b
          if (sort.includes('_DESC')) {
            subject = b
            object = a
          }

          return typeof subject[SortEnumToField[sort]] === 'number'
            ? subject[SortEnumToField[sort]] - object[SortEnumToField[sort]]
            : String(subject[SortEnumToField[sort]]).localeCompare(
                String(object[SortEnumToField[sort]])
              )
        })
      : filteredData

  return (
    <Table>
      <Table.Header
        titles={[
          <div>
            Retailer
            <Dropdown
              overlay={renderMenu('merchant')}
              trigger={['click']}
              className="cursor-pointer"
              overlayStyle={{ width: 200 }}
            >
              <FilterOutlined className="px-2" />
            </Dropdown>
            <SortAscendingOutlined
              className={`cursor-pointer ${
                sort === SortEnum.RETAILER ? 'text-blue-400' : 'text-black'
              } ${
                sort === SortEnum.RETAILER_DESC ? 'text-red-400' : 'text-black'
              }`}
              onClick={() => handleToggleSort(SortEnum.RETAILER)}
            />
          </div>,
          <div>
            Country
            <Dropdown
              overlay={renderMenu('countryCode')}
              trigger={['click']}
              className="cursor-pointer"
              overlayStyle={{ width: 200 }}
            >
              <FilterOutlined className="px-2" />
            </Dropdown>
            <SortAscendingOutlined
              className={`cursor-pointer ${
                sort === SortEnum.COUNTRY_CODE ? 'text-blue-400' : 'text-black'
              } ${
                sort === SortEnum.COUNTRY_CODE_DESC
                  ? 'text-red-400'
                  : 'text-black'
              }`}
              onClick={() => handleToggleSort(SortEnum.COUNTRY_CODE)}
            />
          </div>,
          <div>
            Status
            <SortAscendingOutlined
              className={`cursor-pointer ${
                sort === SortEnum.STATUS ? 'text-blue-400' : 'text-black'
              } ${
                sort === SortEnum.STATUS_DESC ? 'text-red-400' : 'text-black'
              }`}
              onClick={() => handleToggleSort(SortEnum.STATUS)}
            />
          </div>,
          <div>
            Target
            <SortAscendingOutlined
              className={`cursor-pointer ${
                sort === SortEnum.TARGET ? 'text-blue-400' : 'text-black'
              } ${
                sort === SortEnum.TARGET_DESC ? 'text-red-400' : 'text-black'
              }`}
              onClick={() => handleToggleSort(SortEnum.TARGET)}
            />
          </div>,
          '',
          ''
        ]}
      />
      <tbody>
        {sortedData.map((definition, index) => {
          const { id, merchant, country_code, active, target } = definition

          return (
            <Table.Row key={index}>
              <Table.Cell label={merchant} />
              <Table.Cell label={country_code} />
              <Table.Cell label={active ? 'Active' : 'Inactive'} />
              <Table.Cell label={target.toString()} />
              <Table.Cell
                label={' '}
                cellStyle={{ paddingRight: Space.LARGE * 3 }}
              />
              <Table.Cell
                render={
                  <div className="flex flex-row pr-4">
                    <Button
                      loading={isLoading}
                      onClick={handleDeleteClick(id, definition)}
                      size="large"
                      className="w-5/6 text-white rounded bg-red-400 mr-2"
                    >
                      Delete
                    </Button>
                    <Button
                      onClick={handleEditClick(index, definition)}
                      size="large"
                      className="w-5/6 text-white rounded bg-blue-400"
                    >
                      Edit
                    </Button>
                  </div>
                }
              />
            </Table.Row>
          )
        })}
      </tbody>
    </Table>
  )
}

export default DefinitionsTable
