import { Typography } from 'antd'
import { PromoIcon } from 'assets/icons'
import { BoxLabelEnum, prettyBoxLabel } from 'data/box'
import React from 'react'

const { Text } = Typography

interface FieldProps {
  label?: string | BoxLabelEnum[]
  value?: string | number
  wrapperClassName?: string
  onClick?: (x: any) => void
  isCleared?: boolean
  id?: string
  isPromo?: boolean
  isPromoNameLabel?: boolean
}

const Field = ({
  label,
  value,
  wrapperClassName,
  onClick,
  isCleared,
  id,
  isPromo,
  isPromoNameLabel
}: FieldProps) => (
  <div
    id={id}
    className={`flex flex-col flex-grow ${wrapperClassName || ''}`}
    onClick={onClick}
  >
    {label && (
      <Text strong className="text-xs pb-1 truncate">
        {prettyBoxLabel[String(label)]}
      </Text>
    )}
    {value && (
      <div className="flex flex-grow items-center">
        {isPromo && isPromoNameLabel && <PromoIcon className="pr-2" />}
        <Text
          editable={false}
          className={`p-2 truncate text-xs border border-solid rounded w-full cursor-pointer ${
            !isCleared
              ? 'bg-red-100 border-red-700'
              : 'bg-white border-gray-400'
          } ${isPromo && isCleared ? 'bg-green-100' : ''}`}
        >
          {value}
        </Text>
      </div>
    )}
  </div>
)

export default Field
