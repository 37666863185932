import { PermissionEnum } from 'data/reducers'
import { ForbiddenPage } from 'pages/app'
import SurveyCreationHome from 'pages/app/survey-creation'
import React from 'react'
import useHandleRequirePermissions from 'services/hooks/use-handle-require-permission'
import SentryRoute from 'services/sentry/sentry-route'
import { AppSubRoutes } from '../routes'

const SurveyCreationRoutesNavigator = () => {
  // TODO update permission
  const hasPermission = useHandleRequirePermissions([PermissionEnum.ADMIN])

  return (
    <>
      <SentryRoute
        exact
        path={AppSubRoutes.SURVEY_CREATION.HOME.pathname}
        component={hasPermission ? SurveyCreationHome : ForbiddenPage}
      />
    </>
  )
}

export default SurveyCreationRoutesNavigator
