import { Modal } from 'antd'
import React from 'react'
import { useMutation } from 'react-query'
import { ReceiptApi } from 'services/api/requests'

export const useHandleRejectAnnotation = (): [
  boolean,
  (id, reason) => Promise<any>
] => {
  const { mutateAsync, isLoading } = useMutation(
    ReceiptApi.rejectReceiptAnnotation
  )

  const handleReject = async (id, reason) => {
    if (id) {
      await mutateAsync(
        { id, reason },
        {
          onError: () => {
            Modal.error({
              title: 'Reject Error!',
              content: (
                <>
                  <p>Something went wrong while rejecting this receipt.</p>
                  <p>Please contact support.</p>
                  <p>#Receipt Id: {id}</p>
                </>
              )
            })
          }
        }
      )
    } else {
      Modal.error({
        title: 'Error!',
        content: (
          <>
            <p>Something went wrong while trying to reject this receipt.</p>
            <p>Receipt not found.</p>
            <p>Please contact support.</p>
          </>
        )
      })
    }
  }

  return [isLoading, handleReject]
}
