import { Layout, Modal } from 'antd'
import EmptyComponent from 'components/empty-component'
import LoadingRectangular from 'components/skeleton/loading-rectangular'
import SplashScreen from 'components/splash'
import { AppActions } from 'data/actions'
import { AppSelectors, VisualizerSelectors } from 'data/selectors'
import LinesTable from 'pages/app/visualizer/version-1/lines-table'
import ReceiptImage from 'pages/app/visualizer/version-1/receipt-image'
import SummaryTable from 'pages/app/visualizer/version-1/summary-table'
import React, { useEffect, useState } from 'react'
import { useQuery } from 'react-query'
import { useDispatch, useSelector } from 'react-redux'
import { MinervaReceiptApi } from 'services/api/requests/minerva'
import { HEADER_HEIGHT, VISUALIZER_SIDER_WIDTH } from 'services/styles/layout'

const { Content, Sider } = Layout

const VisualizerReceipt = () => {
  const dispatch = useDispatch()
  const isSiderOpen = useSelector(AppSelectors.siderState)
  const visualizerFilter = useSelector(VisualizerSelectors.receiptFilter)

  const [currentPage, setCurrentPage] = useState<number>(0)

  useEffect(() => {
    if (isSiderOpen) dispatch(AppActions.toggleSider())
  }, [])

  const handleOnLoadDataFailed = () => {
    Modal.error({
      title: 'Fetch Error!',
      content: (
        <>
          <p>Something went wrong while getting this receipt.</p>
          <p>Please try again</p>
        </>
      )
    })
  }

  const { isLoading, data } = useQuery(
    ['visualizer', visualizerFilter],
    ({ queryKey }: { queryKey: [string, string | undefined] }) =>
      MinervaReceiptApi.getExportReceipt(queryKey?.[1]),
    {
      enabled: !!visualizerFilter,
      onError: handleOnLoadDataFailed,
      initialData: { status: 204 }
    }
  )

  // @ts-ignore
  const status = data?.status

  return (
    <Layout
      style={{
        height: `calc(100vh - ${HEADER_HEIGHT}px)`
      }}
    >
      {isLoading ? (
        <SplashScreen />
      ) : (
        <>
          <Content className="z-20 overflow-scroll">
            {status === 204 ? (
              <div className="h-screen flex justify-center items-center">
                <EmptyComponent
                  description={
                    <>
                      <strong>Receipt not found</strong>
                      <p>Change your receipt filter to load more</p>
                    </>
                  }
                />
              </div>
            ) : (
              <>
                <SummaryTable rawData={data} />
                <LinesTable rawData={data.receipt_line_items} />
              </>
            )}
          </Content>
          <Sider
            width={VISUALIZER_SIDER_WIDTH}
            className="bg-white z-30 sticky right-0 overflow-scroll"
          >
            {status === 204 ? (
              <LoadingRectangular height={1000} width={2000} />
            ) : (
              <ReceiptImage
                data={data.msft_annotation}
                currentPage={currentPage}
                setCurrentPage={setCurrentPage}
              />
            )}
          </Sider>
        </>
      )}
    </Layout>
  )
}

export default VisualizerReceipt
