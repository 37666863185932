import { BoxActions } from 'data/box'
import Field from 'pages/app/extraction/version-1/task/components/field'
import Section from 'pages/app/extraction/version-1/task/components/section'
import {
  LineItemHeader,
  SectionEnum
} from 'pages/app/extraction/version-1/types'
import React from 'react'
import { useDispatch } from 'react-redux'

const OtherInfoSection = ({ mergedImportantFields }) => {
  const dispatch = useDispatch()

  const paymentInfo = LineItemHeader[SectionEnum.TOTAL].map(header => ({
    ...mergedImportantFields[header],
    label: header
  }))

  const dateTimeInfo = LineItemHeader[SectionEnum.TIME].map(header => ({
    ...mergedImportantFields[header],
    label: header
  }))

  const isAllCleared = [...paymentInfo, ...dateTimeInfo].every(
    item => !!item?.text || item.isNotRequired
  )

  const handleSetActiveId = (id?: string) =>
    dispatch(BoxActions.setActiveId(id))

  const renderRow = (headers, data) => (
    <div className="flex gap-2">
      {headers.map((header, index) => {
        const content = data.find(content => content.label === header)
        return (
          <Field
            key={`${header}_${content?.id}_${index}`}
            id={content?.id}
            wrapperClassName={`w-1/${headers.length}`}
            label={content?.label}
            value={content?.text || '\u00A0'}
            onClick={() => handleSetActiveId(content.id)}
            isCleared={!!content?.text || content.isNotRequired}
          />
        )
      })}
    </div>
  )

  return (
    <Section title="Other Info" isCleared={isAllCleared}>
      {renderRow(LineItemHeader[SectionEnum.TOTAL], paymentInfo)}
      {renderRow(LineItemHeader[SectionEnum.TIME], dateTimeInfo)}
    </Section>
  )
}

export default OtherInfoSection
