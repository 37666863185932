import { Button, Form, Image, Input, message } from 'antd'
import Logo from 'assets/misc/logo.png'
import { AuthSelectors } from 'data/selectors'
import React, { useState } from 'react'
import { useSelector } from 'react-redux'
import { useFirebase } from 'react-redux-firebase'
import { useHistory } from 'react-router'
import { AuthRoute } from 'routes/auth/routes'
import { emailValidationRules } from 'services/form-rules'

const formItemLayout = {
  labelCol: { span: 24, style: { margin: 0, padding: 0 } },
  wrapperCol: { span: 24, style: { margin: 0 } }
}

export default () => {
  const firebase = useFirebase()
  const authError = useSelector(AuthSelectors.firebaseAuthError)
  const history = useHistory()
  const [isLoading, setIsLoading] = useState<boolean>(false)

  const handleResetPassword = async ({ email }) => {
    try {
      setIsLoading(true)
      await firebase.resetPassword(email)
      message.open({
        key: 'reset-password-success',
        type: 'success',
        content: 'Successfully sent your reset password email',
        duration: 2
      })
    } catch (e) {
      message.open({
        key: 'reset-password-error',
        type: 'error',
        content: 'Your reset password email could not be sent',
        duration: 2
      })
    } finally {
      setIsLoading(false)
    }
  }

  const handleNavigationClick = () => history.push(AuthRoute.LOGIN)

  return (
    <div
      className="justify-center items-center flex flex-col"
      style={{ width: 350 }}
    >
      <Image preview={false} src={Logo} style={{ height: 120 }} alt="logo" />

      <Form
        {...formItemLayout}
        className="w-full mt-8 no-gap-form"
        key={'reset-password-form'}
        labelAlign="left"
        onFinish={handleResetPassword}
      >
        <Form.Item
          key={'reset-password-email'}
          name="email"
          rules={emailValidationRules}
        >
          <Input
            className="h-12 rounded-md mb-2"
            autoComplete="off"
            placeholder="Email"
          />
        </Form.Item>

        <Form.Item noStyle>
          <Button
            loading={isLoading}
            className="rounded-md h-12 mt-2 w-full bg-black text-white hover:bg-black focus:bg-black"
            htmlType="submit"
          >
            Reset Password
          </Button>
        </Form.Item>
        <div className="text-red-500">{authError?.message || ''}</div>
      </Form>

      <span
        className="underline mt-6 cursor-pointer"
        onClick={handleNavigationClick}
      >
        Go back to login
      </span>
    </div>
  )
}
