import { BoxLabelEnum, IMPORTANT_FIELDS } from 'data/box/types'
import { createSelector } from 'reselect'
import { traverseLinks } from 'services/helpers/graph'
import { parseCurrency, parseCurrencyStrict } from 'services/helpers/number'
import { ApplicationReducerState } from '../reducers'

// TODO: refactor naming to highlights
export const box = (state: ApplicationReducerState) => state.box

export const boxes = createSelector(box, boxState =>
  boxState.boxes.map(box => {
    if (box.label === BoxLabelEnum.ITEM_UNIT_PRICE) {
      const linkedProps = traverseLinks(boxState.boxes, box.links)
      const unitQty = linkedProps?.[BoxLabelEnum.ITEM_QUANTITY]?.text
      return unitQty
        ? {
            ...box,
            text: (parseCurrency(box.text) * parseCurrency(unitQty)).toString(),
            label: BoxLabelEnum.ITEM_VALUE
          }
        : box
    } else {
      return box
    }
  })
)

export const activeId = createSelector(box, box => box.activeId)

export const targetId = createSelector(box, box => box.targetId)

// round up to 2 decimal places
export const sumPrice = (isTaxIncluded?: boolean) =>
  createSelector(
    box,
    box =>
      Math.round(
        box.boxes.reduce((acc, curr) => {
          if (
            curr.label === BoxLabelEnum.ITEM_VALUE ||
            (!isTaxIncluded && curr.label === BoxLabelEnum.RECEIPT_TOTAL_TAX) ||
            curr.label === BoxLabelEnum.RECEIPT_TIP
          ) {
            return acc + parseCurrency(curr.text)
          }
          return acc
        }, 0) * 100
      ) / 100
  )

export const sumTax = createSelector(
  box,
  box =>
    Math.round(
      box.boxes.reduce((acc, curr) => {
        if (curr.label === BoxLabelEnum.RECEIPT_TOTAL_TAX) {
          return acc + parseCurrencyStrict(curr.text)
        }
        return acc
      }, 0) * 100
    ) / 100
)

export type SummaryType = {
  [BoxLabelEnum.ITEM_VALUE]?: number
  [BoxLabelEnum.RECEIPT_TOTAL_TAX]?: number
  [BoxLabelEnum.ITEM_PROMO_PRICE]?: number
  [BoxLabelEnum.RECEIPT_SUBTOTAL_VALUE]?: number
  [BoxLabelEnum.RECEIPT_TOTAL_VALUE]?: number
}

// Don't use tax field. use sumTax instead
export const summary = createSelector(
  box,
  box =>
    box.boxes.reduce((acc, curr) => {
      if (curr.label === BoxLabelEnum.ITEM_VALUE) {
        return {
          ...acc,
          [curr?.label]: (acc?.[curr?.label] || 0) + parseCurrency(curr.text)
        }
      }
      if (
        curr.label === BoxLabelEnum.RECEIPT_TOTAL_TAX ||
        curr.label === BoxLabelEnum.RECEIPT_TIP ||
        curr.label === BoxLabelEnum.ITEM_PROMO_PRICE
      ) {
        return {
          ...acc,
          [curr?.label]:
            (acc?.[curr?.label] || 0) + parseCurrencyStrict(curr.text)
        }
      } else if (
        curr.label === BoxLabelEnum.RECEIPT_SUBTOTAL_VALUE ||
        curr.label === BoxLabelEnum.RECEIPT_TOTAL_VALUE
      ) {
        return {
          ...acc,
          [curr?.label]: curr.text
        }
      }
      return acc
    }, {}) as SummaryType
)

// extracted Receipt Total
export const targetPrice = createSelector(box, box =>
  parseCurrency(
    box.boxes?.find(i => i.label === BoxLabelEnum.RECEIPT_TOTAL_VALUE)?.text ||
      '0'
  )
)

export const importantFields = createSelector(boxes, boxes =>
  boxes.reduce((acc, curr) => {
    // @ts-ignore
    if (IMPORTANT_FIELDS.includes(curr?.label)) {
      return { ...acc, [curr.label]: { text: curr.text, id: curr.id } }
    }
    return acc
  }, {})
)
