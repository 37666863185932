import Icon from '@ant-design/icons'
import React from 'react'

const AutoLinkingOffSvg = () => (
  <svg
    width="50px"
    height="30px"
    viewBox="0 0 60 40"
    version="1.1"
    xmlns="http://www.w3.org/2000/svg"
  >
    <title>autolinking off copy</title>
    <g
      id="Platform-3.0"
      stroke="none"
      strokeWidth="1"
      fill="none"
      fillRule="evenodd"
    >
      <g id="Field-Status" transform="translate(-32.000000, -700.000000)">
        <g
          id="autolinking-off-copy"
          transform="translate(32.000000, 700.000000)"
        >
          <rect
            id="Rectangle-Copy-34"
            fillOpacity="0.46637347"
            fill="#000000"
            x="0"
            y="0"
            width="60"
            height="40"
            rx="20"
          ></rect>
          <g
            id="link-svgrepo-com-(2)-copy"
            transform="translate(19.000000, 9.000000)"
            fill="#FFFFFF"
            fillRule="nonzero"
          >
            <path
              d="M20.5270337,1.4734182 C18.5628307,-0.490756106 15.3667065,-0.491161871 13.402233,1.47233616 L13.4026387,1.47274192 L10.2945668,4.58130947 L11.7292384,6.01568962 L14.8378514,2.90644579 L14.8375809,2.90617528 C16.0113416,1.73446051 17.9194129,1.73513678 19.0923621,2.90806885 C20.2654466,4.08100093 20.2653113,5.98999115 19.0923621,7.16332899 L14.4278872,11.8274652 C13.8596723,12.3958071 13.1041264,12.7087874 12.300429,12.7087874 C11.4967315,12.7087874 10.7410504,12.3958071 10.1728355,11.8274652 L8.73816385,13.2619806 C9.68969725,14.2135001 10.9548918,14.7376136 12.3005642,14.7376136 C13.6462367,14.7376136 14.9114312,14.2135001 15.8625588,13.2619806 L20.527169,8.59784439 C22.4909662,6.63326433 22.4909662,3.43718673 20.5270337,1.4734182 Z"
              id="Path"
            ></path>
            <path
              d="M7.16309542,19.0928272 L7.16309542,19.0928272 C5.98974044,20.2654888 4.08085759,20.2652183 2.90777313,19.092151 C1.73468867,17.9188131 1.73468867,16.0095524 2.90763787,14.8363498 L7.57157173,10.1724841 C8.74492671,8.99928148 10.6539448,8.99928148 11.8270293,10.1724841 L13.2618361,8.73796866 C11.2973626,6.7736591 8.10123837,6.77352385 6.13703538,8.73796866 L1.47283101,13.4018344 C-0.490966211,15.366144 -0.490966211,18.5623568 1.47296626,20.5268016 C2.45533827,21.509024 3.74542009,22 5.03577242,22 C6.32558372,22 7.61566553,21.5091593 8.59749651,20.5274779 L8.59776703,20.5273426 L11.7065152,17.4178283 L10.2717084,15.9833129 L7.16309542,19.0928272 Z"
              id="Path"
            ></path>
          </g>
          <line
            x1="20.5"
            y1="30.5"
            x2="41"
            y2="10"
            id="Line-3"
            stroke="#FFFFFF"
            strokeWidth="2"
            strokeLinecap="square"
            transform="translate(30.500000, 20.500000) scale(-1, 1) translate(-30.500000, -20.500000) "
          ></line>
        </g>
      </g>
    </g>
  </svg>
)

const AutoLinkingOffIcon = props => (
  <Icon component={AutoLinkingOffSvg} {...props} />
)

export default AutoLinkingOffIcon
