import { HistoryState } from 'data/history/types'
import handleActionsImmer from 'services/integrations/immer'
import HistoryConstants from './constants'

const initialState = {
  receiptIdSearch: null
}

const audit = handleActionsImmer<HistoryState>(
  {
    [HistoryConstants.SET_RECEIPT_ID_SEARCH]: (
      state: HistoryState,
      id: string
    ) => {
      state.receiptIdSearch = id
    }
  },
  initialState
)

export default audit
