import { PermissionEnum } from 'data/reducers'

export const AppRoute = {
  DASHBOARD: { pathname: '/', requirePermissions: [PermissionEnum.ANNOTATION] },
  EXTRACTION: {
    pathname: '/extraction',
    requirePermissions: [PermissionEnum.ANNOTATION]
  },
  ANNOTATION: {
    pathname: '/annotation-tool',
    requirePermissions: [PermissionEnum.ANNOTATION]
  },
  RECEIPT_REVIEW: {
    pathname: '/receipt-review',
    requirePermissions: [PermissionEnum.OFFLINE, PermissionEnum.EMAIL]
  },
  PII_REDACTION: {
    pathname: '/pii-redaction',
    requirePermissions: [PermissionEnum.ADMIN]
  },
  EMAIL_SUBJECT_WHITELIST: {
    pathname: '/email-subject-whitelist',
    requirePermissions: [PermissionEnum.SUBJECT_WHITELIST]
  },
  SURVEY_CREATION: {
    pathname: '/survey-creation',
    requirePermissions: [PermissionEnum.ADMIN]
  },
  AUDIT: { pathname: '/audit', requirePermissions: [PermissionEnum.ADMIN] },
  ADMIN: { pathname: '/admin', requirePermissions: [PermissionEnum.ADMIN] }
}

export const AppSubRoutes = {
  ANNOTATION: {
    HOME: {
      pathname: '/annotation-tool',
      requirePermissions: [PermissionEnum.ANNOTATION]
    },
    TASK: {
      pathname: '/annotation-tool/:countryCode/:retailerFilter',
      requirePermissions: [PermissionEnum.ANNOTATION]
    }
  },
  EXTRACTION: {
    HOME: {
      pathname: '/extraction',
      requirePermissions: [PermissionEnum.ANNOTATION]
    },
    V1: {
      HOME: {
        pathname: '/extraction/v1',
        requirePermissions: [PermissionEnum.ANNOTATION]
      },
      TASK: {
        pathname: '/extraction/v1/:countryCode',
        requirePermissions: [PermissionEnum.ANNOTATION]
      }
    },
    V2: {
      HOME: {
        pathname: '/extraction/v2',
        requirePermissions: [PermissionEnum.ANNOTATION]
      },
      TASK: {
        pathname: '/extraction/v2/:countryCode',
        requirePermissions: [PermissionEnum.ANNOTATION]
      }
    }
  },
  AUDIT: {
    HOME: {
      pathname: '/audit',
      requirePermissions: [PermissionEnum.ADMIN]
    },
    USER: {
      pathname: '/audit/:id',
      requirePermissions: [PermissionEnum.ADMIN]
    }
  },
  ADMIN: {
    MERCHANT_DEFINITION: {
      pathname: '/admin/merchant-definition',
      requirePermissions: [PermissionEnum.ADMIN]
    },
    COMPLETED_MERCHANTS: {
      pathname: '/admin/annotation-overview',
      requirePermissions: [PermissionEnum.ADMIN]
    },
    PROCESSING_HISTORY: {
      pathname: '/admin/processing-history',
      requirePermissions: [PermissionEnum.ADMIN]
    },
    VISUALIZER: {
      pathname: '/admin/visualizer',
      requirePermissions: [PermissionEnum.ADMIN]
    },
    USERS: {
      pathname: '/admin/users',
      requirePermissions: [PermissionEnum.ADMIN]
    },
    RELABEL_RECEIPT: {
      pathname: '/admin/relabel-receipt',
      requirePermissions: [PermissionEnum.ADMIN]
    }
  },
  VISUALIZER: {
    HOME: {
      pathname: '/admin/visualizer',
      requirePermissions: [PermissionEnum.ADMIN]
    },
    V1: {
      pathname: '/admin/visualizer/v1',
      requirePermissions: [PermissionEnum.ADMIN]
    },
    V2: {
      pathname: '/admin/visualizer/v2',
      requirePermissions: [PermissionEnum.ADMIN]
    },
    MERCHANT: {
      pathname: '/admin/visualizer/merchant/:countryCode',
      requirePermissions: [PermissionEnum.ADMIN]
    }
  },
  RECEIPT_REVIEW: {
    HOME: {
      pathname: '/receipt-review',
      requirePermissions: [PermissionEnum.OFFLINE, PermissionEnum.EMAIL]
    },
    TASK: {
      pathname: '/receipt-review/:country_code',
      requirePermissions: [PermissionEnum.OFFLINE]
    },
    EMAIL: {
      pathname: '/receipt-review/EMAIL',
      requirePermissions: [PermissionEnum.EMAIL]
    }
  },
  PII_REDACTION: {
    HOME: {
      pathname: '/pii-redaction',
      requirePermissions: [PermissionEnum.REDACTION]
    },
    TASK: {
      pathname: '/pii-redaction/:merchant_name',
      requirePermissions: [PermissionEnum.REDACTION]
    }
  },
  EMAIL_SUBJECT_WHITELIST: {
    HOME: {
      pathname: '/email-subject-whitelist',
      requirePermissions: [PermissionEnum.SUBJECT_WHITELIST]
    }
  },
  SURVEY_CREATION: {
    HOME: {
      pathname: '/survey-creation',
      requirePermissions: [PermissionEnum.ADMIN]
    }
  }
}

// const flatObjectValues = obj => {
//   return Object.values<string>(obj).reduce(
//     (acc, curr) => [
//       ...acc,
//       ...(typeof curr !== 'string' ? flatObjectValues(curr) : [curr])
//     ],
//     []
//   )
// }
//
// export const AppProtectedRoutes = {
//   admin: [],
//   disabled: [...Object.values(AppRoute), ...flatObjectValues(AppSubRoutes)],
//   editor: [
//     AppSubRoutes.ANNOTATION.HOME,
//     AppSubRoutes.ANNOTATION.TASK,
//     AppRoute.ADMIN,
//     ...flatObjectValues(AppSubRoutes.VISUALIZER),
//     ...flatObjectValues(AppSubRoutes.AUDIT),
//     ...flatObjectValues(AppSubRoutes.ADMIN)
//   ],
//   superEditor: [
//     AppRoute.ADMIN,
//     ...flatObjectValues(AppSubRoutes.VISUALIZER),
//     ...flatObjectValues(AppSubRoutes.AUDIT),
//     ...flatObjectValues(AppSubRoutes.ADMIN)
//   ]
// }
