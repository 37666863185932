import { useEffect, useState } from 'react'

const getWindowDimensions = () => {
  const { innerWidth: width, innerHeight: height } = window
  return {
    width,
    height
  }
}

const useWindowDimensions = () => {
  const [windowDimensions, setWindowDimensions] = useState(
    getWindowDimensions()
  )

  useEffect(() => {
    const _handleResize = () => {
      setWindowDimensions(getWindowDimensions())
    }

    window.addEventListener('resize', _handleResize)
    return () => window.removeEventListener('resize', _handleResize)
  }, [])

  return windowDimensions
}

export default useWindowDimensions
