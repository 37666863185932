export const getPiiRedactedScripts = () => `<!-- START INJECTED UG SCRIPTS -->
<script>
function unescapeHTMLCharacters(rawStr) {
  return rawStr
    .replace(/&nbsp;/g, ' ')
    .replace(/&amp;/g, '&')
    .replace(/&lt;/g, '<')
    .replace(/&gt;/g, '>')
}

function escapeWhiteSpace (rawStr) {
  return rawStr.replace(/s/g, '&nbsp;')
}

function serializeMessage (payload) {
  return JSON.stringify(payload)
}

function postMessage(message){
   window.parent.postMessage(serializeMessage(message), "*")
}

function replaceTextContent (anchorNode, textToReplace, label) {
  const labelClassName = label.toLowerCase().replaceAll(' ', '-')

  const originalEl = document.createElement('span')
  originalEl.classList.add("u-group-pii-label")
  originalEl.classList.add("u-group-pii-" + labelClassName)
  originalEl.setAttribute('style', 'display: none;')
  originalEl.textContent = textToReplace

  const replacedEl = document.createElement('span')
  replacedEl.classList.add("u-group-pii-redacted-" + labelClassName)
  replacedEl.textContent = label

  const wholeText = anchorNode.textContent

  anchorNode.parentNode.innerHTML = unescapeHTMLCharacters(anchorNode.parentNode.innerHTML).replace(
    wholeText,
    (found) => found.replace(textToReplace, originalEl.outerHTML + replacedEl.outerHTML)
  )
}

function isRedactedElement(classList) {
  return Array.from(classList || []).some(className => className.includes('u-group-pii-redacted-'))
}

function labelSelection (selectedRange, label) {
  let selectionBaseWholeText = selectedRange.anchorNode.textContent
  let selectionBaseOffset = selectedRange.baseOffset
  let selectionExtentOffset = selectedRange.extentOffset
  let text_to_replace = selectionBaseWholeText.slice(selectionBaseOffset,selectionExtentOffset).replace(/\\n+$/g,'') // prevent triple click adding new line

  if (text_to_replace.length <= 1) {
    console.log("Wont replace empty or short string")
    return
  }

  if (isRedactedElement(selectedRange.anchorNode.parentNode?.classList)) {
    // TODO
    window.alert('Wont replace redacted label')
    return
  }

  replaceTextContent(
    selectedRange.anchorNode,
    text_to_replace,
    label
  )

  postMessage({
    userAction: label,
    text: text_to_replace
  })
}

// set hotkeys:
const labelMap = {
  "1": "Name",
  "2": "Address",
  "q": "Membership id",
  "w": "Phone",
  "e": "Email",
  "r": "Username"
}

function reverseRedactedElement (selectedRedactedElement) {
  if(!selectedRedactedElement?.parentNode?.innerHTML) {
    console.log('reverseRedactedElement parentNode not found!!!')
    return
  }
 const redactedElement = selectedRedactedElement.previousElementSibling.outerHTML + selectedRedactedElement.outerHTML
 const originalText = selectedRedactedElement.previousElementSibling.textContent
 selectedRedactedElement.parentNode.innerHTML = selectedRedactedElement.parentNode.innerHTML.replace(redactedElement, originalText)

  postMessage({
    userAction: "clean",
    text: originalText
  })
}

window.addEventListener('keydown', e => {
  const keydown = e.key.toLowerCase()
  let selectedElement = window.getSelection()

  const selectedLabel = labelMap[ keydown ]

  if (selectedLabel) {
    labelSelection(selectedElement, selectedLabel)
  }

  if(keydown === "z" && isRedactedElement(selectedElement.anchorNode?.parentNode?.classList)){
    reverseRedactedElement(selectedElement.anchorNode?.parentNode)
  }
})
</script>
<!-- END INJECTED UG SCRIPTS -->
`

export const getRedactedStyles = () => `<!-- START INJECTED UG STYLES -->
<style>
a {
  pointer-events: none;
}

span[class*="u-group-pii-redacted-"] {
    font-weight: bold;
    color: red;
    text-transform: uppercase;
    user-select: all;
    cursor: pointer;
}
</style>
<!-- END INJECTED UG STYLES -->
`

export const appendScriptsToDocument = (documentHTML: String) => {
  const scripts = getPiiRedactedScripts()
  const redactedStyles = getRedactedStyles()

  const injectedStyleHTML = documentHTML.replace(
    '</head>',
    redactedStyles + '</head>'
  )

  return injectedStyleHTML.replace('</body>', scripts + '</body>')
}

// Note: "max heap memory" error if use replace(), HTML string is too big to traverse
export const removeInjectedScriptsFromDocument = (documentHTML: String) => {
  const [part1, scriptAndPart2] = documentHTML.split(
    '<!-- START INJECTED UG SCRIPTS -->'
  )
  const [scriptOnly, part2] = scriptAndPart2.split(
    '<!-- END INJECTED UG SCRIPTS -->'
  )

  return `${part1}${part2}`
}
