import React from 'react'
import { useQuery } from 'react-query'
import { QueryKeys } from 'services/api/query-key'
import { Noctua2ApprovalApi } from 'services/api/requests/notua-ii'
import TasksTable from './tasks-table'

const ReceiptReviewHomePage = () => {
  const { data } = useQuery([QueryKeys.ApprovalOverview], () =>
    Noctua2ApprovalApi.getApprovalOverview()
  )

  return (
    <div className="p-10">
      <h3 className="mb-4">Receipts Review</h3>
      <TasksTable data={data} />
    </div>
  )
}

export default ReceiptReviewHomePage
