import { Checkbox, Form, Input, Row } from 'antd'
import React, { FocusEvent, useEffect, useState } from 'react'

const ValidateTimeInput = ({
  label,
  fieldName,
  value,
  handleOnValid,
  format,
  hasNoTimeOff = false
}) => {
  const [form] = Form.useForm()

  const ruleTimePattern =
    format === 'HH:mm:ss'
      ? /^(?:[01]\d|2[0-3])[^0-9]?(?:[0-5]\d)[^0-9]?(?:[0-5]\d)$/
      : /^(0[0-9]|1[0-9]|2[0-3])[^0-9]?[0-5][0-9]$/

  const formattedValue = value?.format(format)

  const [latestValidValue, setLatestValidValue] = useState(
    formattedValue || null
  )

  useEffect(() => {
    if (ruleTimePattern.test(formattedValue)) {
      setLatestValidValue(formattedValue)
    }
  }, [formattedValue])

  const defaultTimeValue = format.replace(/HH|mm|ss/g, '00')

  const handleNoTimeCheckboxChange = (checked: boolean) =>
    handleOnValid(checked ? null : latestValidValue || defaultTimeValue)

  const handleOnBlur = (e: FocusEvent<HTMLInputElement>) => {
    const newValue = e.currentTarget.value

    if (!form.getFieldError(fieldName).length) {
      let formattedTime = newValue

      const nonDigitReg = /[^0-9]/g
      formattedTime = formattedTime.replace(nonDigitReg, '')

      formattedTime = formattedTime
        .split('')
        .reduce(
          (acc, curr, index, arr) =>
            acc +
            curr +
            (index % 2 !== 0 && index !== arr.length - 1 ? ':' : ''),
          ''
        )

      return handleOnValid(formattedTime)
    }

    return !!latestValidValue && handleOnValid(latestValidValue)
  }

  return (
    <>
      <Row justify="space-between">
        <span className="font-bold">{label}</span>
        {!hasNoTimeOff && (
          <div>
            <Checkbox
              defaultChecked={!value}
              onChange={({ target }) =>
                handleNoTimeCheckboxChange(target.checked)
              }
            >
              Has no time
            </Checkbox>
          </div>
        )}
      </Row>
      <Row>
        <Form
          className="w-full"
          form={form}
          onValuesChange={changesValue => {
            form.validateFields([fieldName]).catch(() => {
              if (form.getFieldError(fieldName).length === 0) {
                setLatestValidValue(changesValue[fieldName])
              }
            })
          }}
        >
          <Form.Item
            key={fieldName}
            name={fieldName}
            rules={[
              {
                required: true,
                type: 'string',
                pattern: ruleTimePattern,
                message: 'Invalid time format'
              }
            ]}
            initialValue={latestValidValue}
          >
            <Input
              disabled={!hasNoTimeOff && !value}
              value={latestValidValue}
              autoComplete="off"
              placeholder="Receipt Time..."
              onBlur={handleOnBlur}
            />
          </Form.Item>
        </Form>
      </Row>
    </>
  )
}

export default ValidateTimeInput
