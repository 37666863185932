import { createAction } from 'redux-actions'
import BoxConstants from './constants'
import { BoxProps } from './types'

export const addBox = (box: BoxProps) => createAction(BoxConstants.ADD_BOX)(box)

export const removeBox = (id: string | number) =>
  createAction(BoxConstants.REMOVE_BOX)(id)

export const editLabel = (payload: EditLabelProps) =>
  createAction(BoxConstants.EDIT_LABEL)(payload)

export const editText = (payload: EditTextProps) =>
  createAction(BoxConstants.EDIT_TEXT)(payload)

export const addLink = (payload: EditLinkProps) =>
  createAction(BoxConstants.ADD_LINK)(payload)

export const removeLink = (payload: EditLinkProps) =>
  createAction(BoxConstants.REMOVE_LINK)(payload)

export const setActiveId = (id: string | number) =>
  createAction(BoxConstants.SET_ACTIVE_ID)(id)

export const setTargetId = (id: string | number) =>
  createAction(BoxConstants.SET_TARGET_ID)(id)

export const resetBoxState = () => createAction(BoxConstants.RESET_BOX_STATE)()

interface EditProps {
  id: string | number
}

interface EditLabelProps extends EditProps {
  label: string
}

interface EditTextProps extends EditProps {
  text: string
}

interface EditLinkProps extends EditProps {
  targetId: string | number
}
