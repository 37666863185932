import { Input } from 'antd'
import React from 'react'

interface Props {
  label?: string
  error?: string
  placeholder?: string
  wrapperClassname?: string
  onChange?: (value) => void
  onBlur?: (value) => void
  type?: string
  defaultValue?: string | ReadonlyArray<string> | number
  value?: string | ReadonlyArray<string> | number
}

const StandardInput = (props: Props) => {
  const {
    error,
    label,
    wrapperClassname,
    onChange,
    onBlur,
    ...inputProps
  } = props
  const handleOnChange = event => {
    onChange && onChange(event.target.value)
  }

  const handleOnBlur = event => {
    onBlur && onBlur(event.target.value)
  }

  return (
    <div className={wrapperClassname}>
      {label && <div>{label}</div>}
      <Input {...inputProps} onBlur={handleOnBlur} onChange={handleOnChange} />
      {error && <div className="text-red-400">{error}</div>}
    </div>
  )
}

export default StandardInput
