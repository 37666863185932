import { PermissionEnum } from 'data/reducers'
import { ForbiddenPage } from 'pages/app'
import {
  VisualizeReceiptByMerchant,
  VisualizerHome,
  VisualizerReceiptV1,
  VisualizerReceiptV2
} from 'pages/app/visualizer'
import React from 'react'
import { AppSubRoutes } from 'routes/app/routes'
import useHandleRequirePermissions from 'services/hooks/use-handle-require-permission'
import SentryRoute from 'services/sentry/sentry-route'

const VisualizerRoutesNavigator = () => {
  const hasPermission = useHandleRequirePermissions([PermissionEnum.ADMIN])

  return (
    <>
      <SentryRoute
        exact
        path={AppSubRoutes.VISUALIZER.HOME.pathname}
        component={hasPermission ? VisualizerHome : ForbiddenPage}
      />
      <SentryRoute
        exact
        path={AppSubRoutes.VISUALIZER.V2.pathname}
        component={hasPermission ? VisualizerReceiptV2 : ForbiddenPage}
      />
      <SentryRoute
        exact
        path={AppSubRoutes.VISUALIZER.V1.pathname}
        component={hasPermission ? VisualizerReceiptV1 : ForbiddenPage}
      />
      <SentryRoute
        exact
        path={AppSubRoutes.VISUALIZER.MERCHANT.pathname}
        component={hasPermission ? VisualizeReceiptByMerchant : ForbiddenPage}
      />
    </>
  )
}

export default VisualizerRoutesNavigator
