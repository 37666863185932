import { BoxLabelEnum, BoxProps } from 'data/box'
import { ResponseReceiptAnnotationType } from 'services/api/requests/annotation'
import { parseCurrencyStrict } from 'services/helpers/number'

export const formatField = (box: BoxProps) => ({
  ...box,
  text:
    box.label === BoxLabelEnum.ITEM_PROMO_PRICE
      ? parseCurrencyStrict(box.text).toString()
      : box.text
})

const fallbackData = {
  metadata: { content: [], size_list: [{ width: 1000, height: 1000 }] },
  0: { fields: [], annotations: [] }
}
export const formatDataFields = (data: ResponseReceiptAnnotationType) => {
  if (!data) return fallbackData
  return {
    ...data,
    ...data.metadata.content?.reduce(
      (acc, _, index) => ({
        ...acc,
        [String(index)]: {
          ...data[String(index)],
          fields: data[String(index)].fields.map(field => formatField(field))
        }
      }),
      {}
    )
  }
}
