import { Dropdown, notification } from 'antd'
import { RecordActions } from 'pages/app/annotation-tool/reducers/record/actions'
import { SelectionActions } from 'pages/app/annotation-tool/reducers/selection/actions'
import { TaskContext } from 'pages/app/annotation-tool/task/context'
import BoxDropdownMenu from 'pages/app/annotation-tool/task/sider/components/box-dropdown-menu'
import { getLinkableFields } from 'pages/app/annotation-tool/task/types'
import React, { useContext } from 'react'
import { Field } from 'services/api/requests/types'
import {
  getBoxColor,
  getBoxColorDarker,
  getBoxColorLight
} from 'services/styles/color'
import { Space } from 'services/styles/spacing'

const _renderMessageContent = (action: string, sourceBox, targetBox) => (
  <span>
    {action}{' '}
    <span
      className="rounded text-white font-bold px-2 pb-0.5"
      style={{ backgroundColor: getBoxColor(sourceBox?.label) }}
    >
      {sourceBox?.text}
    </span>{' '}
    to{' '}
    <span
      className="rounded text-white font-bold px-2 pb-0.5"
      style={{ backgroundColor: getBoxColor(targetBox?.label) }}
    >
      {targetBox?.text}
    </span>
  </span>
)

interface FieldBoxProps {
  field: Field
}

const FieldBox = ({ field }: FieldBoxProps) => {
  const { state: parentState, dispatchTask: parentDispatcher } = useContext(
    TaskContext
  )

  const { record, currentPage, mode, selection } = parentState
  const { linkMode } = mode

  const { target: targetSelection, source: sourceSelection } = selection

  const currentPageData = record.pages[currentPage]
  const { fields, words } = currentPageData

  const handleDispatchTask = (action, value) =>
    parentDispatcher({ type: action, payload: value })

  const { label, fieldElements, id } = field

  const _handleTargetSelectionChange = newValue =>
    handleDispatchTask(SelectionActions.SET_TARGET_ID, newValue)
  const _handleSourceSelectionChange = newValue =>
    handleDispatchTask(SelectionActions.SET_SOURCE_ID, newValue)

  const _addLink = (payload: { id: string; targetId: string }) =>
    handleDispatchTask(RecordActions.ADD_LINK, payload)

  const _removeLink = (payload: { id: string; targetId: string }) =>
    handleDispatchTask(RecordActions.REMOVE_LINK, payload)

  const handleLinkEvent = (source, target) => () => {
    if (!linkMode) {
      _handleTargetSelectionChange(
        target === targetSelection ? undefined : target
      )
    }

    if (linkMode && source && source !== target) {
      const payload = { id: source, targetId: target }
      const targetBox = fields.find(field => field.id === target)
      const sourceBox = fields.find(field => field.id === source)
      const isExisted = targetBox.links.includes(source)

      const linkableFields =
        sourceBox && getLinkableFields(sourceBox.label, targetBox.label)

      if (isExisted) {
        _removeLink(payload)
        notification.warning({
          style: {
            borderRadius: 12,
            padding: Space.TINY,
            backgroundColor: 'rgba(255,255,255,0.7)'
          },
          message: _renderMessageContent('Unlinked', sourceBox, targetBox),
          placement: 'bottomLeft'
        })
      } else if (linkableFields) {
        _addLink(payload)
        notification.success({
          style: {
            borderRadius: 12,
            padding: Space.TINY,
            backgroundColor: 'rgba(255,255,255,0.7)'
          },
          message: _renderMessageContent('Linked', sourceBox, targetBox),
          placement: 'bottomLeft'
        })
      }
    }
  }

  const isFocused = targetSelection === id
  const renderLabeledWords = () =>
    fieldElements.map(elementId => {
      const wordBox = words.find(word => word.id === elementId)
      if (!wordBox) return null
      const [
        { x: x0, y: y0 },
        { x: x1, y: y1 },
        { x: x2, y: y2 },
        { x: x3, y: y3 }
      ] = wordBox.boundingPoly.vertices

      return (
        <path
          key={elementId}
          className="z-20 cursor-pointer opacity-50 absolute"
          d={`M${x0},${y0}
       L${x1},${y1}
       L${x2},${y2} 
       L${x3},${y3}z`}
          fill={getBoxColorLight(label)}
        />
      )
    })

  return (
    <Dropdown
      placement="bottomCenter"
      overlay={<BoxDropdownMenu data={field} />}
      overlayStyle={{ width: '14rem', minWidth: 'min-content' }}
      trigger={['contextMenu']}
      visible={sourceSelection === id && !linkMode}
      onVisibleChange={visible =>
        !linkMode && _handleSourceSelectionChange(visible ? id : null)
      }
    >
      <g
        onClick={handleLinkEvent(targetSelection, id)}
        className="highlight"
        strokeWidth={isFocused ? 8 : 2}
        stroke={isFocused ? 'red' : getBoxColorDarker(label)}
      >
        {renderLabeledWords()}
      </g>
    </Dropdown>
  )
}

export default FieldBox
