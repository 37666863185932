import { ColumnsType } from 'antd/lib/table/interface'
import TableStripedRows from 'components/table-striped-rows'
import BaseTable from 'pages/app/visualizer/version-1/base-table'
import React from 'react'

const formatData = rawData => {
  return (
    rawData
      .map(line => {
        return Object.entries<any>(line).reduce((acc, [key, value]) => {
          if (key === 'item_promotions' && value.length) {
            acc = {
              ...acc,
              children: value
                .map(promo => ({
                  key: promo?.order,
                  item_code: promo?.title,
                  item_unit_type: promo?.prefix,
                  item_value: promo?.reduced_by_cents,
                  item_unit_price: promo?.to_reduce_cents
                }))
                .slice()
                .sort((b, a) => b.key - a.key)
            }
          }
          return {
            ...acc,
            [key + '']: value,
            key: line.receipt_item_id
          }
        }, {})
      })
      .slice()
      .sort((b, a) => b.key - a.key) || []
  )
}

const renderNumberContent = (toFixed = undefined) => text => (
  <span style={{ fontFamily: 'monospace' }}>
    {!isNaN(text) && toFixed ? Number(text).toFixed(toFixed) : text}
  </span>
)

const LinesTable = ({ rawData }) => {
  const formattedData = rawData ? formatData(rawData) : []

  const fixedColumn = [
    {
      title: 'Name',
      dataIndex: 'item_code'
    },
    {
      title: 'Item Type',
      dataIndex: 'item_type'
    },
    {
      title: 'Unit Type',
      dataIndex: 'item_unit_type'
    },
    {
      title: 'Quantity',
      dataIndex: 'item_quantity',
      align: 'right',
      render: renderNumberContent()
    },
    {
      title: 'Barcode/SKU',
      dataIndex: 'item_product_identifier',
      render: renderNumberContent()
    },
    {
      title: 'Unit Price',
      dataIndex: 'item_unit_price',
      align: 'right',
      render: renderNumberContent()
    },
    {
      title: 'Price',
      dataIndex: 'item_value',
      align: 'right',
      render: renderNumberContent()
    }
  ] as ColumnsType

  return (
    <BaseTable title={'Lines Items'}>
      <TableStripedRows
        loading={false}
        dataSource={formattedData}
        columns={fixedColumn}
      />
    </BaseTable>
  )
}

export default LinesTable
