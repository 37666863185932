export enum RecordActions {
  SET_RECORD = 'SET_RECORD',
  RESET_WORKING_STATE = 'RESET_WORKING_STATE',

  EDIT_PURCHASE_TIME = 'EDIT_PURCHASE_TIME',
  EDIT_PURCHASE_DATE = 'EDIT_PURCHASE_DATE',
  EDIT_TOTAL = 'EDIT_TOTAL',
  EDIT_REJECTION_REASON = 'EDIT_REJECTION_REASON',
  EDIT_ORDER_ID = 'EDIT_ORDER_ID',
  EDIT_USER_EMAIL = 'EDIT_USER_EMAIL',
  EDIT_RETAILER_EMAIL = 'EDIT_RETAILER_EMAIL',
  EDIT_RETAILER_NAME = 'EDIT_RETAILER_NAME'
}
