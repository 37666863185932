import Overlay from 'components/modals'
import { DialogKeys } from 'components/modals/types'
import { OverlayActions } from 'data/actions'
import { AuthSelectors } from 'data/selectors'
import React, { useEffect } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { isEmpty } from 'react-redux-firebase'
import { useHistory, useLocation } from 'react-router'
import AppRoutesNavigator from 'routes/app'
import AuthRoutesNavigator from 'routes/auth'
import { isOnAuthRoutes } from 'routes/auth/routes'

const MainRoutesNavigator = () => {
  const dispatch = useDispatch()
  const history = useHistory()
  const { pathname } = useLocation()
  const auth = useSelector(AuthSelectors.firebaseAuthState)
  const loggedIn = !isEmpty(auth)
  const profile = useSelector(AuthSelectors.firebaseProfile)

  useEffect(() => {
    if (auth.email) {
      // hide session timeout dialog when logged in
      dispatch(OverlayActions.hideDialog({ key: DialogKeys.SESSION_TIMEOUT }))

      // update protected routes
      // if (profile?.role) {
      //   dispatch(
      //     AppActions.setProtectedRoutes(
      //       AppProtectedRoutes?.[profile.role] || []
      //     )
      //   )
      // }
      if (isOnAuthRoutes(pathname)) {
        history.replace('/')
      }
    }
  }, [auth, profile?.role])

  // disable check app version on client side
  // useCheckAppVersion()

  return (
    <>
      {!loggedIn ? <AuthRoutesNavigator /> : <AppRoutesNavigator />}
      <Overlay>
        <Overlay.Dialog />
      </Overlay>
    </>
  )
}

export default MainRoutesNavigator
