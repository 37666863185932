import { CurrentPageActions } from 'pages/app/extraction/version-2/reducers/current-page/actions'
import { LoaderActions } from 'pages/app/extraction/version-2/reducers/loader/actions'
import { ModeActions } from 'pages/app/extraction/version-2/reducers/mode/actions'
import { MouseEventActions } from 'pages/app/extraction/version-2/reducers/mouse-event/actions'
import { RecordActions } from 'pages/app/extraction/version-2/reducers/record/actions'
import { SelectionActions } from 'pages/app/extraction/version-2/reducers/selection/actions'
import { SubRequirementsActions } from 'pages/app/extraction/version-2/reducers/sub-requirements/actions'
import { DBReceiptAnnotation, Vertex } from 'services/api/requests/types'
import handleActionsImmer from 'services/integrations/immer'

export const initialState: CombinedStates = {
  loaders: {
    isLoading: false,
    isImageLoaded: false
  },
  mode: {
    isUnitPrice: false,
    editingText: false,
    isPanningMode: false,
    linkMode: false,
    autoFocus: false
  },
  mouseEvent: {
    popupList: [],
    userActions: []
  },
  selection: {
    target: null,
    source: null
  },
  subRequirements: {
    quantity: false,
    price: true,
    barcode: false
  },
  currentPage: 0,
  record: null
}

export type UserAction = {
  type: string
  boundingPoly: { vertices: Vertex[] }
  fieldElements: string[]
}

export type SubRequirements = {
  quantity: boolean
  price: boolean
  barcode: boolean
}

export interface CombinedStates {
  loaders: {
    isLoading: boolean
    isImageLoaded: boolean
  }
  mode: {
    isUnitPrice: boolean
    editingText: boolean
    isPanningMode: boolean
    linkMode: boolean
    autoFocus: boolean
  }
  mouseEvent: {
    popupList: (boolean | null)[]
    userActions: UserAction[]
  }
  selection: {
    target?: string
    source?: string
  }
  subRequirements: SubRequirements
  currentPage: number
  record?: DBReceiptAnnotation
}

// TODO separate reducers
export const combinedReducers = handleActionsImmer<CombinedStates>(
  {
    // record reducer
    [RecordActions.SET_RECORD]: (
      state: CombinedStates,
      newRecord: DBReceiptAnnotation
    ) => {
      state.record = newRecord
    },
    [RecordActions.EDIT_TEXT]: (state: CombinedStates, { id, text }) => {
      const currentPage = state.currentPage
      const currentPageData = state.record.pages[currentPage]
      state.record.pages[currentPage] = {
        ...currentPageData,
        fields: currentPageData.fields.map(field =>
          field.id === id ? { ...field, text } : field
        )
      }
    },
    [RecordActions.EDIT_LABEL]: (state: CombinedStates, { id, label }) => {
      const currentPage = state.currentPage
      const currentPageData = state.record.pages[currentPage]
      state.record.pages[currentPage] = {
        ...currentPageData,
        fields: currentPageData.fields.map(field =>
          field.id === id ? { ...field, label } : field
        )
      }
    },
    [RecordActions.ADD_FIELD]: (state: CombinedStates, newField) => {
      const currentPage = state.currentPage
      const currentPageData = state.record.pages[currentPage]
      const currentFields = currentPageData.fields
      state.record.pages[currentPage].fields = [...currentFields, newField]
    },
    [RecordActions.REMOVE_FIELD]: (state: CombinedStates, id) => {
      const currentPage = state.currentPage
      const currentPageData = state.record.pages[currentPage]
      const currentFields = currentPageData.fields
      state.record.pages[currentPage].fields = currentFields.reduce(
        (acc, box) =>
          box.id !== id
            ? [
                ...acc,
                {
                  ...box,
                  links: box.links.filter(item => item !== id)
                }
              ]
            : acc,
        []
      )

      // reset active id
      state.selection.source = undefined
      if (id === state.selection.target) {
        state.selection.target = undefined
      }
    },
    [RecordActions.ADD_LINK]: (state: CombinedStates, { id, targetId }) => {
      const currentPage = state.currentPage
      const currentPageData = state.record.pages[currentPage]
      const currentFields = currentPageData.fields
      state.record.pages[currentPage].fields = currentFields.map(box =>
        box.id === id
          ? { ...box, links: [...box.links, targetId] }
          : box.id === targetId
          ? { ...box, links: [...box.links, id] }
          : box
      )
    },
    [RecordActions.REMOVE_LINK]: (state: CombinedStates, { id, targetId }) => {
      const currentPage = state.currentPage
      const currentPageData = state.record.pages[currentPage]
      const currentFields = currentPageData.fields
      state.record.pages[currentPage].fields = currentFields.map(box =>
        box.id === id
          ? { ...box, links: box.links.filter(item => item !== targetId) }
          : box.id === targetId
          ? { ...box, links: box.links.filter(item => item !== id) }
          : box
      )
    },
    // selection reducer
    [SelectionActions.SET_SOURCE_ID]: (
      state: CombinedStates,
      newValue: string
    ) => {
      state.selection.source = newValue
    },
    [SelectionActions.SET_TARGET_ID]: (
      state: CombinedStates,
      newValue: string
    ) => {
      state.selection.target = newValue
    },
    // mode reducer
    [ModeActions.SET_EDITING_TEXT]: (
      state: CombinedStates,
      newValue: boolean
    ) => {
      state.mode.editingText = newValue
    },
    [ModeActions.SET_PANNING_MODE]: (
      state: CombinedStates,
      newValue: boolean
    ) => {
      state.mode.isPanningMode = newValue
    },
    [ModeActions.SET_IS_UNIT_PRICE]: (
      state: CombinedStates,
      newValue: boolean
    ) => {
      state.mode.isUnitPrice = newValue
    },
    [ModeActions.SET_LINK_MODE]: (state: CombinedStates, newValue: boolean) => {
      state.mode.linkMode = newValue
    },
    [ModeActions.SET_AUTO_FOCUS]: (
      state: CombinedStates,
      newValue: boolean
    ) => {
      state.mode.autoFocus = newValue
    },
    // loader reducer
    [LoaderActions.SET_IMAGE_LOADER]: (
      state: CombinedStates,
      newValue: boolean
    ) => {
      state.loaders.isImageLoaded = newValue
    },
    //  mouse reducer
    [MouseEventActions.SET_POPUP_LIST]: (
      state: CombinedStates,
      newValue: (boolean | null)[]
    ) => {
      state.mouseEvent.popupList = newValue
    },
    [MouseEventActions.SET_SELECT_AREA_ACTIONS]: (
      state: CombinedStates,
      newValue: UserAction[]
    ) => {
      state.mouseEvent.userActions = newValue
    },
    [MouseEventActions.ADD_SELECT_AREA_ACTIONS]: (
      state: CombinedStates,
      newValue: UserAction[]
    ) => {
      const currentActions = state.mouseEvent.userActions
      state.mouseEvent.userActions = [...currentActions, ...newValue]
    },
    //  current page reducer
    [CurrentPageActions.SET_CURRENT_PAGE]: (
      state: CombinedStates,
      newValue: number
    ) => {
      state.currentPage = newValue
    },
    // sub-requirements
    [SubRequirementsActions.SET_SUB_REQUIREMENTS]: (
      state: CombinedStates,
      newValue: SubRequirements
    ) => {
      state.subRequirements = { ...state.subRequirements, ...newValue }
    },
    //  reset
    [RecordActions.RESET_WORKING_STATE]: (state: CombinedStates) => {
      state.record = null
      state.selection.target = null
      state.selection.source = null
      state.mode.isUnitPrice = false
      state.currentPage = 0
    }
  },
  initialState
)
