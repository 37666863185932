export const DialogKeys = {
  REJECT_RECEIPT: 'REJECT_RECEIPT',
  VALIDATE_RECEIPT: 'VALIDATE_RECEIPT',
  CREATE_DEFINITION: 'CREATE_DEFINITION',
  EDIT_DEFINITION: 'EDIT_DEFINITION',
  PROMPT_IDLE: 'PROMPT_IDLE',
  SESSION_TIMEOUT: 'SESSION_TIMEOUT',
  ADD_NEW_VENDOR: 'ADD_NEW_VENDOR',
  CONFIRM: 'CONFIRM',
  SURVEY_CREATION_PREVIEW: 'SURVEY_CREATION_PREVIEW'
}
