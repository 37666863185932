import { DeleteOutlined, FilterOutlined } from '@ant-design/icons'
import { Checkbox, Dropdown, Input } from 'antd'
import Table from 'components/table'
import { PermissionEnum, profileSchema } from 'data/reducers'
import { AuthSelectors } from 'data/selectors'
import React, { ReactNode, useState } from 'react'
import { useSelector } from 'react-redux'
import { useFirestore, useFirestoreConnect } from 'react-redux-firebase'
import { toProperCase } from 'services/helpers/canonicalization'
import { Space } from 'services/styles/spacing'

const availablePermissions = Object.keys(PermissionEnum)

interface MenuItemProps {
  actionComponent: ReactNode | string
}

const MenuItem = ({ actionComponent }: MenuItemProps) => (
  <div className={'my-1'}>{actionComponent}</div>
)

const UsersTable = () => {
  useFirestoreConnect(['users'])
  const usersData = useSelector(AuthSelectors.firestoreCollection) as {
    users: any
  }

  const [filterProps, setFilterProps] = useState({
    username: '',
    email: ''
  })

  const [isLoading, setIsLoading] = useState(false)
  const firestore = useFirestore()

  const renderPermissionCheckbox = (
    targetPermission: PermissionEnum,
    userPermissions: PermissionEnum[],
    handleOnChange: (props: PermissionEnum[]) => Promise<void>
  ) => {
    const findPermission = userPermissions.find(i => i === targetPermission)

    return (
      <Checkbox
        disabled={isLoading}
        checked={!!findPermission}
        onChange={async e => {
          const newPermissions = e.target.checked
            ? [...userPermissions, targetPermission]
            : userPermissions.filter(i => i !== targetPermission)

          await handleOnChange(newPermissions)
        }}
      />
    )
  }

  const UsernameMenu = (
    <div className={'rounded-lg bg-white border border-gray-400 border-solid'}>
      <div className={'p-2'}>
        <MenuItem
          actionComponent={
            <Input
              onClick={e => {
                e.preventDefault()
                e.stopPropagation()
              }}
              placeholder="Filter username..."
              onChange={e =>
                setFilterProps(prev => ({
                  ...prev,
                  username: e.target.value
                }))
              }
            />
          }
        />
      </div>
    </div>
  )

  const EmailMenu = (
    <div className={'rounded-lg bg-white border border-gray-400 border-solid'}>
      <div className={'p-2'}>
        <MenuItem
          actionComponent={
            <Input
              onClick={e => {
                e.preventDefault()
                e.stopPropagation()
              }}
              placeholder="Filter email..."
              onChange={e =>
                setFilterProps(prev => ({
                  ...prev,
                  email: e.target.value
                }))
              }
            />
          }
        />
      </div>
    </div>
  )

  const filteredData = Object.entries(usersData?.users || {}).filter(
    ([, value]) => {
      const { username, email } = value as profileSchema
      return (
        username.toLowerCase().includes(filterProps.username.toLowerCase()) &&
        email.toLowerCase().includes(filterProps.email.toLowerCase())
      )
    }
  )

  if (!usersData?.users) return <span>Loading...</span>

  return (
    <Table>
      <Table.Header
        headerStyle={[{}, {}, { textAlign: 'center' }]}
        titles={[
          <Dropdown overlay={UsernameMenu} trigger={['click']}>
            <div className="cursor-pointer">
              Username
              <FilterOutlined className="px-2" />
              <span className="text-gray-400">{filterProps.username}</span>
            </div>
          </Dropdown>,
          <Dropdown overlay={EmailMenu} trigger={['click']}>
            <div className="cursor-pointer">
              Email
              <FilterOutlined className="px-2" />
              <span className="text-gray-400">{filterProps.email}</span>
            </div>
          </Dropdown>,
          ...availablePermissions.map(i => toProperCase(i)),
          ''
        ]}
      />
      <tbody>
        {usersData?.users &&
          filteredData.map(([key, value]) => {
            const {
              username,
              email,
              role,
              permissions
            } = value as profileSchema

            const handleChangePermission = async (newPermissions: string[]) => {
              setIsLoading(true)
              try {
                await firestore.update(
                  { collection: 'users', doc: key },
                  {
                    username,
                    email,
                    role,
                    permissions: newPermissions
                  }
                )
              } finally {
                setIsLoading(false)
              }
            }

            const handleDeletePermissions = async () => {
              setIsLoading(true)
              try {
                await firestore.update(
                  { collection: 'users', doc: key },
                  {
                    username,
                    email,
                    role,
                    permissions: []
                  }
                )
              } finally {
                setIsLoading(false)
              }
            }

            return (
              <Table.Row key={key}>
                <Table.Cell label={username} />
                <Table.Cell label={email} />
                {availablePermissions.map((permission: PermissionEnum) => (
                  <Table.Cell
                    key={permission}
                    cellStyle={{ textAlign: 'center' }}
                    render={renderPermissionCheckbox(
                      permission,
                      permissions || [],
                      handleChangePermission
                    )}
                  />
                ))}
                <Table.Cell
                  cellStyle={{
                    display: 'flex',
                    alignItems: 'center',
                    textAlign: 'center',
                    margin: Space.SMALL,
                    cursor: 'pointer',
                    color: 'red'
                  }}
                  render={<DeleteOutlined onClick={handleDeletePermissions} />}
                />
              </Table.Row>
            )
          })}
      </tbody>
    </Table>
  )
}

export default UsersTable
