enum ApprovalKeys {
  ApprovalOverview = 'receipt-approval-overview',
  PhysicalPending = 'pending-receipt',
  EmailPending = 'pending-email-receipt',
  SearchOfflineRetailer = 'search-retailer',
  SearchEmailRetailer = 'search-email-retailer',
  SearchUser = 'search-user'
}

enum PiiRedactionKeys {
  PiiRedactionOverview = 'redaction-overview',
  PiiRedactionReceipt = 'pii-redaction-receipt'
}

enum EmailSubjectWhitelistKeys {
  EmailSubjectWhitelistReceipts = 'email-subject-whitelist-receipts',
  EmailSubjectWhitelistReceiptsById = 'email-subject-whitelist-receipts-id',
  EmailSubjectWhitelistRedactImage = 'email-subject-whitelist-redact'
}

enum RelabelReceipt {
  RelabelReceipt = 'relabel-receipt'
}

export const QueryKeys = {
  ...ApprovalKeys,
  ...PiiRedactionKeys,
  ...EmailSubjectWhitelistKeys,
  ...RelabelReceipt
}
