import { BoxLabelEnum } from 'data/box'

interface MerchantDefinitionRequirement {
  required?: boolean
  disabled?: boolean
}

// only date, time, total, line names are required
export const BaseMerchantDefinition: MerchantDefinition = {
  [BoxLabelEnum.ITEM_DESCRIPTION]: { required: true },
  [BoxLabelEnum.RECEIPT_TOTAL_VALUE]: { required: true },
  [BoxLabelEnum.RECEIPT_PURCHASE_DATE]: { required: true },
  [BoxLabelEnum.RECEIPT_PURCHASE_TIME]: { required: true },
  [BoxLabelEnum.RECEIPT_MERCHANT_NAME]: { required: true },
  [BoxLabelEnum.ITEM_VALUE]: {},
  [BoxLabelEnum.ITEM_QUANTITY]: {},
  [BoxLabelEnum.ITEM_UNIT_PRICE]: {},
  [BoxLabelEnum.ITEM_CODE]: {},
  [BoxLabelEnum.RECEIPT_SUBTOTAL_VALUE]: {},
  [BoxLabelEnum.RECEIPT_TOTAL_TAX]: {},
  [BoxLabelEnum.RECEIPT_TIP]: {},
  [BoxLabelEnum.RECEIPT_STORE_ADDRESS]: {},
  [BoxLabelEnum.RECEIPT_STORE_PHONE]: {},
  [BoxLabelEnum.ITEM_UNIT_TYPE]: {},
  [BoxLabelEnum.RECEIPT_STORE_ID]: {},
  [BoxLabelEnum.RECEIPT_STORE_NAME]: {},
  [BoxLabelEnum.RECEIPT_TOTAL_DISCOUNT]: {},
  [BoxLabelEnum.RECEIPT_CASH_REGISTER_ID]: {},
  [BoxLabelEnum.RECEIPT_TRANSACTION_ID]: {},
  [BoxLabelEnum.ITEM_PROMO_NAME]: {},
  [BoxLabelEnum.ITEM_PROMO_PRICE]: {},
  [BoxLabelEnum.OTHER]: {},
  [BoxLabelEnum.ITEM_OTHER]: {}
}

export type MerchantDefinition = {
  [key in BoxLabelEnum]: MerchantDefinitionRequirement
}
