import { createSelector } from 'reselect'
import { ApplicationReducerState } from '../reducers'

export const firebaseState = (state: ApplicationReducerState) => state.firebase
export const firestoreState = (state: ApplicationReducerState) =>
  state.firestore

export const firebaseAuthState = createSelector(
  firebaseState,
  firebase => firebase.auth
)

export const firebaseProfile = createSelector(
  firebaseState,
  firebase => firebase.profile
)

export const firebaseAuthError = createSelector(
  firebaseState,
  firebase => firebase.authError
)

export const firebaseErrors = createSelector(
  firebaseState,
  firebase => firebase.errors
)

export const firestoreCollection = createSelector(
  firestoreState,
  firestore => firestore.data
)
