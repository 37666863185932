import { Typography } from 'antd'
import { AuditSelectors } from 'data/selectors'
import FieldCell from 'pages/app/annotation-tool/task/content/components/field-cell'
import Section from 'pages/app/annotation-tool/task/content/components/section'
import ItemsSectionHeader from 'pages/app/annotation-tool/task/content/line-item-section/header'
import { columnsStyle } from 'pages/app/annotation-tool/task/content/line-item-section/styles'
import { getSinglePageLineItem } from 'pages/app/annotation-tool/task/helpers'
import {
  AnnotationHotkeys,
  AnnotationLabelEnum,
  ITEM_FIELDS,
  LineItemHeader,
  SectionEnum
} from 'pages/app/annotation-tool/task/types'
import React from 'react'
import { useSelector } from 'react-redux'
import { DBReceiptAnnotation, Field } from 'services/api/requests/types'
import { toStartCase } from 'services/helpers/canonicalization'

const { Text } = Typography

export default ({ currPage }) => {
  const data = useSelector(
    AuditSelectors.receiptData
  ) as DBReceiptAnnotation & { definition: any }

  if (!data) return null
  const merchantDefinition = data.definition
  const totalPages = data?.pages?.length || 0
  const highlights = data.pages[currPage].fields

  const mergeLineItems = getSinglePageLineItem(highlights)

  // single page only
  const missingRegularFields = merchantDefinition?.reduce(
    (acc, curr) =>
      curr?.required &&
      !highlights.find(i => i.label === curr.label) &&
      !ITEM_FIELDS.includes(curr.label as AnnotationLabelEnum)
        ? [...acc, { label: curr.label, text: '' }]
        : acc,
    []
  )
  const missingLineItems = mergeLineItems.filter(
    item =>
      !(
        (AnnotationLabelEnum.ITEM_DESCRIPTION in item &&
          AnnotationLabelEnum.ITEM_PRICE in item) ||
        AnnotationLabelEnum.ITEM_PROMO_NAME in item
      )
  )

  // render
  const renderRegularRow = (data: Partial<Field>[]) =>
    merchantDefinition?.map(definition => {
      const isItem = ITEM_FIELDS.includes(definition.label)
      let highlights = data.filter(i => i.label === definition.label)
      if (!highlights.length) {
        highlights = [definition]
      }

      const annotationHotkey = AnnotationHotkeys[definition.label]
      return !isItem
        ? highlights.map((highlight, idx) => (
            <div
              className="flex flex-row w-full pb-2"
              key={`${highlight}_${highlight?.id}_${idx}`}
            >
              <div className="w-1/3">
                <Text strong>{toStartCase(highlight.label)}</Text>
              </div>
              <FieldCell
                id={highlight?.id}
                wrapperClassName={'w-2/3'}
                placeholder={annotationHotkey && `Hotkey ${annotationHotkey}`}
                value={highlight?.text}
                isError={definition?.required && !highlight?.text}
                isDisabled={definition?.disabled}
                onClick={() => {}}
              />
            </div>
          ))
        : null
    })

  const renderLineItems = () =>
    mergeLineItems.map((entry, index) => {
      const column = LineItemHeader[entry.type].filter(i =>
        merchantDefinition?.find(j => j?.label === i)
      )
      const rawRow = column.map(header => ({
        ...entry?.[header],
        label: header
      }))
      const generateCells = () =>
        [
          ...LineItemHeader[SectionEnum.LINE_ITEM],
          ...LineItemHeader[SectionEnum.PROMOTION],
          ...LineItemHeader[SectionEnum.ITEM_OTHER],
          ...LineItemHeader[SectionEnum.SUB_ITEM],
          ...LineItemHeader[SectionEnum.ITEM_CATEGORY],
          ...LineItemHeader[SectionEnum.ITEM_SUBTOTAL],
          ...LineItemHeader[SectionEnum.ITEM_TAX],
          ...LineItemHeader[SectionEnum.LOYALTY_EARNED],
          ...LineItemHeader[SectionEnum.LOYALTY_REDEEMED],
          ...LineItemHeader[SectionEnum.LOYALTY_BALANCE]
        ].map((header, index) => {
          const content = rawRow.find(content => content.label === header)
          const fieldRequired = merchantDefinition?.find(
            i => i.label === header
          )

          return (
            content && (
              <FieldCell
                key={`${header}_${content?.id}_${index}`}
                id={entry.key?.[0].toString()}
                wrapperClassName={columnsStyle?.[header]}
                value={content?.text || '\u00A0'}
                isError={fieldRequired?.required && !content?.text}
                isDisabled={!fieldRequired}
                isPromoName={
                  content?.label === AnnotationLabelEnum.ITEM_PROMO_NAME
                }
                onClick={() => {}}
              />
            )
          )
        })
      return (
        <div
          key={String(entry.key?.[0])}
          className={
            'flex gap-2 py-1 px-4 -my-1 -mx-4 bg-transparent flex items-center'
          }
        >
          <div className="text-xs" style={{ width: '1.25rem' }}>
            {index + 1}
          </div>
          {generateCells()}
        </div>
      )
    })

  const pageText: string = `${currPage + 1}/${totalPages}`

  return (
    <div className="px-4 pb-4">
      <Section
        title="Regular Field"
        page={pageText}
        isComplete={!missingRegularFields?.length}
      >
        {renderRegularRow(highlights)}
      </Section>
      <Section
        title="Line Items"
        isComplete={!missingLineItems.length}
        page={pageText}
      >
        <div className="flex flex-col gap-2">
          <ItemsSectionHeader definition={merchantDefinition} />
          {renderLineItems()}
        </div>
      </Section>
    </div>
  )
}
