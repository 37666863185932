import { Tabs } from 'antd'
import { AuthSelectors } from 'data/selectors'
import React from 'react'
import { useQuery } from 'react-query'
import { useSelector } from 'react-redux'
import { QueryKeys } from 'services/api/query-key'
import { EmailSubjectWhitelistApi } from 'services/api/requests/apolli-visualizer'
import { FOOTER_HEIGHT, HEADER_HEIGHT } from 'services/styles/layout'

const WorkflowSider = ({ selectedRecord }) => {
  const auth = useSelector(AuthSelectors.firebaseAuthState)

  const { transactionId, htmlLink } = selectedRecord
  const htmlLinkExtender = `${htmlLink}&user_id=${auth.uid}`

  const { data: redactedData } = useQuery(
    [htmlLinkExtender, QueryKeys.EmailSubjectWhitelistRedactImage],
    () => EmailSubjectWhitelistApi.getRedactedHtml(htmlLinkExtender),
    {
      enabled: !!htmlLink
    }
  )

  const renderIframe = (title, srcDoc, src) => (
    <iframe
      key={src}
      title={title}
      srcDoc={srcDoc}
      src={src}
      frameBorder="0"
      style={{
        width: '100%',
        height: '73vh',
        border: '1px solid #ddd'
      }}
    />
  )

  const redactedHTML = redactedData?.html

  return (
    <Tabs
      defaultActiveKey={'iframe'}
      style={{
        height: `calc(100vh - ${HEADER_HEIGHT}px - ${FOOTER_HEIGHT}px)`,
        overflow: 'scroll'
      }}
      tabBarStyle={{
        border: '0.5px solid grey',
        justifyContent: 'center',
        backgroundColor: 'white',
        position: 'sticky',
        top: 0,
        zIndex: 50
      }}
    >
      <Tabs.TabPane
        tab={transactionId || 'Nothing selected'}
        key="iframe"
        className={'p-4 pt-0'}
      >
        {renderIframe(transactionId, redactedHTML, null)}
      </Tabs.TabPane>
    </Tabs>
  )
}

export default WorkflowSider
