import { createAction } from 'redux-actions'
import AppConstants from './constants'

export const toggleSider = () => createAction(AppConstants.TOGGLE_SIDER)()

export const setAppVersion = newVersion =>
  createAction(AppConstants.APP_VERSION)(newVersion)

export const setLastActiveTime = (lastActiveTime: Date | null) =>
  createAction(AppConstants.SET_LAST_ACTIVE_TIME)(lastActiveTime)
