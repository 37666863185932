import TableStripedRows from 'components/table-striped-rows'
import { startCase } from 'lodash'
import React from 'react'
import useWindowDimensions from 'services/hooks/use-get-dimensions'
import BaseTable from '../base-table'
import { FieldsKeyDictionary } from '../types'

const formatData = rawData =>
  FieldsKeyDictionary.map((label, index) => ({
    key: index,
    columnName: label.replace(/^receipt/, ''),
    value: rawData?.[label] || ''
  }))

const SummaryTable = ({ rawData }) => {
  const formattedData = rawData ? formatData(rawData) : []

  const { width } = useWindowDimensions()

  const column = [
    {
      title: 'Name',
      dataIndex: 'columnName',
      width: width / 4.5,
      render: text => <span>{startCase(text)}</span>
    },
    {
      title: 'Text',
      dataIndex: 'value',
      render: text => <span>{text}</span>
    }
  ]

  return (
    <BaseTable title={'Receipt Summary'}>
      <TableStripedRows
        loading={false}
        dataSource={formattedData}
        columns={column}
      />
    </BaseTable>
  )
}

export default SummaryTable
