import { Layout, Modal } from 'antd'
import EmptyComponent from 'components/empty-component'
import SplashScreen from 'components/splash'
import { BoxActions, ExtractionActions } from 'data/actions'
import { ExtractionSelectors } from 'data/selectors'
import { formatDataFields } from 'pages/app/extraction/version-1/helpers'
import TaskContent from 'pages/app/extraction/version-1/task/content'
import TaskFooter from 'pages/app/extraction/version-1/task/footer'
import TaskSider from 'pages/app/extraction/version-1/task/sider'
import React from 'react'
import { useQuery } from 'react-query'
import { useDispatch, useSelector } from 'react-redux'
import { useParams } from 'react-router'
import { ReceiptApi } from 'services/api/requests'
import { ResponseReceiptAnnotationType } from 'services/api/requests/annotation'
import {
  EXTRACTION_SIDER_WIDTH,
  FOOTER_HEIGHT,
  HEADER_HEIGHT
} from 'services/styles/layout'

const { Content, Sider, Footer } = Layout

const ExtractionTaskPage = () => {
  const currentReceiptId = useSelector(ExtractionSelectors.receiptId)
  const retailerFilter = useSelector(ExtractionSelectors.retailerFilter)
  const dispatch = useDispatch()
  const { countryCode } = useParams<{ countryCode: string }>()

  const handleOnDataLoaded = ({
    data
  }: {
    data: ResponseReceiptAnnotationType
  }) => {
    dispatch(BoxActions.resetBoxState())
    if (data) {
      const formattedData = formatDataFields(
        data
      ) as ResponseReceiptAnnotationType

      dispatch(ExtractionActions.setRawData(formattedData))
      dispatch(ExtractionActions.setCurrentPage(0))
      formattedData?.['0']?.fields?.map(i => dispatch(BoxActions.addBox(i)))
      dispatch(ExtractionActions.setReceiptId(data.metadata?.id))
    }
  }

  const handleOnLoadDataFailed = () => {
    Modal.error({
      title: 'Fetch Error!',
      content: (
        <>
          <p>Something went wrong while getting this receipt.</p>
          <p>Please contact support.</p>
        </>
      )
    })
  }

  const { isLoading, data } = useQuery(
    [countryCode, retailerFilter, 'receipt-extraction-v1', currentReceiptId],
    ({
      queryKey
    }: {
      queryKey: [string, string | undefined, string, string]
    }) => ReceiptApi.getReceiptAnnotation(queryKey[0], queryKey?.[1]),
    {
      enabled: !currentReceiptId,
      onSuccess: handleOnDataLoaded,
      onError: handleOnLoadDataFailed
    }
  )

  // @ts-ignore
  const status = data?.status

  return (
    <Layout>
      <Layout
        style={{
          height: `calc(100vh - ${HEADER_HEIGHT}px - ${FOOTER_HEIGHT}px)`
        }}
      >
        {isLoading ? (
          <SplashScreen />
        ) : (
          <>
            <Sider
              width={EXTRACTION_SIDER_WIDTH}
              className="z-20 overflow-hidden"
            >
              <TaskContent />
            </Sider>
            <Content className="bg-white z-30 sticky right-0 overflow-scroll">
              {status === 204 ? (
                <div className="h-screen flex justify-center items-center">
                  <EmptyComponent
                    description={
                      <>
                        <strong>No more receipts to show</strong>
                        <p>Change your retailer filter to load more</p>
                      </>
                    }
                  />
                </div>
              ) : (
                <TaskSider />
              )}
            </Content>
          </>
        )}
      </Layout>

      <Footer
        className="flex items-center justify-between z-40 py-0 px-4"
        style={{ height: FOOTER_HEIGHT }}
      >
        <TaskFooter />
      </Footer>
    </Layout>
  )
}

export default ExtractionTaskPage
