import { Typography } from 'antd'
import { PromoIcon } from 'assets/icons'
import { BoxLabelEnum, prettyBoxLabel } from 'data/box'
import React, { CSSProperties } from 'react'
import { Color } from 'services/styles/color'

const { Text } = Typography

interface FieldProps {
  label?: string | BoxLabelEnum[]
  value?: string | number
  wrapperClassName?: string
  onClick?: (x: any) => void
  isDisabled?: boolean
  isError?: boolean
  id?: string
  isPromo?: boolean
  isPromoNameLabel?: boolean
}

const disabledStyle: CSSProperties = {
  backgroundColor: Color.GREY_300,
  border: '1px solid grey',
  cursor: 'not-allowed'
}

const Field = ({
  isDisabled,
  isError,
  label,
  value,
  wrapperClassName,
  onClick,
  id,
  isPromo,
  isPromoNameLabel
}: FieldProps) => (
  <div
    id={id}
    className={`flex flex-col flex-grow ${wrapperClassName || ''}`}
    onClick={!isDisabled ? onClick : () => {}}
  >
    {label && (
      <Text strong className="text-xs pb-1 truncate">
        {prettyBoxLabel[String(label)]}
      </Text>
    )}
    {value && (
      <div className="flex flex-grow items-center ">
        {isPromo && isPromoNameLabel && <PromoIcon className="pr-2" />}
        <Text
          editable={false}
          className={`p-2 truncate text-xs border border-solid rounded w-full cursor-pointer ${
            isError ? 'bg-red-100 border-red-700' : 'bg-white border-gray-400'
          }`}
          style={isDisabled ? disabledStyle : {}}
        >
          {value}
        </Text>
      </div>
    )}
  </div>
)

export default Field
