import { BoxLabelEnum } from 'data/box'
import Hotkeys from 'services/hotkeys'

export enum SectionEnum {
  MERCHANT = 'Merchant Info',
  LINE_ITEM = 'Line Items',
  PROMOTION = 'Promotion',
  TOTAL = 'Total & Tax',
  TIME = 'Date & Time',
  RECEIPT_DISCOUNT = 'Receipt Discount',
  TRANSACTION = 'Receipt Transaction',
  SUMMARY = 'Summary'
}

export const LineItemHeader = {
  [SectionEnum.MERCHANT]: [
    BoxLabelEnum.RECEIPT_MERCHANT_NAME,
    BoxLabelEnum.RECEIPT_STORE_PHONE,
    BoxLabelEnum.RECEIPT_STORE_ID,
    BoxLabelEnum.RECEIPT_STORE_ADDRESS,
    BoxLabelEnum.RECEIPT_STORE_NAME
  ],
  [SectionEnum.LINE_ITEM]: [
    BoxLabelEnum.ITEM_DESCRIPTION,
    BoxLabelEnum.ITEM_CODE,
    BoxLabelEnum.ITEM_UNIT_PRICE,
    BoxLabelEnum.ITEM_QUANTITY,
    BoxLabelEnum.ITEM_VALUE
  ],
  [SectionEnum.PROMOTION]: [
    BoxLabelEnum.ITEM_PROMO_NAME,
    BoxLabelEnum.ITEM_PROMO_PRICE
  ],
  [SectionEnum.RECEIPT_DISCOUNT]: [
    BoxLabelEnum.RECEIPT_TOTAL_DISCOUNT,
    BoxLabelEnum.RECEIPT_TIP
  ],
  [SectionEnum.TOTAL]: [
    BoxLabelEnum.RECEIPT_SUBTOTAL_VALUE,
    BoxLabelEnum.RECEIPT_TOTAL_TAX,
    BoxLabelEnum.RECEIPT_TOTAL_VALUE
  ],
  [SectionEnum.TRANSACTION]: [
    BoxLabelEnum.RECEIPT_TRANSACTION_ID,
    BoxLabelEnum.RECEIPT_CASH_REGISTER_ID
  ],
  [SectionEnum.TIME]: [
    BoxLabelEnum.RECEIPT_PURCHASE_DATE,
    BoxLabelEnum.RECEIPT_PURCHASE_TIME
  ],
  [SectionEnum.SUMMARY]: ['', '', 'Calculated Total']
}

export const LabelToSectionTitle = {
  [BoxLabelEnum.RECEIPT_MERCHANT_NAME]: SectionEnum.MERCHANT,
  [BoxLabelEnum.RECEIPT_STORE_ADDRESS]: SectionEnum.MERCHANT,
  [BoxLabelEnum.RECEIPT_STORE_PHONE]: SectionEnum.MERCHANT,
  [BoxLabelEnum.ITEM_DESCRIPTION]: SectionEnum.LINE_ITEM,
  [BoxLabelEnum.ITEM_QUANTITY]: SectionEnum.LINE_ITEM,
  [BoxLabelEnum.ITEM_UNIT_PRICE]: SectionEnum.LINE_ITEM,
  [BoxLabelEnum.ITEM_VALUE]: SectionEnum.LINE_ITEM,
  [BoxLabelEnum.ITEM_CODE]: SectionEnum.LINE_ITEM,
  [BoxLabelEnum.ITEM_PROMO_NAME]: SectionEnum.PROMOTION,
  [BoxLabelEnum.ITEM_PROMO_PRICE]: SectionEnum.PROMOTION,
  [BoxLabelEnum.RECEIPT_SUBTOTAL_VALUE]: SectionEnum.TOTAL,
  [BoxLabelEnum.RECEIPT_TOTAL_TAX]: SectionEnum.TOTAL,
  [BoxLabelEnum.RECEIPT_TOTAL_VALUE]: SectionEnum.TOTAL,
  [BoxLabelEnum.RECEIPT_PURCHASE_DATE]: SectionEnum.TIME,
  [BoxLabelEnum.RECEIPT_PURCHASE_TIME]: SectionEnum.TIME,

  //   new fields
  [BoxLabelEnum.RECEIPT_STORE_ID]: SectionEnum.MERCHANT,
  [BoxLabelEnum.RECEIPT_STORE_NAME]: SectionEnum.MERCHANT,
  [BoxLabelEnum.ITEM_UNIT_TYPE]: SectionEnum.LINE_ITEM,
  [BoxLabelEnum.RECEIPT_TOTAL_DISCOUNT]: SectionEnum.RECEIPT_DISCOUNT,
  [BoxLabelEnum.RECEIPT_TIP]: SectionEnum.RECEIPT_DISCOUNT,
  [BoxLabelEnum.RECEIPT_TRANSACTION_ID]: SectionEnum.TRANSACTION,
  [BoxLabelEnum.RECEIPT_CASH_REGISTER_ID]: SectionEnum.TRANSACTION
}

export const HotKeyToLabelEnum = Object.entries(Hotkeys).reduce(
  (acc, [key, value]) => {
    acc[value.toLowerCase()] = key
    return acc
  },
  {}
)
