import { AuditState } from 'data/audit/types'
import { DBReceiptAnnotation } from 'services/api/requests/types'
import handleActionsImmer from 'services/integrations/immer'
import AuditConstants from './constants'

const initialState = {
  receiptIdsList: [],
  labelsIdsList: [],
  currentIndex: 0,
  data: null,
  receiptFilter: null
}

const audit = handleActionsImmer<AuditState>(
  {
    [AuditConstants.SET_CURRENT_INDEX]: (state: AuditState, index: number) => {
      state.currentIndex = index
    },
    [AuditConstants.SET_RECEIPTS_LIST]: (
      state: AuditState,
      receiptIdsList: number[]
    ) => {
      state.receiptIdsList = receiptIdsList
    },
    [AuditConstants.SET_LABELS_LIST]: (
      state: AuditState,
      labelsIdsList: number[]
    ) => {
      state.labelsIdsList = labelsIdsList
    },
    [AuditConstants.SET_RECEIPT_DATA]: (
      state: AuditState,
      receipt: DBReceiptAnnotation
    ) => {
      state.data = receipt
    },
    [AuditConstants.SET_RECEIPT_FILTER]: (state: AuditState, id: string) => {
      state.receiptFilter = id
    },
    [AuditConstants.RESET_AUDIT_STATE]: (state: AuditState) => {
      state.receiptIdsList = []
      state.currentIndex = 0
      state.data = null
      state.receiptFilter = null
    }
  },
  initialState
)

export default audit
