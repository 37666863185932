import axios from 'axios'
import config from 'services/config'
import {
  axiosReqInterceptor,
  axiosResInterceptor
} from 'services/integrations/axios'

const MinervaReceiptApiClient = axios.create({
  baseURL: config.baseUrl + 'minerva/receipt/'
})

MinervaReceiptApiClient.interceptors.request.use(axiosReqInterceptor)
MinervaReceiptApiClient.interceptors.response.use(axiosResInterceptor)

export const getExportReceipt = async (id: string) => {
  if (!id) return { status: 204 } // fallback
  const { data } = await MinervaReceiptApiClient.get(`export/${id}`)
  return data
}
