import React from 'react'

export default () => (
  <div className="p-10">
    <h3 className="mb-4">Forbidden</h3>
    <p>
      You don't have permission to access this page. Please ask admin for
      permission.
    </p>
  </div>
)
