import axios from 'axios'
import config from 'services/config'
import {
  appendScriptsToDocument,
  removeInjectedScriptsFromDocument
} from 'services/helpers/scripts'
import {
  axiosReqInterceptor,
  axiosResInterceptor
} from 'services/integrations/axios'

const RedactionApiClient = axios.create({
  baseURL: config.baseUrl + 'noctua-ii/redaction/'
})

RedactionApiClient.interceptors.request.use(axiosReqInterceptor)
RedactionApiClient.interceptors.response.use(axiosResInterceptor)

export interface ResPiiRedactionOverview {
  merchant: {
    id: number
    name: string
    target: number
  }
  pending_count: number
  redaction_in_progress_count: number
  redacted_count: number
  validation_in_progress_count: number
  validated_count: number
}

export const getPiiRedactionOverview = async () => {
  const { data } = await RedactionApiClient.get<ResPiiRedactionOverview[]>(
    'merchant/'
  )

  return data
}

interface ResGetPiiRedactionData {
  html: string
  file_id: number
}

export type RedactStep = 'redact' | 'validate'
export const getPiiRedactionData = async (
  step: RedactStep,
  merchant_id: string
) => {
  const { data } = await RedactionApiClient.get<ResGetPiiRedactionData>(
    `file/${step}/${merchant_id}`
  )

  if (!data.html) return data

  function unescapeHTMLCharacters(rawStr) {
    return rawStr
      .replace(/&nbsp;/g, ' ')
      .replace(/&amp;/g, '&')
      .replace(/&lt;/g, '<')
      .replace(/&gt;/g, '>')
  }

  const injectedScriptsHTML = appendScriptsToDocument(
    unescapeHTMLCharacters(data.html)
  )

  return { ...data, injectedScriptsHTML }
}

export const postPiiRedactionData = async body => {
  const formattedBody = {
    ...body,
    result_html: removeInjectedScriptsFromDocument(body.redactedHTML),
    user_actions: body.userActions,
    html_changed: !!body.userActions.length
  }
  // remove processing data
  const {
    userActions,
    injectedScriptsHTML,
    redactedHTML,
    ...rest
  } = formattedBody
  const { data } = await RedactionApiClient.post(`file/${body.file_id}`, rest)

  return data
}

export const unlockPiiRedactionData = async () => {
  try {
    const { data } = await RedactionApiClient.delete('file-lock')
    return data
  } catch (e) {
    console.warn(e)
  }
}
