import { Button, Table, Tag } from 'antd'
import React from 'react'
import { toStartCase } from 'services/helpers/canonicalization'
import { EventsProps } from './types'

const { Column } = Table

const Events = ({ data, onViewDataClick }: EventsProps) => (
  <section>
    <h4 className="mb-4">Events</h4>
    <Table dataSource={data?.HistoryEvents} pagination={false} rowKey="eventId">
      <Column title="Event ID" dataIndex="eventId" />
      <Column
        title="Result"
        dataIndex="result"
        render={(result, { eventId }) => (
          <Tag
            key={eventId}
            color={
              result
                ? result?.toLowerCase() === 'pass'
                  ? 'success'
                  : result?.toLowerCase() === 'on-hold'
                  ? 'default'
                  : 'error'
                : 'default'
            }
          >
            {eventId !== 0 ? toStartCase(result || 'Not provided') : 'Start'}
          </Tag>
        )}
      />
      <Column title="Event" dataIndex="previousActivity" />
      <Column
        title="Updated"
        dataIndex="updatedAt"
        render={updatedAt => (
          <div key={updatedAt}>{new Date(updatedAt).toLocaleString()}</div>
        )}
      />
      <Column
        title="Previous Activity"
        dataIndex="dataFromPreviousActivity"
        render={prevActivityData => (
          <Button
            key={prevActivityData}
            type="primary"
            ghost
            onClick={() => onViewDataClick(prevActivityData)}
          >
            View Data
          </Button>
        )}
      />
    </Table>
  </section>
)

export default Events
