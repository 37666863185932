import RegularFieldSection from 'pages/app/annotation-tool/task/content/regular-field-section'
import React from 'react'
import LineItemSection from './line-item-section'

const TaskContent = () => {
  return (
    <div className="px-4 pb-4">
      <RegularFieldSection />
      <LineItemSection />
    </div>
  )
}

export default TaskContent
