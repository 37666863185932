import { DBReceiptAnnotation, Field } from 'services/api/requests/types'
import handleActionsImmer from 'services/integrations/immer'
import { CurrentPageActions } from './current-page/actions'
import { DefinitionActions } from './definition/actions'
import { ModeActions } from './mode/actions'
import { MouseEventActions } from './mouse-event/actions'
import { RecordActions } from './record/actions'
import { SelectionActions } from './selection/actions'

export const initialState: CombinedStates = {
  mode: {
    editingText: false,
    isPanningMode: false,
    linkMode: false,
    autoFocus: false,
    isDropdownEnabled: true
  },
  mouseEvent: {
    popupList: []
  },
  selection: {
    target: null,
    source: null
  },
  merchantDefinition: [],
  currentPage: 0,
  record: null
}

export interface CombinedStates {
  mode: {
    editingText: boolean
    isPanningMode: boolean
    linkMode: boolean
    autoFocus: boolean
    isDropdownEnabled: boolean
  }
  mouseEvent: {
    popupList: (boolean | null)[]
  }
  selection: {
    target?: string
    source?: string
  }
  merchantDefinition: any
  currentPage: number
  record?: DBReceiptAnnotation
}

export const combinedReducers = handleActionsImmer<CombinedStates>(
  {
    // record reducer
    [RecordActions.SET_RECORD]: (
      state: CombinedStates,
      newRecord: DBReceiptAnnotation
    ) => {
      state.record = newRecord
    },
    [RecordActions.EDIT_OCR_TEXT]: (state: CombinedStates, { id, text }) => {
      const currentPage = state.currentPage
      const currentPageData = state.record.pages[currentPage]
      state.record.pages[currentPage].words = currentPageData.words.map(word =>
        word.id === id ? { ...word, text } : word
      )
    },
    [RecordActions.EDIT_TEXT]: (state: CombinedStates, { id, text }) => {
      const currentPage = state.currentPage
      const currentPageData = state.record.pages[currentPage]
      state.record.pages[currentPage] = {
        ...currentPageData,
        fields: currentPageData.fields.map(field =>
          field.id === id ? { ...field, text } : field
        )
      }
    },
    [RecordActions.EDIT_LABEL]: (state: CombinedStates, { id, label }) => {
      const currentPage = state.currentPage
      const currentPageData = state.record.pages[currentPage]
      state.record.pages[
        currentPage
      ].fields = currentPageData.fields.map(field =>
        field.id === id ? { ...field, label } : field
      ) as Field[]
    },
    [RecordActions.ADD_FIELD]: (state: CombinedStates, newField) => {
      const currentPage = state.currentPage
      const currentPageData = state.record.pages[currentPage]
      const currentFields = currentPageData.fields
      state.record.pages[currentPage].fields = [...currentFields, newField]
    },
    [RecordActions.REMOVE_OCR]: (state: CombinedStates, id) => {
      const currentPage = state.currentPage
      const currentPageData = state.record.pages[currentPage]
      const currentWords = currentPageData.words
      state.record.pages[currentPage].words = currentWords.reduce(
        (acc, box) => (box.id !== id ? [...acc, box] : acc),
        []
      )
    },
    [RecordActions.REMOVE_FIELD]: (state: CombinedStates, id) => {
      const currentPage = state.currentPage
      const currentPageData = state.record.pages[currentPage]
      const currentFields = currentPageData.fields
      state.record.pages[currentPage].fields = currentFields.reduce(
        (acc, box) =>
          box.id !== id
            ? [
                ...acc,
                {
                  ...box,
                  links: box.links.filter(item => item !== id)
                }
              ]
            : acc,
        []
      )

      // reset active id
      state.selection.source = undefined
      if (id === state.selection.target) {
        state.selection.target = undefined
      }
    },
    [RecordActions.ADD_LINK]: (state: CombinedStates, { id, targetId }) => {
      const currentPage = state.currentPage
      const currentPageData = state.record.pages[currentPage]
      const currentFields = currentPageData.fields
      state.record.pages[currentPage].fields = currentFields.map(box =>
        box.id === id
          ? { ...box, links: [...box.links, targetId] }
          : box.id === targetId
          ? { ...box, links: [...box.links, id] }
          : box
      )
    },
    [RecordActions.REMOVE_LINK]: (state: CombinedStates, { id, targetId }) => {
      const currentPage = state.currentPage
      const currentPageData = state.record.pages[currentPage]
      const currentFields = currentPageData.fields
      state.record.pages[currentPage].fields = currentFields.map(box =>
        box.id === id
          ? { ...box, links: box.links.filter(item => item !== targetId) }
          : box.id === targetId
          ? { ...box, links: box.links.filter(item => item !== id) }
          : box
      )
    },
    //  current page reducer
    [CurrentPageActions.SET_CURRENT_PAGE]: (
      state: CombinedStates,
      newValue: number
    ) => {
      state.currentPage = newValue
    },
    // selection reducer
    [SelectionActions.SET_SOURCE_ID]: (
      state: CombinedStates,
      newValue: string
    ) => {
      state.selection.source = newValue
    },
    [SelectionActions.SET_TARGET_ID]: (
      state: CombinedStates,
      newValue: string
    ) => {
      state.selection.target = newValue
    },
    //  mouse reducer
    [MouseEventActions.SET_POPUP_LIST]: (
      state: CombinedStates,
      newValue: (boolean | null)[]
    ) => {
      state.mouseEvent.popupList = newValue
    },
    // mode reducer
    [ModeActions.SET_EDITING_TEXT]: (
      state: CombinedStates,
      newValue: boolean
    ) => {
      state.mode.editingText = newValue
    },
    [ModeActions.SET_PANNING_MODE]: (
      state: CombinedStates,
      newValue: boolean
    ) => {
      state.mode.isPanningMode = newValue
    },
    [ModeActions.SET_LINK_MODE]: (state: CombinedStates, newValue: boolean) => {
      state.mode.linkMode = newValue
    },
    [ModeActions.SET_AUTO_FOCUS]: (
      state: CombinedStates,
      newValue: boolean
    ) => {
      state.mode.autoFocus = newValue
    },
    [ModeActions.SET_TOKEN_DROPDOWN_MODE]: (
      state: CombinedStates,
      newValue: boolean
    ) => {
      state.mode.isDropdownEnabled = newValue
    },
    // definition reducer
    [DefinitionActions.SET_DEFINITION]: (
      state: CombinedStates,
      newDefinition: any
    ) => {
      state.merchantDefinition = newDefinition
    },
    //  reset
    [RecordActions.RESET_WORKING_STATE]: (state: CombinedStates) => {
      state.record = null
      state.selection.target = null
      state.selection.source = null
      state.merchantDefinition = []
      state.currentPage = 0
      state.mode = {
        ...state.mode,
        editingText: false,
        isPanningMode: false,
        linkMode: false,
        isDropdownEnabled: true
      }
    }
  },
  initialState
)
