import { Dropdown } from 'antd'
import { TaskContext } from 'pages/app/annotation-tool/task/context'
import { getNewField } from 'pages/app/annotation-tool/task/helpers'
import BoxDropdownMenu from 'pages/app/annotation-tool/task/sider/components/box-dropdown-menu'
import React, { useContext } from 'react'
import { Field, Word } from 'services/api/requests/types'

interface Props {
  word: Word
  visible?: boolean
}

const WordBox = ({ word, visible }: Props) => {
  const { state: parentState } = useContext(TaskContext)
  const { mouseEvent, record, currentPage } = parentState
  const currentPageData = record.pages[currentPage]

  const { popupList } = mouseEvent

  const isSingleSelection = visible && !popupList.includes(false)
  const singleWordField: Field = {
    ...word,
    label: null,
    links: [],
    fieldElements: [word.id]
  }

  const newField =
    visible && !isSingleSelection
      ? getNewField(currentPageData.words, popupList)
      : singleWordField

  const [
    { x: x0, y: y0 },
    { x: x1, y: y1 },
    { x: x2, y: y2 },
    { x: x3, y: y3 }
  ] = word.boundingPoly.vertices

  const invalidToken = !word.text.length

  return (
    <Dropdown
      placement="bottomCenter"
      overlay={<BoxDropdownMenu data={newField} />}
      overlayStyle={{ width: '14rem', minWidth: 'min-content' }}
      trigger={[]}
      visible={visible || invalidToken}
    >
      <path
        className={`text-box opacity-10 hover:opacity-30 ${
          invalidToken ? 'invalid-box' : ''
        }`}
        d={`M${x0},${y0}
       L${x1},${y1}
       L${x2},${y2} 
       L${x3},${y3}z`}
        fill="rgba(0,0,0)"
        style={{ cursor: 'crosshair' }}
      />
    </Dropdown>
  )
}

export default WordBox
