import LoadingRows from 'components/skeleton/loading-rows'
import TasksTable from 'pages/app/extraction/version-1/home/tasks-table'
import React from 'react'
import { useQuery } from 'react-query'
import { ReceiptApi } from 'services/api/requests'
import { DBReceiptOverview } from 'services/api/requests/types'

const ExtractionHomePage = () => {
  const { isLoading, data } = useQuery<DBReceiptOverview>(
    'extraction-overview-v1',
    ReceiptApi.getReceiptOverview
  )

  return (
    <div className="p-10">
      <h3 className="mb-4">Extraction</h3>
      {!isLoading ? <TasksTable data={data} /> : <LoadingRows />}
    </div>
  )
}

export default ExtractionHomePage
