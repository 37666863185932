import { Layout } from 'antd'
import { Content, Footer } from 'antd/es/layout/layout'
import EmptyComponent from 'components/empty-component'
import SplashScreen from 'components/splash'
import StandardBreadcrumb from 'components/standard-breadcrumb'
import { initialState, reducer } from 'pages/app/pii-redaction/reducers'
import { RecordActions } from 'pages/app/pii-redaction/reducers/record/actions'
import qs from 'query-string'
import React, { useEffect, useReducer, useRef } from 'react'
import { useMutation, useQuery } from 'react-query'
import { useLocation } from 'react-router'
import { QueryKeys } from 'services/api/query-key'
import { Noctua2PiiRedactionApi } from 'services/api/requests/notua-ii'
import { useEventListener } from 'services/hooks/use-event-listener'
import { FOOTER_HEIGHT, HEADER_HEIGHT } from 'services/styles/layout'
import { TaskContext } from './context'
import TaskFooter from './footer'

const PiiRedactionPage = () => {
  const iFrameRef = useRef(null)

  const [state, dispatchTask] = useReducer(reducer, initialState)

  const { mutateAsync } = useMutation(
    Noctua2PiiRedactionApi.unlockPiiRedactionData
  )

  const { pathname, search } = useLocation()
  const merchantId = pathname.replace(/^.*[\\\/]/, '')
  const { step } = qs.parse(search, { decode: false })

  const resetWorkingState = () => {
    dispatchTask({ type: RecordActions.RESET_WORKING_STATE })
  }

  // TODO type
  const handleOnDataLoaded = async (data1: any) => {
    resetWorkingState()

    if (data1) {
      dispatchTask({
        type: RecordActions.SET_DATA,
        payload: data1 // initial
      })

      dispatchTask({
        type: RecordActions.SET_RECORD,
        payload: data1.injectedScriptsHTML // initial
      })
    }
  }

  const { isLoading, isFetching, data } = useQuery(
    [QueryKeys.PiiRedactionReceipt],
    () => Noctua2PiiRedactionApi.getPiiRedactionData(step, merchantId),
    { onSuccess: handleOnDataLoaded }
  )

  // redacted actions
  // TODO Change to message listener, which is more reliable
  useEffect(() => {
    dispatchTask({
      type: RecordActions.SET_RECORD,
      payload:
        iFrameRef?.current?.contentWindow?.document?.documentElement?.outerHTML // changes
    })
  }, [iFrameRef?.current?.contentWindow?.document?.documentElement?.outerHTML])

  useEventListener('message', e => {
    if (!e.data) return null
    if (typeof e.data !== 'string') return null

    const iFrameMessage = JSON.parse(e.data)

    if (!iFrameMessage?.userAction) return null

    dispatchTask({
      type: RecordActions.ADD_USER_ACTION,
      payload: iFrameMessage
    })
  })

  useEffect(() => {
    // unlock when exit
    return () => {
      ;(async () => await mutateAsync())()
    }
  }, [])
  return (
    <TaskContext.Provider value={{ state, dispatchTask }}>
      <Layout>
        <Layout
          style={{
            height: `calc(100vh - ${HEADER_HEIGHT}px - ${FOOTER_HEIGHT}px)`
          }}
        >
          <div className="py-2 px-4">
            <StandardBreadcrumb />
          </div>
          {isLoading || isFetching ? (
            <SplashScreen />
          ) : (
            <Content>
              {data?.injectedScriptsHTML ? (
                <iframe
                  ref={iFrameRef}
                  referrerPolicy="origin"
                  allowFullScreen={true}
                  title="pii-redaction"
                  srcDoc={data.injectedScriptsHTML}
                  frameBorder="0"
                  width="100%"
                  height="100%"
                />
              ) : (
                <div className="h-screen flex justify-center items-center">
                  <EmptyComponent
                    description={
                      <>
                        <strong>No more receipts to show</strong>
                        <p>Change your retailer filter to load more</p>
                      </>
                    }
                  />
                </div>
              )}
            </Content>
          )}
        </Layout>
        <Footer
          className="flex items-center justify-between z-40 py-0 px-4"
          style={{ height: FOOTER_HEIGHT }}
        >
          <TaskFooter
            externalLoader={
              !iFrameRef?.current?.contentWindow?.document?.documentElement
                ?.outerHTML
            }
          />
        </Footer>
      </Layout>
    </TaskContext.Provider>
  )
}

export default PiiRedactionPage
