import { DBReceiptAnnotation } from 'services/api/requests/types'

const fallbackData = {
  metadata: { content: [], size_list: [{ width: 1000, height: 1000 }] },
  pages: [{ fields: [], words: [] }]
}
export const formatDataFields = (data: DBReceiptAnnotation) => {
  if (!data) return fallbackData
  return data
}
