import { AutoLinkingOffIcon, AutoLinkingOnIcon } from 'assets/icons'
import React from 'react'

interface Props {
  value: boolean
  onValueChange: (value) => void
  wrapperClassName?: string
}

const AutoFocusToggle = ({ value, onValueChange, wrapperClassName }: Props) => {
  const toggle = () => onValueChange(!value)

  return (
    <div
      className={
        'absolute bottom-2 ml-2 right-2 flex items-center justify-between cursor-pointer ' +
        wrapperClassName
      }
    >
      {value ? (
        <AutoLinkingOnIcon onClick={toggle} />
      ) : (
        <AutoLinkingOffIcon onClick={toggle} />
      )}
    </div>
  )
}

export default AutoFocusToggle
