import { AutoComplete, Typography } from 'antd'
import TextArea from 'antd/lib/input/TextArea'
import { ModeActions } from 'pages/app/annotation-tool/reducers/mode/actions'
import { MouseEventActions } from 'pages/app/annotation-tool/reducers/mouse-event/actions'
import { RecordActions } from 'pages/app/annotation-tool/reducers/record/actions'
import { SelectionActions } from 'pages/app/annotation-tool/reducers/selection/actions'
import { TaskContext } from 'pages/app/annotation-tool/task/context'
import { getLinkableFields } from 'pages/app/annotation-tool/task/types'
import React, { ReactNode, useContext } from 'react'
import { Field } from 'services/api/requests/types'
import { getBoxColor, getBoxColorLight } from 'services/styles/color'

const { Text } = Typography

const LabelSelectMenu = ({
  value,
  setValue,
  onEditingModeChange,
  disabled
}) => {
  const { state: parentState } = useContext(TaskContext)
  const { merchantDefinition } = parentState
  const boxLabelsList = merchantDefinition?.map(i => i.label)
  const options = boxLabelsList.map(label => ({ value: label }))

  const handleOnSelect = (value1, option) => setValue(option.value)
  return (
    <AutoComplete
      style={{ borderRadius: 99 }}
      autoFocus
      className="w-full"
      disabled={disabled}
      onClick={event => event.stopPropagation()}
      onFocus={() => onEditingModeChange(true)}
      onBlur={() => {
        onEditingModeChange(false)
      }}
      defaultValue={value}
      onSelect={handleOnSelect}
      allowClear
      options={options}
      placeholder="Item label..."
      filterOption={(inputValue, option) =>
        option!.value
          .toUpperCase()
          .indexOf(String(inputValue).toUpperCase()) !== -1
      }
    />
  )
}

interface MenuItemProps {
  title: string
  actionComponent?: ReactNode | string
  valueComponent?: ReactNode | string
}

const MenuItem = ({
  title,
  actionComponent,
  valueComponent
}: MenuItemProps) => (
  <div className={'my-1'}>
    <div className={'flex flex-row justify-between items-center'}>
      <span className={'px-4 font-semibold text-gray-500'}>{title}</span>
      {actionComponent}
    </div>
    <div className={'px-4'}>{valueComponent}</div>
  </div>
)

const BoxDropdownMenu = ({ data }: { data: Field }) => {
  const { state: parentState, dispatchTask: parentDispatcher } = useContext(
    TaskContext
  )
  const { record, selection, currentPage, mode } = parentState
  const { source: sourceSelection, target: targetSelection } = selection
  const currentPageData = record.pages[currentPage]
  const { fields, words } = currentPageData
  const { label, text, links, id } = data

  const isField = fields.find(i => i.id === data.id)
  const isSingleWord = words.find(i => i.id === data.id)

  const handleDispatchTask = (action, value) =>
    parentDispatcher({ type: action, payload: value })

  const _handleSourceSelectionChange = newValue =>
    handleDispatchTask(SelectionActions.SET_SOURCE_ID, newValue)
  const _handleTargetSelectionChange = newValue =>
    handleDispatchTask(SelectionActions.SET_TARGET_ID, newValue)

  const _editText = newText => {
    const action =
      isSingleWord && !isField
        ? RecordActions.EDIT_OCR_TEXT
        : RecordActions.EDIT_TEXT

    handleDispatchTask(action, {
      id: data.id,
      text: newText
    })
  }

  const _editLabel = newLabel => {
    if (isField) {
      handleDispatchTask(RecordActions.EDIT_LABEL, {
        id: data.id,
        label: newLabel
      })
      _handleSourceSelectionChange(null)
    } else {
      handleDispatchTask(RecordActions.ADD_FIELD, { ...data, label: newLabel })
      const targetField = fields.find(i => i.id === targetSelection)
      const linkableFields =
        targetField && getLinkableFields(targetField.label, newLabel)
      if (targetSelection && linkableFields) {
        handleDispatchTask(RecordActions.ADD_LINK, {
          id: targetSelection,
          targetId: data.id
        })
        !mode.autoFocus && _handleTargetSelectionChange(null)
      }
      handleDispatchTask(MouseEventActions.SET_POPUP_LIST, [])
    }
  }

  const _onEditingModeChange = newValue =>
    handleDispatchTask(ModeActions.SET_EDITING_TEXT, newValue)

  const handleOnTextChange = e => _editText(e.target.value)

  return (
    <div className={'rounded-lg bg-white'}>
      <div className={'py-2'}>
        <MenuItem
          title={'LABEL'}
          valueComponent={
            <LabelSelectMenu
              disabled={!text.length}
              value={label}
              setValue={_editLabel}
              onEditingModeChange={_onEditingModeChange}
            />
          }
        />{' '}
        <MenuItem
          title={'TEXT'}
          valueComponent={
            <>
              <TextArea
                onClick={event => event.stopPropagation()}
                className={'rounded-sm'}
                disabled={!isSingleWord}
                value={text}
                onPressEnter={e => {
                  e.currentTarget.blur()
                  if (sourceSelection === id) {
                    _handleSourceSelectionChange(null)
                  }
                }}
                onFocus={() => _onEditingModeChange(true)}
                onBlur={() => _onEditingModeChange(false)}
                onChange={handleOnTextChange}
                autoSize={{ minRows: 1 }}
              />
              {!text?.length && (
                <Text className="text-red-400"> Text cannot be empty</Text>
              )}
            </>
          }
        />{' '}
        <MenuItem
          title={'LINKS'}
          valueComponent={links.map(link => {
            const linkedBox = fields.find(item => item.id === link)
            const borderColor = getBoxColor(linkedBox?.label)
            const backgroundColor = getBoxColorLight(linkedBox?.label)
            return (
              <div
                key={link}
                className="rounded-sm my-0.5 px-3"
                style={{ border: `1px solid  ${borderColor}`, backgroundColor }}
              >
                {' '}
                {linkedBox?.label}:{linkedBox?.text}{' '}
              </div>
            )
          })}
        />
      </div>
    </div>
  )
}

export default BoxDropdownMenu
