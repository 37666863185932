import { Layout, Modal } from 'antd'
import EmptyComponent from 'components/empty-component'
import ResizableSider from 'components/resizable-sider'
import SplashScreen from 'components/splash'
import React, { useEffect, useReducer } from 'react'
import { useQuery } from 'react-query'
import { QueryKeys } from 'services/api/query-key'
import { Noctua2ApprovalApi } from 'services/api/requests/notua-ii'
import { ResGetPendingReceiptType } from 'services/api/requests/notua-ii/approval'
import { useUnloadBeacon } from 'services/hooks/use-unload-beacon'
import { FOOTER_HEIGHT, HEADER_HEIGHT } from 'services/styles/layout'
import TaskContent from './content'
import { TaskContext } from './context'
import TaskFooter from './footer'
import { combinedReducers, initialState } from './reducers'
import { RecordActions } from './reducers/actions'
import TaskSider from './sider'

const { Content, Sider, Footer } = Layout

const EmailReceiptReviewTaskPage = () => {
  const [state, dispatchTask] = useReducer(combinedReducers, initialState)

  const resetWorkingState = () => {
    dispatchTask({ type: RecordActions.RESET_WORKING_STATE })
  }

  const handleOnDataLoaded = async (data: ResGetPendingReceiptType) => {
    resetWorkingState()
    if (data) {
      const receiptRecord = data.data[0]

      const userEmail =
        receiptRecord.messageTo === 'r@unocart.com'
          ? receiptRecord.messageFrom
          : receiptRecord.messageTo

      dispatchTask({
        type: RecordActions.SET_RECORD,
        payload: { ...receiptRecord, messageTo: userEmail }
      })
    }
  }

  const handleOnLoadDataFailed = err => {
    const modalData = {
      title: err?.message || 'Fetch Error!',
      content: err?.message ? (
        <p>{err.message}</p>
      ) : (
        <>
          <p>Something went wrong while getting this receipt.</p>
          <p>Please contact support.</p>
        </>
      )
    }

    Modal.error(modalData)
  }

  const { isLoading, isFetching } = useQuery(
    [QueryKeys.EmailPending, 'EMAIL', state.record],
    () => Noctua2ApprovalApi.getPendingEmailReceipt(),
    {
      enabled: !state.record,
      onSuccess: handleOnDataLoaded,
      onError: handleOnLoadDataFailed
    }
  )

  useUnloadBeacon({
    method: 'DELETE',
    url: `https://asia.api.data-123.com/noctua-ii/approval/status?receipt_id=${state?.record?.id}&source=email`
  })

  useEffect(() => {
    return () => {
      // unlock old receipt
      if (state?.record?.id) {
        ;(async () =>
          await Noctua2ApprovalApi.unlockReceipt(
            state?.record?.id + '',
            'email'
          ))()
      }
    }
  }, [state?.record?.id])

  return (
    <TaskContext.Provider value={{ state, dispatchTask }}>
      <Layout>
        <Layout
          style={{
            height: `calc(100vh - ${HEADER_HEIGHT}px - ${FOOTER_HEIGHT}px)`
          }}
        >
          <Content className="bg-white z-30 sticky right-0 overflow-scroll">
            {state.record ? (
              <TaskContent />
            ) : (
              <div className="flex justify-center items-center h-full">
                <EmptyComponent
                  description={
                    <>
                      <strong>No more receipts to show</strong>
                      <p>Please contact admin</p>
                    </>
                  }
                />
              </div>
            )}
          </Content>
          <ResizableSider className="z-20 bg-gray-100">
            {state.record && (
              <TaskSider height={`calc(100vh - ${HEADER_HEIGHT}px)`} />
            )}
          </ResizableSider>
        </Layout>

        <Footer
          className="flex items-center justify-between z-40 py-0 px-4"
          style={{ height: FOOTER_HEIGHT }}
        >
          {state.record && <TaskFooter />}
        </Footer>
        {(isLoading || isFetching) && (
          <div
            className="absolute overflow-hidden"
            style={{
              height: `calc(100vh - ${HEADER_HEIGHT}px - ${FOOTER_HEIGHT}px)`
            }}
          >
            <SplashScreen />
          </div>
        )}
      </Layout>
    </TaskContext.Provider>
  )
}

export default EmailReceiptReviewTaskPage
