import { Button, Form, Image, Input } from 'antd'
import Logo from 'assets/misc/logo.png'
import { AuthSelectors } from 'data/selectors'
import React, { useState } from 'react'
import { useSelector } from 'react-redux'
import { useFirebase } from 'react-redux-firebase'
import { useHistory } from 'react-router'
import { AuthRoute } from 'routes/auth/routes'
import {
  emailValidationRules,
  passwordValidationRules
} from 'services/form-rules'

const formItemLayout = {
  labelCol: { span: 24, style: { margin: 0, padding: 0 } },
  wrapperCol: { span: 24, style: { margin: 0 } }
}

export default () => {
  const firebase = useFirebase()
  const authError = useSelector(AuthSelectors.firebaseAuthError)
  const history = useHistory()
  const [isLoading, setIsLoading] = useState<boolean>(false)

  const handleLogin = async ({ email, password }) => {
    try {
      setIsLoading(true)
      await firebase.login({ email, password })
    } finally {
      setIsLoading(false)
    }
  }

  const handleNavigationClick = () => history.push(AuthRoute.REGISTER)
  const handleResetPasswordClick = () => history.push(AuthRoute.RESET)

  return (
    <div
      className="justify-center items-center flex flex-col"
      style={{ width: 350 }}
    >
      <Image preview={false} src={Logo} style={{ height: 120 }} alt="logo" />

      <Form
        {...formItemLayout}
        className="w-full mt-8 no-gap-form"
        key={'login-form'}
        labelAlign="left"
        onFinish={handleLogin}
      >
        <Form.Item
          key={'login-email'}
          name="email"
          rules={emailValidationRules}
        >
          <Input
            className="h-12 rounded-md mb-2"
            autoComplete="off"
            placeholder="Email"
          />
        </Form.Item>
        <Form.Item
          key={'login-password'}
          name="password"
          validateFirst
          rules={passwordValidationRules}
        >
          <Input.Password
            className="h-12 rounded-md mb-2"
            autoComplete={'new-password'}
            placeholder="Password"
          />
        </Form.Item>

        <Form.Item noStyle>
          <Button
            loading={isLoading}
            className="rounded-md h-12 mt-2 w-full bg-black text-white hover:bg-black focus:bg-black"
            htmlType="submit"
          >
            Sign In
          </Button>
        </Form.Item>
        <div className="text-red-500">{authError?.message || ''}</div>

        <span className="mt-6">
          Forgot password?{' '}
          <span
            className="underline cursor-pointer"
            onClick={handleResetPasswordClick}
          >
            Reset it
          </span>
        </span>
      </Form>

      <span
        className="underline mt-6 cursor-pointer"
        onClick={handleNavigationClick}
      >
        Create an account
      </span>
    </div>
  )
}
