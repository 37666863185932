import { IGetTransactionsResPayload } from 'services/api/requests/apolli-visualizer/email-subject-whitelist'
import { WorkflowActions } from './actions'

export interface CombinedStates {
  data: IGetTransactionsResPayload | null // raw db data
  record: IGetTransactionsResPayload | null // workflow record
  startDate: string | null
  endDate: string | null
  retailerFilter: string | null
  countryFilter: string | null
  receiptSubjectFilter: string | null
  idFilter: string | null
  inputMode: 'retailer' | 'id'
}

export const initialData = {
  regex: '',
  panelistId: '******',
  retailerName: '******',
  transactions: []
}

export const initialState: CombinedStates = {
  record: initialData,
  data: initialData,
  startDate: null,
  endDate: null,
  retailerFilter: null,
  countryFilter: null,
  receiptSubjectFilter: null,
  idFilter: null,
  inputMode: 'retailer'
}

export const combinedReducers = {
  // data reducer
  [WorkflowActions.SET_DATA]: (
    state: CombinedStates,
    newData: IGetTransactionsResPayload
  ) => {
    return {
      ...state,
      data: newData
    }
  }, // record reducer
  [WorkflowActions.SET_RECORD]: (
    state: CombinedStates,
    newRecord: IGetTransactionsResPayload
  ) => {
    return {
      ...state,
      record: newRecord
    }
  },
  // inputs reducer
  [WorkflowActions.SET_START_DATE]: (
    state: CombinedStates,
    newDate: string | null
  ) => {
    return {
      ...state,
      startDate: newDate
    }
  },
  [WorkflowActions.SET_END_DATE]: (
    state: CombinedStates,
    newDate: string | null
  ) => {
    return {
      ...state,
      endDate: newDate
    }
  },
  [WorkflowActions.SET_COUNTRY_FILTER]: (
    state: CombinedStates,
    newValue: string | null
  ) => {
    return {
      ...state,
      countryFilter: newValue
    }
  },
  [WorkflowActions.SET_RETAILER_FILTER]: (
    state: CombinedStates,
    newValue: string | null
  ) => {
    return {
      ...state,
      retailerFilter: newValue
    }
  },
  [WorkflowActions.SET_RECEIPT_SUBJECT_FILTER]: (
    state: CombinedStates,
    newValue: string | null
  ) => {
    return {
      ...state,
      receiptSubjectFilter: newValue
    }
  },
  [WorkflowActions.SET_ID_FILTER]: (
    state: CombinedStates,
    newValue: string | null
  ) => {
    return {
      ...state,
      idFilter: newValue
    }
  },
  [WorkflowActions.TOGGLE_INPUT_MODE]: (state: CombinedStates) => {
    const newValue = state.inputMode === 'retailer' ? 'id' : 'retailer'
    return {
      ...state,
      inputMode: newValue
    }
  },
  //  reset
  [WorkflowActions.RESET_WORKING_STATE]: (state: CombinedStates) => {
    return initialState
  }
}

export function reducer(state, action) {
  return combinedReducers[action.type](state, action.payload)
}
