import { Image as AntImage, message } from 'antd'
import AutoFocusToggle from 'components/auto-focus'
import Pagination from 'components/pagination'
import ZoomToolbar from 'components/zoom-toolbar'
import { BoxLabelEnum, LINKABLE_FIELDS } from 'data/box'
import { verticesToId } from 'pages/app/extraction/version-1/task/helpers'
import { CombinedStates } from 'pages/app/extraction/version-2/reducers'
import { CurrentPageActions } from 'pages/app/extraction/version-2/reducers/current-page/actions'
import { LoaderActions } from 'pages/app/extraction/version-2/reducers/loader/actions'
import { ModeActions } from 'pages/app/extraction/version-2/reducers/mode/actions'
import { MouseEventActions } from 'pages/app/extraction/version-2/reducers/mouse-event/actions'
import { RecordActions } from 'pages/app/extraction/version-2/reducers/record/actions'
import { SelectionActions } from 'pages/app/extraction/version-2/reducers/selection/actions'
import FieldBox from 'pages/app/extraction/version-2/task/components/field-box'
import WordBox from 'pages/app/extraction/version-2/task/components/word-box'
import { mergeBoundingBoxes } from 'pages/app/extraction/version-2/task/helpers'
import SelectableArea from 'pages/app/extraction/version-2/task/selectable-area'
import { HotKeyToLabelEnum } from 'pages/app/extraction/version-2/types'
import React, { Dispatch, useRef } from 'react'
import { TransformComponent, TransformWrapper } from 'react-zoom-pan-pinch'
import { Field, Word } from 'services/api/requests/types'
import { preloadImage } from 'services/helpers/misc'
import { useEventListener } from 'services/hooks/use-event-listener'
import { Color } from 'services/styles/color'
import { FOOTER_HEIGHT, HEADER_HEIGHT } from 'services/styles/layout'
import { Space } from 'services/styles/spacing'

interface Props {
  parentState: CombinedStates
  parentDispatcher: Dispatch<any>
}

const TaskSider = ({ parentState, parentDispatcher }: Props) => {
  const zoomWrapperRef = useRef(null)
  const wrapperRef = useRef(null)
  const {
    record,
    loaders,
    mode,
    mouseEvent,
    currentPage,
    selection
  } = parentState

  const { target, source } = selection
  const { popupList, userActions } = mouseEvent
  const { editingText, isPanningMode, linkMode, autoFocus } = mode
  const { isImageLoaded } = loaders
  const totalPages = record?.pages?.length || 0

  const handleDispatchTask = (action, value) =>
    parentDispatcher({ type: action, payload: value })

  // linking
  const openLinkModeMessage = () =>
    message.open({
      key: 'link-mode',
      type: 'info',
      content: 'Select box to link/ unlink',
      duration: 0
    })
  const closeLinkModeMessage = () => message.destroy('link-mode')
  const handleOnLinkModeChange = (checked: boolean) => {
    if (checked) {
      openLinkModeMessage()
    } else {
      closeLinkModeMessage()
      handleDispatchTask(SelectionActions.SET_TARGET_ID, null)
    }

    handleDispatchTask(ModeActions.SET_LINK_MODE, checked)
  }
  // labeling text
  const handleResetMouseEvents = () => {
    handleDispatchTask(MouseEventActions.SET_SELECT_AREA_ACTIONS, [])
    handleDispatchTask(MouseEventActions.SET_POPUP_LIST, [])
  }
  const handleLabelText = (key: BoxLabelEnum) => {
    if (!record || !popupList.length) return null
    const selectedBoxes = record.pages[currentPage].words.reduce(
      (acc: any, curr: Word, index: number) =>
        popupList[index] !== null && popupList[index] !== undefined
          ? {
              text: acc.text + ' ' + curr.text,
              bounding: [...acc.bounding, curr.boundingPoly],
              fieldElements: [...acc.fieldElements, curr.id]
            }
          : acc,
      { text: '', bounding: [], fieldElements: [] }
    )

    const newBoxBoundingPoly = mergeBoundingBoxes(selectedBoxes.bounding)
    const newId = verticesToId(newBoxBoundingPoly.vertices)

    const newField: Field = {
      id: newId,
      text: selectedBoxes.text,
      boundingPoly: newBoxBoundingPoly,
      fieldElements: selectedBoxes.fieldElements,
      label: key,
      links: [],
      userDraw: userActions
    }

    handleDispatchTask(RecordActions.ADD_FIELD, newField)

    const linkableFields = LINKABLE_FIELDS.includes(key)
    if (target && linkableFields) {
      handleDispatchTask(RecordActions.ADD_LINK, {
        id: target,
        targetId: newId
      })
    }
    handleResetMouseEvents()
    return newId
  }
  const handleDeleteHighLights = () => {
    const highlights = Array.from(
      document.querySelectorAll('.highlight.selected')
    )

    if (highlights.length) {
      const selectedBoxes = (record.pages[currentPage].fields || []).filter(
        (highlight, index) =>
          popupList[index] !== null && popupList[index] !== undefined
      )

      selectedBoxes.map(highlight =>
        handleDispatchTask(RecordActions.REMOVE_FIELD, highlight.id)
      )

      handleResetMouseEvents()
    }
  }

  const handleDeleteField = () => {
    if (source) {
      handleDispatchTask(RecordActions.REMOVE_FIELD, source)
      handleDispatchTask(SelectionActions.SET_SOURCE_ID, null)
    }
    if (target) {
      handleDispatchTask(RecordActions.REMOVE_FIELD, target)
      handleDispatchTask(SelectionActions.SET_TARGET_ID, null)
    }
  }
  // pagination actions
  const handlePrevPageClick = () =>
    handleDispatchTask(CurrentPageActions.SET_CURRENT_PAGE, currentPage - 1)
  const handleNextPageClick = () =>
    handleDispatchTask(CurrentPageActions.SET_CURRENT_PAGE, currentPage + 1)
  // zoom actions
  const handleZoomIn = () => zoomWrapperRef?.current?.zoomIn()
  const handleZoomOut = () => zoomWrapperRef?.current?.zoomOut()
  const handleResetZoom = () => zoomWrapperRef?.current?.resetTransform()

  const keydownEventHandler = ({ key }) => {
    const keydown = key.toLowerCase()
    // labeling events
    const isLabelTextAvailable = popupList.includes(true)
    const label = HotKeyToLabelEnum[keydown]
    if (isLabelTextAvailable && label) handleLabelText(label)
    // delete multiple highlights
    if (['backspace', 'delete'].includes(keydown) && !editingText) {
      handleDeleteHighLights()
      handleDeleteField()
    }

    // linking event
    if (keydown === 'c' && target) {
      handleOnLinkModeChange(true)
    }
    //  transform events: zoom/ moving
    if (keydown === ' ' && !editingText) {
      handleDispatchTask(ModeActions.SET_PANNING_MODE, true)
    }
    const _isNextDisabled = currentPage >= totalPages - 1
    const _isPrevDisabled = currentPage <= 0
    if (keydown === 'arrowleft' && !_isPrevDisabled && !editingText) {
      handlePrevPageClick()
    }
    if (keydown === 'arrowright' && !_isNextDisabled && !editingText) {
      handleNextPageClick()
    }
    if (['+', '='].includes(keydown) && !editingText) {
      handleZoomIn()
    }
    if (keydown === '-' && !editingText) {
      handleZoomOut()
    }
  }
  const keyupEventHandler = ({ key }) => {
    const keyup = key.toLowerCase()
    if (keyup === ' ') {
      handleDispatchTask(ModeActions.SET_PANNING_MODE, false)
    }

    // linking event
    if (keyup === 'c' && target) {
      handleOnLinkModeChange(false)
    }
  }
  useEventListener('keydown', keydownEventHandler)
  useEventListener('keyup', keyupEventHandler)

  if (!record) return null

  const currentPageData = record.pages[currentPage]
  try {
    record.pages.map(i => preloadImage(i.image_url))
  } catch (e) {}

  // renders
  const renderBoxes = (textAnnotations: Word[]) =>
    textAnnotations.map((word: Word, index) => (
      <WordBox key={`${word.id}_${index}`} word={word} />
    ))
  const renderLabeledBoxes = (fields: Field[], words: Word[]) =>
    fields.map((field, index) => {
      const _editText = newText =>
        handleDispatchTask(RecordActions.EDIT_TEXT, {
          id: field.id,
          text: newText
        })
      const _editLabel = newLabel =>
        handleDispatchTask(RecordActions.EDIT_LABEL, {
          id: field.id,
          label: newLabel
        })
      const _onEditingModeChange = newValue =>
        handleDispatchTask(ModeActions.SET_EDITING_TEXT, newValue)

      const _handleSourceSelectionChange = newValue =>
        handleDispatchTask(SelectionActions.SET_SOURCE_ID, newValue)

      const _handleTargetSelectionChange = newValue =>
        handleDispatchTask(SelectionActions.SET_TARGET_ID, newValue)

      const _addLink = (payload: { id: string; targetId: string }) =>
        handleDispatchTask(RecordActions.ADD_LINK, payload)

      const _removeLink = (payload: { id: string; targetId: string }) =>
        handleDispatchTask(RecordActions.REMOVE_LINK, payload)

      return (
        <FieldBox
          fields={fields}
          words={words}
          key={`${field.id}_${index}`}
          field={field}
          handleEditText={_editText}
          handleEditLabel={_editLabel}
          onEditingModeChange={_onEditingModeChange}
          handleSourceSelectionChange={_handleSourceSelectionChange}
          handleTargetSelectionChange={_handleTargetSelectionChange}
          addLink={_addLink}
          removeLink={_removeLink}
          sourceSelection={source}
          targetSelection={target}
          linkMode={linkMode}
        />
      )
    })
  const renderLinks = (fields: Field[]) =>
    fields.map((field, index) => {
      if (!field?.links?.length) return null

      const [
        { x: startX0, y: startY0 },
        ,
        { x: startX1, y: startY1 }
      ] = field.boundingPoly.vertices

      return (
        <g key={`${field.id}_${index}`}>
          {field.links.map(link => {
            const targetBox = fields.find(item => item.id === link)
            if (!targetBox) return null

            const [
              { x: endX0, y: endY0 },
              ,
              { x: endX1, y: endY1 }
            ] = targetBox.boundingPoly.vertices

            const startCenter = {
              x: (startX0 + startX1) / 2,
              y: (startY0 + startY1) / 2
            }
            const endCenter = {
              x: (endX0 + endX1) / 2,
              y: (endY0 + endY1) / 2
            }

            return (
              <line
                style={{ opacity: 1 }}
                key={`${field.id}-${link}-${startCenter.x}-${endCenter.x}`}
                x1={startCenter.x}
                y1={startCenter.y}
                x2={endCenter.x}
                y2={endCenter.y}
                stroke={'red'}
              />
            )
          })}
        </g>
      )
    })

  // TODO move arrow functions out of return
  return (
    <div ref={wrapperRef}>
      <SelectableArea
        data={record}
        currentPage={currentPage}
        handleDataChange={newData =>
          handleDispatchTask(RecordActions.SET_RECORD, newData)
        }
        handleUserActionsChange={newActions =>
          handleDispatchTask(
            MouseEventActions.ADD_SELECT_AREA_ACTIONS,
            newActions
          )
        }
        editingText={editingText}
        zoomState={zoomWrapperRef?.current?.state}
        setPopupList={newList =>
          handleDispatchTask(MouseEventActions.SET_POPUP_LIST, newList)
        }
        dragContainer=".draggable-area"
      />
      <TransformWrapper
        key={'extraction-transform-wrapper_'}
        centerOnInit
        ref={zoomWrapperRef}
        initialScale={0.25}
        minScale={0.1}
        maxScale={5}
        doubleClick={{ disabled: true }}
        wheel={{ disabled: true }}
        panning={{ activationKeys: [' '] }}
        centerZoomedOut
      >
        <TransformComponent
          wrapperStyle={{
            backgroundColor: Color.GREY_300,
            width: '100%',
            height: `calc(100vh - ${HEADER_HEIGHT}px - ${FOOTER_HEIGHT}px)`
          }}
        >
          <div style={{ cursor: isPanningMode ? 'move' : 'default' }}>
            <AntImage
              key={currentPageData.image_url}
              {...currentPageData.size}
              alt="receipt"
              onLoad={() =>
                handleDispatchTask(LoaderActions.SET_IMAGE_LOADER, true)
              }
              className="relative"
              src={currentPageData.image_url}
              preview={false}
            />
          </div>
          <svg
            className="absolute draggable-area "
            viewBox={`0 0 ${currentPageData.size.width} ${currentPageData.size.height}`}
          >
            {isImageLoaded ? renderBoxes(currentPageData.words) : null}
            {isImageLoaded
              ? renderLabeledBoxes(
                  currentPageData.fields,
                  currentPageData.words
                )
              : null}
            {isImageLoaded ? renderLinks(currentPageData.fields) : null}
          </svg>
        </TransformComponent>
      </TransformWrapper>

      <Pagination>
        <Pagination.LeftArrow
          onClick={handlePrevPageClick}
          disabled={currentPage <= 0}
          style={{ left: Space.SMALL }}
        />
        <Pagination.RightArrow
          onClick={handleNextPageClick}
          disabled={currentPage >= totalPages - 1}
        />
      </Pagination>

      <ZoomToolbar>
        <ZoomToolbar.ZoomIn onClick={handleZoomIn} />
        <ZoomToolbar.ZoomOut onClick={handleZoomOut} />
        <ZoomToolbar.Reset onClick={handleResetZoom} />
      </ZoomToolbar>

      <AutoFocusToggle
        value={autoFocus}
        onValueChange={newValue =>
          handleDispatchTask(ModeActions.SET_AUTO_FOCUS, newValue)
        }
      />
    </div>
  )
}

export default TaskSider
