import { BoxLabelEnum, IMPORTANT_FIELDS } from 'data/box'
import { ApplicationReducerState } from 'data/reducers'
import { createSelector } from 'reselect'
import { parseCurrency, parseCurrencyStrict } from 'services/helpers/number'

export const extractionState = (state: ApplicationReducerState) =>
  state.extraction

export const currentReceipt = createSelector(
  extractionState,
  extraction => extraction.done
)

export const settings = createSelector(
  extractionState,
  extraction => extraction.settings
)

export const autoFocus = createSelector(
  extractionState,
  extraction => extraction.settings.autoFocus
)

export const retailerFilter = createSelector(
  extractionState,
  extraction => extraction.retailerFilter
)

export const data = createSelector(
  extractionState,
  extraction => extraction.rawData
)

export const totalReceiptPage = createSelector(
  data,
  rawData => rawData?.metadata?.content?.length || 0
)

export const receiptTotal = createSelector(data, rawData =>
  parseCurrency(rawData?.metadata?.total || '0')
)

export const minervaId = createSelector(
  data,
  rawData => rawData?.metadata?.minerva_id
)

export const totalReceipts = createSelector(
  data,
  rawData => rawData?.metadata?.us_pending || 0
)

export const importantFields = createSelector(data, rawData =>
  rawData?.metadata?.content?.reduce((wrapAcc, wrapCurr, index) => {
    return {
      ...wrapAcc,
      ...rawData[String(index)].fields.reduce((acc, curr) => {
        if (IMPORTANT_FIELDS.includes(curr.label)) {
          return { ...acc, [curr.label]: curr }
        }
        return acc
      }, {})
    }
  }, {})
)

// indicate when to fetch new receipt
export const receiptId = createSelector(
  extractionState,
  extraction => extraction.receiptId
)

export const currentPage = createSelector(
  extractionState,
  extraction => extraction.currentPage
)

// TODO fix to have tax field calculated all pages
// Don't use Tax field. use sumPrice instead
export const summaryOfPages = (current, currentSummary) =>
  createSelector(data, rawData =>
    rawData?.metadata?.content?.reduce((wrapAcc, wrapCurr, index) => {
      return index === Number(current)
        ? {
            ...wrapAcc,
            ...currentSummary
          }
        : {
            ...wrapAcc,
            ...rawData[String(index)].fields.reduce((acc, curr) => {
              if (
                curr.label === BoxLabelEnum.ITEM_VALUE ||
                curr.label === BoxLabelEnum.RECEIPT_TOTAL_TAX ||
                curr.label === BoxLabelEnum.RECEIPT_TIP ||
                curr.label === BoxLabelEnum.ITEM_PROMO_PRICE
              ) {
                return {
                  ...acc,
                  [curr?.label]:
                    (acc?.[curr?.label] || 0) + parseCurrency(curr.text)
                }
              } else if (
                curr.label === BoxLabelEnum.RECEIPT_SUBTOTAL_VALUE ||
                curr.label === BoxLabelEnum.RECEIPT_TOTAL_VALUE
              ) {
                return {
                  ...acc,
                  [curr?.label]: curr?.text
                }
              }
              return acc
            }, {})
          }
    }, {})
  )

// need detect duplication
export const sumPriceOfPages = (current, currentSum, isTaxIncluded?: boolean) =>
  createSelector(data, rawData =>
    rawData?.metadata?.content?.reduce(
      (wrapAcc, wrapCurr, index) =>
        index === Number(current)
          ? wrapAcc + currentSum
          : wrapAcc +
            Math.round(
              rawData[String(index)].fields.reduce((acc, curr) => {
                if (
                  curr.label === BoxLabelEnum.ITEM_VALUE ||
                  (!isTaxIncluded &&
                    curr.label === BoxLabelEnum.RECEIPT_TOTAL_TAX) ||
                  curr.label === BoxLabelEnum.RECEIPT_TIP
                ) {
                  return acc + parseCurrency(curr.text)
                }
                if (curr.label === BoxLabelEnum.ITEM_PROMO_PRICE) {
                  return acc - parseCurrencyStrict(curr.text)
                }
                return acc
              }, 0) * 100
            ) /
              100,
      0
    )
  )

// sum tax
export const sumTaxOfPages = (current, currentTax) =>
  createSelector(data, rawData =>
    rawData?.metadata?.content?.reduce(
      (wrapAcc, wrapCurr, index) =>
        index === Number(current)
          ? wrapAcc + currentTax
          : wrapAcc +
            Math.round(
              rawData[String(index)].fields.reduce((acc, curr) => {
                if (curr.label === BoxLabelEnum.RECEIPT_TOTAL_TAX) {
                  return acc + parseCurrencyStrict(curr.text)
                }
                return acc
              }, 0) * 100
            ) /
              100,
      0
    )
  )
