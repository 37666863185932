import { Image as AntImage } from 'antd'
import { AuditSelectors } from 'data/selectors'
import WordBox from 'pages/app/audit/user/components/word-box'
import Pagination from 'pages/app/extraction/version-1/task/components/pagination'
import React, { useEffect, useRef, useState } from 'react'
import { useSelector } from 'react-redux'
import { TransformComponent, TransformWrapper } from 'react-zoom-pan-pinch'
import { preloadImage } from 'services/helpers/misc'
import { useEventListener } from 'services/hooks/use-event-listener'
import useGetDimensions from 'services/hooks/use-get-dimensions'
import { Color } from 'services/styles/color'
import {
  AUDIT_SIDER_WIDTH,
  FOOTER_HEIGHT,
  HEADER_HEIGHT
} from 'services/styles/layout'
import { Space } from 'services/styles/spacing'
import FieldBox from './components/field-box'

const AuditContent = ({ currPage, setCurrPage }) => {
  const data = useSelector(AuditSelectors.receiptData)

  const zoomWrapperRef = useRef(null)
  const wrapperRef = useRef(null)

  const { width: windowWidth } = useGetDimensions()

  const [isPanningMode, setIsPanningMode] = useState<boolean>(false)
  const [isImageLoaded, setIsImageLoaded] = useState(false)

  const currentPageData = data?.pages?.[currPage]

  const keydownEventHandler = ({ keyCode }) => {
    if (keyCode === 107 || keyCode === 187) {
      zoomWrapperRef.current.zoomIn()
    }
    if (keyCode === 109 || keyCode === 189) {
      zoomWrapperRef.current.zoomOut()
    }
    if (keyCode === 32) {
      setIsPanningMode(true)
    }
  }

  const keyupEventHandler = ({ key }: { key: string }) => {
    if (String(key) === ' ') {
      setIsPanningMode(false)
    }
  }

  useEffect(() => {
    setIsImageLoaded(false)
  }, [currentPageData])

  useEffect(() => {
    if (isImageLoaded) {
      zoomWrapperRef.current.centerView()
    }
  }, [isImageLoaded, windowWidth])

  useEventListener('keydown', keydownEventHandler)
  useEventListener('keyup', keyupEventHandler)

  if (!data) return null
  const totalPages = data.pages?.length || 0
  const highLights = currentPageData.fields || []
  const words = currentPageData.words || []

  try {
    data.pages.map(i => preloadImage(i.image_url))
  } catch (e) {}

  const renderWords = () =>
    words.map((word, index) => (
      <WordBox key={`${word.id}_${index}`} word={word} />
    ))

  const renderLabeledBoxes = () =>
    highLights.map((highlight, index) => {
      return (
        <FieldBox
          key={`${highlight.id}_${index}`}
          fields={currentPageData.fields}
          field={highlight}
          words={currentPageData.words}
        />
      )
    })

  const renderLinks = () =>
    highLights.map((box, index) => {
      if (box?.links?.length) {
        const [
          { x: startX0, y: startY0 },
          ,
          { x: startX1, y: startY1 }
        ] = box.boundingPoly.vertices

        return (
          <g key={`${box.id}_${index}`}>
            {box.links.map(link => {
              const targetBox = highLights.find(item => item.id === link)

              if (targetBox) {
                const [
                  { x: endX0, y: endY0 },
                  ,
                  { x: endX1, y: endY1 }
                ] = targetBox.boundingPoly.vertices

                const startCenter = {
                  x: (startX0 + startX1) / 2,
                  y: (startY0 + startY1) / 2
                }
                const endCenter = {
                  x: (endX0 + endX1) / 2,
                  y: (endY0 + endY1) / 2
                }

                return (
                  <line
                    style={{ opacity: 1 }}
                    key={`${box.id}-${link}-${startCenter.x}-${endCenter.x}`}
                    x1={startCenter.x}
                    y1={startCenter.y}
                    x2={endCenter.x}
                    y2={endCenter.y}
                    stroke={'red'}
                  />
                )
              }
              return null
            })}
          </g>
        )
      }
      return null
    })

  const handlePrevPageClick = () => setCurrPage(currPage - 1)

  const handleNextPageClick = () => setCurrPage(currPage + 1)

  return (
    <div ref={wrapperRef}>
      <TransformWrapper
        key={'audit-receipt-wrapper'}
        centerOnInit
        ref={zoomWrapperRef}
        initialScale={0.25}
        minScale={0.1}
        maxScale={5}
        doubleClick={{ disabled: true }}
        wheel={{ activationKeys: ['+', '-'] }}
        panning={{ activationKeys: [' '] }}
        centerZoomedOut
      >
        <TransformComponent
          wrapperStyle={{
            backgroundColor: Color.GREY_300,
            width: '100%',
            height: `calc(100vh - ${HEADER_HEIGHT}px - ${FOOTER_HEIGHT}px)`
          }}
        >
          <div style={{ cursor: isPanningMode ? 'move' : 'default' }}>
            <AntImage
              {...currentPageData.size}
              key={currentPageData.image_url}
              alt="receipt"
              onLoad={() => setIsImageLoaded(true)}
              className="relative"
              src={currentPageData.image_url}
              preview={false}
            />
          </div>
          <svg
            className="absolute draggable-area "
            viewBox={`0 0 ${currentPageData.size.width} ${currentPageData.size.height}`}
          >
            {isImageLoaded ? renderWords() : null}
            {isImageLoaded ? renderLabeledBoxes() : null}
            {isImageLoaded ? renderLinks() : null}
          </svg>
        </TransformComponent>
      </TransformWrapper>
      <Pagination>
        <Pagination.LeftArrow
          onClick={handlePrevPageClick}
          disabled={currPage <= 0}
        />
        <Pagination.RightArrow
          onClick={handleNextPageClick}
          disabled={currPage >= totalPages - 1}
          style={{ right: AUDIT_SIDER_WIDTH + Space.SMALL }}
        />
        <Pagination.PageIndicator
          style={{ marginRight: AUDIT_SIDER_WIDTH }}
          current={currPage + 1}
          total={totalPages || 0}
        />
      </Pagination>
    </div>
  )
}

export default AuditContent
