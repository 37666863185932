import { PermissionEnum } from 'data/reducers'
import { ForbiddenPage } from 'pages/app'
import PiiRedactionPage from 'pages/app/pii-redaction'
import PiiRedactionHomePage from 'pages/app/pii-redaction/home'
import React from 'react'
import { AppSubRoutes } from 'routes/app/routes'
import useHandleRequirePermissions from 'services/hooks/use-handle-require-permission'
import SentryRoute from 'services/sentry/sentry-route'

const PiiRedactionRoutesNavigator = () => {
  const hasPermission = useHandleRequirePermissions([PermissionEnum.REDACTION])

  return (
    <>
      <SentryRoute
        exact
        path={AppSubRoutes.PII_REDACTION.HOME.pathname}
        component={hasPermission ? PiiRedactionHomePage : ForbiddenPage}
      />
      <SentryRoute
        exact
        path={AppSubRoutes.PII_REDACTION.TASK.pathname}
        component={hasPermission ? PiiRedactionPage : ForbiddenPage}
      />
    </>
  )
}

export default PiiRedactionRoutesNavigator
