import { Button, Modal, Typography } from 'antd'
import React, { useState } from 'react'
import { PreviousActivityDataProps } from './types'

const { Text } = Typography

const PreviousActivityDataModal = ({
  data,
  isVisible,
  onClose
}: PreviousActivityDataProps) => {
  const [copyResult, setCopyResult] = useState<string>('')
  const prettyData = JSON.stringify(data, null, 2)

  const onCopy = async () => {
    if (!navigator.clipboard) {
      setCopyResult('Could not copy to clipboard, browser unsupported')
      setTimeout(() => setCopyResult(''), 3000)
    }

    navigator.clipboard.writeText(prettyData)
    setCopyResult('Copied to clipboard!')
    setTimeout(() => setCopyResult(''), 3000)
  }

  return (
    <Modal
      title="Previous Activity Data"
      visible={isVisible}
      onCancel={onClose}
      footer={[
        <Text className="mr-4">{copyResult}</Text>,
        <Button onClick={onCopy}>Copy all Data</Button>,
        <Button onClick={onClose}>Close</Button>
      ]}
      keyboard
      centered
      width={1000}
      bodyStyle={{
        overflowY: 'scroll',
        overscrollBehavior: 'contain',
        maxHeight: 'calc(100vh - 200px)'
      }}
    >
      <code>
        <pre style={{ whiteSpace: 'pre-wrap' }}>{prettyData}</pre>
      </code>
    </Modal>
  )
}

export default PreviousActivityDataModal
