import { Col, Row, Skeleton } from 'antd'
import React from 'react'
import { Space } from 'services/styles/spacing'

const singleRowHeight = Space.LARGE
const numberOfRows = 3

const LoadingRows = () => (
  <Row>
    <Col span={24}>
      {[...Array(numberOfRows)].map((e, i) => (
        <div key={i} style={{ margin: `${Space.TINY}px 0px` }}>
          {/* require both w-full and width 100% */}
          <Skeleton.Avatar
            className={'w-full rounded-lg'}
            style={{ height: singleRowHeight, width: '100%' }}
            active
            size={'small'}
            shape="square"
          />
        </div>
      ))}
    </Col>
  </Row>
)

export default LoadingRows
