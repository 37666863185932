import { Dropdown, message, notification, Select } from 'antd'
import TextArea from 'antd/lib/input/TextArea'
import { BoxActions, ExtractionActions } from 'data/actions'
import { BoxLabelEnum, boxLabelsList } from 'data/box'
import { BoxSelectors, ExtractionSelectors } from 'data/selectors'
import React, { Dispatch, ReactNode, SetStateAction } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { useEventListener } from 'services/hooks/use-event-listener'
import {
  getBoxColor,
  getBoxColorDarker,
  getBoxColorLight
} from 'services/styles/color'
import { Space } from 'services/styles/spacing'

interface Props {
  editingText: boolean
  setEditingText: Dispatch<SetStateAction<boolean>>
  id: string | number
  text: string
  label: string
  links?: any
  x: number
  y: number
  boxWidth: number
  boxHeight: number
}

const LabelSelectMenu = ({ value, handleOnChange }) => {
  const formatText = (label: string) =>
    label === BoxLabelEnum.ITEM_CODE ? 'Barcode/SKU' : label

  return (
    <Select
      style={{ borderRadius: 99 }}
      className="w-full"
      value={value}
      onChange={handleOnChange}
      onClick={event => event.stopPropagation()}
    >
      {boxLabelsList.map(label => (
        <Select.Option key={label} value={label}>
          {formatText(label)}
        </Select.Option>
      ))}
    </Select>
  )
}

interface MenuItemProps {
  title: string
  actionComponent?: ReactNode | string
  valueComponent?: ReactNode | string
}

const MenuItem = ({
  title,
  actionComponent,
  valueComponent
}: MenuItemProps) => (
  <div className={'my-1'}>
    <div className={'flex flex-row justify-between items-center'}>
      <span className={'px-4 font-semibold text-gray-500'}>{title}</span>
      {actionComponent}
    </div>
    <div className={'px-4'}>{valueComponent}</div>
  </div>
)

const _renderMessageContent = (action: string, sourceBox, targetBox) => (
  <span>
    {action}{' '}
    <span
      className="rounded text-white font-bold px-2 pb-0.5"
      style={{ backgroundColor: getBoxColor(sourceBox?.label) }}
    >
      {sourceBox?.text}
    </span>{' '}
    to{' '}
    <span
      className="rounded text-white font-bold px-2 pb-0.5"
      style={{ backgroundColor: getBoxColor(targetBox?.label) }}
    >
      {targetBox?.text}
    </span>
  </span>
)

const DropdownEditBox = ({
  editingText,
  setEditingText,
  id,
  text,
  label,
  links,
  x,
  y,
  boxWidth,
  boxHeight
}: Props) => {
  const dispatch = useDispatch()
  const boxActiveId = useSelector(BoxSelectors.activeId)
  const boxTargetId = useSelector(BoxSelectors.targetId)
  const labeledBoxes = useSelector(BoxSelectors.boxes)
  const settings = useSelector(ExtractionSelectors.settings)

  const handleFocusInput = () => setEditingText(true)
  const handleLoseFocusInput = () => setEditingText(false)

  const handleOnLinkModeChange = (checked: boolean) => {
    const newCheckedList = { ...settings, linkMode: checked }
    dispatch(ExtractionActions.setSettings(newCheckedList))
  }

  const handleSetTargetBoxId = (id: string) =>
    dispatch(BoxActions.setTargetId(id))

  const handleEditLabel = value =>
    dispatch(BoxActions.editLabel({ id, label: value }))

  const handleEditText = event =>
    dispatch(BoxActions.editText({ id, text: event.target.value }))

  const openLinkModeMessage = () =>
    message.open({
      key: 'link-mode',
      type: 'info',
      content: 'Select box to link/ unlink',
      duration: 0
    })

  const closeLinkModeMessage = () => message.destroy('link-mode')

  const menu = (
    <div className={'rounded-lg bg-white'}>
      <div className={'py-2'}>
        <MenuItem
          title={'LABEL'}
          valueComponent={
            <LabelSelectMenu value={label} handleOnChange={handleEditLabel} />
          }
        />
        <MenuItem
          title={'TEXT'}
          valueComponent={
            <TextArea
              onClick={event => event.stopPropagation()}
              className={'rounded-sm'}
              value={text}
              onPressEnter={e => {
                e.currentTarget.blur()
                if (boxActiveId === id) {
                  dispatch(BoxActions.setActiveId(undefined))
                }
              }}
              // autoFocus
              onChange={handleEditText}
              onFocus={handleFocusInput}
              onBlur={handleLoseFocusInput}
              autoSize={{ minRows: 1 }}
            />
          }
        />
        <MenuItem
          title={'LINKS'}
          valueComponent={links.map(link => {
            const linkedBox = labeledBoxes.find(item => item.id === link)
            const borderColor = getBoxColor(linkedBox?.label)
            const backgroundColor = getBoxColorLight(linkedBox?.label)
            return (
              <div
                key={link}
                className="rounded-sm my-0.5 px-3"
                style={{ border: `1px solid ${borderColor}`, backgroundColor }}
              >
                {linkedBox?.label}: {linkedBox?.text}
              </div>
            )
          })}
        />
      </div>
    </div>
  )

  const handleLinkEvent = (source, target) => () => {
    if (!settings.linkMode) {
      target === boxTargetId
        ? handleSetTargetBoxId(undefined)
        : handleSetTargetBoxId(target)
    }

    if (settings.linkMode && source && source !== target) {
      const payload = { id: source, targetId: target }
      const targetBox = labeledBoxes.find(box => box.id === target)
      const sourceBox = labeledBoxes.find(box => box.id === source)
      const isExisted = targetBox.links.includes(source)

      if (isExisted) {
        dispatch(BoxActions.removeLink(payload))
        notification.warning({
          style: {
            borderRadius: 12,
            padding: Space.TINY,
            backgroundColor: 'rgba(255,255,255,0.7)'
          },
          message: _renderMessageContent('Unlinked', sourceBox, targetBox),
          placement: 'bottomLeft'
        })
      } else {
        dispatch(BoxActions.addLink(payload))
        notification.success({
          style: {
            borderRadius: 12,
            padding: Space.TINY,
            backgroundColor: 'rgba(255,255,255,0.7)'
          },
          message: _renderMessageContent('Linked', sourceBox, targetBox),
          placement: 'bottomLeft'
        })
      }
    }
  }

  // TODO clean use obj/dict
  const handleKeydown = ({ keyCode }) => {
    if (
      (keyCode === 46 || keyCode === 8) &&
      !editingText &&
      boxActiveId &&
      boxActiveId === id
    ) {
      dispatch(BoxActions.removeBox(boxActiveId))
    }

    if (keyCode === 67 && boxTargetId) {
      !settings.linkMode && handleOnLinkModeChange(true)
      openLinkModeMessage()
    }
  }

  const handleKeyUp = ({ keyCode }) => {
    settings.linkMode && handleOnLinkModeChange(false)
    if (keyCode === 67 && boxTargetId) {
      closeLinkModeMessage()
      if (boxTargetId === id) {
        dispatch(BoxActions.setTargetId(undefined))
      }
    }
  }

  useEventListener('keydown', handleKeydown)
  useEventListener('keyup', handleKeyUp)

  const isFocused = boxTargetId === id
  // sider use box id to scroll
  return (
    <Dropdown
      placement="bottomCenter"
      overlay={menu}
      overlayStyle={{ width: '12rem', minWidth: 'min-content' }}
      trigger={['contextMenu']}
      visible={boxActiveId === id && !settings.linkMode}
      onVisibleChange={visible =>
        !settings.linkMode &&
        dispatch(BoxActions.setActiveId(visible ? id : undefined))
      }
    >
      <div
        onClick={handleLinkEvent(boxTargetId, id)}
        className={'z-20 cursor-pointer absolute highlight'}
        style={{
          top: y,
          left: x,
          width: boxWidth,
          height: boxHeight,
          opacity: 0.5,
          border: isFocused
            ? '10px solid red'
            : `4px  solid ${getBoxColorDarker(label)}`,
          backgroundColor: getBoxColorLight(label)
        }}
      />
    </Dropdown>
  )
}

export default DropdownEditBox
