import LoadingRows from 'components/skeleton/loading-rows'
import StandardBreadcrumb from 'components/standard-breadcrumb'
import AnnotationOverviewTable from 'pages/app/admin/annotation-overview/components/overview-table'
import React from 'react'
import { useQuery } from 'react-query'
import { Noctua2LabelApi } from 'services/api/requests/notua-ii'
import { DBReceiptOverview } from 'services/api/requests/notua-ii/types'

const AdminAnnotationOverview = () => {
  const { isFetching, data } = useQuery<DBReceiptOverview>(
    'list-complete',
    Noctua2LabelApi.getListComplete
  )

  return (
    <div className="p-10">
      <StandardBreadcrumb />
      <h3 className="mb-4">Annotation Overview</h3>
      {!isFetching ? <AnnotationOverviewTable data={data} /> : <LoadingRows />}
    </div>
  )
}

export default AdminAnnotationOverview
