import { PermissionEnum } from 'data/reducers'
import { ForbiddenPage } from 'pages/app'
import EmailSubjectWhitelistPage from 'pages/app/email-subject-whitelist'
import React from 'react'
import { AppSubRoutes } from 'routes/app/routes'
import useHandleRequirePermissions from 'services/hooks/use-handle-require-permission'
import SentryRoute from 'services/sentry/sentry-route'

const EmailSubjectWhitelistRoutesNavigator = () => {
  const hasPermission = useHandleRequirePermissions([
    PermissionEnum.SUBJECT_WHITELIST
  ])

  return (
    <>
      <SentryRoute
        exact
        path={AppSubRoutes.EMAIL_SUBJECT_WHITELIST.HOME.pathname}
        component={hasPermission ? EmailSubjectWhitelistPage : ForbiddenPage}
      />
    </>
  )
}

export default EmailSubjectWhitelistRoutesNavigator
