import React from 'react'

const ShortcutsTooltip = () => (
  <>
    <p style={{ borderBottom: '1px solid grey' }}>SHORTCUTS</p>
    <p>
      <span className="mr-12">+/=</span>: Zoom in
    </p>
    <p>
      <span className="mr-16">-</span>: Zoom out
    </p>
    <p>
      <span className="mr-14">C</span>: Link mode
    </p>
    <p>
      <span className="mr-14">Space</span>: Panning mode
    </p>
    <p>
      <span className="mr-14">O</span>: Select highlights
    </p>
    <p>
      <span className="mr-16">`</span>: Create box mode
    </p>
    <p>
      <span className="mr-6">Delete</span>: Delete label
    </p>
  </>
)

export default ShortcutsTooltip
