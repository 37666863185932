import { Modal } from 'antd'
import React from 'react'
import { useMutation } from 'react-query'
import { Noctua2DefinitionApi } from 'services/api/requests/notua-ii'
import queryClient from 'services/react-query'

const useCreateDefinition = (): [boolean, (postBody) => Promise<any>] => {
  const { mutateAsync, isLoading } = useMutation(
    Noctua2DefinitionApi.createDefinition
  )

  const handleCreateDefinition = async postBody => {
    await mutateAsync(postBody, {
      onError: () => {
        Modal.error({
          title: 'Update Definition Error!',
          content: 'Something went wrong while create definition.'
        })
      },
      onSuccess: () => queryClient.invalidateQueries('definition-all')
    })
  }

  return [isLoading, handleCreateDefinition]
}

export default useCreateDefinition
