import { AnnotationLabelEnum } from 'pages/app/annotation-tool/task/types'

export const columnsStyle = {
  [AnnotationLabelEnum.ITEM_DESCRIPTION]: 'w-1/3 flex',
  [AnnotationLabelEnum.ITEM_CODE]: 'w-1/12 flex',
  [AnnotationLabelEnum.ITEM_CODE_2]: 'w-1/12 flex',
  [AnnotationLabelEnum.ITEM_UNIT_PRICE]: 'w-1/12 flex',
  [AnnotationLabelEnum.ITEM_QUANTITY]: 'w-1/12 flex',
  [AnnotationLabelEnum.ITEM_PRICE]: 'w-1/12 flex flex-col justify-end',
  [AnnotationLabelEnum.ITEM_SELLER]: 'w-1/12 flex',
  [AnnotationLabelEnum.ITEM_ORDER_ID]: 'w-1/12 flex',
  [AnnotationLabelEnum.ITEM_DELIVERY_DATE]: 'w-1/12 flex',
  [AnnotationLabelEnum.ITEM_DESCRIPTION_EXTRA]: 'w-1/12 flex',
  [AnnotationLabelEnum.ITEM_CATEGORY]: 'w-12/12 flex',
  [AnnotationLabelEnum.ITEM_SUBTOTAL]: 'w-12/12 flex',
  [AnnotationLabelEnum.ITEM_ADDRESS]: 'w-1/12 flex',
  [AnnotationLabelEnum.ITEM_REG_PRICE]: 'w-1/12 flex',
  [AnnotationLabelEnum.ITEM_LINE_TAX]: 'w-1/12 flex',
  [AnnotationLabelEnum.ITEM_PROMO_NAME]: 'w-4/12',
  [AnnotationLabelEnum.ITEM_PROMO_CODE]: 'w-2/12',
  [AnnotationLabelEnum.ITEM_PROMO_UNIT_PRICE]: 'w-2/12',
  [AnnotationLabelEnum.ITEM_PROMO_QUANTITY]: 'w-2/12',
  [AnnotationLabelEnum.ITEM_PROMO_PRICE]: 'w-2/12',
  [AnnotationLabelEnum.ITEM_OTHER_DESCRIPTION]: 'w-10/12',
  [AnnotationLabelEnum.ITEM_OTHER_PRICE]: 'w-2/12',
  [AnnotationLabelEnum.SUB_ITEM_DESCRIPTION]: 'w-10/12',
  [AnnotationLabelEnum.SUB_ITEM_PRICE]: 'w-2/12',
  [AnnotationLabelEnum.ITEM_TAX_DESCRIPTION]: 'w-10/12',
  [AnnotationLabelEnum.ITEM_TAX_PRICE]: 'w-2/12',
  [AnnotationLabelEnum.LOYALTY_EARNED_DESCRIPTION]: 'w-10/12',
  [AnnotationLabelEnum.LOYALTY_EARNED_POINTS]: 'w-2/12',
  [AnnotationLabelEnum.LOYALTY_REDEEMED_DESCRIPTION]: 'w-10/12',
  [AnnotationLabelEnum.LOYALTY_REDEEMED_POINTS]: 'w-2/12',
  [AnnotationLabelEnum.LOYALTY_BALANCE_DESCRIPTION]: 'w-10/12',
  [AnnotationLabelEnum.LOYALTY_BALANCE_POINTS]: 'w-2/12'
}
