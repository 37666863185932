import { MinusOutlined, PlusOutlined } from '@ant-design/icons'
import React from 'react'

const ZoomIn = ({ onClick }) => {
  return (
    <div
      className={'flex justify-center text-white cursor-pointer'}
      onClick={onClick}
    >
      <span className="rounded py-0.5 w-6 bg-opacity-50 bg-black flex justify-center">
        <PlusOutlined style={{ fontSize: 18 }} />
      </span>
    </div>
  )
}

const ZoomOut = ({ onClick }) => {
  return (
    <div
      className={'flex justify-center text-white cursor-pointer'}
      onClick={onClick}
    >
      <span className="rounded py-0.5 w-6 bg-opacity-50 bg-black flex justify-center">
        <MinusOutlined style={{ fontSize: 18 }} />
      </span>
    </div>
  )
}

const Reset = ({ onClick }) => {
  return (
    <div
      className={'flex justify-center text-white cursor-pointer'}
      onClick={onClick}
    >
      <span className="rounded py-0.5 w-12 bg-opacity-50 bg-black flex justify-center text-sm">
        Reset
      </span>
    </div>
  )
}

const ZoomToolbar = ({ children }) => (
  <div
    className={
      'absolute top-2 w-28 ml-2 right-2 flex items-center justify-between'
    }
  >
    {children}
  </div>
)

ZoomToolbar.ZoomIn = ZoomIn
ZoomToolbar.ZoomOut = ZoomOut
ZoomToolbar.Reset = Reset

export default ZoomToolbar
