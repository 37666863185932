import { PermissionEnum } from 'data/reducers'
import { ForbiddenPage } from 'pages/app'
import {
  AnnotationToolHome,
  AnnotationToolTask
} from 'pages/app/annotation-tool'
import React from 'react'
import { AppSubRoutes } from 'routes/app/routes'
import useHandleRequirePermissions from 'services/hooks/use-handle-require-permission'
import SentryRoute from 'services/sentry/sentry-route'

const AnnotationRoutesNavigator = () => {
  const hasPermission = useHandleRequirePermissions([PermissionEnum.ANNOTATION])

  return (
    <>
      <SentryRoute
        exact
        path={AppSubRoutes.ANNOTATION.HOME.pathname}
        component={hasPermission ? AnnotationToolHome : ForbiddenPage}
      />
      <SentryRoute
        exact
        path={AppSubRoutes.ANNOTATION.TASK.pathname}
        component={hasPermission ? AnnotationToolTask : ForbiddenPage}
      />
    </>
  )
}

export default AnnotationRoutesNavigator
