import { SyncOutlined } from '@ant-design/icons'
import { Button, Tag } from 'antd'
import Table from 'components/table'
import { AppActions } from 'data/actions'
import { AppSelectors } from 'data/selectors'
import React from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { useHistory } from 'react-router'
import { AppSubRoutes } from 'routes/app/routes'
import { DBReceiptOverview } from 'services/api/requests/notua-ii/types'
import { Color } from 'services/styles/color'
import { Space } from 'services/styles/spacing'

interface Props {
  data: DBReceiptOverview
}

const TasksTable = ({ data }: Props) => {
  const dispatch = useDispatch()
  const history = useHistory()
  const isSiderOpen = useSelector(AppSelectors.siderState)

  const handleNavigationClick = (
    countryCode: string,
    retailerFilter: string
  ) => () => {
    if (isSiderOpen) dispatch(AppActions.toggleSider())
    history.push(
      AppSubRoutes.ANNOTATION.HOME.pathname +
        `/${countryCode}/${retailerFilter}`
    )
  }

  return (
    <Table>
      <Table.Header
        titles={[
          'Country',
          'Retailer',
          'Completed / Target',
          'Mapping',
          'Status',
          ''
        ]}
      />
      <tbody>
        {data.map(
          ({
            countryCode,
            merchant,
            complete,
            target,
            tutorial_url,
            pending
          }) => {
            const isCompleted = complete >= target
            const isNotReady = pending === 0
            const completeColor =
              isCompleted || isNotReady ? 'text-green-400' : 'text-black'

            const buttonColor = isCompleted || isNotReady ? null : Color.SUCCESS

            const handleReviewClick = url => () => window.open(url, '_blank')

            return (
              <Table.Row key={`${countryCode}_${merchant}`}>
                <Table.Cell label={countryCode} />
                <Table.Cell label={merchant} />
                <Table.Cell
                  render={
                    <span>
                      <span className={completeColor}>{complete}</span> /{' '}
                      <span className="text-gray-400">{target}</span>
                    </span>
                  }
                />
                <Table.Cell
                  render={
                    tutorial_url ? (
                      <Button
                        type="link"
                        onClick={handleReviewClick(tutorial_url)}
                      >
                        Review
                      </Button>
                    ) : null
                  }
                />

                <Table.Cell
                  label={!isNotReady && ' '}
                  render={
                    isNotReady ? (
                      <Tag
                        icon={<SyncOutlined spin />}
                        color="processing"
                        className="pt-1.5 pb-2.5 px-2"
                      >
                        processing
                      </Tag>
                    ) : null
                  }
                  cellStyle={{ paddingRight: Space.LARGE * 3 }}
                />
                <Table.Cell
                  render={
                    <Button
                      disabled={isCompleted || isNotReady}
                      onClick={handleNavigationClick(countryCode, merchant)}
                      size="large"
                      className="w-11/12 text-white rounded"
                      style={{ backgroundColor: buttonColor }}
                    >
                      Start
                    </Button>
                  }
                />
              </Table.Row>
            )
          }
        )}
      </tbody>
    </Table>
  )
}

export default TasksTable
