import { Modal } from 'antd'
import React from 'react'
import { useMutation } from 'react-query'
import { Noctua2DefinitionApi } from 'services/api/requests/notua-ii'
import queryClient from 'services/react-query'

const useDeleteDefinition = (): [boolean, (id) => Promise<any>] => {
  const { mutateAsync, isLoading } = useMutation(
    Noctua2DefinitionApi.deleteDefinition
  )

  const handleDeleteDefinition = async id => {
    await mutateAsync(id, {
      onError: () => {
        Modal.error({
          title: 'Delete Definition Error!',
          content: 'Something went wrong while delete definition.'
        })
      },
      onSuccess: () => queryClient.invalidateQueries('definition-all')
    })
  }

  return [isLoading, handleDeleteDefinition]
}

export default useDeleteDefinition
