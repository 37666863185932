import Divider from 'components/divider'
import { BoxLabelEnum } from 'data/box'
import React from 'react'
import Hotkeys from 'services/hotkeys'

const HotkeysTooltip = () => (
  <>
    <p style={{ borderBottom: '1px solid grey' }}>HOTKEYS</p>
    <Divider text1={'Merchant'} />
    <table className="table-auto tooltip-table">
      <thead>
        <tr>
          <th>Name</th>
          <th>Address</th>
          <th>Phone</th>
          <th>Store ID + Name</th>
        </tr>
      </thead>
      <tbody>
        <tr>
          <td>{Hotkeys[BoxLabelEnum.RECEIPT_MERCHANT_NAME]}</td>
          <td>{Hotkeys[BoxLabelEnum.RECEIPT_STORE_ADDRESS]}</td>
          <td>{Hotkeys[BoxLabelEnum.RECEIPT_STORE_PHONE]}</td>
          <td>{Hotkeys[BoxLabelEnum.RECEIPT_STORE_ID]}</td>
          <td>{Hotkeys[BoxLabelEnum.RECEIPT_STORE_ID]}</td>
        </tr>
      </tbody>
    </table>
    <Divider text1={'Line'} />

    <table className="table-auto tooltip-table">
      <thead>
        <tr>
          <th>Name</th>
          <th>Quantity</th>
          <th>Unit Price</th>
          <th>Total Price</th>
          <th>Product ID</th>
        </tr>
      </thead>
      <tbody>
        <tr>
          <td>{Hotkeys[BoxLabelEnum.ITEM_DESCRIPTION]}</td>
          <td>{Hotkeys[BoxLabelEnum.ITEM_QUANTITY]}</td>
          <td>{Hotkeys[BoxLabelEnum.ITEM_UNIT_PRICE]}</td>
          <td>{Hotkeys[BoxLabelEnum.ITEM_VALUE]}</td>
          <td>{Hotkeys[BoxLabelEnum.ITEM_CODE]}</td>
        </tr>
      </tbody>
    </table>

    <Divider text1={'Total'} />

    <table className="table-auto tooltip-table">
      <thead>
        <tr>
          <th>Total Discount</th>
          <th>Subtotal</th>
          <th>Tax</th>
          <th>Total</th>
          <th>Tips</th>
        </tr>
      </thead>
      <tbody>
        <tr>
          <td>{Hotkeys[BoxLabelEnum.RECEIPT_TOTAL_DISCOUNT]}</td>
          <td>{Hotkeys[BoxLabelEnum.RECEIPT_SUBTOTAL_VALUE]}</td>
          <td>{Hotkeys[BoxLabelEnum.RECEIPT_TOTAL_TAX]}</td>
          <td>{Hotkeys[BoxLabelEnum.RECEIPT_TOTAL_VALUE]}</td>
          <td>{Hotkeys[BoxLabelEnum.RECEIPT_TIP]}</td>
        </tr>
      </tbody>
    </table>

    <Divider text1={'Promo'} />

    <table className="table-auto tooltip-table">
      <thead>
        <tr>
          <th>Promo Name</th>
          <th>Promo Price</th>
        </tr>
      </thead>
      <tbody>
        <tr>
          <td>{Hotkeys[BoxLabelEnum.ITEM_PROMO_NAME]}</td>
          <td>{Hotkeys[BoxLabelEnum.ITEM_PROMO_PRICE]}</td>
        </tr>
      </tbody>
    </table>

    <Divider text1={'Date / Time'} />
    <table className="table-auto tooltip-table">
      <thead>
        <tr>
          <th>Date</th>
          <th>Time</th>
        </tr>
      </thead>
      <tbody>
        <tr>
          <td>{Hotkeys[BoxLabelEnum.RECEIPT_PURCHASE_DATE]}</td>
          <td>{Hotkeys[BoxLabelEnum.RECEIPT_PURCHASE_TIME]}</td>
        </tr>
      </tbody>
    </table>

    <Divider text1={'Other'} />
    <table className="table-auto tooltip-table">
      <thead>
        <tr>
          <th>Cash Register / POS ID</th>
          <th>Transaction ID</th>
        </tr>
      </thead>
      <tbody>
        <tr>
          <td>{Hotkeys[BoxLabelEnum.RECEIPT_CASH_REGISTER_ID]}</td>
          <td>{Hotkeys[BoxLabelEnum.RECEIPT_TRANSACTION_ID]}</td>
        </tr>
      </tbody>
    </table>
  </>
)

export default HotkeysTooltip
