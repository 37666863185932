import { Button, Progress, Tooltip } from 'antd'
import Table from 'components/table'
import { AppActions, ExtractionActions } from 'data/actions'
import { AppSelectors } from 'data/selectors'
import React from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { useHistory } from 'react-router'
import { AppSubRoutes } from 'routes/app/routes'
import { DBReceiptOverview } from 'services/api/requests/types'
import { Color } from 'services/styles/color'
import { Space } from 'services/styles/spacing'

interface Props {
  data: DBReceiptOverview
}

const TasksTable = ({ data }: Props) => {
  const dispatch = useDispatch()
  const history = useHistory()
  const isSiderOpen = useSelector(AppSelectors.siderState)

  const formattedData = data
    ? Object.entries(data).map(([country, value]) => ({
        country,
        ...value
      }))
    : []

  const handleNavigationClick = (country: string) => () => {
    dispatch(ExtractionActions.setReceiptId(null))
    if (isSiderOpen) dispatch(AppActions.toggleSider())
    history.push(AppSubRoutes.EXTRACTION.V1.HOME + `/${country}`)
  }

  return (
    <Table>
      <Table.Header
        titles={[
          'Country',
          'Pending Receipts',
          'Agents Online',
          'Current Progress',
          ''
        ]}
      />
      <tbody>
        {formattedData.map(({ country, progress, pending, online }) => (
          <Table.Row key={country}>
            <Table.Cell label={country} />
            <Table.Cell label={pending.toString()} />
            <Table.Cell label={online.toString()} />
            <Table.Cell
              cellStyle={{ paddingRight: Space.LARGE, width: '30%' }}
              render={
                <Tooltip title={`${progress.toFixed(2)}%`}>
                  <Progress
                    percent={progress}
                    showInfo={false}
                    strokeWidth={Space.SMALL}
                    strokeColor={Color.SUCCESS}
                  />
                </Tooltip>
              }
            />
            <Table.Cell
              render={
                <Button
                  onClick={handleNavigationClick(country)}
                  size="large"
                  className="w-11/12 text-white rounded"
                  style={{ backgroundColor: Color.SUCCESS }}
                >
                  Start
                </Button>
              }
            />
          </Table.Row>
        ))}
      </tbody>
    </Table>
  )
}

export default TasksTable
