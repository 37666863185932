import { Layout } from 'antd'
import React, { useState } from 'react'
import useWindowDimensions from 'services/hooks/use-get-dimensions'
import { HEADER_HEIGHT } from 'services/styles/layout'

const { Sider } = Layout

let isResizing = null

const ResizableSider = ({ children, ...props }) => {
  const { width } = useWindowDimensions()
  const [drawerWidth, setDrawerWidth] = useState(width / 3)

  const handleMouseup = () => {
    if (!isResizing) return
    isResizing = false
    document.removeEventListener('mousemove', cbHandleMouseMove)
    document.removeEventListener('mouseup', cbHandleMouseUp)
  }

  const handleMousedown = e => {
    e.stopPropagation()
    e.preventDefault()
    document.addEventListener('mousemove', cbHandleMouseMove)
    document.addEventListener('mouseup', cbHandleMouseUp)
    isResizing = true
  }

  const handleMousemove = e => {
    const offsetRight =
      document.body.offsetWidth - (e.clientX - document.body.offsetLeft)
    const minWidth = width / 5
    const maxWidth = (width * 2) / 3

    if (offsetRight > minWidth && offsetRight < maxWidth) {
      setDrawerWidth(offsetRight)
    }
  }

  const cbHandleMouseMove = React.useCallback(handleMousemove, [width])
  const cbHandleMouseUp = React.useCallback(handleMouseup, [cbHandleMouseMove])

  return (
    <Sider
      width={drawerWidth}
      collapsible={false}
      style={{
        height: '100%',
        minHeight: `calc(100vh - ${HEADER_HEIGHT})`
      }}
      className="site-layout-background overflow-x-hidden p-6 flex items-center justify-center sticky top-0"
      {...props}
    >
      <div className="sidebar-dragger" onMouseDown={handleMousedown} />
      {children}
    </Sider>
  )
}

export default ResizableSider
