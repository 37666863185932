import axios from 'axios'
import config from 'services/config'
import {
  axiosReqInterceptor,
  axiosResInterceptor
} from 'services/integrations/axios'

const MinervaStateMachineApiClient = axios.create({
  baseURL: config.baseUrl + 'minerva/state-machine/'
})

MinervaStateMachineApiClient.interceptors.request.use(axiosReqInterceptor)
MinervaStateMachineApiClient.interceptors.response.use(axiosResInterceptor)

export const getReceiptHistory = async id => {
  if (!id) return { status: 204 } // fallback
  const { data } = await MinervaStateMachineApiClient.get(`history/${id}`)
  return data
}
