export const availableEnglishLocales = [
  'en-AU',
  'en-CA',
  'en-NZ',
  'en-PH',
  'en-ZA',
  'en-VI',
  'en-GB',
  'en-US'
]

export const availableSpanishLocales = ['es-419', 'es-MX', 'es-US', 'es-ES']

export const availableFrenchLocales = ['fr-FR', 'fr-CA', 'fr-BE']
