import { BoxLabelEnum } from 'data/box'
import {
  BaseMerchantDefinition,
  MerchantDefinition
} from 'pages/app/extraction/version-2/task/definitions/types'

const WoolworthsDefinition: MerchantDefinition = {
  ...BaseMerchantDefinition,
  [BoxLabelEnum.ITEM_CODE]: { disabled: true },
  [BoxLabelEnum.ITEM_VALUE]: { required: true },
  [BoxLabelEnum.RECEIPT_TIP]: { disabled: true },
  [BoxLabelEnum.RECEIPT_STORE_ADDRESS]: { required: true },
  [BoxLabelEnum.RECEIPT_STORE_PHONE]: { required: true },
  [BoxLabelEnum.RECEIPT_STORE_ID]: { required: true },
  [BoxLabelEnum.RECEIPT_TOTAL_DISCOUNT]: { required: true },
  [BoxLabelEnum.RECEIPT_CASH_REGISTER_ID]: { required: true },
  [BoxLabelEnum.RECEIPT_TRANSACTION_ID]: { required: true }
}
export default WoolworthsDefinition
