import { SyncOutlined } from '@ant-design/icons'
import { Button, DatePicker, Input, Select } from 'antd'
import { RangePickerProps } from 'antd/lib/date-picker'
import moment from 'moment'
import { WorkflowActions } from 'pages/app/email-subject-whitelist/reducers/actions'
import React, { useContext, useState } from 'react'
import { useDispatch } from 'react-redux'
import exportExcel from 'services/export/excel'
import { Color } from 'services/styles/color'
import { WorkflowContext } from '../context'

const { RangePicker } = DatePicker

const EmailSubjectWhitelistFooter = ({ externalLoader = false, refetch }) => {
  const dispatch = useDispatch()

  const { state, dispatchTask } = useContext(WorkflowContext)

  const {
    startDate,
    endDate,
    retailerFilter,
    countryFilter,
    idFilter,
    inputMode
  } = state
  const availableCountryCodes = ['US', 'CA']

  const submitChanges = async () => {
    refetch()
  }

  const handleSubmitClick = async e => {
    e.currentTarget.blur()
    // disable confirm
    // dispatch(
    //   OverlayActions.showDialog({
    //     key: DialogKeys.CONFIRM,
    //     component: (
    //       <ConfirmModal
    //         onOk={submitChanges}
    //         title="Have you exported record?"
    //         message="You must export table before moving to next batch"
    //       />
    //     )
    //   })
    // )

    await submitChanges()
  }

  const handleExportClick = () => {
    // export subjectWhitelisted only
    const { record, data, ...inputs } = state
    const { transactions, ...metadata } = record

    const transactionsData = transactions.reduce((acc, curr) => {
      if (curr.subjectWhitelisted) {
        return [
          ...acc,
          {
            transactionId: curr.transactionId,
            retailer: metadata.retailerName,
            subjectLine: curr.subject
          }
        ]
      }

      return acc
    }, [])

    const exportMetadata = [metadata]

    const { inputMode, idFilter, ...retailerModeInputs } = inputs

    const exportInputData =
      inputMode === 'retailer'
        ? [retailerModeInputs]
        : [{ idFilter, countryFilter: inputs.countryFilter }]
    exportExcel({
      data: [transactionsData, exportMetadata, exportInputData],
      fileName: record.retailerName
    })
  }

  const [retailerText, setRetailerText] = useState<string>(retailerFilter)
  const [receiptSubjectText, setReceiptSubjectText] = useState<string>(
    retailerFilter
  )
  const [idsText, setIdsText] = useState<string>(idFilter)

  const handleRetailerInputChange = e => {
    setRetailerText(e.target.value)
  }
  const handleReceiptSubjectInputChange = e => {
    setReceiptSubjectText(e.target.value)
  }
  const handleIdsInputChange = e => {
    setIdsText(e.target.value)
  }
  const handleRetailerInputEnter = e => {
    e.currentTarget.blur()
    dispatchTask({
      type: WorkflowActions.SET_RETAILER_FILTER,
      payload: retailerText
    })
  }
  const handleIdsInputEnter = e => {
    e.currentTarget.blur()
    dispatchTask({
      type: WorkflowActions.SET_ID_FILTER,
      payload: idsText
    })
  }
  const handleCountryInputChange = value => {
    dispatchTask({
      type: WorkflowActions.SET_COUNTRY_FILTER,
      payload: value
    })
  }
  const handleReceiptSubjectInputEnter = e => {
    e.currentTarget.blur()
    dispatchTask({
      type: WorkflowActions.SET_RECEIPT_SUBJECT_FILTER,
      payload: receiptSubjectText
    })
  }
  const handleToggleInputClick = () => {
    dispatchTask({
      type: WorkflowActions.TOGGLE_INPUT_MODE
    })
  }

  const handleOnRangePickerChange: RangePickerProps['onChange'] = (
    dates,
    dateStrings
  ) => {
    if (dates) {
      const newStartDate = dateStrings[0]
      const newEndDate = dateStrings[1]
      if (newStartDate !== startDate) {
        dispatchTask({
          type: WorkflowActions.SET_START_DATE,
          payload: newStartDate
        })
      }
      if (newEndDate !== endDate) {
        dispatchTask({
          type: WorkflowActions.SET_END_DATE,
          payload: newEndDate
        })
      }
    } else {
      // clear
      dispatchTask({
        type: WorkflowActions.SET_START_DATE,
        payload: null
      })
      dispatchTask({
        type: WorkflowActions.SET_END_DATE,
        payload: null
      })
    }
  }

  const renderCountrySelector = () => (
    <Select
      placeholder={'Choose a country'}
      value={countryFilter}
      style={{ width: 200 }}
      getPopupContainer={triggerNode => triggerNode.parentNode}
      onChange={handleCountryInputChange}
    >
      {availableCountryCodes.map((option, index) => (
        <Select.Option key={`${index}_${option}`} value={option}>
          {option}
        </Select.Option>
      ))}
    </Select>
  )

  const renderInputs = () => {
    if (inputMode === 'retailer') {
      return (
        <div>
          <RangePicker
            format={'YYYY-MM-DD'}
            ranges={{
              Today: [moment(), moment()],
              'This Month': [
                moment().startOf('month'),
                moment().endOf('month')
              ],
              'Last 7 days': [moment().subtract(7, 'days'), moment()]
            }}
            onChange={handleOnRangePickerChange}
          />

          <Input
            placeholder={'Search retailer'}
            value={retailerText}
            onChange={handleRetailerInputChange}
            onPressEnter={handleRetailerInputEnter}
            onBlur={handleRetailerInputEnter}
            style={{ width: 150 }}
          />

          <Input
            placeholder={'Search receipt subject'}
            value={receiptSubjectText}
            onChange={handleReceiptSubjectInputChange}
            onPressEnter={handleReceiptSubjectInputEnter}
            onBlur={handleReceiptSubjectInputEnter}
            style={{ width: 150 }}
          />

          {renderCountrySelector()}
        </div>
      )
    }

    return (
      <div>
        <Input
          placeholder={'ids separate by ","'}
          value={idsText}
          onChange={handleIdsInputChange}
          onPressEnter={handleIdsInputEnter}
          onBlur={handleIdsInputEnter}
          style={{ width: 300 }}
        />
        {renderCountrySelector()}
      </div>
    )
  }

  return (
    <>
      <div className="flex items-center w-full mr-2">
        {renderInputs()}
        <Button
          className="flex items-center justify-center ml-2"
          type="primary"
          shape="circle"
          icon={<SyncOutlined />}
          onClick={handleToggleInputClick}
          size={'small'}
        />
      </div>
      <div className="flex flex-grow items-center">
        <Button
          onClick={handleExportClick}
          size="large"
          className="text-white hover:text-white w-28 rounded bg-blue-400 hover:bg-blue-600"
        >
          Export
        </Button>
        <Button
          disabled={inputMode === 'id'}
          loading={externalLoader}
          onClick={handleSubmitClick}
          size="large"
          className="text-white hover:text-white w-28 rounded"
          style={{ backgroundColor: Color.SUCCESS }}
        >
          Refresh
        </Button>
      </div>
    </>
  )
}

export default EmailSubjectWhitelistFooter
