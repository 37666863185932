import { DownCircleOutlined, UpCircleOutlined } from '@ant-design/icons'
import React from 'react'

interface Props {
  value: boolean
  onValueChange: (value) => void
  wrapperClassName?: string
}

const DropdownToggle = ({ value, onValueChange, wrapperClassName }: Props) => {
  const toggle = () => onValueChange(!value)

  return (
    <div
      onClick={toggle}
      className={
        'absolute bottom-12 ml-2 right-3.5 cursor-pointer px-2 pt-1' +
        ' pb-2 self-center' +
        ' rounded-full ' +
        wrapperClassName
      }
      style={{ backgroundColor: 'rgba(0,0,0,0.3)' }}
    >
      {value ? (
        <DownCircleOutlined
          style={{ fontSize: 24 }}
          className="text-blue-400"
        />
      ) : (
        <UpCircleOutlined style={{ fontSize: 24, color: 'white' }} />
      )}
    </div>
  )
}

export default DropdownToggle
