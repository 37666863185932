import React, { CSSProperties, ReactNode } from 'react'
import { Space } from 'services/styles/spacing'

interface cellProps {
  onClick?: () => void
  data?: any
  field?: string
  label?: string
  render?: React.ReactNode
  visible?: boolean
  cellStyle?: CSSProperties
}

interface rowProps {
  onClick?: () => void
  rowStyle?: CSSProperties
  children?: ReactNode
}

interface headerProps {
  titles: (string | ReactNode)[]
  headerStyle?: CSSProperties[]
}

const Table = props => (
  <table
    className="w-full"
    {...props}
    style={{
      borderCollapse: 'separate',
      borderSpacing: `0 ${Space.TINY}px`,
      ...props.style
    }}
  >
    {props.children}
  </table>
)

const Row = (props: rowProps) => {
  return (
    <tr
      className="rounded-lg"
      onClick={props.onClick}
      style={{
        backgroundColor: 'white',
        height: Space.LARGE,
        ...props.rowStyle
      }}
    >
      {props.children}
    </tr>
  )
}

const Header = ({ titles, headerStyle }: headerProps) => {
  return (
    <thead>
      <tr>
        {titles.map((title, index) => (
          <th
            className="text-base font-semibold text-gray-700 text-left first:pl-4"
            key={index}
            style={headerStyle?.[index]}
          >
            {title}
          </th>
        ))}
      </tr>
    </thead>
  )
}

const Cell = ({
  data,
  field,
  label,
  cellStyle,
  render,
  onClick,
  visible = true
}: cellProps) => {
  const visibility = visible ? 'visible' : 'hidden'
  return (
    <td
      className="text-left first:rounded-l-lg first:pl-4 last:rounded-r-lg"
      style={{ verticalAlign: 'middle', ...cellStyle }}
      onClick={onClick}
    >
      {render ? (
        visible ? (
          render
        ) : (
          <span />
        )
      ) : (
        <div style={{ visibility }}>{label || data?.[field]}</div>
      )}
    </td>
  )
}

Table.Header = Header
Table.Row = Row
Table.Cell = Cell

export default Table
