import { BoxLabelEnum } from 'data/box'
import { SubRequirements } from 'pages/app/extraction/version-2/reducers'
import Field from 'pages/app/extraction/version-2/task/content/components/field'
import Section from 'pages/app/extraction/version-2/task/content/components/sections'
import LineItemsHeader from 'pages/app/extraction/version-2/task/content/line-section/header'
import { columnsStyle } from 'pages/app/extraction/version-2/task/content/line-section/styles'
import { MerchantDefinition } from 'pages/app/extraction/version-2/task/definitions/types'
import {
  LineItemHeader,
  SectionEnum
} from 'pages/app/extraction/version-2/types'
import React from 'react'

interface LineItemsSectionProps {
  missingLineItems: any[]
  mergeLineItems: any[]
  subRequirements: SubRequirements
  isUnitPriceMode: boolean
  currentPage: number
  totalPages: number
  targetSelection: string | null
  handleChangeSourceSelection: (id) => void
  handleChangeTargetSelection: (id) => void
  handleChangeSubRequirements: ({ key: value }) => void
  handleModeChange: (newValue) => void
  merchantDefinition: MerchantDefinition
}

const LineItemsSection = (props: LineItemsSectionProps) => {
  const {
    missingLineItems,
    mergeLineItems,
    subRequirements,
    isUnitPriceMode,
    currentPage,
    totalPages,
    targetSelection,
    handleChangeSourceSelection,
    handleChangeTargetSelection,
    handleChangeSubRequirements,
    handleModeChange,
    merchantDefinition
  } = props
  const checkPromoLabel = (label: BoxLabelEnum) =>
    [BoxLabelEnum.ITEM_PROMO_NAME, BoxLabelEnum.ITEM_PROMO_PRICE].includes(
      label
    )

  const handleSetTargetId = (id: string) =>
    handleChangeTargetSelection(targetSelection !== id ? id : null)

  const pageText: string = `${currentPage + 1}/${totalPages}`

  // fields that are allowed to be null
  const renderLineItems = () =>
    mergeLineItems.map((entry, index) => {
      const column = [...LineItemHeader[entry.type]]
      const rawRow = column.map(header => ({
        ...entry?.[header],
        label: header
      }))
      const isFocused = entry.key.includes(targetSelection)
      const generateCells = () =>
        [
          ...LineItemHeader[SectionEnum.LINE_ITEM],
          ...LineItemHeader[SectionEnum.PROMOTION]
        ].map((header, index) => {
          const content = rawRow.find(content => content.label === header)
          return (
            content && (
              <Field
                key={`${header}_${content?.id}_${index}`}
                id={entry.key?.[0].toString()}
                wrapperClassName={columnsStyle[header]}
                value={content?.text || '\u00A0'}
                isError={merchantDefinition[header]?.required && !content?.text}
                isDisabled={merchantDefinition[header]?.disabled}
                isPromo={checkPromoLabel(content?.label)}
                isPromoNameLabel={
                  content?.label === BoxLabelEnum.ITEM_PROMO_NAME
                }
                onClick={
                  entry.key
                    ? () => handleSetTargetId(entry.key[0])
                    : handleChangeSourceSelection
                }
              />
            )
          )
        })

      return (
        <div
          key={String(entry.key?.[0])}
          className={`flex gap-2 py-1 px-4 -my-1 -mx-4 ${
            isFocused ? 'bg-blue-500' : 'bg-transparent'
          } flex items-center`}
        >
          <div className="text-xs" style={{ width: '1.25rem' }}>
            {index + 1}
          </div>
          {generateCells()}
        </div>
      )
    })

  return (
    <Section
      title="Line Items"
      isComplete={!missingLineItems.length}
      page={pageText}
    >
      <div className="flex flex-col gap-2">
        <LineItemsHeader
          subRequirement={subRequirements}
          handleSubRequirementsChange={handleChangeSubRequirements}
          isUnitPrice={isUnitPriceMode}
          handleModeChange={handleModeChange}
        />
        {renderLineItems()}
      </div>
    </Section>
  )
}

export default LineItemsSection
