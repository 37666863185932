import handleActionsImmer from 'services/integrations/immer'
import BoxConstants from './constants'
import { BoxProps, BoxState } from './types'

const initialState = {
  boxes: []
}

const box = handleActionsImmer<BoxState>(
  {
    [BoxConstants.ADD_BOX]: (state: BoxState, box: BoxProps) => {
      state.boxes.push(box)
    },
    [BoxConstants.EDIT_LABEL]: (state: BoxState, { id, label }) => {
      state.boxes = state.boxes.map(box =>
        box.id === id ? { ...box, label } : box
      )
    },
    [BoxConstants.EDIT_TEXT]: (state: BoxState, { id, text }) => {
      state.boxes = state.boxes.map(box =>
        box.id === id ? { ...box, text } : box
      )
    },
    [BoxConstants.ADD_LINK]: (state: BoxState, { id, targetId }) => {
      state.boxes = state.boxes.map(box =>
        box.id === id
          ? { ...box, links: [...box.links, targetId] }
          : box.id === targetId
          ? { ...box, links: [...box.links, id] }
          : box
      )
    },
    [BoxConstants.REMOVE_LINK]: (state: BoxState, { id, targetId }) => {
      state.boxes = state.boxes.map(box =>
        box.id === id
          ? { ...box, links: box.links.filter(item => item !== targetId) }
          : box.id === targetId
          ? { ...box, links: box.links.filter(item => item !== id) }
          : box
      )
    },
    [BoxConstants.REMOVE_BOX]: (state: BoxState, id: string | number) => {
      state.boxes = state.boxes.reduce(
        (acc, box) =>
          box.id !== id
            ? [
                ...acc,
                {
                  ...box,
                  links: box.links.filter(item => item !== id)
                }
              ]
            : acc,
        []
      )

      // reset active id
      state.activeId = undefined
      if (id === state.targetId) {
        state.targetId = undefined
      }
    },
    [BoxConstants.SET_ACTIVE_ID]: (state: BoxState, id: string | number) => {
      state.activeId = id
    },
    [BoxConstants.SET_TARGET_ID]: (state: BoxState, id: string | number) => {
      state.targetId = id
    },
    [BoxConstants.RESET_BOX_STATE]: (state: BoxState) => {
      state.boxes = []
      state.activeId = null
      state.targetId = null
    }
  },
  initialState
)

export default box
