import { Image, Spin } from 'antd'
import Logo from 'assets/misc/logo-with-text.png'
import React from 'react'
import ReactCSSTransitionGroup from 'react-addons-css-transition-group'

const AnimatedVisibility = ({ children }) => (
  <ReactCSSTransitionGroup transitionName="fade">
    {children}
  </ReactCSSTransitionGroup>
)

const SplashScreen = () => (
  <div className="w-screen h-screen">
    <AnimatedVisibility>
      <div
        className={
          'fixed inset-0 bg-opacity-50 bg-gray-400 z-50 flex justify-center items-center'
        }
      >
        <div>
          <Image
            preview={false}
            src={Logo}
            className={'mt-6'}
            style={{ height: 80 }}
            alt="logo"
          />
          <div className={'flex flex-row justify-center items-center'}>
            <Spin size="large" />
            <p className={'ml-4'}>Loading...</p>
          </div>
        </div>
      </div>
    </AnimatedVisibility>
  </div>
)

export default SplashScreen
