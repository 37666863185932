import { ApplicationReducerState } from 'data/reducers'
import { createSelector } from 'reselect'

export const auditState = (state: ApplicationReducerState) => state.audit

export const currentIndex = createSelector(
  auditState,
  audit => audit.currentIndex
)

export const receiptIdsList = createSelector(
  auditState,
  audit => audit.receiptIdsList
)

export const labelsIdsList = createSelector(
  auditState,
  audit => audit.labelsIdsList
)

export const receiptData = createSelector(auditState, audit => audit.data)

export const receiptFilter = createSelector(
  auditState,
  audit => audit.receiptFilter
)
