import { Card } from 'antd'
import React from 'react'
import { toStartCase } from 'services/helpers/canonicalization'
import { DefinitionProps } from './types'

const { Meta } = Card

const CardGrid = ({ children }) => (
  <Card.Grid
    hoverable={false}
    style={{ width: '25%', height: 120, lineBreak: 'anywhere' }}
  >
    {children}
  </Card.Grid>
)

const fields = [
  { title: 'Task ID', desc: 'taskId' },
  { title: 'Dedupe Key', desc: 'dedupeKey' },
  { title: 'Stage', desc: 'receiptStage' },
  { title: 'Status', desc: 'executionStatus' },
  { title: 'Created', desc: 'createdAt' },
  { title: 'Updated', desc: 'updatedAt' },
  { title: 'Rejected', desc: 'receiptRejected' },
  { title: 'Rejected Reason', desc: 'receiptRejectionReason' }
]

const Definition = ({ data }: DefinitionProps) => (
  <section className="mb-4">
    <Card title={`Receipt ID: ${data?.receiptId}`} bodyStyle={{ padding: 0 }}>
      {fields.map(({ title, desc }) => (
        <CardGrid key={title}>
          <Meta
            title={title}
            description={
              title === 'Created' || title === 'Updated'
                ? data?.[desc]
                : title === 'Stage' ||
                  title === 'Status' ||
                  title === 'Rejected' ||
                  title === 'Rejected Reason'
                ? toStartCase(data?.[desc]?.toString())
                : data?.[desc]?.toString() || ''
            }
          />
        </CardGrid>
      ))}
    </Card>
  </section>
)

export default Definition
