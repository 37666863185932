import { SelectionActions } from 'pages/app/annotation-tool/reducers/selection/actions'
import FieldCell from 'pages/app/annotation-tool/task/content/components/field-cell'
import Section from 'pages/app/annotation-tool/task/content/components/section'
import ItemsSectionHeader from 'pages/app/annotation-tool/task/content/line-item-section/header'
import { columnsStyle } from 'pages/app/annotation-tool/task/content/line-item-section/styles'
import { TaskContext } from 'pages/app/annotation-tool/task/context'
import {
  getIncompleteLineItems,
  getSinglePageLineItem
} from 'pages/app/annotation-tool/task/helpers'
import {
  AnnotationLabelEnum,
  LineItemHeader,
  SectionEnum
} from 'pages/app/annotation-tool/task/types'
import React, { useContext, useEffect } from 'react'
import { scrollIntoView } from 'services/helpers/dom'

const LineItemSection = () => {
  const { state: parentState, dispatchTask: parentDispatcher } = useContext(
    TaskContext
  )
  const {
    record,
    currentPage,
    selection,
    merchantDefinition,
    mode
  } = parentState
  const { autoFocus, linkMode } = mode

  const currentPageData = record.pages[currentPage]
  const totalPages = record?.pages?.length || 0

  const handleDispatchTask = (action, value) =>
    parentDispatcher({ type: action, payload: value })

  const mergeLineItems = getSinglePageLineItem(currentPageData.fields)

  const missingLineItems = getIncompleteLineItems(
    [currentPageData],
    merchantDefinition
  ).flat()

  const handleSetTargetId = (id: string) => {
    const _handleSetTargetId = (id: string) =>
      handleDispatchTask(SelectionActions.SET_TARGET_ID, id)

    _handleSetTargetId(selection.target !== id ? id : null)
  }

  const handleSetSourceId = (id: string) =>
    handleDispatchTask(SelectionActions.SET_SOURCE_ID, id)

  // auto select missing item
  useEffect(() => {
    // set first line in missing line as active id
    if (autoFocus) {
      const newTargetId = missingLineItems?.[0]?.key[0]
      if (selection.target !== newTargetId && !linkMode) {
        handleSetTargetId(newTargetId)
      }
    } else if (!linkMode) {
      if (selection.target) handleSetTargetId(null)
    }
  }, [missingLineItems.length, autoFocus])

  useEffect(() => {
    if (selection.target) {
      scrollIntoView(selection.target)
    }
  }, [selection.target])

  // renders
  const renderLineItems = () =>
    mergeLineItems.map((entry, index) => {
      const column = LineItemHeader[entry.type].filter(i =>
        merchantDefinition?.find(j => j?.label === i)
      )
      const rawRow = column.map(header => ({
        ...entry?.[header],
        label: header
      }))
      const isFocused = entry.key.includes(selection.target)
      const generateCells = () =>
        [
          ...LineItemHeader[SectionEnum.LINE_ITEM],
          ...LineItemHeader[SectionEnum.PROMOTION],
          ...LineItemHeader[SectionEnum.ITEM_OTHER],
          ...LineItemHeader[SectionEnum.SUB_ITEM],
          ...LineItemHeader[SectionEnum.ITEM_CATEGORY],
          ...LineItemHeader[SectionEnum.ITEM_SUBTOTAL],
          ...LineItemHeader[SectionEnum.ITEM_TAX],
          ...LineItemHeader[SectionEnum.LOYALTY_EARNED],
          ...LineItemHeader[SectionEnum.LOYALTY_REDEEMED],
          ...LineItemHeader[SectionEnum.LOYALTY_BALANCE]
        ].map((header, index) => {
          const content = rawRow.find(content => content.label === header)
          const fieldRequired = merchantDefinition?.find(
            i => i.label === header
          )

          return (
            content && (
              <FieldCell
                key={`${header}_${content?.id}_${index}`}
                id={entry.key?.[0].toString()}
                wrapperClassName={columnsStyle?.[header]}
                value={content?.text || '\u00A0'}
                isError={fieldRequired?.required && !content?.text}
                isDisabled={!fieldRequired}
                isPromoName={
                  content?.label === AnnotationLabelEnum.ITEM_PROMO_NAME
                }
                onClick={
                  entry.key
                    ? () => handleSetTargetId(entry.key[0])
                    : handleSetSourceId
                }
              />
            )
          )
        })
      return (
        <div
          key={String(entry.key?.[0])}
          className={`flex gap-2 py-1 px-4 -my-1 -mx-4 ${
            isFocused ? 'bg-blue-500' : 'bg-transparent'
          } flex items-center`}
        >
          <div className="text-xs" style={{ width: '1.25rem' }}>
            {index + 1}
          </div>
          {generateCells()}
        </div>
      )
    })

  const pageText: string = `${currentPage + 1}/${totalPages}`

  return (
    <Section
      title="Line Items"
      isComplete={!missingLineItems.length}
      page={pageText}
    >
      <div className="flex flex-col gap-2">
        <ItemsSectionHeader definition={merchantDefinition} />
        {renderLineItems()}
      </div>
    </Section>
  )
}

export default LineItemSection
