import React from 'react'
import { Word } from 'services/api/requests/types'

interface Props {
  // isLast: boolean
  // handleLabelText: (key: BoxLabelEnum) => string
  word: Word
}

const WordBox = ({
  // isLast,
  // handleLabelText,
  word
}: Props) => {
  const [
    { x: x0, y: y0 },
    { x: x1, y: y1 },
    { x: x2, y: y2 },
    { x: x3, y: y3 }
  ] = word.boundingPoly.vertices

  return (
    <path
      className="text-box opacity-10 hover:opacity-30"
      d={`M${x0},${y0}
       L${x1},${y1}
       L${x2},${y2} 
       L${x3},${y3}z`}
      fill="rgba(0,0,0)"
      style={{ cursor: 'crosshair' }}
    />
  )
}

export default WordBox
