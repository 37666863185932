import { BoxLabelEnum } from 'data/box'
import { AnnotationLabelEnum } from 'pages/app/annotation-tool/task/types'

export const Color = {
  SUCCESS: '#1AB447',
  GREY_300: '#d1d5db',
  GREY_400: '#94a3b8',
  GREY_600: 'rgb(75, 85, 99)',

  [BoxLabelEnum.ITEM_DESCRIPTION]: '#B4FEB6',
  [BoxLabelEnum.ITEM_VALUE]: '#B4FEB6',
  [BoxLabelEnum.ITEM_UNIT_PRICE]: '#B4FEB6',
  [BoxLabelEnum.ITEM_QUANTITY]: '#B4FEB6',
  [BoxLabelEnum.ITEM_CODE]: '#B4FEB6',
  [BoxLabelEnum.ITEM_PROMO_NAME]: '#22823a',
  [BoxLabelEnum.ITEM_PROMO_PRICE]: '#22823a',
  [BoxLabelEnum.RECEIPT_SUBTOTAL_VALUE]: '#facc15',
  [BoxLabelEnum.RECEIPT_TOTAL_VALUE]: '#facc15',
  [BoxLabelEnum.RECEIPT_TOTAL_TAX]: '#facc15',
  [BoxLabelEnum.RECEIPT_TIP]: '#facc15',
  [BoxLabelEnum.RECEIPT_MERCHANT_NAME]: '#e879f9',
  [BoxLabelEnum.RECEIPT_STORE_ADDRESS]: '#e879f9',
  [BoxLabelEnum.RECEIPT_STORE_PHONE]: '#e879f9',
  [BoxLabelEnum.RECEIPT_PURCHASE_DATE]: '#60a5fa',
  [BoxLabelEnum.RECEIPT_PURCHASE_TIME]: '#fb923c',
  [BoxLabelEnum.OTHER]: '#fcae6e', // fallback Color
  [BoxLabelEnum.ITEM_UNIT_TYPE]: '#B4FEB6',
  [BoxLabelEnum.RECEIPT_STORE_ID]: '#e879f9',
  [BoxLabelEnum.RECEIPT_STORE_NAME]: '#e879f9',
  [BoxLabelEnum.RECEIPT_TOTAL_DISCOUNT]: '#22823a',
  [BoxLabelEnum.RECEIPT_CASH_REGISTER_ID]: '#52b2bf',
  [BoxLabelEnum.RECEIPT_TRANSACTION_ID]: '#52b2bf',

  //   annotation colors
  [AnnotationLabelEnum.ITEM_DESCRIPTION]: '#B4FEB6',
  [AnnotationLabelEnum.ITEM_PRICE]: '#B4FEB6',
  [AnnotationLabelEnum.ITEM_UNIT_PRICE]: '#B4FEB6',
  [AnnotationLabelEnum.ITEM_QUANTITY]: '#B4FEB6',
  [AnnotationLabelEnum.ITEM_CODE]: '#B4FEB6',
  [AnnotationLabelEnum.ITEM_CODE_2]: '#B4FEB6',
  [AnnotationLabelEnum.ITEM_UNIT_TYPE]: '#B4FEB6',
  [AnnotationLabelEnum.ITEM_SELLER]: '#B4FEB6',
  [AnnotationLabelEnum.ITEM_ORDER_ID]: '#B4FEB6',
  [AnnotationLabelEnum.ITEM_DELIVERY_DATE]: '#B4FEB6',
  [AnnotationLabelEnum.ITEM_DESCRIPTION_EXTRA]: '#B4FEB6',
  [AnnotationLabelEnum.ITEM_ADDRESS]: '#B4FEB6',
  [AnnotationLabelEnum.ITEM_REG_PRICE]: '#B4FEB6',
  [AnnotationLabelEnum.ITEM_LINE_TAX]: '#B4FEB6',

  [AnnotationLabelEnum.SUB_ITEM_DESCRIPTION]: '#B4FEB6',
  [AnnotationLabelEnum.SUB_ITEM_PRICE]: '#B4FEB6',
  [AnnotationLabelEnum.ITEM_PROMO_NAME]: '#22823a',
  [AnnotationLabelEnum.ITEM_PROMO_UNIT_PRICE]: '#22823a',
  [AnnotationLabelEnum.ITEM_PROMO_QUANTITY]: '#22823a',
  [AnnotationLabelEnum.ITEM_PROMO_PRICE]: '#22823a',
  [AnnotationLabelEnum.ITEM_PROMO_CODE]: '#22823a',
  [AnnotationLabelEnum.ITEM_OTHER_DESCRIPTION]: '#B4FEB6',
  [AnnotationLabelEnum.ITEM_OTHER_PRICE]: '#B4FEB6',
  [AnnotationLabelEnum.ITEM_CATEGORY]: '#B4FEB6',
  [AnnotationLabelEnum.ITEM_SUBTOTAL]: '#B4FEB6',
  [AnnotationLabelEnum.ITEM_TAX_DESCRIPTION]: '#B4FEB6',
  [AnnotationLabelEnum.ITEM_TAX_PRICE]: '#B4FEB6',
  [AnnotationLabelEnum.RECEIPT_SUBTOTAL_VALUE]: '#facc15',
  [AnnotationLabelEnum.RECEIPT_TOTAL_VALUE]: '#facc15',
  [AnnotationLabelEnum.RECEIPT_TOTAL_TAX]: '#facc15',
  [AnnotationLabelEnum.RECEIPT_SUMMED_TOTAL_TAX]: '#facc15',
  [AnnotationLabelEnum.RECEIPT_TIP]: '#facc15',
  [AnnotationLabelEnum.RECEIPT_MERCHANT_LOGO_NAME]: '#e879f9',
  [AnnotationLabelEnum.RECEIPT_STORE_ADDRESS]: '#e879f9',
  [AnnotationLabelEnum.RECEIPT_STORE_PHONE]: '#e879f9',
  [AnnotationLabelEnum.RECEIPT_PURCHASE_DATE]: '#60a5fa',
  [AnnotationLabelEnum.RECEIPT_PURCHASE_TIME]: '#fb923c',
  [AnnotationLabelEnum.OTHER]: '#fcae6e', // fallback Color
  [AnnotationLabelEnum.ITEM_UNIT_TYPE]: '#B4FEB6',
  [AnnotationLabelEnum.RECEIPT_STORE_ID]: '#e879f9',
  [AnnotationLabelEnum.RECEIPT_STORE_NAME]: '#e879f9',
  [AnnotationLabelEnum.RECEIPT_TOTAL_DISCOUNT]: '#22823a',
  [AnnotationLabelEnum.RECEIPT_CASH_REGISTER_ID]: '#52b2bf',
  [AnnotationLabelEnum.RECEIPT_TRANSACTION_ID]: '#52b2bf',

  [AnnotationLabelEnum.RECEIPT_BOTTOM_TRANSACTION_ID]: '#52b2bf',
  [AnnotationLabelEnum.EFT_PURCHASE_TIME]: '#52b2bf',
  [AnnotationLabelEnum.EFT_PURCHASE_DATE]: '#52b2bf',
  [AnnotationLabelEnum.EFT_TRANSACTION_ID]: '#52b2bf',
  [AnnotationLabelEnum.RECEIPT_BOTTOM_REGISTER_ID]: '#52b2bf',
  [AnnotationLabelEnum.EFT_REGISTER_ID]: '#52b2bf',
  [AnnotationLabelEnum.EFT_MERCHANT_NAME]: '#e879f9',
  [AnnotationLabelEnum.EFT_RRN]: '#52b2bf',
  [AnnotationLabelEnum.EFT_STORE_ID]: '#52b2bf',
  [AnnotationLabelEnum.RECEIPT_BOTTOM_STORE_ID]: '#52b2bf',
  [AnnotationLabelEnum.RECEIPT_BOTTOM_BARCODE]: '#52b2bf',
  [AnnotationLabelEnum.RECEIPT_MEMBERSHIP_DISCOUNT]: '#52b2bf',
  [AnnotationLabelEnum.ITEM_MEMBERSHIP_TYPE]: '#B4FEB6',
  [AnnotationLabelEnum.EFT_STORE_NAME]: '#e879f9',
  [AnnotationLabelEnum.LOYALTY_EARNED_DESCRIPTION]: '#FA8015',
  [AnnotationLabelEnum.LOYALTY_EARNED_POINTS]: '#FA8015',
  [AnnotationLabelEnum.LOYALTY_REDEEMED_DESCRIPTION]: '#FA8015',
  [AnnotationLabelEnum.LOYALTY_REDEEMED_POINTS]: '#FA8015',
  [AnnotationLabelEnum.LOYALTY_BALANCE_DESCRIPTION]: '#FA8015',
  [AnnotationLabelEnum.LOYALTY_BALANCE_POINTS]: '#FA8015'
}

export const LightenDarkenColor = (col, amt) => {
  let usePound = false

  if (col[0] === '#') {
    col = col.slice(1)
    usePound = true
  }

  const num = parseInt(col, 16)

  let r = (num >> 16) + amt

  if (r > 255) r = 255
  else if (r < 0) r = 0

  let b = ((num >> 8) & 0x00ff) + amt

  if (b > 255) b = 255
  else if (b < 0) b = 0

  let g = (num & 0x0000ff) + amt

  if (g > 255) g = 255
  else if (g < 0) g = 0

  return (usePound ? '#' : '') + (g | (b << 8) | (r << 16)).toString(16)
}

export const getBoxColor = (label?: string) =>
  Color?.[label] || Color[BoxLabelEnum.OTHER]

export const getBoxColorLight = (label: string) =>
  LightenDarkenColor(getBoxColor(label), 50)

export const getBoxColorDarker = (label: string) =>
  LightenDarkenColor(getBoxColor(label), -80)

export const getColorByBgColor = (
  bgColor,
  onLight = '#000',
  onDark = '#fff'
) => {
  if (!bgColor) {
    return ''
  }
  return parseInt(bgColor.replace('#', ''), 16) > 0xffffff / 2
    ? onLight
    : onDark
}
