const releaseChannel = process.env.REACT_APP_ENV

const getConfig = (channel?: string): Config => {
  switch (channel) {
    case 'production':
      return {
        environment: 'production',
        baseUrl: 'https://asia.api.data-123.com/',
        sentryDsn:
          'https://2ca7a227ca6b42d38ca0995739cd91b9@o78584.ingest.sentry.io/6158149'
      }
    default:
      return {
        environment: channel,
        baseUrl: 'https://asia.api.data-123.com/'
      }
  }
}

export interface Config {
  baseUrl: string
  environment?: string
  sentryDsn?: string
}

const config = getConfig(releaseChannel)

export default config
