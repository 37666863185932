import { Layout } from 'antd'
import { Content, Footer } from 'antd/es/layout/layout'
import ResizableSider from 'components/resizable-sider'
import StandardBreadcrumb from 'components/standard-breadcrumb'
import { AuthSelectors } from 'data/selectors'
import { WorkflowActions } from 'pages/app/email-subject-whitelist/reducers/actions'
import WorkflowSider from 'pages/app/email-subject-whitelist/sider'
import React, { useReducer, useState } from 'react'
import { useQuery } from 'react-query'
import { useSelector } from 'react-redux'
import { QueryKeys } from 'services/api/query-key'
import { EmailSubjectWhitelistApi } from 'services/api/requests/apolli-visualizer'
import { FOOTER_HEIGHT, HEADER_HEIGHT } from 'services/styles/layout'
import TransactionsTable from './components/transactions-table'
import { WorkflowContext } from './context'
import EmailSubjectWhitelistFooter from './footer'
import { initialData, initialState, reducer } from './reducers'

const EmailSubjectWhitelistPage = () => {
  const [state, dispatchTask] = useReducer(reducer, initialState)
  const auth = useSelector(AuthSelectors.firebaseAuthState)

  const {
    startDate,
    endDate,
    retailerFilter,
    countryFilter,
    receiptSubjectFilter,
    idFilter,
    inputMode,
    record
  } = state

  const [searcher, setSearcher] = useState<{ [key: string]: string | null }>(
    null
  )

  const [selectedRecord, setSelectedRecord] = useState({
    transactionId: null,
    htmlLink: null
  })

  const handleOnDataLoaded = async data1 => {
    if (!data1?.transactions?.length) {
      dispatchTask({
        type: WorkflowActions.SET_DATA,
        payload: initialData
      })

      dispatchTask({
        type: WorkflowActions.SET_RECORD,
        payload: initialData
      })

      return
    }

    if (data1) {
      dispatchTask({
        type: WorkflowActions.SET_DATA,
        payload: data1
      })

      dispatchTask({
        type: WorkflowActions.SET_RECORD,
        payload: data1
      })
    }
  }

  const { isLoading, isFetching, refetch } = useQuery(
    [
      startDate,
      endDate,
      retailerFilter,
      countryFilter,
      receiptSubjectFilter,
      auth.uid,
      QueryKeys.EmailSubjectWhitelistReceipts
    ],
    () =>
      EmailSubjectWhitelistApi.getTransactionsByRetailer({
        startDate,
        endDate,
        retailerName: retailerFilter,
        countryCode: countryFilter,
        subjectRegex: receiptSubjectFilter,
        userId: auth.uid
      }),
    {
      onSuccess: handleOnDataLoaded,
      enabled:
        inputMode === 'retailer' &&
        !!startDate &&
        !!endDate &&
        !!retailerFilter &&
        !!countryFilter &&
        !!auth.uid
    }
  )

  const { isLoading: isLoadingIds } = useQuery(
    [
      idFilter,
      countryFilter,
      auth.uid,
      QueryKeys.EmailSubjectWhitelistReceiptsById
    ],
    () =>
      EmailSubjectWhitelistApi.getTransactionsById({
        transactionIds: idFilter,
        countryCode: countryFilter,
        userId: auth.uid
      }),
    {
      onSuccess: handleOnDataLoaded,
      enabled:
        inputMode === 'id' &&
        !!idFilter?.length &&
        !!countryFilter &&
        !!auth.uid
    }
  )

  // Multiple columns filter: object values must match searcher
  const filteredData = {
    ...record,
    transactions: record.transactions.filter(i => {
      if (!searcher) {
        return true
      }

      const keys = Object.keys(searcher)

      return keys.every(key => {
        if (!searcher[key]) {
          return true
        }
        return String(i[key])?.includes(searcher[key])
      })
    })
  }

  return (
    <WorkflowContext.Provider value={{ state, dispatchTask }}>
      <Layout>
        <Layout
          style={{
            height: `calc(100vh - ${HEADER_HEIGHT}px - ${FOOTER_HEIGHT}px)`
          }}
        >
          <Content>
            <div className="py-2 px-4">
              <StandardBreadcrumb />
            </div>
            <div className="w-full">
              <TransactionsTable
                suspendActions={false}
                onViewClick={setSelectedRecord}
                transactions={filteredData.transactions}
                isLoading={isLoading || isFetching || isLoadingIds}
                setSearcher={setSearcher}
              />
            </div>
          </Content>
          <ResizableSider className="z-20 bg-gray-100">
            <WorkflowSider selectedRecord={selectedRecord} />
          </ResizableSider>
        </Layout>
        <Footer
          className="flex items-center justify-between z-40 py-0 px-4"
          style={{ height: FOOTER_HEIGHT }}
        >
          <EmailSubjectWhitelistFooter
            externalLoader={isLoading || isFetching || isLoadingIds}
            refetch={refetch}
          />
        </Footer>
      </Layout>
    </WorkflowContext.Provider>
  )
}

export default EmailSubjectWhitelistPage
