import { Button, Modal } from 'antd'
import { DialogKeys } from 'components/modals/types'
import { OverlayActions } from 'data/actions'
import React from 'react'
import { useDispatch } from 'react-redux'

interface Props {
  onOk: () => Promise<void>
  onCancel?: () => Promise<void>
  title?: string
  message?: string
}

const ConfirmModal = ({ onOk, onCancel, title, message }: Props) => {
  const dispatch = useDispatch()

  const handleCloseModal = () =>
    dispatch(OverlayActions.hideDialog({ key: DialogKeys.CONFIRM }))

  const handleOkClick = async () => {
    await onOk()
    handleCloseModal()
  }

  return (
    <Modal
      visible
      onOk={handleOkClick}
      onCancel={handleCloseModal}
      footer={[
        <Button key="back" type="ghost" danger onClick={handleOkClick}>
          Yes
        </Button>,
        <Button key="submit" type="primary" onClick={handleCloseModal}>
          No
        </Button>
      ]}
    >
      <div className="h-5/6 py-4">
        <h5>{title || 'No action performed'}</h5>
        <p className="text-danger">
          {message || 'Are you sure all Pii are redacted?'}
        </p>
      </div>
    </Modal>
  )
}

export default ConfirmModal
