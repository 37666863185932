import axios from 'axios'
import { MerchantDefinition } from 'pages/app/annotation-tool/task/types'
import { DBReceiptAnnotation } from 'services/api/requests/types'
import config from 'services/config'
import {
  axiosReqInterceptor,
  axiosResInterceptor
} from 'services/integrations/axios'
import { DBReceiptOverview } from './types'

const LabelApiNoctua2Client = axios.create({
  baseURL: config.baseUrl + 'noctua-ii/label/'
})

LabelApiNoctua2Client.interceptors.request.use(axiosReqInterceptor)
LabelApiNoctua2Client.interceptors.response.use(axiosResInterceptor)

export const getReceiptAnnotation = async (
  country: string,
  retailerFilter?: string
) => {
  const { data } = await LabelApiNoctua2Client.get<DBReceiptAnnotation>(
    `annotate/${country}${
      retailerFilter ? `?merchant=${encodeURIComponent(retailerFilter)}` : ''
    }`
  )

  return data
}

export const getReceiptOverview = async () => {
  const { data } = await LabelApiNoctua2Client.get<DBReceiptOverview>(
    'merchant-overview'
  )

  return data
}

export const getRetailerDefinition = async (
  country: string,
  retailerFilter?: string
) => {
  const { data } = await LabelApiNoctua2Client.get<MerchantDefinition>(
    `definition/${country}${
      retailerFilter ? `/${encodeURIComponent(retailerFilter)}` : ''
    }`
  )
  return data
}

type updateProps = {
  id: string | number
  putBody: DBReceiptAnnotation
}
export const updateReceiptAnnotation = async ({ id, putBody }: updateProps) => {
  const { data } = await LabelApiNoctua2Client.put<DBReceiptAnnotation>(
    `approve/${id}`,
    putBody
  )
  return data
}

export const skipReceiptAnnotation = async (id: string | number) => {
  const { data } = await LabelApiNoctua2Client.get<DBReceiptAnnotation>(
    `skip/${id}`
  )
  return data
}

type rejectProps = {
  id: string | number
  reason: string
}
export const rejectReceiptAnnotation = async ({ id, reason }: rejectProps) => {
  const { data } = await LabelApiNoctua2Client.post<DBReceiptAnnotation>(
    `reject/${id}`,
    { reason }
  )
  return data
}

export const unlockReceipt = async () => {
  // const { data } = await LabelApiNoctua2Client.post('unlock')
  // return data
}

export const getListComplete = async () => {
  const { data } = await LabelApiNoctua2Client.get<DBReceiptOverview>(
    'list-complete'
  )

  return data
}

export const getRelabelPreview = async (minervaIds?: string) => {
  const { data, status } = await LabelApiNoctua2Client.get(
    `relabel/minerva/${minervaIds}`
  )

  return status !== 204 ? data : []
}

export const relabelReceipts = async (minervaIds?: number[]) => {
  const { data } = await LabelApiNoctua2Client.post('relabel/minerva', {
    ids: minervaIds
  })

  return data
}
