import { Image, Layout, Modal } from 'antd'
import SplashScreen from 'components/splash'
import { ExtractionSelectors } from 'data/selectors'
import Pagination from 'pages/app/extraction/version-1/task/components/pagination'
import React, { useRef, useState } from 'react'
import { useQuery } from 'react-query'
import { useSelector } from 'react-redux'
import { useParams } from 'react-router'
import { TransformComponent, TransformWrapper } from 'react-zoom-pan-pinch'
import { Noctua2VisualizerApi } from 'services/api/requests/notua-ii'
import { Color } from 'services/styles/color'
import { HEADER_HEIGHT } from 'services/styles/layout'
import { Space } from 'services/styles/spacing'

const { Content } = Layout

const VisualizeReceiptByMerchant = () => {
  const retailerFilter = useSelector(ExtractionSelectors.retailerFilter)
  const { countryCode } = useParams<{ countryCode: string }>()

  const wrapperRef = useRef(null)
  const zoomWrapperRef = useRef(null)
  const [isPanningMode, setIsPanningMode] = useState<boolean>(false)
  const [currentPage, setCurrentPage] = useState<number>(0)

  const handleOnLoadDataFailed = () => {
    Modal.error({
      title: 'Fetch Error!',
      content: (
        <>
          <p>Something went wrong while getting this receipt.</p>
          <p>Please try again</p>
        </>
      )
    })
  }

  const handlePrevPageClick = () => setCurrentPage(currentPage - 1)

  const handleNextPageClick = () => setCurrentPage(currentPage + 1)

  const { isFetching, data } = useQuery(
    ['visualizer-merchant', countryCode || 'US', retailerFilter],
    ({ queryKey }: { queryKey: [string, string, string | undefined] }) =>
      Noctua2VisualizerApi.getReceipt(queryKey?.[1], queryKey?.[2]),
    {
      enabled: !!retailerFilter,
      onError: handleOnLoadDataFailed
    }
  )

  const receiptImage = data?.content?.[currentPage]
  const imageSize = data?.size_list?.[currentPage]

  const totalPages = data?.content?.length

  return (
    <Layout
      style={{
        height: `calc(100vh - ${HEADER_HEIGHT}px)`
      }}
    >
      {isFetching ? (
        <SplashScreen />
      ) : (
        <>
          <Content className="bg-white z-30 sticky right-0 overflow-scroll">
            <div ref={wrapperRef}>
              <TransformWrapper
                key={'audit-receipt-wrapper'}
                centerOnInit
                ref={zoomWrapperRef}
                initialScale={0.25}
                minScale={0.1}
                maxScale={5}
                doubleClick={{ disabled: true }}
                wheel={{ activationKeys: ['+', '-'] }}
                panning={{ activationKeys: [' '] }}
                centerZoomedOut
              >
                <TransformComponent
                  wrapperStyle={{
                    backgroundColor: Color.GREY_300,
                    width: '100%',
                    height: `calc(100vh - ${HEADER_HEIGHT}px)`
                  }}
                >
                  <div style={{ cursor: isPanningMode ? 'move' : 'default' }}>
                    <Image
                      width={imageSize?.width || 1000}
                      height={imageSize?.height || 3000}
                      key={receiptImage?.toString()}
                      alt="receipt"
                      className="relative"
                      src={receiptImage}
                      preview={false}
                    />
                  </div>
                </TransformComponent>
              </TransformWrapper>
              <Pagination>
                <Pagination.LeftArrow
                  onClick={handlePrevPageClick}
                  disabled={currentPage <= 0}
                />
                <Pagination.RightArrow
                  onClick={handleNextPageClick}
                  disabled={currentPage >= totalPages - 1}
                  style={{ right: Space.SMALL }}
                />
                <Pagination.PageIndicator
                  current={currentPage + 1}
                  total={totalPages || 0}
                  style={{ marginRight: 0, bottom: Space.SMALL }}
                />
              </Pagination>
            </div>
          </Content>
        </>
      )}
    </Layout>
  )
}

export default VisualizeReceiptByMerchant
