import { Button, Card, Col, Row, Statistic } from 'antd'
import { ColumnsType } from 'antd/lib/table/interface'
import StandardBreadcrumb from 'components/standard-breadcrumb'
import StandardInput from 'components/standard-input'
import TableStripedRows from 'components/table-striped-rows'
import React, { useState } from 'react'
import { useMutation, useQuery } from 'react-query'
import { QueryKeys } from 'services/api/query-key'
import { Noctua2LabelApi } from 'services/api/requests/notua-ii'
import { LabelStatusEnum } from 'services/api/requests/notua-ii/types'
import queryClient from 'services/react-query'

const MIN_ID_LENGTH = 7
const RelabelReceiptPage = () => {
  const [inputIds, setInputIds] = useState('')

  const validQuery = inputIds.length >= MIN_ID_LENGTH || inputIds.includes(',')

  const {
    isFetching: isSearching,
    refetch: refetchSearchQuery,
    data
  } = useQuery(
    [QueryKeys.RelabelReceipt, inputIds],
    () => Noctua2LabelApi.getRelabelPreview(inputIds),
    {
      enabled: validQuery
    }
  )

  const { mutateAsync, isLoading: isMutating } = useMutation(
    [QueryKeys.RelabelReceipt, inputIds],
    Noctua2LabelApi.relabelReceipts,
    {
      onSuccess: () => {
        queryClient.invalidateQueries(QueryKeys.RelabelReceipt)
      }
    }
  )

  const column = [
    { title: 'Id', dataIndex: 'id' },
    { title: 'Minerva id', dataIndex: 'minervaId' },
    { title: 'Merchant', dataIndex: 'merchant' },
    { title: 'Country code', dataIndex: 'countryCode' },
    { title: 'Label status', dataIndex: 'labelStatus' }
  ] as ColumnsType

  const handleRelabelReceipts = async () => {
    // relabel LABELLED only
    const validMinervaIds = data?.reduce(
      (acc, curr) =>
        curr.labelStatus === LabelStatusEnum.LABELLED
          ? [...acc, curr.minervaId]
          : acc,
      []
    )
    await mutateAsync(validMinervaIds)
  }
  const idsList = inputIds.split(',')
  const invalidReceiptIds = idsList.reduce((acc, curr) => {
    if (data?.some(i => i.minervaId === parseInt(curr.trim()))) {
      return acc
    }
    return [...acc, curr]
  }, [])

  const doneRelabelReceipts = data?.filter(
    i => i.labelStatus === LabelStatusEnum.PRIORITY_INGESTED
  )

  return (
    <div className="p-10">
      <StandardBreadcrumb />
      <h3 className="mb-4">Relabel Receipts</h3>
      <StandardInput
        label="Minerva Ids"
        placeholder={'eg. id1,id2,id3'}
        defaultValue={inputIds}
        onBlur={value => {
          setInputIds(value)
        }}
      />

      <div className="my-4">
        <Button
          type="primary"
          onClick={handleRelabelReceipts}
          disabled={!data}
          loading={isSearching || isMutating}
        >
          Relabel All
        </Button>
        <Button
          className="ml-2"
          type="default"
          onClick={() => refetchSearchQuery()}
          disabled={!data}
          loading={isSearching}
        >
          Refresh
        </Button>
      </div>

      {data && (
        <Row gutter={16} className="mb-4">
          <Col span={12}>
            <Card bordered={false}>
              <Statistic
                title="Valid receipt ids"
                value={data.length}
                valueStyle={{
                  color: !invalidReceiptIds?.length ? '#3f8600' : '#cf1322'
                }}
                suffix={`/ ${idsList.length}`}
              />
            </Card>
          </Col>
          <Col span={12}>
            <Card bordered={false}>
              <Statistic
                title="Relabel job complete"
                value={doneRelabelReceipts.length}
                valueStyle={{
                  color:
                    doneRelabelReceipts?.length === idsList.length
                      ? '#3f8600'
                      : '#cf1322'
                }}
                suffix={`/ ${idsList.length}`}
              />
            </Card>
          </Col>
        </Row>
      )}
      <TableStripedRows
        loading={isSearching}
        dataSource={data}
        columns={column}
      />
    </div>
  )
}

export default RelabelReceiptPage
