import { store } from 'data'
import { useEffect } from 'react'

export const useUnloadBeacon = ({ method, url }) => {
  // @ts-ignore
  const token = store.getState().firebase.auth.stsTokenManager.accessToken

  const eventHandler = () => {
    try {
      if (token) {
        fetch(url, {
          method,
          // mode: 'cors',
          keepalive: true,
          headers: {
            'Content-Type': 'application/json',
            authorization: `Bearer ${token}`
          }
        })
      }
    } catch (e) {}
  }

  useEffect(() => {
    require('events').EventEmitter.defaultMaxListeners = 20

    window.addEventListener('unload', eventHandler, true)
    return () => {
      window.removeEventListener('unload', eventHandler, true)
    }
  }, [])
}
