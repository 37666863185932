import { Button, Modal } from 'antd'
import ConfirmModal from 'components/confirm-modal'
import { DialogKeys } from 'components/modals/types'
import { OverlayActions } from 'data/actions'
import TaskDetails from 'pages/app/pii-redaction/footer/task-details'
import React, { useContext } from 'react'
import { useMutation, useQueryClient } from 'react-query'
import { useDispatch } from 'react-redux'
import { QueryKeys } from 'services/api/query-key'
import { Noctua2PiiRedactionApi } from 'services/api/requests/notua-ii'
import { Color } from 'services/styles/color'
import { TaskContext } from '../context'

const TaskFooter = ({ externalLoader = false }) => {
  const dispatch = useDispatch()

  const { state } = useContext(TaskContext)
  const queryClient = useQueryClient()

  const { mutateAsync, isLoading } = useMutation(
    Noctua2PiiRedactionApi.postPiiRedactionData,
    {
      onSuccess: () => {
        queryClient.invalidateQueries(QueryKeys.PiiRedactionReceipt)
      }
    }
  )

  const { record, data, userActions } = state

  const submitChanges = async () => {
    await mutateAsync(
      { ...data, userActions, redactedHTML: record },
      {
        onError: () => {
          Modal.error({
            title: 'Submission Error!',
            content: 'Cannot submit your request'
          })
        }
      }
    )
  }
  const handleSubmitClick = async e => {
    e.currentTarget.blur()
    if (!userActions.length) {
      return dispatch(
        OverlayActions.showDialog({
          key: DialogKeys.CONFIRM,
          component: <ConfirmModal onOk={submitChanges} />
        })
      )
    }

    await submitChanges()
  }

  return (
    <>
      <TaskDetails />
      <div className="flex flex-grow items-center">
        <Button
          loading={externalLoader || isLoading}
          onClick={handleSubmitClick}
          size="large"
          className="text-white hover:text-white w-28 rounded"
          style={{ backgroundColor: Color.SUCCESS }}
        >
          Submit
        </Button>
      </div>
    </>
  )
}

export default TaskFooter
