import Field from 'pages/app/extraction/version-2/task/content/components/field'
import Section from 'pages/app/extraction/version-2/task/content/components/sections'
import {
  LineItemHeader,
  SectionEnum
} from 'pages/app/extraction/version-2/types'
import React from 'react'

const MerchantSection = ({
  mergedRegularFields,
  handleChangeSourceSelection,
  merchantDefinition
}) => {
  const merchantInfo = LineItemHeader[SectionEnum.MERCHANT].map(header => ({
    ...mergedRegularFields[header],
    label: header
  }))

  const isAllCleared = true
  // merchantInfo.every(
  //   item => !!item?.text || item.isNotRequired
  // )

  const renderRow = (headers, data) => (
    <>
      {headers.map((header, index) => {
        const content = data.find(content => content.label === header)
        return (
          <Field
            key={`${header}_${content?.id}_${index}`}
            id={content?.id}
            wrapperClassName={`w-1/${headers.length}`}
            label={content?.label}
            value={content?.text || '\u00A0'}
            onClick={() => handleChangeSourceSelection(content.id)}
            isError={merchantDefinition[header]?.required && !content?.text}
            isDisabled={merchantDefinition[header]?.disabled}
          />
        )
      })}
    </>
  )

  const MerchantHeaders = LineItemHeader[SectionEnum.MERCHANT]

  return (
    <Section title="Merchant" isComplete={isAllCleared}>
      <div className="flex gap-2">
        {renderRow(MerchantHeaders.slice(0, 3), merchantInfo)}
      </div>
      <div className="flex gap-2">
        {renderRow(
          MerchantHeaders.slice(3, MerchantHeaders.length),
          merchantInfo
        )}
      </div>
    </Section>
  )
}

export default MerchantSection
