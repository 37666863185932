import { UploadOutlined } from '@ant-design/icons'
import { Button, Layout, Space, Typography, Upload, UploadProps } from 'antd'
import { UploadFile } from 'antd/lib/upload/interface'
import DnDArea from 'components/drag-and-drop-area'
import StandardInput from 'components/standard-input'
import StandardSelect from 'components/standard-select'
import { DefinitionContext } from 'pages/app/admin/merchant-definition/modals/context'
import * as Papa from 'papaparse'
import React, { useContext, useState } from 'react'
import { FullDefinition } from 'services/api/requests/notua-ii/types'

const { Text } = Typography

const { Content, Sider } = Layout

const MODAL_Y_PADDING = 200

const BASIC_DETAILS_WIDTH = 300

const availableCountryCodes = ['AU', 'US', 'CA', 'BR']

const DefinitionContent = () => {
  const { state, dispatchDefinition } = useContext(DefinitionContext)

  const [fileList, setFileList] = useState<UploadFile[]>([])

  const {
    full_definition,
    merchant,
    target,
    tutorial_url,
    country_code,
    active
  } = state
  const handleUpdateFields = (newFields: FullDefinition[]) => {
    dispatchDefinition({ ...state, full_definition: newFields })
  }
  const handleUpdateRetailerName = (newString: string) => {
    dispatchDefinition({ ...state, merchant: newString })
  }
  const handleUpdateTargetCount = (newString: string) => {
    const newTarget = Number(newString)
    dispatchDefinition({ ...state, target: newTarget })
  }
  const handleUpdateCountryCode = (newValue: string) => {
    dispatchDefinition({ ...state, country_code: newValue })
  }
  const handleUpdateStatus = (newString: string) => {
    const newValue = newString === 'ACTIVE'
    dispatchDefinition({ ...state, active: newValue })
  }
  const handleUpdateTutorialUrl = (newValue: string) => {
    dispatchDefinition({ ...state, tutorial_url: newValue })
  }

  const handleUploadFileChange: UploadProps['onChange'] = info => {
    Papa.parse(info.file, {
      complete: function (results) {
        const parsedContent: string[][] = results.data

        const isStatusCorrect = parsedContent.every(i =>
          ['REQ', 'OPT', 'N/A'].includes(i[2])
        )

        const isRowFormatCorrect = parsedContent.every(i => i.length === 3)
        if (!isRowFormatCorrect) {
          return alert(
            `${info.file.name} is incorrect format\nCorrect format: label,name,status`
          )
        }

        if (!isStatusCorrect) {
          return alert(
            `${info.file.name} has incorrect status format\nCorrect format: 'REQ', 'OPT', 'N/A'`
          )
        }

        const formattedData = parsedContent.map(row => ({
          label: row[0],
          name: row[1],
          status: row[2] as 'REQ' | 'OPT' | 'N/A'
        }))

        handleUpdateFields(formattedData)

        setFileList([info.file])
      }
    })
  }

  const customUploadProps: UploadProps = {
    beforeUpload: file => {
      const isCSV = file.type === 'text/csv'
      if (!isCSV) {
        alert(`${file.name} is not a csv file`)
      }
      return false
    },
    fileList,
    onChange: handleUploadFileChange,
    multiple: false,
    showUploadList: false
  }

  return (
    <Layout>
      <Layout
        style={{
          height: `calc(100vh - ${MODAL_Y_PADDING}px)`
        }}
      >
        <Sider
          width={BASIC_DETAILS_WIDTH}
          className="bg-white z-20 overflow-scroll pr-10"
        >
          <Text strong>Basic Details</Text>
          <Space direction="vertical" className="w-full">
            <StandardInput
              label="Retailer name"
              placeholder="eg. WOOLWORTHS"
              onChange={handleUpdateRetailerName}
              error={!merchant && 'Required field'}
              value={merchant}
            />
            <StandardSelect
              label="Country"
              value={country_code}
              menu={availableCountryCodes}
              onChange={handleUpdateCountryCode}
            />
            <StandardSelect
              label="Status"
              value={active ? 'ACTIVE' : 'INACTIVE'}
              menu={['ACTIVE', 'INACTIVE']}
              onChange={handleUpdateStatus}
            />
            <StandardInput
              label="Target count"
              placeholder="eg. 1500"
              type="number"
              value={target}
              onChange={handleUpdateTargetCount}
            />
            <StandardInput
              label="Link to mapping doc"
              placeholder="eg. https://..."
              value={tutorial_url}
              onChange={handleUpdateTutorialUrl}
            />

            {/* Upload fields */}
            <div>Create fields from file</div>
            <Upload {...customUploadProps}>
              <Button icon={<UploadOutlined />}>Upload csv only</Button>
            </Upload>
          </Space>
        </Sider>
        <Content className="bg-white z-30 sticky right-0 overflow-scroll">
          <Text strong>Fields</Text>
          <DnDArea data={full_definition} setData={handleUpdateFields} />
        </Content>
      </Layout>
    </Layout>
  )
}

export default DefinitionContent
