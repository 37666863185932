import { CopyOutlined } from '@ant-design/icons'
import { Button, Popover } from 'antd'
import React, { useState } from 'react'

interface Props {
  text: string
}

const CopyToClipboardButton = ({ text }) => {
  const [copied, setCopied] = useState(true) // avoid content switching

  const handleCopy = () => {
    navigator.clipboard
      .writeText(text)
      .then(() => {
        setCopied(true)
      })
      .catch(err => {
        setCopied(false)
        console.error('Failed to copy: ', err)
      })
  }

  return (
    <Popover
      getPopupContainer={(triggerNode: any) => triggerNode.parentNode}
      content={copied ? 'Copied!' : 'Try again'}
      trigger="focus"
      placement="right"
    >
      <Button
        type="text"
        icon={<CopyOutlined />}
        onClick={handleCopy}
        className="flex items-center justify-center"
      />
    </Popover>
  )
}

export default CopyToClipboardButton
