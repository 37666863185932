import { RecordActions } from './record/actions'

// TODO type
export interface CombinedStates {
  data: any
  record: any
  userActions: any[]
}

export const initialState: CombinedStates = {
  record: null,
  data: null,
  userActions: []
}

export const combinedReducers = {
  // data reducer
  [RecordActions.SET_DATA]: (state: CombinedStates, newData: any) => {
    return {
      ...state,
      data: newData
    }
  }, // record reducer
  [RecordActions.SET_RECORD]: (state: CombinedStates, newRecord: any) => {
    return {
      ...state,
      record: newRecord
    }
  },
  [RecordActions.ADD_USER_ACTION]: (state: CombinedStates, newAction: any) => {
    return {
      ...state,
      userActions: [...state.userActions, newAction]
    }
  },
  //  reset
  [RecordActions.RESET_WORKING_STATE]: (state: CombinedStates) => {
    return {
      record: null,
      data: null,
      userActions: []
    }
  }
}

export function reducer(state, action) {
  return combinedReducers[action.type](state, action.payload)
}
