import AppRootLayout from 'components/layout/app-root'
import React from 'react'
import AdminRoutesNavigator from 'routes/app/admin'
import AnnotationRoutesNavigator from 'routes/app/annotation-tool'
import AuditRoutesNavigator from 'routes/app/audit'
import DashboardRoutesNavigator from 'routes/app/dashboard'
import EmailSubjectWhitelistRoutesNavigator from 'routes/app/email-subject-whitelist'
import PiiRedactionRoutesNavigator from 'routes/app/pii-redaction'
import ReceiptReviewRoutesNavigator from 'routes/app/receipt-reivew'
import SurveyCreationRoutesNavigator from 'routes/app/survey-creation'
import config from 'services/config'
import { useUnloadBeacon } from 'services/hooks/use-unload-beacon'
import useCustomIdleTimer from 'services/idle-timer/hooks'
import ExtractionRoutesNavigator from './extraction'

const AppRoutesNavigator = () => {
  // unlock pii-redaction
  useUnloadBeacon({
    method: 'DELETE',
    url: config.baseUrl + 'noctua-ii/redaction/file-lock'
  })

  // TODO unlock receipt for multiple noctua versions
  useUnloadBeacon({
    method: 'POST',
    url: config.baseUrl + 'noctua-ii/receipt/unlock'
  })

  useCustomIdleTimer()

  return (
    <AppRootLayout>
      <DashboardRoutesNavigator />
      <ExtractionRoutesNavigator />
      <AnnotationRoutesNavigator />
      <ReceiptReviewRoutesNavigator />
      <PiiRedactionRoutesNavigator />
      <EmailSubjectWhitelistRoutesNavigator />
      <SurveyCreationRoutesNavigator />
      <AuditRoutesNavigator />
      <AdminRoutesNavigator />
    </AppRootLayout>
  )
}

export default AppRoutesNavigator
