import { ExtractionSettingsType } from 'data/extraction/types'
import { createAction } from 'redux-actions'
import { ResponseReceiptAnnotationType } from 'services/api/requests/annotation'
import ExtractionConstants from './constants'

export const setRetailerFilter = (retailer: string) =>
  createAction(ExtractionConstants.SET_RETAILER_FILTER)(retailer)

export const setSettings = (settings: ExtractionSettingsType) =>
  createAction(ExtractionConstants.SET_SETTINGS)(settings)

export const approveReceipt = () =>
  createAction(ExtractionConstants.SET_DONE_NUMBER)()

export const setRawData = (data: ResponseReceiptAnnotationType) =>
  createAction(ExtractionConstants.SET_RAW_DATA)(data)

export const setReceiptId = (id: string | number) =>
  createAction(ExtractionConstants.SET_RECEIPT_ID)(id)

export const setCurrentPage = (pageNum: number) =>
  createAction(ExtractionConstants.SET_CURRENT_PAGE)(pageNum)
