import StandardBreadcrumb from 'components/standard-breadcrumb'
import VersionTable from 'pages/app/visualizer/home/components/version-table'
import React from 'react'

const VisualizerHome = () => {
  return (
    <div className="p-10">
      <StandardBreadcrumb />
      <h3 className="mb-4">Visualizer</h3>
      <VersionTable />
    </div>
  )
}

export default VisualizerHome
