import axios from 'axios'
import { DBReceiptAnnotation } from 'services/api/requests/types'
import config from 'services/config'
import {
  axiosReqInterceptor,
  axiosResInterceptor
} from 'services/integrations/axios'

const AdminApiClient = axios.create({
  baseURL: config.baseUrl + 'noctua-ii/admin/'
})

AdminApiClient.interceptors.request.use(axiosReqInterceptor)
AdminApiClient.interceptors.response.use(axiosResInterceptor)

export type ResponseUsersOverviewType = {
  user: {
    email: string
    user_id: number
  }[]
}

export const getUsersOverview = async () => {
  const { data } = await AdminApiClient.get<ResponseUsersOverviewType>('audit')
  return data
}

export type ResponseUserExtractedReceiptsType = {
  user_id: number
  email: string
  latest_receipts: number[]
  latest_labels: number[]
}

export const getUserExtractedReceipts = async (id: string | number) => {
  const { data } = await AdminApiClient.post<ResponseUserExtractedReceiptsType>(
    `audit/${id}`
  )
  return data
}

// fallback because backend might not return error
export const getReceiptsAnalytics = async (id?: number) => {
  const fallbackData = {
    pages: [],
    metadata: {}
  } as DBReceiptAnnotation
  if (!id) {
    return fallbackData
  }
  const res = await AdminApiClient.get<DBReceiptAnnotation>(`analytics/${id}`)

  return res.status === 204 ? { ...res, data: fallbackData } : res
}
