import { QuestionCircleOutlined } from '@ant-design/icons'
import { Tooltip } from 'antd'
import { TaskContext } from 'pages/app/annotation-tool/task/context'
import AnnotationHotkeysTooltip from 'pages/app/annotation-tool/task/footer/tooltips/hotkeys'
import ShortcutsTooltip from 'pages/app/annotation-tool/task/footer/tooltips/shortcut'
import React, { useContext } from 'react'
import { useParams } from 'react-router'

const TaskDetails = () => {
  const { countryCode } = useParams<{ countryCode: string }>()

  const { state: parentState } = useContext(TaskContext)
  const { record } = parentState

  if (!record) return null

  const minervaId = record.metadata.minerva_id
  const receiptInQueue =
    record.metadata[countryCode === 'AU' ? 'AU_pending' : 'US_pending']

  return (
    <div className="flex items-center justify-between w-full mr-2">
      <div className="flex items-center">
        <Tooltip title={ShortcutsTooltip}>
          <QuestionCircleOutlined className="text-lg mr-4" />
        </Tooltip>
        <Tooltip
          title={AnnotationHotkeysTooltip}
          overlayStyle={{ maxWidth: '500px' }}
        >
          <span className="underline">Hotkeys</span>
        </Tooltip>{' '}
        <span className="mx-2">|</span>
        <span>
          Receipt ID: <span className="font-bold">{minervaId}</span>
        </span>
        <span className="mx-2">|</span>
        <span>
          <span className="font-bold">{receiptInQueue}</span> more in queue
        </span>
      </div>
    </div>
  )
}

export default TaskDetails
