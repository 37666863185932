import React from 'react'
import StandardBreadcrumb from '../../../../components/standard-breadcrumb'
import './styles.less'
import UsersTable from './users-table'

const UsersOverview = () => (
  <div className="p-10">
    <StandardBreadcrumb />
    <h3 className="mb-4">Manage Users</h3>
    <UsersTable />
  </div>
)

export default UsersOverview
