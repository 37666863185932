import moment from 'moment'

const convertMilliSecondToSecond = (milliseconds: number): number =>
  Math.ceil(milliseconds / 1000)

const seconds2time = (input: number): string => {
  const date = new Date(0)
  date.setSeconds(input) // specify value for SECONDS here
  return date.toISOString().substring(14, 19)
}

const diffSeconds = (dt2: Date, dt1: Date) => {
  const diff = (dt2.getTime() - dt1.getTime()) / 1000
  return Math.abs(Math.round(diff))
}

const formatDate = (value: string) => {
  const stamp = moment(value, 'YYYY-MM-DD')
  return stamp.isValid() ? stamp : undefined
}

const formatTime = (value: string, format?: string) => {
  const stamp = moment(value, format || 'HH:mm')
  return stamp.isValid() ? stamp : undefined
}

export {
  convertMilliSecondToSecond,
  seconds2time,
  diffSeconds,
  formatDate,
  formatTime
}
