import { PermissionEnum } from 'data/reducers'
import { ForbiddenPage } from 'pages/app'
import { ExtractionVersionPage } from 'pages/app/extraction'
import {
  ExtractionHomePage,
  ExtractionTaskPage
} from 'pages/app/extraction/version-1'
import {
  ExtractionTaskV2Page,
  ExtractionV2HomePage
} from 'pages/app/extraction/version-2'
import React from 'react'
import { Switch } from 'react-router'
import useHandleRequirePermissions from 'services/hooks/use-handle-require-permission'
import SentryRoute from 'services/sentry/sentry-route'
import { AppSubRoutes } from '../routes'

const ExtractionRoutesNavigator = () => {
  const hasPermission = useHandleRequirePermissions([PermissionEnum.ANNOTATION])

  return (
    <>
      <SentryRoute
        exact
        path={AppSubRoutes.EXTRACTION.HOME.pathname}
        component={hasPermission ? ExtractionVersionPage : ForbiddenPage}
      />
      <Switch>
        <SentryRoute
          exact
          path={AppSubRoutes.EXTRACTION.V1.HOME.pathname}
          component={hasPermission ? ExtractionHomePage : ForbiddenPage}
        />
        <SentryRoute
          exact
          path={AppSubRoutes.EXTRACTION.V1.TASK.pathname}
          component={hasPermission ? ExtractionTaskPage : ForbiddenPage}
        />
        <SentryRoute
          exact
          path={AppSubRoutes.EXTRACTION.V2.HOME.pathname}
          component={hasPermission ? ExtractionV2HomePage : ForbiddenPage}
        />
        <SentryRoute
          exact
          path={AppSubRoutes.EXTRACTION.V2.TASK.pathname}
          component={hasPermission ? ExtractionTaskV2Page : ForbiddenPage}
        />
      </Switch>
    </>
  )
}

export default ExtractionRoutesNavigator
