import { Input, Modal, Row, Space, Typography } from 'antd'
import CopyToClipboardButton from 'components/copy-to-clipboard'
import { DialogKeys } from 'components/modals/types'
import { OverlayActions } from 'data/actions'
import { isNaN, parseInt } from 'lodash'
import React from 'react'
import { useDispatch } from 'react-redux'

const { Text } = Typography
const { TextArea } = Input

interface Props {
  onOk?: () => void
  surveyCreationData?: any
  surveyRequestData?: any
}

const prettifyJSON = (jsonObject: object) =>
  JSON.stringify(
    jsonObject,
    (key, value) => {
      const parsedIntValue = parseInt(value)
      const isDateType = /.*T.*/.test(value)
      const containsNonDigit = /\D/.test(value)

      // Check if the value is a number
      if (!isNaN(parsedIntValue) && !isDateType && !containsNonDigit) {
        return parsedIntValue
      } else {
        return value
      }
    },
    2
  )

const SurveyCreationPreview = ({
  onOk,
  surveyCreationData,
  surveyRequestData
}: Props) => {
  const dispatch = useDispatch()

  const closeSelf = () =>
    dispatch(
      OverlayActions.hideDialog({ key: DialogKeys.SURVEY_CREATION_PREVIEW })
    )

  const handleOkClick = async () => {
    onOk && onOk()
    closeSelf()
  }

  return (
    <Modal
      title={'Survey Creation Preview'}
      visible
      confirmLoading={false}
      onOk={handleOkClick}
      onCancel={closeSelf}
      okButtonProps={{
        style: {
          width: 200,
          backgroundColor: '#60a5fa',
          borderRadius: 6,
          height: 40
        }
      }}
      cancelButtonProps={{ type: 'text' }}
      okText={'Done'}
      className="top-8"
      width={1000}
    >
      <Space direction="vertical" className="w-full">
        <Row align="middle">
          <Text strong>Survey request info</Text>
          <CopyToClipboardButton text={prettifyJSON(surveyRequestData)} />
        </Row>
        <TextArea
          value={prettifyJSON(surveyRequestData)}
          rows={6}
          disabled
          style={{
            background: '#f5f5f5',
            border: 'none',
            resize: 'none',
            color: 'black'
          }}
        />
        <Row align="middle">
          <Text strong>Survey Creation JSON</Text>
          <CopyToClipboardButton text={prettifyJSON(surveyCreationData)} />
        </Row>
        <TextArea
          value={prettifyJSON(surveyCreationData)}
          rows={8}
          disabled
          style={{
            background: '#f5f5f5',
            border: 'none',
            resize: 'none',
            color: 'black'
          }}
        />
      </Space>
    </Modal>
  )
}

export default SurveyCreationPreview
