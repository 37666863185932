import { Dropdown, notification, Select } from 'antd'
import TextArea from 'antd/lib/input/TextArea'
import { BoxLabelEnum, boxLabelsList } from 'data/box'
import React, { ReactNode } from 'react'
import { Field, Word } from 'services/api/requests/types'
import {
  getBoxColor,
  getBoxColorDarker,
  getBoxColorLight
} from 'services/styles/color'
import { Space } from 'services/styles/spacing'

const _renderMessageContent = (action: string, sourceBox, targetBox) => (
  <span>
    {action}{' '}
    <span
      className="rounded text-white font-bold px-2 pb-0.5"
      style={{ backgroundColor: getBoxColor(sourceBox?.label) }}
    >
      {sourceBox?.text}
    </span>{' '}
    to{' '}
    <span
      className="rounded text-white font-bold px-2 pb-0.5"
      style={{ backgroundColor: getBoxColor(targetBox?.label) }}
    >
      {targetBox?.text}
    </span>
  </span>
)

interface MenuItemProps {
  title: string
  actionComponent?: ReactNode | string
  valueComponent?: ReactNode | string
}

const LabelSelectMenu = ({ value, handleOnChange }) => {
  const formatText = (label: string) =>
    label === BoxLabelEnum.ITEM_CODE ? 'Barcode/SKU' : label

  return (
    <Select
      style={{ borderRadius: 99 }}
      className="w-full"
      value={value}
      onChange={handleOnChange}
      onClick={event => event.stopPropagation()}
    >
      {boxLabelsList.map(label => (
        <Select.Option key={label} value={label}>
          {formatText(label)}
        </Select.Option>
      ))}
    </Select>
  )
}

const MenuItem = ({
  title,
  actionComponent,
  valueComponent
}: MenuItemProps) => (
  <div className={'my-1'}>
    <div className={'flex flex-row justify-between items-center'}>
      <span className={'px-4 font-semibold text-gray-500'}>{title}</span>
      {actionComponent}
    </div>
    <div className={'px-4'}>{valueComponent}</div>
  </div>
)

interface FieldBoxProps {
  fields: Field[]
  words: Word[]
  field: Field
  sourceSelection?: string
  targetSelection?: string
  handleEditText: (newValue) => void
  handleEditLabel: (newValue) => void
  onEditingModeChange: (newValue) => void
  handleSourceSelectionChange: (newValue) => void
  handleTargetSelectionChange: (newValue) => void
  addLink: (payload) => void
  removeLink: (payload) => void
  linkMode: boolean
}

const FieldBox = ({
  fields,
  words,
  field,
  handleEditText,
  handleEditLabel,
  onEditingModeChange,
  handleSourceSelectionChange,
  handleTargetSelectionChange,
  sourceSelection,
  targetSelection,
  addLink,
  removeLink,
  linkMode
}: FieldBoxProps) => {
  const { label, boundingPoly, fieldElements, text, links, id } = field

  const handleLinkEvent = (source, target) => () => {
    if (!linkMode) {
      handleTargetSelectionChange(
        target === targetSelection ? undefined : target
      )
    }

    if (linkMode && source && source !== target) {
      const payload = { id: source, targetId: target }
      const targetBox = fields.find(field => field.id === target)
      const sourceBox = fields.find(field => field.id === source)
      const isExisted = targetBox.links.includes(source)

      if (isExisted) {
        removeLink(payload)
        notification.warning({
          style: {
            borderRadius: 12,
            padding: Space.TINY,
            backgroundColor: 'rgba(255,255,255,0.7)'
          },
          message: _renderMessageContent('Unlinked', sourceBox, targetBox),
          placement: 'bottomLeft'
        })
      } else {
        addLink(payload)
        notification.success({
          style: {
            borderRadius: 12,
            padding: Space.TINY,
            backgroundColor: 'rgba(255,255,255,0.7)'
          },
          message: _renderMessageContent('Linked', sourceBox, targetBox),
          placement: 'bottomLeft'
        })
      }
    }
  }

  const menu = (
    <div className={'rounded-lg bg-white'}>
      <div className={'py-2'}>
        <MenuItem
          title={'LABEL'}
          valueComponent={
            <LabelSelectMenu value={label} handleOnChange={handleEditLabel} />
          }
        />{' '}
        <MenuItem
          title={'TEXT'}
          valueComponent={
            <TextArea
              onClick={event => event.stopPropagation()}
              className={'rounded-sm'}
              value={text}
              onPressEnter={e => {
                e.currentTarget.blur()
                if (sourceSelection === id) {
                  handleSourceSelectionChange(null)
                }
              }} // autoFocus
              onChange={e => handleEditText(e.target.value)}
              onFocus={() => onEditingModeChange(true)}
              onBlur={() => onEditingModeChange(false)}
              autoSize={{ minRows: 1 }}
            />
          }
        />{' '}
        <MenuItem
          title={'LINKS'}
          valueComponent={links.map(link => {
            const linkedBox = fields.find(item => item.id === link)
            const borderColor = getBoxColor(linkedBox?.label)
            const backgroundColor = getBoxColorLight(linkedBox?.label)
            return (
              <div
                key={link}
                className="rounded-sm my-0.5 px-3"
                style={{
                  border: `1px solid
  ${borderColor}`,
                  backgroundColor
                }}
              >
                {' '}
                {linkedBox?.label}:{linkedBox?.text}{' '}
              </div>
            )
          })}
        />
      </div>
    </div>
  )

  const isFocused = targetSelection === id
  const renderLabeledWords = () =>
    fieldElements.map(elementId => {
      const wordBox = words.find(word => word.id === elementId)
      // TODO - fix for this receipt 3310686
      if (!wordBox) return null
      const [
        { x: x0, y: y0 },
        { x: x1, y: y1 },
        { x: x2, y: y2 },
        { x: x3, y: y3 }
      ] = wordBox.boundingPoly.vertices

      return (
        <path
          key={elementId}
          className="z-20 cursor-pointer opacity-50 absolute"
          d={`M${x0},${y0}
       L${x1},${y1}
       L${x2},${y2} 
       L${x3},${y3}z`}
          fill={getBoxColorLight(label)}
        />
      )
    })

  return (
    <Dropdown
      placement="bottomCenter"
      overlay={menu}
      overlayStyle={{ width: '12rem', minWidth: 'min-content' }}
      trigger={['contextMenu']}
      visible={sourceSelection === id && !linkMode}
      onVisibleChange={visible =>
        !linkMode && handleSourceSelectionChange(visible ? id : null)
      }
    >
      <g
        onClick={handleLinkEvent(targetSelection, id)}
        className="highlight"
        strokeWidth={isFocused ? 8 : 2}
        stroke={isFocused ? 'red' : getBoxColorDarker(label)}
      >
        {renderLabeledWords()}
      </g>
    </Dropdown>
  )
}

export default FieldBox
