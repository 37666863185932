import { SearchOutlined } from '@ant-design/icons'
import { Button, Checkbox, Input, Space, Table } from 'antd'
import { FilterConfirmProps } from 'antd/es/table/interface'
import { CheckboxChangeEvent } from 'antd/lib/checkbox'
import { ColumnsType, ColumnType } from 'antd/lib/table/interface'
import { WorkflowContext } from 'pages/app/email-subject-whitelist/context'
import { WorkflowActions } from 'pages/app/email-subject-whitelist/reducers/actions'
import React, {
  Dispatch,
  SetStateAction,
  useContext,
  useRef,
  useState
} from 'react'
import Highlighter from 'react-highlight-words'
import { IEmailTransaction } from 'services/api/requests/apolli-visualizer/email-subject-whitelist'
import { FOOTER_HEIGHT, HEADER_HEIGHT } from 'services/styles/layout'

type TransactionDataIndex = keyof IEmailTransaction

interface Props {
  transactions: IEmailTransaction[]
  isLoading: boolean
  suspendActions: boolean
  setSearcher: Dispatch<SetStateAction<any>>
  onViewClick: Dispatch<SetStateAction<any>>
}

const TransactionsTable = ({
  transactions,
  isLoading,
  suspendActions,
  setSearcher,
  onViewClick
}: Props) => {
  const { state, dispatchTask } = useContext(WorkflowContext)

  const [searchText, setSearchText] = useState('')
  const [searchedColumn, setSearchedColumn] = useState('')
  const searchInput = useRef(null)

  const handleSearch = (
    selectedKeys: string[],
    confirm: (param?: FilterConfirmProps) => void,
    dataIndex: TransactionDataIndex
  ) => {
    confirm()
    setSearchText(selectedKeys[0])
    setSearchedColumn(dataIndex)
  }

  const handleReset = (clearFilters: () => void) => {
    clearFilters()
    setSearchText('')
  }

  const getColumnSearchProps = (
    dataIndex: TransactionDataIndex
  ): ColumnType<IEmailTransaction> => ({
    filterDropdown: ({
      setSelectedKeys,
      selectedKeys,
      confirm,
      clearFilters
    }) => (
      <div style={{ padding: 8 }}>
        <Input
          ref={searchInput}
          placeholder={`Search ${dataIndex}`}
          value={selectedKeys[0]}
          onChange={e =>
            setSelectedKeys(e.target.value ? [e.target.value] : [])
          }
          onPressEnter={() =>
            handleSearch(selectedKeys as string[], confirm, dataIndex)
          }
          style={{ marginBottom: 8, display: 'block' }}
        />
        <Space>
          <Button
            type="primary"
            onClick={() =>
              handleSearch(selectedKeys as string[], confirm, dataIndex)
            }
            icon={<SearchOutlined />}
            size="small"
            style={{ width: 90 }}
          >
            Search
          </Button>
          <Button
            onClick={() => clearFilters && handleReset(clearFilters)}
            size="small"
            style={{ width: 90 }}
          >
            Reset
          </Button>
          <Button
            type="link"
            size="small"
            onClick={() => {
              confirm({ closeDropdown: false })
              setSearchText((selectedKeys as string[])[0])
              setSearchedColumn(dataIndex)
            }}
          >
            Filter
          </Button>
        </Space>
      </div>
    ),
    filterIcon: (filtered: boolean) => (
      <SearchOutlined style={{ color: filtered ? '#1890ff' : undefined }} />
    ),
    onFilterDropdownVisibleChange: visible => {
      if (visible) {
        setTimeout(() => searchInput.current?.select(), 100)
      }
    },
    render: text =>
      searchedColumn === dataIndex ? (
        <Highlighter
          highlightStyle={{ backgroundColor: '#ffc069', padding: 0 }}
          searchWords={[searchText]}
          autoEscape
          textToHighlight={text ? text.toString() : ''}
        />
      ) : (
        text
      )
  })

  const columns: ColumnsType<IEmailTransaction> = [
    {
      title: 'ID',
      dataIndex: 'transactionId',
      key: 'transactionId',
      width: '10%',
      ...getColumnSearchProps('transactionId'),
      sorter: (a, b) => a.transactionId.localeCompare(b.transactionId),

      sortDirections: ['descend', 'ascend']
    },
    {
      title: 'Date',
      dataIndex: 'transactionDate',
      key: 'transactionDate',
      width: '15%',
      ...getColumnSearchProps('transactionDate'),
      sorter: (a, b) => a.transactionDate.localeCompare(b.transactionDate),
      sortDirections: ['descend', 'ascend']
    },
    {
      title: 'Time',
      dataIndex: 'transactionTime',
      key: 'transactionTime',
      width: '15%',
      ...getColumnSearchProps('transactionTime'),
      sorter: (a, b) => a.transactionTime.localeCompare(b.transactionTime),
      sortDirections: ['descend', 'ascend']
    },
    {
      title: 'Whitelisted',
      dataIndex: 'subjectWhitelisted',
      key: 'subjectWhitelisted',
      width: '12%',
      ...getColumnSearchProps('subjectWhitelisted'),
      render: (value, record, index) => (
        <Checkbox
          onChange={(e: CheckboxChangeEvent) => {
            const updatedRecordState = {
              ...state.record,
              transactions: [
                ...state.record.transactions.map(
                  (transaction, transactionIndex) =>
                    transactionIndex === index
                      ? { ...transaction, subjectWhitelisted: e.target.checked }
                      : transaction
                )
              ]
            }

            dispatchTask({
              type: WorkflowActions.SET_RECORD,
              payload: updatedRecordState
            })
          }}
          checked={value}
        />
      ),
      sorter: (a, b) =>
        String(a.subjectWhitelisted).localeCompare(
          String(b.subjectWhitelisted)
        ),
      sortDirections: ['descend', 'ascend']
    },
    {
      title: 'Subject',
      dataIndex: 'subject',
      key: 'subject',
      ...getColumnSearchProps('subject'),
      sorter: (a, b) => a.subject.localeCompare(b.subject),
      sortDirections: ['descend', 'ascend']
    },
    {
      title: 'Action',
      key: 'action',
      width: '12%',
      render: (_, record) => {
        const handleButtonClick = () => {
          onViewClick(record)
        }
        return (
          <Space size="middle">
            <Button
              loading={suspendActions}
              type="primary"
              onClick={handleButtonClick}
              size="small"
              style={{ width: '100%' }}
            >
              View
            </Button>
          </Space>
        )
      }
    }
  ]

  const dataSourceWithKey = transactions.map((i, index) => ({
    ...i,
    key: index
  }))

  const scrollY =
    document.documentElement.scrollHeight - HEADER_HEIGHT - FOOTER_HEIGHT - 100 // must > than 40px
  return (
    <Table
      onChange={(pagination, filters: any, sorter1: any, extra) => {
        setSearcher(filters)
      }}
      loading={isLoading}
      columns={columns}
      dataSource={dataSourceWithKey}
      scroll={{
        y: scrollY
      }}
      pagination={false}
    />
  )
}

export default TransactionsTable
