import { HotKeyToLabelEnum } from 'pages/app/extraction/version-2/types'
import { MenuInfo } from 'rc-menu/lib/interface'
import React from 'react'
import { useEventListener } from 'services/hooks/use-event-listener'

interface Props {
  visible: boolean
  handleLabelText: (info: Partial<MenuInfo>) => string
  x: number
  y: number
  boxWidth: number
  boxHeight: number
}

const DropdownAddBox = (props: Props) => {
  const { visible, handleLabelText, x, y, boxWidth, boxHeight } = props
  const handleKeydown = ({ key }: KeyboardEvent) => {
    if (!visible) return
    const keydown = key.toLowerCase()
    const label = HotKeyToLabelEnum[keydown]
    if (label) handleLabelText(label)
  }

  useEventListener('keydown', handleKeydown)

  return (
    <div
      className={
        'text-box absolute bg-gray-500 opacity-30 hover:opacity-50 border border-solid border-black'
      }
      style={{
        top: y,
        left: x,
        width: boxWidth,
        height: boxHeight,
        cursor: 'crosshair'
      }}
    />
  )
}

export default DropdownAddBox
