import { Switch } from 'antd'
import { ExtractionActions } from 'data/actions'
import { BoxActions, BoxLabelEnum } from 'data/box'
import { BoxSelectors, ExtractionSelectors } from 'data/selectors'
import Field from 'pages/app/extraction/version-1/task/components/field'
import Section from 'pages/app/extraction/version-1/task/components/section'
import {
  LineItemHeader,
  SectionEnum
} from 'pages/app/extraction/version-1/types'
import React from 'react'
import { useDispatch, useSelector } from 'react-redux'

const columnsStyle = {
  [BoxLabelEnum.ITEM_DESCRIPTION]: 'w-7/12 flex flex-col justify-end',
  [BoxLabelEnum.ITEM_CODE]: 'w-2/12',
  [BoxLabelEnum.ITEM_QUANTITY]: 'w-1/12',
  [BoxLabelEnum.ITEM_VALUE]: 'w-2/12 flex flex-col justify-end',
  [BoxLabelEnum.ITEM_PROMO_NAME]: 'w-10/12',
  [BoxLabelEnum.ITEM_PROMO_PRICE]: 'w-2/12'
}

const LineItemsHeader = () => {
  const dispatch = useDispatch()

  const settings = useSelector(ExtractionSelectors.settings)

  const handleOnChange = (checked: boolean, option: string) => {
    const newCheckedList = { ...settings, [option]: checked }
    dispatch(ExtractionActions.setSettings(newCheckedList))
  }

  const isFirstAndLastCols = title =>
    [BoxLabelEnum.ITEM_VALUE, BoxLabelEnum.ITEM_DESCRIPTION].includes(title)

  const settingsProps = {
    [BoxLabelEnum.ITEM_CODE]: 'barcode',
    [BoxLabelEnum.ITEM_QUANTITY]: 'quantity'
  }

  return (
    <div className="flex gap-2">
      {Object.entries(columnsStyle)
        .slice(0, 4)
        .map(([label, style]) => (
          <div key={label} className={style}>
            {!isFirstAndLastCols(label) && (
              <Switch
                defaultChecked={settings[settingsProps[label]]}
                onChange={c => handleOnChange(c, settingsProps[label])}
              />
            )}
            <Field
              label={label}
              wrapperClassName={
                isFirstAndLastCols(label) ? 'flex-grow-0' : 'pt-1'
              }
            />
          </div>
        ))}
    </div>
  )
}

interface LineItemsSectionProps {
  missingLineItems: any[]
  mergeLineItems: any[]
}

const LineItemsSection = ({
  missingLineItems,
  mergeLineItems
}: LineItemsSectionProps) => {
  const dispatch = useDispatch()
  const targetBoxId = useSelector(BoxSelectors.targetId)
  const currentPage = String(useSelector(ExtractionSelectors.currentPage))
  const totalPages = useSelector(ExtractionSelectors.totalReceiptPage)
  const settings = useSelector(ExtractionSelectors.settings)

  const getIsPromoField = (label: BoxLabelEnum) =>
    [BoxLabelEnum.ITEM_PROMO_NAME, BoxLabelEnum.ITEM_PROMO_PRICE].includes(
      label
    )

  const handleSetTargetId = (id: string | number) => {
    targetBoxId !== id
      ? dispatch(BoxActions.setTargetId(id))
      : dispatch(BoxActions.setTargetId(undefined))
  }

  // TODO fix this function
  const handleSetActiveId = (id: string) => {
    dispatch(BoxActions.setActiveId(id))
  }

  const page: string = `${
    isNaN(parseInt(currentPage)) ? 0 : parseInt(currentPage) + 1
  }/${totalPages}`

  return (
    <Section
      title="Line Items"
      isCleared={!missingLineItems.length}
      page={page}
    >
      <div className="flex flex-col gap-2">
        <LineItemsHeader />

        {mergeLineItems.map(entry => {
          const column = [...LineItemHeader[entry.type]]

          const rawRow = column.map(header => ({
            ...entry?.[header],
            label: header,
            isNotRequired:
              (!settings.quantity && header === BoxLabelEnum.ITEM_QUANTITY) ||
              (!settings.barcode && header === BoxLabelEnum.ITEM_CODE) ||
              header === BoxLabelEnum.ITEM_PROMO_PRICE
          }))

          const isFocused = entry.key.includes(targetBoxId)

          const generateCells = () =>
            [
              ...LineItemHeader[SectionEnum.LINE_ITEM],
              ...LineItemHeader[SectionEnum.PROMOTION]
            ].map((header, index) => {
              const content = rawRow.find(content => content.label === header)
              return (
                content && (
                  <Field
                    key={`${header}_${content?.id}_${index}`}
                    id={entry.key?.[0].toString()}
                    wrapperClassName={columnsStyle[header]}
                    value={content?.text || '\u00A0'}
                    isCleared={!!content?.text || content?.isNotRequired}
                    isPromo={getIsPromoField(content?.label)}
                    isPromoNameLabel={
                      content?.label === BoxLabelEnum.ITEM_PROMO_NAME
                    }
                    onClick={
                      entry.key
                        ? () => handleSetTargetId(entry.key[0])
                        : handleSetActiveId
                    }
                  />
                )
              )
            })

          return (
            <div
              key={String(entry.key?.[0])}
              className={`flex gap-2 py-1 px-4 -my-1 -mx-4 ${
                isFocused ? 'bg-blue-500' : 'bg-transparent'
              }`}
            >
              {generateCells()}
            </div>
          )
        })}
      </div>
    </Section>
  )
}

export default LineItemsSection
