import { Empty } from 'antd'
import { EmptyDataSVG } from 'assets/pages/extraction'
import React, { ReactNode } from 'react'

interface Props {
  description: ReactNode
}

const EmptyComponent = ({ description }: Props) => (
  <Empty
    image={<EmptyDataSVG />}
    imageStyle={{ height: '100%' }}
    description={description}
  />
)

export default EmptyComponent
