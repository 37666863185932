import { store } from 'data'

export const axiosReqInterceptor = async request => {
  const interceptorBlacklist = ['unovision']
  if (interceptorBlacklist.some(i => request.baseURL.includes(i))) {
    return request
  }
  // @ts-ignore
  const token = store.getState().firebase.auth.stsTokenManager.accessToken

  request.headers = Object.assign(
    request.headers,
    token ? { Authorization: `Bearer ${token}` } : null
  )
  console.log(`Request: ${request.url}`)
  return request
}

export const axiosResInterceptor = async res => {
  console.log(`Response: ${res.config.url}`)
  return res
}
