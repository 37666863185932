import { Modal } from 'antd'
import { DialogKeys } from 'components/modals/types'
import { OverlayActions } from 'data/actions'
import DefinitionContent from 'pages/app/admin/merchant-definition/modals/components/definition-content'
import { DefinitionContext } from 'pages/app/admin/merchant-definition/modals/context'
import useUpdateDefinition from 'pages/app/admin/merchant-definition/modals/edit-definition/hooks'
import React, { useEffect, useState } from 'react'
import { useDispatch } from 'react-redux'
import { DefinitionType } from 'services/api/requests/notua-ii/types'
import { Color } from 'services/styles/color'

interface Props {
  onOk?: () => void
  data?: DefinitionType
}

const EditDefinitionModal = ({ onOk, data }: Props) => {
  const dispatch = useDispatch()
  const [record, setRecord] = useState(data)
  const [error, setError] = useState(false)

  const [isLoading, handleUpdateDefinition] = useUpdateDefinition()

  const handleCancel = () =>
    dispatch(OverlayActions.hideDialog({ key: DialogKeys.EDIT_DEFINITION }))

  const handleOkClick = async () => {
    if (!error) {
      onOk && onOk()
      await handleUpdateDefinition(record.id, record)
      handleCancel()
    }
  }

  useEffect(() => {
    // prevent too many re-render on typing
    if (!error && !data.merchant) {
      setError(true)
    }
    if (error && !!data.merchant) {
      setError(false)
    }
  }, [data.merchant])

  return (
    <Modal
      title={'Edit Definition'}
      visible
      confirmLoading={false}
      onOk={handleOkClick}
      onCancel={handleCancel}
      okButtonProps={{
        disabled: error,
        loading: isLoading,
        style: {
          width: 200,
          backgroundColor: error ? Color.GREY_400 : '#60a5fa',
          borderRadius: 6,
          height: 40
        }
      }}
      cancelButtonProps={{ type: 'text' }}
      okText={'Confirm'}
      className="top-4"
      width={1000}
    >
      <DefinitionContext.Provider
        value={{ state: record, dispatchDefinition: setRecord }}
      >
        <DefinitionContent />
      </DefinitionContext.Provider>
    </Modal>
  )
}

export default EditDefinitionModal
