enum ExtractionConstants {
  SET_DONE_NUMBER = 'EXTRACTION/SET_DONE_NUMBER',
  SET_RAW_DATA = 'EXTRACTION/SET_RAW_DATA',
  SET_RECEIPT_ID = 'EXTRACTION/SET_RECEIPT_ID',
  SET_CURRENT_PAGE = 'EXTRACTION/SET_CURRENT_PAGE',
  SET_RETAILER_FILTER = 'EXTRACTION/SET_RETAILER_FILTER',
  SET_SETTINGS = 'EXTRACTION/SET_SETTINGS'
}

export default ExtractionConstants
