import { Typography } from 'antd'
import { SelectionActions } from 'pages/app/annotation-tool/reducers/selection/actions'
import { TaskContext } from 'pages/app/annotation-tool/task/context'
import React, { useContext } from 'react'
import { Field } from 'services/api/requests/types'
import { toStartCase } from 'services/helpers/canonicalization'
import {
  AnnotationHotkeys,
  AnnotationLabelEnum,
  ITEM_FIELDS
} from '../../types'
import FieldCell from '../components/field-cell'
import Section from '../components/section'

const { Text } = Typography

const RegularFieldSection = () => {
  const { state: parentState, dispatchTask: parentDispatcher } = useContext(
    TaskContext
  )
  const { record, currentPage, merchantDefinition } = parentState
  const currentPageData = record.pages[currentPage]
  const { fields } = currentPageData
  const totalPages = record?.pages?.length || 0

  const handleDispatchTask = (action, value) =>
    parentDispatcher({ type: action, payload: value })
  const handleSetSourceId = (id: string) =>
    handleDispatchTask(SelectionActions.SET_SOURCE_ID, id)

  // single page only
  const missingRegularFields = merchantDefinition?.reduce(
    (acc, curr) =>
      curr?.required &&
      !fields.find(i => i.label === curr.label) &&
      !ITEM_FIELDS.includes(curr.label as AnnotationLabelEnum)
        ? [...acc, { label: curr.label, text: '' }]
        : acc,
    []
  )

  // render
  const renderRow = (data: Partial<Field>[]) =>
    merchantDefinition?.map(definition => {
      const isItem = ITEM_FIELDS.includes(definition.label)
      let highlights = data.reduce(
        (acc, curr) =>
          curr.label === definition.label
            ? [...acc, { ...curr, name: definition.name }]
            : acc,
        []
      )
      if (!highlights.length) {
        highlights = [definition]
      }

      const annotationHotkey = AnnotationHotkeys[definition.label]
      return !isItem
        ? highlights.map((highlight, idx) => (
            <div
              className="flex flex-row w-full pb-2"
              key={`${highlight}_${highlight?.id}_${idx}`}
            >
              <div className="w-1/3">
                <Text strong>
                  {highlight?.name || toStartCase(highlight.label)}
                </Text>
              </div>
              <FieldCell
                id={highlight?.id}
                wrapperClassName={'w-2/3'}
                placeholder={annotationHotkey && `Hotkey ${annotationHotkey}`}
                value={highlight?.text}
                isError={definition?.required && !highlight?.text}
                isDisabled={definition?.disabled}
                onClick={() =>
                  highlight?.id ? handleSetSourceId(highlight?.id) : {}
                }
              />
            </div>
          ))
        : null
    })

  const pageText: string = `${currentPage + 1}/${totalPages}`
  return (
    <Section
      title="Regular Field"
      page={pageText}
      isComplete={!missingRegularFields?.length}
    >
      {renderRow(fields)}
    </Section>
  )
}

export default RegularFieldSection
