import { BoxLabelEnum } from 'data/box'
import { getBoxColorLight } from 'services/styles/color'

export const FieldsKeyDictionary = [
  'receiptType',
  'receiptMerchantName',
  'receiptStorePhone',
  'receiptStoreAddress',
  'receiptStoreName',
  'receiptStoreId',
  'receiptPurchaseDate',
  'receiptPurchaseTime',
  'receiptTotalValue',
  'receiptSubtotalValue',
  'receiptTotalTax',
  'receiptTip',
  'receiptCashRegisterId',
  'receiptTransactionId',
  // 'receiptLineItems',
  'receiptTotalDiscount'
]

// msft annotation
export const FixedExtractionList = [
  'ReceiptLineItems',
  // 'ReceiptType', // doesnt have bounding box
  'ReceiptMerchantName',
  'ReceiptStorePhone',
  'ReceiptStoreAddress',
  'ReceiptStoreName',
  'ReceiptStoreId',
  'ReceiptPurchaseDate',
  'ReceiptPurchaseTime',
  'ReceiptTotalValue',
  'ReceiptSubtotalValue',
  'ReceiptTotalTax',
  'ReceiptTip',
  'ReceiptCashRegisterId',
  'ReceiptTransactionId',
  'ReceiptTotalDiscount'
]

export const ExtractionColor = {
  ReceiptLineItems: '#B4FEB6',
  ReceiptMerchantName: getBoxColorLight(BoxLabelEnum.RECEIPT_MERCHANT_NAME),
  ReceiptStoreId: getBoxColorLight(BoxLabelEnum.RECEIPT_MERCHANT_NAME),
  ReceiptStoreName: getBoxColorLight(BoxLabelEnum.RECEIPT_MERCHANT_NAME),
  ReceiptStorePhone: getBoxColorLight(BoxLabelEnum.RECEIPT_MERCHANT_NAME),
  ReceiptStoreAddress: getBoxColorLight(BoxLabelEnum.RECEIPT_MERCHANT_NAME),
  ReceiptPurchaseDate: getBoxColorLight(BoxLabelEnum.RECEIPT_PURCHASE_DATE),
  ReceiptPurchaseTime: getBoxColorLight(BoxLabelEnum.RECEIPT_PURCHASE_TIME),
  ReceiptTotalValue: getBoxColorLight(BoxLabelEnum.RECEIPT_TOTAL_VALUE),
  ReceiptSubtotalValue: getBoxColorLight(BoxLabelEnum.RECEIPT_SUBTOTAL_VALUE),
  ReceiptTotalTax: getBoxColorLight(BoxLabelEnum.RECEIPT_TOTAL_TAX),
  ReceiptTip: getBoxColorLight(BoxLabelEnum.RECEIPT_TOTAL_TAX),
  ReceiptTotalDiscount: getBoxColorLight(BoxLabelEnum.RECEIPT_SUBTOTAL_VALUE),
  ReceiptCashRegisterId: getBoxColorLight(BoxLabelEnum.RECEIPT_MERCHANT_NAME),
  ReceiptTransactionId: getBoxColorLight(BoxLabelEnum.RECEIPT_MERCHANT_NAME),
  ReceiptId: getBoxColorLight(BoxLabelEnum.RECEIPT_MERCHANT_NAME)
}
