import { Button } from 'antd'
import Table from 'components/table'
import { AppActions, AuditActions } from 'data/actions'
import { AppSelectors } from 'data/selectors'
import React from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { useHistory } from 'react-router'
import { AppSubRoutes } from 'routes/app/routes'
import { ResponseUsersOverviewType } from 'services/api/requests/admin'

interface Props {
  data: ResponseUsersOverviewType
}

const UsersTable = ({ data }: Props) => {
  const dispatch = useDispatch()
  const history = useHistory()
  const isSiderOpen = useSelector(AppSelectors.siderState)

  const handleNavigationClick = (userId: number) => () => {
    if (isSiderOpen) dispatch(AppActions.toggleSider())
    dispatch(AuditActions.resetAuditState())
    history.push(AppSubRoutes.AUDIT.HOME + `/${userId}`)
  }

  return (
    <Table>
      <Table.Header titles={['ID', 'Email', '']} />
      <tbody>
        {data.user.map(({ user_id, email }) => (
          <Table.Row key={user_id}>
            <Table.Cell label={user_id.toString()} />
            <Table.Cell label={email} />
            <Table.Cell
              render={
                <Button
                  onClick={handleNavigationClick(user_id)}
                  size="large"
                  className="w-11/12 text-white rounded bg-blue-400"
                >
                  Audit
                </Button>
              }
            />
          </Table.Row>
        ))}
      </tbody>
    </Table>
  )
}

export default UsersTable
