import { OverlaySelectors } from 'data/selectors'
import React from 'react'
import { useSelector } from 'react-redux'

const Overlay = ({ children }) => <>{children}</>

const Dialog = () => {
  const dialogs = useSelector(OverlaySelectors.dialogs)
  return <div>{dialogs?.[0]?.component}</div>
}
Overlay.Dialog = Dialog

export default Overlay
