import {
  BoundingPoly,
  Field,
  Page,
  Vertex,
  Word
} from 'services/api/requests/types'
import { traverseLinks } from 'services/helpers/graph'
import {
  AnnotationLabelEnum,
  CATEGORY_TYPE_LINKABLE,
  ITEM_FIELDS,
  ITEM_TAX_TYPE_LINKABLE,
  ITEM_TYPE_LINKABLE,
  LabelToSectionTitle,
  LOYALTY_BALANCE_TYPE_LINKABLE,
  LOYALTY_EARNED_TYPE_LINKABLE,
  LOYALTY_REDEEMED_TYPE_LINKABLE,
  OTHER_TYPE_LINKABLE,
  PROMO_TYPE_LINKABLE,
  SectionEnum,
  SUB_ITEM_TYPE_LINKABLE,
  SUBTOTAL_TYPE_LINKABLE
} from './types'

function arrayMin(arr: any[]) {
  return arr.reduce(function (p, v) {
    return p < v ? p : v
  })
}

function arrayMax(arr: any[]) {
  return arr.reduce(function (p, v) {
    return p > v ? p : v
  })
}

export const mergeBoundingBoxes = (
  targetBoxes: BoundingPoly[]
): { vertices: Vertex[] } => {
  const maxX = arrayMax(
    targetBoxes.map(box => arrayMax(box.vertices.map(vertex => vertex.x)))
  )
  const maxY = arrayMax(
    targetBoxes.map(box => arrayMax(box.vertices.map(vertex => vertex.y)))
  )
  const minX = arrayMin(
    targetBoxes.map(box => arrayMin(box.vertices.map(vertex => vertex.x)))
  )
  const minY = arrayMin(
    targetBoxes.map(box => arrayMin(box.vertices.map(vertex => vertex.y)))
  )

  const VertexTopLeft = { x: minX, y: minY }
  const VertexTopRight = { x: maxX, y: minY }
  const VertexBottomRight = { x: maxX, y: maxY }
  const VertexBottomLeft = { x: minX, y: maxY }

  return {
    vertices: [
      VertexTopLeft,
      VertexTopRight,
      VertexBottomRight,
      VertexBottomLeft
    ]
  }
}

export const verticesToId = (vertices: Vertex[]) =>
  vertices.reduce(
    (acc, curr, index) =>
      index === vertices.length - 1
        ? acc + `${curr.x * 10}-${curr.y * 10}`
        : acc + `${curr.x * 10}-${curr.y * 10}_`,
    ''
  )

// TODO type for return value
export const filterHighLightsProperties = (
  highlights: Field[],
  conditions: AnnotationLabelEnum[]
) =>
  highlights
    .filter(box => conditions.find(condition => condition === box.label))
    .reduce<any>((acc, lineItem) => {
      const linkedProps = traverseLinks(highlights, lineItem.links)

      const linkKeys = linkedProps
        ? Object.values<any>(linkedProps).map(item => item?.id)
        : []

      const historyKey = acc.flatMap(item => item.key)
      const lineKey = Array.from(new Set([lineItem.id, ...linkKeys]))

      return lineKey.every(val => historyKey.includes(val))
        ? acc
        : [
            ...acc,
            {
              type:
                LabelToSectionTitle?.[lineItem.label as AnnotationLabelEnum] ||
                SectionEnum.MERCHANT,
              key: lineKey,
              yPosition: lineItem.boundingPoly.vertices[0].y,
              [lineItem.label as AnnotationLabelEnum]: lineItem,
              ...linkedProps
            }
          ]
    }, [])

// TODO type
export const sortHighLightsByPosition = (highlights: any[]) =>
  highlights.slice().sort((b, a) => b.yPosition - a.yPosition)

export const getNewField = (currWords, popupList): Field => {
  const selectedBoxes = currWords.reduce(
    (acc: any, curr: Word, index: number) =>
      popupList[index] !== null && popupList[index] !== undefined
        ? {
            text: acc.text + ' ' + curr.text,
            bounding: [...acc.bounding, curr.boundingPoly],
            fieldElements: [...acc.fieldElements, curr.id]
          }
        : acc,
    { text: '', bounding: [], fieldElements: [] }
  )

  const newBoxBoundingPoly = mergeBoundingBoxes(selectedBoxes.bounding)
  const newId = verticesToId(newBoxBoundingPoly.vertices)

  return {
    id: newId,
    text: selectedBoxes.text,
    boundingPoly: newBoxBoundingPoly,
    fieldElements: selectedBoxes.fieldElements,
    label: null,
    links: []
  }
}

export const getSinglePageLineItem = (fields: Field[]) => {
  const lineItemEntries = filterHighLightsProperties(fields, ITEM_TYPE_LINKABLE)

  const promoEntries = filterHighLightsProperties(fields, PROMO_TYPE_LINKABLE)

  const otherEntries = filterHighLightsProperties(fields, OTHER_TYPE_LINKABLE)

  const subItemEntries = filterHighLightsProperties(
    fields,
    SUB_ITEM_TYPE_LINKABLE
  )

  const itemCategoryEntries = filterHighLightsProperties(
    fields,
    CATEGORY_TYPE_LINKABLE
  )

  const itemSubtotalEntries = filterHighLightsProperties(
    fields,
    SUBTOTAL_TYPE_LINKABLE
  )

  const itemTaxEntries = filterHighLightsProperties(
    fields,
    ITEM_TAX_TYPE_LINKABLE
  )

  const loyaltyEarnedEntries = filterHighLightsProperties(
    fields,
    LOYALTY_EARNED_TYPE_LINKABLE
  )

  const loyaltyRedeemedEntries = filterHighLightsProperties(
    fields,
    LOYALTY_REDEEMED_TYPE_LINKABLE
  )

  const loyaltyBalanceEntries = filterHighLightsProperties(
    fields,
    LOYALTY_BALANCE_TYPE_LINKABLE
  )

  return sortHighLightsByPosition([
    ...lineItemEntries,
    ...promoEntries,
    ...otherEntries,
    ...subItemEntries,
    ...itemCategoryEntries,
    ...itemSubtotalEntries,
    ...itemTaxEntries,
    ...loyaltyEarnedEntries,
    ...loyaltyRedeemedEntries,
    ...loyaltyBalanceEntries
  ])
}

export const getPagedLineItems = (pages: Page[]): any[][] =>
  pages.map(page => {
    const { fields } = page
    return getSinglePageLineItem(fields)
  })

export const getIncompleteLineItems = (pages: Page[], merchantDefinition) => {
  const requiredLineFieldsInMerchant = ITEM_FIELDS.filter(field => {
    const requiredField = merchantDefinition?.find(i => i.label === field)
    return requiredField?.required
  })
  return getPagedLineItems(pages).map(i =>
    i
      .map((field, index) => ({
        ...field,
        lineNumber: index + 1,
        errors: requiredLineFieldsInMerchant.filter(i => !(i in field))
      }))
      .filter(i => i.type === SectionEnum.LINE_ITEM)
      .filter(i => i.errors.length)
  )
}

export const getIncompleteReceiptFields = (
  pages: Page[],
  merchantDefinition
) => {
  const merchantRequiredFields = merchantDefinition?.reduce(
    (acc, curr) =>
      curr?.required && !ITEM_FIELDS.includes(curr.label as AnnotationLabelEnum)
        ? [...acc, curr.label]
        : acc,
    []
  )

  const result = pages.reduce((pagesAcc, page) => {
    const regularFieldsInPage = page.fields.reduce((pageAcc, field) => {
      if (!ITEM_FIELDS.includes(field.label)) {
        return [...pageAcc, field.label]
      }
      return pageAcc
    }, [])
    return [...pagesAcc, ...regularFieldsInPage]
  }, [])

  return merchantRequiredFields.filter(i => !result.includes(i))
}
