import axios from 'axios'
import { PermissionEnum } from 'data/reducers'
import config from 'services/config'
import { formatSqlValue, reduceImageQuality } from 'services/helpers/misc'
import {
  axiosReqInterceptor,
  axiosResInterceptor
} from 'services/integrations/axios'

const ApprovalApiClient = axios.create({
  baseURL: config.baseUrl + 'noctua-ii/approval/'
})

ApprovalApiClient.interceptors.request.use(axiosReqInterceptor)
ApprovalApiClient.interceptors.response.use(axiosResInterceptor)

export enum ReceiptStatusEnum {
  OK = 'OK',
  PENDING = 'PENDING',
  FAILED = 'FAILED',
  APPROVED = 'APPROVED',
  REJECTED = 'REJECTED',
  IN_REVIEW = 'IN_REVIEW',
  SKIP = 'SKIP',
  USER_EMAIL_MISSING = 'USER_EMAIL_MISSING',
  DELETED = 'DELETED'
}

export type PendingReceiptType = {
  id: number | string
  status: ReceiptStatusEnum
  notes?: any
  points?: any
  content: string[]
  compressedContent?: string[] // frontend props: reduce content quality
  userId: string
  timestampsCreated: string
  timestampsUpdated: string
  annotations?: string
  source: string
  total?: number
  receiptDateTime: string
  receiptAnnotationId: number
  rejectionReason: string
  reviewed: boolean
  unovision: string
  deleted: boolean
  userEdited: boolean
  shakes?: any
  vendorName?: any
  creditCardId?: any
  loyaltyCardId?: any
  adultMale?: any
  adultFemale?: any
  boy?: any
  girl?: any
  rating?: any
  mergedTo?: any
  ingested: boolean
  giftCardId?: any
  attachments?: string[]
  doNotIngest?: any
  userSelectedCategory?: any
  countryCode: string
  uploadedTime: string
  uploadedDate: string
  version: string
  purchaseDate: string
  purchaseTime: string
  backFilledData?: any
  potentialDuplicate?: any
  duplicateOf?: any
  localScrapeDate?: any
  localScrapeTime?: any

  // Email type
  provider?: string
  providerMessageId?: string
  messageFrom?: string
  messageTo?: string
  messageTimestamp?: string // date & time
  messageTitle?: string
  downloadedEmailUrl?: string
  emailAccountEmail?: string
  attachment?: any
  orderId?: any
  onlineOnline?: any
  onlineOffline?: any
  offlineOnline?: any
  receiptId?: number
  skipReason?: any
  transactionTimestamp?: string
  email?: string
  name?: string
}

export type ResGetPendingReceiptType = {
  count: number
  next: boolean
  previous: boolean
  data: PendingReceiptType[]
}

type GetEmailPostBody = {
  email?: string
  seed?: string
}

export const getApprovalOverview = async () => {
  // const { data } = await ApprovalApiClient.get<ResGetPendingReceiptType>(
  //   'overview'
  // )
  const mockData = {
    AU: {
      pending: 99,
      online: 999,
      progress: 50,
      requirePermissions: [PermissionEnum.OFFLINE]
    },
    US: {
      pending: 123,
      online: 123,
      progress: 90,
      requirePermissions: [PermissionEnum.OFFLINE]
    },
    BR: {
      pending: 123,
      online: 123,
      progress: 90,
      requirePermissions: [PermissionEnum.OFFLINE]
    },
    CA: {
      pending: 123,
      online: 123,
      progress: 90,
      requirePermissions: [PermissionEnum.OFFLINE]
    },
    EMAIL: {
      pending: 123,
      online: 123,
      progress: 90,
      requirePermissions: [PermissionEnum.EMAIL]
    }
  }
  return mockData
}

export const lockReceipt = async (
  receipt_id: string,
  source: 'receipt' | 'email'
) => {
  const { data } = await ApprovalApiClient.post('status', {
    receipt_id,
    source
  })

  return data
}

export const unlockReceipt = async (
  receipt_id: string,
  source: 'receipt' | 'email'
) => {
  try {
    const { data } = await ApprovalApiClient.delete(
      `/status?receipt_id=${receipt_id}&source=${source}`
    )
    return data
  } catch (e) {
    console.warn(`Attempt to unlock receipt id ${receipt_id} from ${source}`)
  }
}

export const getPendingPhysicalReceipt = async (country_code: string) => {
  const { data } = await ApprovalApiClient.post<ResGetPendingReceiptType>(
    'receipt',
    { country_code }
  )

  if (!data?.data?.[0]) throw new Error('No receipt found')

  await lockReceipt(data.data[0].id + '', 'receipt')

  return {
    ...data,
    data: data.data.map(receiptData => ({
      ...receiptData,
      compressedContent: receiptData.content.map(i => reduceImageQuality(i))
    }))
  }
}

export const updatePhysicalReceipt = async (record: any) => {
  // clean data
  delete record.compressedContent

  const formattedSQLRecord = formatSqlValue(record)

  const { data } = await ApprovalApiClient.patch('receipt', {
    keys: { id: record.id },
    data: formattedSQLRecord
  })

  await unlockReceipt(record.id + '', 'receipt')

  return data
}

export const getPendingEmailReceipt = async (postBody?: GetEmailPostBody) => {
  const { data } = await ApprovalApiClient.post<ResGetPendingReceiptType>(
    'email',
    postBody
  )

  if (!data?.data?.[0]) throw new Error('No receipt found')

  await lockReceipt(data.data[0].id + '', 'email')

  return data
}

export const updateEmailReceipt = async (record: any) => {
  const formattedValue = formatSqlValue(record)

  const { data } = await ApprovalApiClient.patch('email', {
    keys: { id: record.id },
    data: formattedValue
  })

  await unlockReceipt(record.id + '', 'email')

  return data
}

export const deletePhysicalReceipt = async (id: number) => {
  const { data } = await ApprovalApiClient.delete(
    `status?receipt_id=${id}&source=receipt`
  )

  await unlockReceipt(id + '', 'receipt')

  return data
}

export const deleteEmailReceipt = async (id: number) => {
  const { data } = await ApprovalApiClient.delete(
    `status?receipt_id=${id}&source=receipt`
  )

  await unlockReceipt(id + '', 'email')

  return data
}

export type RetailersType = {
  allowLineItemMatching?: boolean
  autoCreated?: boolean
  canAutoApprove?: boolean
  dailyLimit?: number
  name: string
  online: boolean
  requireUserVerification: boolean
  retailerTypeType?: string
  shakesPerReceipt: number
  shouldIssuePoints: boolean
  timestampsCreated: string
  timestampsUpdated: string
  type?: string
}

// throw error to optimize cache success query
export const searchRetailers = async (searchText: string) => {
  const { data } = await ApprovalApiClient.get<RetailersType[]>(
    `retailer/receipt?search=${searchText}`
  )

  if (!data?.length) throw Error('Retailer not found')

  return data
}

export type EmailRetailersType = {
  name: string
  email: string
}

// throw error to optimize cache success query
export const searchEmailRetailers = async (searchText: string) => {
  const { data } = await ApprovalApiClient.get<EmailRetailersType[]>(
    `retailer/email?search=${searchText}`
  )

  if (!data?.length) throw Error('Retailer not found')

  return data
}

export type UserType = {
  user: {
    id: string
    drawCount: number
    points: number
    levelId: number
    pointsMultiplier: number
    syncedNotification: boolean
    notifiedOfInactivity: boolean
    notifiedOf12hInactivity: boolean
    notifiedOf24hInactivity: boolean
    acceptedTerms: boolean
    hasSetupConnectedEmails: boolean
    claimedWeMoney: any
    lastDeactivatedDate: any
    countryCode: string
    profileCheckRequired: boolean
    numberOfNotifications: number
    hasRedeemedMailboxConnectionBonus: boolean
    panelistId: any
    nielsenId: any
    skipNielsenId: boolean
    timezone: any
    timestamps: {
      created: string
      updated: string
    }
  }
  type: string
}

// throw error to optimize cache success query
export const searchUsers = async (searchText: string): Promise<UserType[]> => {
  const loginPatternRule = /(.+)@(.+)\.(.{2,})/

  if (loginPatternRule.test(searchText)) {
    const { data } = await ApprovalApiClient.get<UserType>(
      `user/exists?email=${searchText}`
    )

    if (!data) throw Error('User not found')

    return [data]
  }

  throw Error('Invalid search input')
}

type NewRetailerPostBody = {
  name: string
  type: 'OFFLINE' | 'ONLINE'
}

export const addNewRetailer = async (newVendor: NewRetailerPostBody) => {
  const defaultVendorProps = {
    shouldIssuePoints: true,
    shakesPerReceipt: 0,
    dailyLimit: 3,
    canAutoApprove: true,
    requireUserVerification: true,
    allowLineItemMatching: false
  }
  const formattedPostBody = {
    ...defaultVendorProps,
    ...newVendor,
    online: newVendor.type === 'ONLINE'
  }

  const { data } = await ApprovalApiClient.post('retailer', formattedPostBody)

  return data
}
