import axios from 'axios'
import {
  axiosReqInterceptor,
  axiosResInterceptor
} from 'services/integrations/axios'

const EmailSubjectWhitelistApiClient = axios.create({
  baseURL: 'https://us.api.staging.datatwix.com'
})

EmailSubjectWhitelistApiClient.interceptors.request.use(axiosReqInterceptor)
EmailSubjectWhitelistApiClient.interceptors.response.use(axiosResInterceptor)

interface IGetTransactionByRetailerReqPayload {
  startDate: string // 'yyyy-mm-dd'
  endDate: string // 'yyyy-mm-dd'
  retailerName: string
  countryCode: string
  subjectRegex?: string
  userId: string
}

interface IGetTransactionByIdReqPayload {
  transactionIds: string // 23370124,23451091
  countryCode: string
  userId: string
}

export interface IEmailTransaction {
  transactionId: string
  transactionDate: string
  transactionTime: string
  htmlLink: string
  subjectWhitelisted: boolean
  subject: string
}

export interface IGetTransactionsResPayload {
  regex: string
  panelistId: string
  retailerName: string
  transactions: IEmailTransaction[]
}

export const getTransactionsByRetailer = async (
  payload: IGetTransactionByRetailerReqPayload
) => {
  const { data } = await EmailSubjectWhitelistApiClient.post(
    '/apollo-visualizer-fetch',
    payload
  )

  return data
}

export const getTransactionsById = async (
  payload: IGetTransactionByIdReqPayload
) => {
  const { data } = await EmailSubjectWhitelistApiClient.post(
    '/apollo-visualizer-retrieve',
    payload
  )

  return data
}

export const getRedactedHtml = async link => {
  const { data } = await axios.get(link)
  return data
}
