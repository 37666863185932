import SplashScreen from 'components/splash'
import { AuthSelectors } from 'data/selectors'
import React from 'react'
import { useSelector } from 'react-redux'
import { isLoaded } from 'react-redux-firebase'

const AuthIsLoaded = ({ children }) => {
  const auth = useSelector(AuthSelectors.firebaseAuthState)
  if (!isLoaded(auth)) return <SplashScreen />
  return children
}

export default AuthIsLoaded
