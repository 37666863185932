import { BoxLabelEnum, BoxProps } from 'data/box'
import { LabelToSectionTitle } from 'pages/app/extraction/version-1/types'
import { Vertex } from 'services/api/requests/types'
import { traverseLinks } from 'services/helpers/graph'

type Box = { vertices: { x: number; y: number }[] }

function arrayMin(arr: any[]) {
  return arr.reduce(function (p, v) {
    return p < v ? p : v
  })
}

function arrayMax(arr: any[]) {
  return arr.reduce(function (p, v) {
    return p > v ? p : v
  })
}

export const mergeBoundingBoxes = (
  targetBoxes: Box[]
): { vertices: Vertex[] } => {
  const maxX = arrayMax(
    targetBoxes.map(box => arrayMax(box.vertices.map(vertex => vertex.x)))
  )
  const maxY = arrayMax(
    targetBoxes.map(box => arrayMax(box.vertices.map(vertex => vertex.y)))
  )
  const minX = arrayMin(
    targetBoxes.map(box => arrayMin(box.vertices.map(vertex => vertex.x)))
  )
  const minY = arrayMin(
    targetBoxes.map(box => arrayMin(box.vertices.map(vertex => vertex.y)))
  )

  const VertexTopLeft = { x: minX, y: minY }
  const VertexBottomRight = { x: maxX, y: maxY }

  return {
    vertices: [VertexTopLeft, VertexBottomRight]
  }
}

export const verticesToId = (vertices: Vertex[]) =>
  vertices.reduce(
    (acc, curr, index) =>
      index === vertices.length - 1
        ? acc + `${curr.x * 10}-${curr.y * 10}`
        : acc + `${curr.x * 10}-${curr.y * 10}_`,
    ''
  )

export const pathToId = (dAttribute: string) =>
  verticesToId(
    // TODO - fix for this receipt 3310686
    dAttribute?.split('L').map(token => {
      const [cleanX, cleanY] = token.replace(/M|z/g, '').split(',')
      return { x: Number(cleanX), y: Number(cleanY) }
    })
  )

// TODO type for return value
export const filterHighLightsProperties = (
  highlights: BoxProps[],
  conditions: BoxLabelEnum[]
) =>
  highlights
    .filter(box => conditions.find(condition => condition === box.label))
    .reduce<any>((acc, lineItem) => {
      const linkedProps = traverseLinks(highlights, lineItem.links)

      const linkKeys = linkedProps
        ? Object.values<any>(linkedProps).map(item => item?.id)
        : []

      const historyKey = acc.flatMap(item => item.key)
      const lineKey = Array.from(new Set([lineItem.id, ...linkKeys]))

      return lineKey.every(val => historyKey.includes(val))
        ? acc
        : [
            ...acc,
            {
              type: LabelToSectionTitle[lineItem.label],
              key: lineKey,
              yPosition: lineItem.boundingPoly.vertices[0].y,
              [lineItem.label]: lineItem,
              ...linkedProps
            }
          ]
    }, [])

// TODO type
export const sortHighLightsByPosition = (highlights: any[]) =>
  highlights.slice().sort((b, a) => b.yPosition - a.yPosition)
