import LoadingRows from 'components/skeleton/loading-rows'
import StandardBreadcrumb from 'components/standard-breadcrumb'
import TasksTable from 'pages/app/pii-redaction/home/components/tasks-table'
import React from 'react'
import { useQuery } from 'react-query'
import { QueryKeys } from 'services/api/query-key'
import { Noctua2PiiRedactionApi } from 'services/api/requests/notua-ii'

const PiiRedactionHomePage = () => {
  const { isLoading, data } = useQuery(
    QueryKeys.PiiRedactionOverview,
    Noctua2PiiRedactionApi.getPiiRedactionOverview,
    { initialData: [] }
  )
  return (
    <div className="p-10">
      <StandardBreadcrumb />
      <h3 className="mb-4">Pii Redaction</h3>
      {!isLoading && data?.length ? (
        <TasksTable data={data} />
      ) : (
        <LoadingRows />
      )}
    </div>
  )
}

export default PiiRedactionHomePage
