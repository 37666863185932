import { Modal } from 'antd'
import React from 'react'
import { useMutation } from 'react-query'
import { Noctua2LabelApi } from 'services/api/requests/notua-ii'

const ReportErrorContent = ({
  id,
  action
}: {
  id?: string | number
  action: string
}) => {
  return id ? (
    <>
      <p>Something went wrong while trying to {action} this receipt.</p>
      <p>Please contact support.</p>
      <p>#Receipt Id: {id}</p>
    </>
  ) : (
    <>
      <p>Something went wrong while trying to {action} this receipt.</p>
      <p>Receipt not found.</p>
      <p>Please contact support.</p>
    </>
  )
}

export const useHandleSubmitAnnotation = (): [
  boolean,
  (id, putBody) => Promise<any>
] => {
  const { mutateAsync, isLoading } = useMutation(
    Noctua2LabelApi.updateReceiptAnnotation
  )

  const handleSubmit = async (id, putBody) => {
    if (id) {
      await mutateAsync(
        { id, putBody },
        {
          onError: () => {
            Modal.error({
              title: 'Submission Error!',
              content: <ReportErrorContent id={id} action="submit" />
            })
          }
        }
      )
    } else {
      Modal.error({
        title: 'Error!',
        content: <ReportErrorContent action="submit" />
      })
    }
  }

  return [isLoading, handleSubmit]
}

export const useHandleRejectAnnotation = (): [
  boolean,
  (id, reason) => Promise<any>
] => {
  const { mutateAsync, isLoading } = useMutation(
    Noctua2LabelApi.rejectReceiptAnnotation
  )

  const handleReject = async (id, reason) => {
    if (id) {
      await mutateAsync(
        { id, reason },
        {
          onError: () => {
            Modal.error({
              title: 'Reject Error!',
              content: <ReportErrorContent id={id} action={'reject'} />
            })
          }
        }
      )
    } else {
      Modal.error({
        title: 'Error!',
        content: <ReportErrorContent action={'reject'} />
      })
    }
  }

  return [isLoading, handleReject]
}

export const useHandleSkipAnnotation = (): [boolean, (id) => Promise<any>] => {
  const { mutateAsync, isLoading } = useMutation(
    Noctua2LabelApi.skipReceiptAnnotation
  )

  const handleSkip = async id => {
    if (id) {
      await mutateAsync(id, {
        onError: () => {
          Modal.error({
            title: 'Skip Error!',
            content: <ReportErrorContent id={id} action={'skip'} />
          })
        }
      })
    } else {
      Modal.error({
        title: 'Error!',
        content: <ReportErrorContent action={'reject'} />
      })
    }
  }

  return [isLoading, handleSkip]
}
