import LoadingRows from 'components/skeleton/loading-rows'
import React from 'react'
import { useQuery } from 'react-query'
import { ResponseUsersOverviewType } from 'services/api/requests/admin'
import { Noctua2AdminApi } from 'services/api/requests/notua-ii'
import UsersTable from './components/users-table'

const AuditHomePage = () => {
  const { isFetching, data } = useQuery<ResponseUsersOverviewType>(
    'user-overview',
    Noctua2AdminApi.getUsersOverview,
    { initialData: { user: [] } }
  )
  return (
    <div className="p-10">
      <h3 className="mb-4">Audit Freelancers</h3>
      {!isFetching ? <UsersTable data={data} /> : <LoadingRows />}
    </div>
  )
}

export default AuditHomePage
