import {
  AutoComplete,
  Col,
  DatePicker,
  Input,
  InputNumber,
  Row,
  Select,
  Tag
} from 'antd'
import ValidateTimeInput from 'components/time-input'
import React, { useContext, useEffect, useState } from 'react'
import { useQuery, useQueryClient } from 'react-query'
import { QueryKeys } from 'services/api/query-key'
import { Noctua2ApprovalApi } from 'services/api/requests/notua-ii'
import {
  EmailRetailersType,
  UserType
} from 'services/api/requests/notua-ii/approval'
import { compareCaseInsensitive, isSubStringOf } from 'services/helpers/string'
import { formatDate, formatTime } from 'services/helpers/time'
import useDebounceEvent from 'services/hooks/use-debounce-event'
import { TaskContext } from '../context'
import { RecordActions } from '../reducers/actions'

// Note: Select component doesn't allow object
const RETAILER_SEPARATOR = ' | '

const TaskContent = () => {
  const { state: parentState, dispatchTask: parentDispatcher } = useContext(
    TaskContext
  )

  const handleDispatchTask = (action, value = null) =>
    parentDispatcher({ type: action, payload: value })

  // fetch next receipt
  const resetWorkingState = () =>
    handleDispatchTask(RecordActions.RESET_WORKING_STATE)

  const { record } = parentState

  const {
    total,
    name,
    orderId,
    messageTo,
    email,
    rejectionReason,
    status,
    id,
    receiptId,
    notes,
    receiptDateTime
    // vendorName,
    // purchaseTime
  } = record

  const dateReg = /\d{4}\-\d{2}\-\d{2}/g
  const timeReg = /(\d{2}:\d{2}:\d{2})/g

  const [rejectionSearch, setRejectionSearch] = useState<string | null>(null)

  const [searchRetailerText, setSearchRetailerText] = useState<string>('')
  const [searchUserText, setSearchUserText] = useState<string>('')

  const queryClient = useQueryClient()
  const cachedSearchRetailersOptions: EmailRetailersType[] =
    queryClient.getQueryData([QueryKeys.SearchEmailRetailer]) || []

  const cachedSearchUserOptions: UserType[] =
    queryClient.getQueryData([QueryKeys.SearchUser]) || []

  const {
    isFetching: isSearchingRetailer,
    refetch: refetchSearchRetailerQuery
  } = useQuery(
    [QueryKeys.SearchEmailRetailer],
    () => Noctua2ApprovalApi.searchEmailRetailers(searchRetailerText),
    {
      retry: 0,
      enabled: false,
      onSuccess: searchResult => {
        const newSearchFound = searchResult.filter(
          newOption =>
            !cachedSearchRetailersOptions.find(prevOptions =>
              compareCaseInsensitive(
                prevOptions.email + '',
                newOption.email + ''
              )
            )
        )

        if (newSearchFound.length) {
          queryClient.setQueryData(
            [QueryKeys.SearchEmailRetailer],
            Array.from(
              new Set([...cachedSearchRetailersOptions, ...newSearchFound])
            )
          )
        }
      }
    }
  )

  const {
    isFetching: isSearchingUser,
    refetch: refetchSearchUserQuery
  } = useQuery(
    [QueryKeys.SearchUser],
    () => Noctua2ApprovalApi.searchUsers(searchUserText),
    {
      retry: 0,
      enabled: false,
      onSuccess: searchResult => {
        const newSearchFound = searchResult.filter(
          newOption =>
            !cachedSearchUserOptions.find(prevOptions =>
              compareCaseInsensitive(prevOptions.user.id, newOption.user.id)
            )
        )

        if (newSearchFound.length) {
          queryClient.setQueryData(
            [QueryKeys.SearchUser],
            Array.from(new Set([...cachedSearchUserOptions, ...newSearchFound]))
          )
        }
      }
    }
  )

  // NOT USE
  // const { mutateAsync: mutateRetailer } = useMutation(
  //   Noctua2ApprovalApi.addNewRetailer
  // )
  // const { mutateAsync: deleteReceipt } = useMutation(
  //   Noctua2ApprovalApi.deleteEmailReceipt
  // )
  // const {
  //   mutateAsync: mutateReceipt,
  //   isLoading: isPatchingReceipt
  // } = useMutation(Noctua2ApprovalApi.updateEmailReceipt)

  const debouncedSearchRetailerVendor = useDebounceEvent(
    async () => await refetchSearchRetailerQuery(),
    [searchRetailerText]
  )

  const debouncedSearchUserVendor = useDebounceEvent(
    async () => await refetchSearchUserQuery(),
    [searchUserText]
  )

  useEffect(() => {
    const isExistInCachedOptions = cachedSearchRetailersOptions.find(option =>
      compareCaseInsensitive(option.name, searchRetailerText)
    )

    if (searchRetailerText.length > 1 && !isExistInCachedOptions) {
      debouncedSearchRetailerVendor()
    }
  }, [searchRetailerText])

  useEffect(() => {
    const isExistInCachedOptions = cachedSearchUserOptions.find(
      option =>
        searchUserText && compareCaseInsensitive(option.user.id, searchUserText)
    )
    if (searchUserText?.length > 1 && !isExistInCachedOptions) {
      debouncedSearchUserVendor()
    }
  }, [searchUserText])

  // NOT USE
  // const handleAddNewVendorClick = () =>
  //   dispatch(
  //     OverlayActions.showDialog({
  //       key: DialogKeys.ADD_NEW_VENDOR,
  //       component: <VendorModal onOk={mutateRetailer} />
  //     })
  //   )

  // vendor change
  const handleSelectRetailerName = (value, option) => {
    const [retailerName, retailerEmail] = value.split(RETAILER_SEPARATOR)

    // update retailer name
    handleDispatchTask(RecordActions.EDIT_RETAILER_NAME, retailerName)

    // update retailer email
    handleDispatchTask(RecordActions.EDIT_RETAILER_EMAIL, retailerEmail)

    //  update searchText
    setSearchRetailerText(retailerName)
  }

  // vendor search
  const handleSearchVendor = async value => {
    const specialCharacterReg = /[^A-Za-z0-9]/g
    const formattedSearchText = value.replace(specialCharacterReg, '_')

    // prevent empty value on blur
    setSearchRetailerText(formattedSearchText)
    // update retailer name, in case no retailer found
    formattedSearchText &&
      handleDispatchTask(RecordActions.EDIT_RETAILER_NAME, formattedSearchText)
  }

  // user change
  const handleSelectUser = (value, option) => {
    // update user email
    handleDispatchTask(RecordActions.EDIT_USER_EMAIL, value)
    //  update searchText
    setSearchUserText(value)
  }

  // user search
  const handleSearchUser = async value => {
    setSearchUserText(value)
  }

  const handleNoTimeCheckboxChange = (checked: boolean) =>
    handleDispatchTask(
      RecordActions.EDIT_PURCHASE_TIME,
      checked ? null : '00:00:00'
    )

  const formattedVendorOptions = cachedSearchRetailersOptions.map(option => ({
    value: option.name + RETAILER_SEPARATOR + option.email,
    label: option.name + RETAILER_SEPARATOR + option.email
  }))

  const formattedUserOptions = cachedSearchUserOptions.map(option => ({
    value: option.user.id,
    label: option.user.id
  }))

  const renderItem = (
    label: string,
    item: any,
    handleOnChange: any = () => {}
  ) => {
    switch (label) {
      case 'Date':
        return (
          <Col span={12}>
            <Row>
              <span className="font-bold">{label}</span>
            </Row>
            <Row>
              <DatePicker
                className={`w-full ${!item ? 'border-red-700' : ''}`}
                allowClear={false}
                defaultValue={item}
                onChange={handleOnChange}
                format={'YYYY-MM-DD'}
              />
            </Row>
          </Col>
        )
      case 'Time':
        return (
          <Col span={12}>
            <ValidateTimeInput
              hasNoTimeOff={true}
              format={'HH:mm:ss'}
              value={item}
              fieldName={'purchaseTime'}
              label={'Time'}
              handleOnValid={handleOnChange}
            />
          </Col>
        )
      case 'Total':
        return (
          <div className="mb-4">
            <Row>
              <span className="font-bold">{label}</span>
            </Row>
            <Row>
              <InputNumber
                className={`w-full ${
                  !item && item !== 0 ? 'border-red-700' : ''
                }`}
                value={item}
                onChange={handleOnChange}
                placeholder={'Receipt total...'}
              />
            </Row>
          </div>
        )
      case 'Retailer':
        return (
          <div className="mb-4">
            <Row justify="space-between">
              <span className="font-bold">{label}</span>
              {/* <Button */}
              {/*  type="link" */}
              {/*  className="pb-0" */}
              {/*  onClick={handleAddNewVendorClick} */}
              {/* > */}
              {/*  Add new */}
              {/* </Button> */}
            </Row>
            <Row>
              <Select
                showSearch
                placeholder="Vendor name..."
                optionFilterProp="children"
                style={{ width: '100%' }}
                onChange={handleSelectRetailerName}
                onSearch={handleSearchVendor}
                filterOption={(inputValue, option) =>
                  isSubStringOf(option!.value, inputValue)
                }
                loading={isSearchingRetailer}
                defaultValue={item}
                value={item}
                searchValue={searchRetailerText}
                options={formattedVendorOptions}
              />
            </Row>
          </div>
        )
      case 'Retailer Email':
        return (
          <div className="mb-4">
            <Row>
              <span className="font-bold">{label}</span>
            </Row>
            <Row>
              <Input
                className="p-2 text-xs rounded w-full"
                value={item}
                onChange={handleOnChange}
              />
            </Row>
          </div>
        )
      case 'User Email':
        return (
          <div className="mb-4">
            <Row>
              <span className="font-bold">{label}</span>
            </Row>
            <Row>
              <Select
                allowClear
                onClear={() => {
                  setSearchUserText('')
                  handleDispatchTask(RecordActions.EDIT_USER_EMAIL, '')
                }}
                showSearch
                placeholder="User email..."
                style={{ width: '100%' }}
                optionFilterProp="children"
                onChange={handleSelectUser}
                onSearch={handleSearchUser}
                filterOption={(inputValue, option) =>
                  isSubStringOf(option!.value, inputValue)
                }
                loading={isSearchingUser}
                defaultValue={item}
                value={item}
                searchValue={searchUserText}
                options={formattedUserOptions}
              />
            </Row>
          </div>
        )
      case 'Order ID':
        return (
          <div className="mb-4">
            <Row>
              <span className="font-bold">{label}</span>
            </Row>
            <Row>
              <Input
                className="p-2 text-xs rounded w-full"
                value={item}
                onChange={handleOnChange}
              />
            </Row>
          </div>
        )
      case 'Rejection reason':
        return (
          <div className="mb-4">
            <Row>
              <span className="font-bold">{label}</span>
            </Row>
            <Row>
              <AutoComplete
                autoFocus
                className={'text-xs rounded w-full'}
                onClick={event => event.stopPropagation()}
                defaultValue={item}
                value={rejectionSearch === null ? item : rejectionSearch}
                onSearch={value => setRejectionSearch(value)}
                onSelect={value => {
                  setRejectionSearch(null)
                  handleDispatchTask(RecordActions.EDIT_REJECTION_REASON, value)
                }}
                onClear={() => {
                  setRejectionSearch(null)
                  handleDispatchTask(RecordActions.EDIT_REJECTION_REASON, null)
                }}
                allowClear
                options={reviewReceiptRejectionReasons.map(i => ({ value: i }))}
                placeholder="Rejection reason..."
                filterOption={(inputValue, option) =>
                  isSubStringOf(option!.value, inputValue)
                }
              />
            </Row>
          </div>
        )
    }
  }

  return (
    <div className="p-6">
      {/* general receipt info */}
      <Row className="flex justify-between items-start mb-3">
        <Col span={12}>
          <Row className="mb-3">
            <Tag color="blue">
              <span className="font-bold text-blue-700">{status}</span>
            </Tag>
          </Row>
          <Row>
            <span className="mr-1">Receipt ID:</span>
            <Tag>{receiptId}</Tag>
          </Row>
        </Col>

        <Col span={12}>
          <Row>
            <span className="font-bold">Notes:</span>
          </Row>
          <span>{notes}</span>
        </Col>
      </Row>

      {/* Editable fields */}
      {renderItem('Total', total, value =>
        handleDispatchTask(RecordActions.EDIT_TOTAL, value)
      )}
      {renderItem('Retailer', name)}
      {renderItem('Retailer Email', email, value =>
        handleDispatchTask(
          RecordActions.EDIT_RETAILER_EMAIL,
          value.target.value
        )
      )}
      {renderItem('User Email', messageTo)}

      <Row className="mb-4" gutter={12}>
        {renderItem(
          'Date',
          formatDate(receiptDateTime?.match?.(dateReg)?.[0]),
          (_, dateString) =>
            handleDispatchTask(RecordActions.EDIT_PURCHASE_DATE, dateString)
        )}
        {renderItem(
          'Time',
          formatTime(receiptDateTime?.match?.(timeReg)?.[0], 'HH:mm:ss'),
          timeString =>
            handleDispatchTask(RecordActions.EDIT_PURCHASE_TIME, timeString)
        )}
      </Row>
      {renderItem('Order ID', orderId, value =>
        handleDispatchTask(RecordActions.EDIT_ORDER_ID, value.target.value)
      )}
      {renderItem('Rejection reason', rejectionReason)}
    </div>
  )
}

const reviewReceiptRejectionReasons = [
  // 'A4_SIZE_RECEIPTS_NOT_ACCEPTED',
  // 'ACCOUNT_NOT_FOUND',
  // 'GIFT_CARD_PURCHASES_NOT_ACCEPTED',
  // 'PETROL_ONLY_NOT_SUPPORTED',
  // 'RECEIPT_OLDER_THAN_14_DAYS',
  // 'RECEIPT_OLDER_THAN_30_DAYS',
  // 'SPLIT_RECEIPT',
  // 'TOO_MANY_RECEIPTS',
  // 'TOTAL_CANNOT_BE_ZERO',
  'UNREADABLE_RECEIPT',
  'EFTPOS_RECEIPT_NOT_ACCEPTED',
  'BLURRY_RECEIPT',
  'RETAILER_NOT_SUPPORTED',
  'SUSPICIOUS_ACTIVITY',
  'DATE_NOT_INCLUDED',
  'RETAILER_UNKNOWN',
  'ITEMS_UNIDENTIFIED',
  'REPEAT_SUBMISSION',
  'DAILY_RETAILER_LIMIT_REACHED',
  'BAD_BACKGROUND',
  'SERVICE_RECEIPTS_NOT_ACCEPTED',
  'TOTAL_IS_MISSING',
  'SCREENSHOTS_NOT_ACCEPTED',
  'PAYMENT_RECEIPTS_NOT_ACCEPTED',
  'DUPLICATE_EMAIL_ORDER_STATUS',
  'INCORRECT_PAYID',
  'VERIFYING_RECEIPTS',
  'RECEIPT_OLDER_THAN_7_DAYS'
]

export default TaskContent
