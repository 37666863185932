import axios from 'axios'
import {
  axiosReqInterceptor,
  axiosResInterceptor
} from 'services/integrations/axios'

const UnovisionVisualizerApiClient = axios.create({
  baseURL: 'https://api.aps1.unocart.com/unovision/v3/visualizer/'
})

UnovisionVisualizerApiClient.interceptors.request.use(axiosReqInterceptor)
UnovisionVisualizerApiClient.interceptors.response.use(axiosResInterceptor)

export const getReceipt = async (id: string) => {
  if (!id) return { status: 204 } // fallback
  const { data } = await UnovisionVisualizerApiClient.get(`${id}`)
  return data
}
