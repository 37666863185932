import DialogConstants from 'data/overlay/constants'
import handleActionsImmer from 'services/integrations/immer'
import { OverlayState } from './types'

const initialDialogState = {
  dialogs: []
}

const overlay = handleActionsImmer<OverlayState>(
  {
    [DialogConstants.SHOW_DIALOG]: (state: OverlayState, payload) => {
      state.dialogs = [payload, ...state.dialogs]
    },
    [DialogConstants.HIDE_DIALOG]: (state: OverlayState, payload) => {
      state.dialogs = state.dialogs.filter(dialog => dialog.key !== payload.key)
    }
  },
  initialDialogState
)

export default overlay
