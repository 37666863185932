enum AuditConstants {
  SET_CURRENT_INDEX = 'AUDIT/SET_CURRENT_INDEX',
  SET_RECEIPTS_LIST = 'AUDIT/SET_RECEIPTS_LIST',
  SET_LABELS_LIST = 'AUDIT/SET_LABELS_LIST',
  SET_RECEIPT_DATA = 'AUDIT/SET_RECEIPT_DATA',
  SET_RECEIPT_FILTER = 'AUDIT/SET_RECEIPT_FILTER',
  RESET_AUDIT_STATE = 'AUDIT/RESET_AUDIT_STATE'
}

export default AuditConstants
