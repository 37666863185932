import { Layout } from 'antd'
import { Header } from 'antd/lib/layout/layout'
import RootHeader from 'components/layout/app-root/header'
import { AppSelectors } from 'data/selectors'
import React from 'react'
import { useSelector } from 'react-redux'
import { SIDER_WIDTH } from 'services/styles/layout'
import RootSider from './sider'

const { Sider, Content } = Layout

const AppRootLayout = ({ children }) => {
  const isSiderOpen = useSelector(AppSelectors.siderState)
  return (
    <Layout>
      <Sider
        trigger={null}
        collapsible
        width={SIDER_WIDTH}
        collapsedWidth={0}
        collapsed={!isSiderOpen}
        className="sticky left-0 top-0 h-screen bg-white p-0 border-r border-solid border-gray-300 z-50"
      >
        <RootSider />
      </Sider>

      <Layout>
        <Header className="sticky top-0 p-0 bg-gray-100 border-b border-solid border-gray-300 z-50">
          <RootHeader />
        </Header>
        <Content>{children}</Content>
      </Layout>
    </Layout>
  )
}

export default AppRootLayout
