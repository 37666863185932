export enum WorkflowActions {
  SET_RECORD = 'SET_RECORD',
  SET_DATA = 'SET_DATA',
  SET_START_DATE = 'SET_START_DATE',
  SET_END_DATE = 'SET_END_DATE',
  SET_RETAILER_FILTER = 'SET_RETAILER_FILTER',
  SET_COUNTRY_FILTER = 'SET_COUNTRY_FILTER',
  SET_RECEIPT_SUBJECT_FILTER = 'SET_RECEIPT_SUBJECT_FILTER',
  SET_ID_FILTER = 'SET_ID_FILTER',
  TOGGLE_INPUT_MODE = 'TOGGLE_INPUT_MODE',
  RESET_WORKING_STATE = 'RESET_WORKING_STATE'
}
