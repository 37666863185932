import EmptyComponent from 'components/empty-component'
import SplashScreen from 'components/splash'
import StandardBreadcrumb from 'components/standard-breadcrumb'
import { HistorySelectors } from 'data/history'
import React, { useState } from 'react'
import { useQuery } from 'react-query'
import { useSelector } from 'react-redux'

import { MinervaStateMachineApi } from 'services/api/requests/minerva'
import {
  Definition,
  Events,
  Header,
  PreviousActivityDataModal
} from './components'
import { HistoryDefinition } from './types'

const ReceiptHistory = () => {
  const [isModalVisible, setIsModalVisible] = useState<boolean>(false)
  const [modalData, setModalData] = useState(null)

  const receiptId = useSelector(HistorySelectors.receiptIdSearch)

  const { isFetching, data, isError } = useQuery<HistoryDefinition>(
    ['receipt-history', receiptId],
    ({ queryKey }: { queryKey: [string, string] }) =>
      MinervaStateMachineApi.getReceiptHistory(queryKey[1])
  )

  const showModal = (prevActivityData: React.SetStateAction<object>) => {
    setIsModalVisible(true)
    setModalData(prevActivityData)
  }

  const handleCloseModal = () => {
    setIsModalVisible(false)
    setModalData(null)
  }

  if (isFetching) return <SplashScreen />

  const showEmptyComponent = isError || !data || data?.status === 204

  return (
    <div className="p-10">
      <StandardBreadcrumb />
      <Header />
      {showEmptyComponent ? (
        <div className="flex flex-col justify-center items-center h-screen">
          <EmptyComponent description="Please check the Receipt ID and try again." />
        </div>
      ) : (
        <>
          <Definition data={data} />
          <Events
            data={data}
            onViewDataClick={prevActivityData => showModal(prevActivityData)}
          />
          <PreviousActivityDataModal
            data={modalData}
            isVisible={isModalVisible}
            onClose={handleCloseModal}
          />
        </>
      )}
    </div>
  )
}

export default ReceiptHistory
