import { Layout, Modal } from 'antd'
import EmptyComponent from 'components/empty-component'
import SplashScreen from 'components/splash'
import { ExtractionSelectors } from 'data/selectors'
import { formatDataFields } from 'pages/app/extraction/version-2/helpers'
import {
  combinedReducers,
  initialState
} from 'pages/app/extraction/version-2/reducers'
import { RecordActions } from 'pages/app/extraction/version-2/reducers/record/actions'
import TaskContent from 'pages/app/extraction/version-2/task/content'
import TaskFooter from 'pages/app/extraction/version-2/task/footer'
import TaskSider from 'pages/app/extraction/version-2/task/sider'
import React, { useReducer } from 'react'
import { useQuery } from 'react-query'
import { useSelector } from 'react-redux'
import { useParams } from 'react-router'
import { Noctua2ReceiptApi } from 'services/api/requests/notua-ii'
import { DBReceiptAnnotation } from 'services/api/requests/types'
import {
  EXTRACTION_SIDER_WIDTH,
  FOOTER_HEIGHT,
  HEADER_HEIGHT
} from 'services/styles/layout'

const { Content, Sider, Footer } = Layout

const ExtractionTaskV2Page = () => {
  const { countryCode } = useParams<{ countryCode: string }>()
  const retailerFilter = useSelector(ExtractionSelectors.retailerFilter)

  const [state, dispatchTask] = useReducer(combinedReducers, initialState)

  const resetWorkingState = () => {
    dispatchTask({ type: RecordActions.RESET_WORKING_STATE })
  }
  const handleOnDataLoaded = (data: DBReceiptAnnotation) => {
    resetWorkingState()
    if (data) {
      dispatchTask({
        type: RecordActions.SET_RECORD,
        payload: formatDataFields(data)
      })
    }
  }

  const handleOnLoadDataFailed = () => {
    Modal.error({
      title: 'Fetch Error!',
      content: (
        <>
          <p>Something went wrong while getting this receipt.</p>
          <p>Please contact support.</p>
        </>
      )
    })
  }

  const { isLoading } = useQuery(
    [countryCode, retailerFilter, 'receipt-extraction-v2', state.record],
    ({
      queryKey
    }: {
      queryKey: [string, string | undefined, string, string]
    }) => Noctua2ReceiptApi.getReceiptAnnotation(queryKey[0], queryKey?.[1]),
    {
      enabled: !state.record,
      onSuccess: handleOnDataLoaded,
      onError: handleOnLoadDataFailed
    }
  )

  // !WARNING BREAKING CHANGE: toggle unitPrice
  // useEffect(() => {
  //   if (state.record) {
  //     const priceToUnitPriceData: DBReceiptAnnotation = {
  //       ...state.record,
  //       pages: state.record.pages.map(page => ({
  //         ...page,
  //         fields: page.fields.map(field =>
  //           field.label ===
  //           (state.mode.isUnitPrice
  //             ? BoxLabelEnum.LINE_TOTAL_PRICE
  //             : BoxLabelEnum.LINE_UNIT_PRICE)
  //             ? {
  //                 ...field,
  //                 label: state.mode.isUnitPrice
  //                   ? BoxLabelEnum.LINE_UNIT_PRICE
  //                   : BoxLabelEnum.LINE_TOTAL_PRICE
  //               }
  //             : field
  //         )
  //       }))
  //     }
  //     dispatchTask({
  //       type: RecordActions.SET_RECORD,
  //       payload: priceToUnitPriceData
  //     })
  //   }
  // }, [state.mode.isUnitPrice])

  return (
    <Layout>
      <Layout
        style={{
          height: `calc(100vh - ${HEADER_HEIGHT}px - ${FOOTER_HEIGHT}px)`
        }}
      >
        {isLoading ? (
          <SplashScreen />
        ) : (
          <>
            <Sider
              width={EXTRACTION_SIDER_WIDTH}
              className="z-20 overflow-hidden"
            >
              <TaskSider parentState={state} parentDispatcher={dispatchTask} />
            </Sider>
            <Content className="bg-white z-30 sticky right-0 overflow-scroll">
              {state.record ? (
                <TaskContent
                  parentState={state}
                  parentDispatcher={dispatchTask}
                />
              ) : (
                <div className="h-screen flex justify-center items-center">
                  <EmptyComponent
                    description={
                      <>
                        <strong>No more receipts to show</strong>
                        <p>Change your retailer filter to load more</p>
                      </>
                    }
                  />
                </div>
              )}
            </Content>
          </>
        )}
      </Layout>

      <Footer
        className="flex items-center justify-between z-40 py-0 px-4"
        style={{ height: FOOTER_HEIGHT }}
      >
        <TaskFooter parentState={state} parentDispatcher={dispatchTask} />
      </Footer>
    </Layout>
  )
}

export default ExtractionTaskV2Page
