import { createContext, Dispatch } from 'react'
import { CombinedStates } from '../reducers'
import { RecordActions } from '../reducers/actions'

interface ITaskContext {
  state: CombinedStates
  dispatchTask: Dispatch<{ type: RecordActions; payload: any }>
}

export const TaskContext = createContext<ITaskContext>(null)
