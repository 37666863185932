import LoadingRows from 'components/skeleton/loading-rows'
import React from 'react'
import { useQuery } from 'react-query'
import { Noctua2LabelApi } from 'services/api/requests/notua-ii'
import { DBReceiptOverview } from 'services/api/requests/notua-ii/types'
import TasksTable from './components/tasks-table'

const AnnotationToolHome = () => {
  const { isLoading, data } = useQuery<DBReceiptOverview>(
    'annotation-overview',
    Noctua2LabelApi.getReceiptOverview
  )

  return (
    <div className="p-10">
      <h3 className="mb-4">Annotation Tool</h3>
      {!isLoading ? <TasksTable data={data} /> : <LoadingRows />}
    </div>
  )
}

export default AnnotationToolHome
