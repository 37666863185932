import { CheckCircleFilled, ExclamationCircleFilled } from '@ant-design/icons'
import { Divider } from 'antd'
import React, { ReactNode } from 'react'

interface SectionProps {
  children: ReactNode
  title: string
  isComplete: boolean
  page?: string
}

const Section = ({ children, title, isComplete, page }: SectionProps) => (
  <section className="flex flex-col gap-2">
    <Divider plain style={{ marginBottom: '0.25rem' }}>
      <span
        className={`flex items-center gap-2 font-bold mb-0 ${
          isComplete ? 'text-green-500' : 'text-red-500'
        }`}
      >
        {title} {page && `(${page})`}
        {isComplete ? <CheckCircleFilled /> : <ExclamationCircleFilled />}
      </span>
    </Divider>
    {children}
  </section>
)

export default Section
