import { Image } from 'antd'
import { isNaN } from 'lodash'
import Pagination from 'pages/app/extraction/version-1/task/components/pagination'
import Highlight from 'pages/app/visualizer/version-1/components/highlight'
import { FixedExtractionList } from 'pages/app/visualizer/version-1/types'
import React, { useRef, useState } from 'react'
import { TransformComponent, TransformWrapper } from 'react-zoom-pan-pinch'
import { useEventListener } from 'services/hooks/use-event-listener'
import { Color, getBoxColor } from 'services/styles/color'
import { HEADER_HEIGHT } from 'services/styles/layout'
import { Space } from 'services/styles/spacing'

const ReceiptImage = ({ data, currentPage, setCurrentPage }) => {
  const wrapperRef = useRef(null)
  const zoomWrapperRef = useRef(null)
  const [isPanningMode, setIsPanningMode] = useState<boolean>(false)
  const highLights = data?.annotations?.[currentPage][0].fields || {}
  const pagesInfo = data?.annotations?.[currentPage][0]?.pages[0]

  const renderSummaryLabeledBoxes = () =>
    FixedExtractionList.slice(1).map((label, index) => {
      if (!highLights?.[label]?.valueData) return null
      const { text, boundingBox } = highLights[label].valueData
      const [{ x: x0, y: y0 }, _, { x: x3, y: y3 }, __] = boundingBox

      const boxWidth = x3 - x0
      const boxHeight = y3 - y0

      return (
        <Highlight
          key={label + index}
          x={x0}
          y={y0}
          boxWidth={boxWidth}
          boxHeight={boxHeight}
          text={text}
          label={label}
        />
      )
    })

  const renderLineItems = () => {
    // first value is array of lines
    if (!highLights?.Items?.value.length) return null
    return highLights?.Items?.value.map((line, index) => {
      // second value is object of fields in a line
      const lineFields = line.value
      return Object.entries<any>(lineFields).map(([label, boxProps]) => {
        const { text, boundingBox } = boxProps.valueData
        const [{ x: x0, y: y0 }, _, { x: x3, y: y3 }, __] = boundingBox

        const boxWidth = x3 - x0
        const boxHeight = y3 - y0

        return (
          <Highlight
            key={label + index}
            x={x0}
            y={y0}
            boxWidth={boxWidth}
            boxHeight={boxHeight}
            text={text}
            label={label}
            color={getBoxColor(label)}
          />
        )
      })
    })
  }

  const renderLinks = () => {
    if (!highLights?.Items?.value.length) return null
    const linkPoints = []
    highLights?.Items?.value.forEach(line => {
      const lineFields = line.value

      const linePoints = Object.entries<any>(lineFields).map(([, boxProps]) => {
        const [
          { x: x0, y: y0 },
          _,
          { x: x3, y: y3 },
          __
        ] = boxProps.valueData.boundingBox

        return {
          x: (x0 + x3) / 2,
          y: (y0 + y3) / 2
        }
      })

      linkPoints.push(linePoints)
    })

    if (!linkPoints.length) return null
    return (
      <svg
        key={'visualizer-parent-links'}
        className={'absolute inset-0 z-50'}
        style={{ pointerEvents: 'none' }}
        width={pagesInfo?.width || 1000}
        height={pagesInfo?.height || 3000}
      >
        {linkPoints.map((line, lineIndex) => (
          <g key={lineIndex}>
            {line.map((point, pointIndex) => {
              if (pointIndex === line.length - 1) return null
              const endPoint = line[pointIndex + 1]
              return (
                <line
                  key={`${lineIndex}-${pointIndex}`}
                  x1={point.x}
                  y1={point.y}
                  x2={endPoint.x}
                  y2={endPoint.y}
                  stroke={'red'}
                />
              )
            })}
          </g>
        ))}
      </svg>
    )
  }

  const handlePrevPageClick = () => setCurrentPage(currentPage - 1)

  const handleNextPageClick = () => setCurrentPage(currentPage + 1)

  const keydownEventHandler = ({ keyCode }) => {
    const _isNextDisabled = isNaN(parseInt(currentPage))
      ? true
      : parseInt(currentPage) >= data?.metadata?.content?.length || 0 - 1
    const _isPrevDisabled = isNaN(parseInt(currentPage))
      ? true
      : parseInt(currentPage) <= 0

    if (keyCode === 37 && !_isPrevDisabled) {
      handlePrevPageClick()
    }
    if (keyCode === 39 && !_isNextDisabled) {
      handleNextPageClick()
    }

    if (keyCode === 107 || keyCode === 187) {
      zoomWrapperRef.current.zoomIn()
    }
    if (keyCode === 109 || keyCode === 189) {
      zoomWrapperRef.current.zoomOut()
    }
    if (keyCode === 32) {
      setIsPanningMode(true)
    }
  }

  const keyupEventHandler = ({ key }: { key: string }) => {
    if (String(key) === ' ') {
      setIsPanningMode(false)
    }
  }

  useEventListener('keydown', keydownEventHandler)
  useEventListener('keyup', keyupEventHandler)

  return (
    <div ref={wrapperRef}>
      <TransformWrapper
        key={'audit-receipt-wrapper'}
        centerOnInit
        ref={zoomWrapperRef}
        initialScale={0.25}
        minScale={0.1}
        maxScale={5}
        doubleClick={{ disabled: true }}
        wheel={{ activationKeys: ['+', '-'] }}
        panning={{ activationKeys: [' '] }}
        centerZoomedOut
      >
        <TransformComponent
          wrapperStyle={{
            backgroundColor: Color.GREY_300,
            width: '100%',
            height: `calc(100vh - ${HEADER_HEIGHT}px)`
          }}
        >
          <div style={{ cursor: isPanningMode ? 'move' : 'default' }}>
            <Image
              width={pagesInfo?.width || 1000}
              height={pagesInfo?.height || 3000}
              key={data?.metadata?.content?.[currentPage].toString()}
              alt="receipt"
              className="relative"
              src={data?.metadata?.content?.[currentPage]}
              preview={false}
            />
            {renderSummaryLabeledBoxes()}
            {renderLineItems()}
            {renderLinks()}
          </div>
        </TransformComponent>
      </TransformWrapper>
      <Pagination>
        <Pagination.LeftArrow
          onClick={handlePrevPageClick}
          disabled={currentPage <= 0}
        />
        <Pagination.RightArrow
          onClick={handleNextPageClick}
          disabled={currentPage >= data?.metadata?.content?.length - 1}
          style={{ right: Space.SMALL }}
        />
        <Pagination.PageIndicator
          current={currentPage + 1}
          total={data?.metadata?.content?.length || 0}
          style={{ marginRight: 0, bottom: Space.SMALL }}
        />
      </Pagination>
    </div>
  )
}

export default ReceiptImage
