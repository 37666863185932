import axios from 'axios'
import config from 'services/config'
import {
  axiosReqInterceptor,
  axiosResInterceptor
} from 'services/integrations/axios'
import {
  DBDefinitionLabelsSuggestion,
  DBDefinitionOverview,
  DefinitionType
} from './types'

const LabelApiNoctua2Client = axios.create({
  baseURL: config.baseUrl + 'noctua-ii/label/'
})

LabelApiNoctua2Client.interceptors.request.use(axiosReqInterceptor)
LabelApiNoctua2Client.interceptors.response.use(axiosResInterceptor)

export const getDefinitionOverview = async () => {
  const { data } = await LabelApiNoctua2Client.get<DBDefinitionOverview>(
    'definition-all'
  )
  return data
}

export const getDefinitionLabelsSuggestion = async (type?: string) => {
  const {
    data
  } = await LabelApiNoctua2Client.get<DBDefinitionLabelsSuggestion>(
    `all${type ? `/?type=${type}` : ''}`
  )

  return data
}

export const updateDefinition = async ({
  id,
  putBody
}: {
  id: string
  putBody: DefinitionType
}) => {
  const { data } = await LabelApiNoctua2Client.put(`definition/${id}`, putBody)
  return data
}

export const createDefinition = async (postBody: DefinitionType) => {
  const { data } = await LabelApiNoctua2Client.post('definition', postBody)
  return data
}

export const deleteDefinition = async (id: string) => {
  const { data } = await LabelApiNoctua2Client.delete(`definition/${id}`)
  return data
}
