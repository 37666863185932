import { Image as AntImage, Tabs } from 'antd'
import ZoomToolbar from 'components/zoom-toolbar'
import React, { useContext, useRef } from 'react'
import { TransformComponent, TransformWrapper } from 'react-zoom-pan-pinch'
import { preloadImage } from 'services/helpers/misc'
import { useEventListener } from 'services/hooks/use-event-listener'
import { Color } from 'services/styles/color'
import { REVIEW_SIDER_WIDTH } from 'services/styles/layout'
import { TaskContext } from '../context'

const TaskSider = ({ height }) => {
  const { state: parentState, dispatchTask: parentDispatcher } = useContext(
    TaskContext
  )

  const { record } = parentState
  const zoomWrapperRef = useRef(null)

  // preload images
  try {
    record.compressedContent.map(image_url => preloadImage(image_url))
  } catch (e) {}

  // zoom actions
  const handleZoomIn = () => zoomWrapperRef?.current?.zoomIn()
  const handleZoomOut = () => zoomWrapperRef?.current?.zoomOut()
  const handleResetZoom = () => zoomWrapperRef?.current?.resetTransform()

  const handleEventChange = ({ key }) => {
    if (['+', '='].includes(key)) {
      handleZoomIn()
    }
    if (key === '-') {
      handleZoomOut()
    }
  }

  useEventListener('keydown', handleEventChange)

  return (
    <Tabs
      defaultActiveKey="image"
      style={{ height, overflow: 'scroll' }}
      tabBarStyle={{
        border: '0.5px solid grey',
        justifyContent: 'center',
        backgroundColor: 'white',
        position: 'sticky',
        top: 0,
        zIndex: 50
      }}
    >
      <Tabs.TabPane tab="Annotations" key="annotation" className={'p-4 pt-0'}>
        <pre>{record.annotations || 'No annotations'}</pre>
      </Tabs.TabPane>
      <Tabs.TabPane tab="Images" key="image" className={'p-4 pt-0'}>
        <TransformWrapper
          key={'review-receipt-wrapper'}
          centerOnInit
          ref={zoomWrapperRef}
          initialScale={1}
          minScale={1}
          maxScale={3}
          doubleClick={{ disabled: true }}
          wheel={{ wheelDisabled: true }}
          centerZoomedOut
        >
          <TransformComponent
            wrapperStyle={{
              cursor: 'grab',
              backgroundColor: Color.GREY_300,
              width: '100%'
            }}
          >
            {record.compressedContent.map((image_url, index) => (
              <div key={image_url}>
                <span className="text-blue-400">
                  <a
                    href={record.content[index]}
                    target="_blank"
                    rel="noreferrer"
                  >
                    {record.content[index]}
                  </a>
                </span>

                <AntImage
                  src={image_url}
                  width={REVIEW_SIDER_WIDTH - 32}
                  alt="receipt"
                  className="relative"
                  preview={false}
                />
              </div>
            ))}
          </TransformComponent>
        </TransformWrapper>
        <div className="absolute z-50 top-1 right-0">
          <ZoomToolbar>
            <ZoomToolbar.ZoomIn onClick={() => handleZoomIn()} />
            <ZoomToolbar.ZoomOut onClick={() => handleZoomOut()} />
            <ZoomToolbar.Reset onClick={() => handleResetZoom()} />
          </ZoomToolbar>
        </div>
      </Tabs.TabPane>
    </Tabs>
  )
}

export default TaskSider
