import { Typography } from 'antd'
import { columnsStyle } from 'pages/app/annotation-tool/task/content/line-item-section/styles'
import {
  LineItemHeader,
  prettyAnnotationLabel,
  SectionEnum
} from 'pages/app/annotation-tool/task/types'
import React from 'react'

const { Text } = Typography

interface Props {
  definition?: any[]
}

const ItemsSectionHeader = ({ definition }: Props) => {
  const headers = LineItemHeader[SectionEnum.LINE_ITEM].filter(
    i => !!definition?.find(j => j?.label === i)
  )

  return (
    <div className="flex gap-2">
      <div style={{ width: '1.25rem' }} />
      {headers.map(header => (
        <div key={header} className={`flex-grow ${columnsStyle[header]}`}>
          <Text strong className="text-xs pb-1 truncate">
            {prettyAnnotationLabel[String(header)]}
          </Text>
        </div>
      ))}
    </div>
  )
}

export default ItemsSectionHeader
