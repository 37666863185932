import * as FileSaver from 'file-saver'
import XLSX from 'sheetjs-style'

interface Props {
  data: any[]
  fileName: string
}

const exportExcel = ({ data, fileName }: Props) => {
  const fileType =
    'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;charset=UTF-8'
  const fileExtension = '.xlsx'

  const wsArray = data.map(i => XLSX.utils.json_to_sheet(i))
  const sheetNames = data.map((i, index) => 'data ' + index)
  const wb = data.reduce(
    (acc, curr, index) => ({
      ...acc,
      Sheets: { ...acc.Sheets, [sheetNames[index]]: wsArray[index] },
      SheetNames: [...acc.SheetNames, sheetNames[index]]
    }),
    { Sheets: {}, SheetNames: [] }
  )

  const excelBuffer = XLSX.write(wb, { bookType: 'xlsx', type: 'array' })
  const excelFileStream = new Blob([excelBuffer], { type: fileType })
  FileSaver.saveAs(excelFileStream, fileName + fileExtension)
}

export default exportExcel
