import { BoxLabelEnum } from 'data/box'

export enum SectionEnum {
  MERCHANT = 'Merchant Info',
  LINE_ITEM = 'Line Items',
  PROMOTION = 'Promotion',
  TOTAL = 'Total & Tax',
  TIME = 'Date & Time',
  SUMMARY = 'Summary'
}

export const SectionTitles = [
  SectionEnum.MERCHANT,
  SectionEnum.LINE_ITEM,
  SectionEnum.PROMOTION,
  SectionEnum.TOTAL,
  SectionEnum.TIME
]

export const LineItemHeader = {
  [SectionEnum.MERCHANT]: [
    BoxLabelEnum.RECEIPT_MERCHANT_NAME,
    BoxLabelEnum.RECEIPT_STORE_PHONE,
    BoxLabelEnum.RECEIPT_STORE_ADDRESS
  ],
  [SectionEnum.LINE_ITEM]: [
    BoxLabelEnum.ITEM_DESCRIPTION,
    BoxLabelEnum.ITEM_CODE,
    BoxLabelEnum.ITEM_QUANTITY,
    BoxLabelEnum.ITEM_VALUE
  ],
  [SectionEnum.PROMOTION]: [
    BoxLabelEnum.ITEM_PROMO_PRICE,
    BoxLabelEnum.ITEM_PROMO_NAME
  ],
  [SectionEnum.TOTAL]: [
    BoxLabelEnum.RECEIPT_SUBTOTAL_VALUE,
    BoxLabelEnum.RECEIPT_TOTAL_TAX,
    BoxLabelEnum.RECEIPT_TOTAL_VALUE
  ],
  [SectionEnum.TIME]: [
    BoxLabelEnum.RECEIPT_PURCHASE_DATE,
    BoxLabelEnum.RECEIPT_PURCHASE_TIME
  ],
  [SectionEnum.SUMMARY]: ['', '', 'Calculated Total']
}

export const LabelToSectionTitle = {
  [BoxLabelEnum.RECEIPT_MERCHANT_NAME]: SectionEnum.MERCHANT,
  [BoxLabelEnum.RECEIPT_STORE_ADDRESS]: SectionEnum.MERCHANT,
  [BoxLabelEnum.RECEIPT_STORE_PHONE]: SectionEnum.MERCHANT,
  [BoxLabelEnum.ITEM_DESCRIPTION]: SectionEnum.LINE_ITEM,
  [BoxLabelEnum.ITEM_QUANTITY]: SectionEnum.LINE_ITEM,
  [BoxLabelEnum.ITEM_VALUE]: SectionEnum.LINE_ITEM,
  [BoxLabelEnum.ITEM_CODE]: SectionEnum.LINE_ITEM,
  [BoxLabelEnum.ITEM_PROMO_PRICE]: SectionEnum.PROMOTION,
  [BoxLabelEnum.ITEM_PROMO_NAME]: SectionEnum.PROMOTION,
  [BoxLabelEnum.RECEIPT_SUBTOTAL_VALUE]: SectionEnum.TOTAL,
  [BoxLabelEnum.RECEIPT_TOTAL_TAX]: SectionEnum.TOTAL,
  [BoxLabelEnum.RECEIPT_TOTAL_VALUE]: SectionEnum.TOTAL,
  [BoxLabelEnum.RECEIPT_PURCHASE_DATE]: SectionEnum.TIME,
  [BoxLabelEnum.RECEIPT_PURCHASE_TIME]: SectionEnum.TIME
}
