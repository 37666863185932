import { Tabs } from 'antd'
import axios from 'axios'
import { swapHtml } from 'pages/app/receipt-review/email-task/sider/helpers'
import React, { useContext, useEffect, useState } from 'react'
import { REVIEW_SIDER_WIDTH } from 'services/styles/layout'
import { TaskContext } from '../context'

const TaskSider = ({ height }) => {
  const { state: parentState, dispatchTask: parentDispatcher } = useContext(
    TaskContext
  )
  const [emailHtml, setEmailHtml] = useState<string>('')

  const { record } = parentState

  const receiptImages: string[] = record?.content || [emailHtml]

  useEffect(() => {
    if (record?.downloadedEmailUrl) {
      ;(async function setUserEmail() {
        const { data } = await axios.get(record.downloadedEmailUrl)
        setEmailHtml(swapHtml(data))
      })()
    }
  }, [record?.downloadedEmailUrl])

  const renderIframe = (title, srcDoc, src) => (
    <iframe
      key={src}
      title={title}
      srcDoc={srcDoc}
      src={src}
      frameBorder="0"
      style={{
        width: REVIEW_SIDER_WIDTH - 32,
        height: '75vh',
        border: '1px solid #ddd'
      }}
    />
  )

  return (
    <Tabs
      defaultActiveKey={record?.attachments?.length ? 'attachment' : 'image'}
      style={{ height, overflow: 'scroll' }}
      tabBarStyle={{
        border: '0.5px solid grey',
        justifyContent: 'center',
        backgroundColor: 'white',
        position: 'sticky',
        top: 0,
        zIndex: 50
      }}
    >
      <Tabs.TabPane tab="Annotations" key="annotation" className={'p-4 pt-0'}>
        <pre>{record.annotations || 'No annotations'}</pre>
      </Tabs.TabPane>
      <Tabs.TabPane tab="Images" key="image" className={'p-4 pt-0'}>
        {receiptImages.map(image_url => (
          <div key={image_url}>
            <span className="text-blue-400">
              <a
                href={record.downloadedEmailUrl}
                target="_blank"
                rel="noreferrer"
              >
                {record.downloadedEmailUrl}
              </a>
            </span>
            {renderIframe(
              record.downloadedEmailUrl,
              emailHtml,
              record.downloadedEmailUrl
            )}
          </div>
        ))}
      </Tabs.TabPane>

      {record?.attachments?.length && (
        <Tabs.TabPane tab="Attachments" key="attachment" className={'p-4 pt-0'}>
          {record.attachments
            .filter(i => !i.endsWith('eml'))
            .map(attachment =>
              renderIframe(
                attachment,
                null,
                attachment.replace(/%20/g, '%2520')
              )
            )}
        </Tabs.TabPane>
      )}
    </Tabs>
  )
}

export default TaskSider
