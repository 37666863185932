export const AuthRoute = {
  LOGIN: '/login',
  REGISTER: '/register',
  FORGOT: '/forgot-password',
  RESET: '/reset-password'
}

const AuthRoutes = Object.values(AuthRoute)

export const isOnAuthRoutes = pathname =>
  AuthRoutes.some(i => pathname?.startsWith(i))
