import firebase from 'firebase/compat/app'
import 'firebase/compat/auth'
import 'firebase/compat/firestore'

const firebaseConfig = {
  apiKey: 'AIzaSyA4SzgKpgyz3XweiZIRzniMSh1OG7nNCbs',
  authDomain: 'minerva-production-d9297.firebaseapp.com',
  projectId: 'minerva-production-d9297',
  storageBucket: 'minerva-production-d9297.appspot.com',
  messagingSenderId: '674966095267',
  appId: '1:674966095267:web:a27889b2c1057d6bbb0616',
  measurementId: 'G-YHQX496X0E'
}

firebase.initializeApp(firebaseConfig)
firebase.firestore()

export default firebase
