import { CloseOutlined } from '@ant-design/icons'
import { Button } from 'antd'
import StandardInput from 'components/standard-input'
import StandardSelect from 'components/standard-select'
import React, { Dispatch, SetStateAction } from 'react'
import { DragDropContext, Draggable, Droppable } from 'react-beautiful-dnd'
import {
  DefaultFullDefinition,
  FullDefinition
} from 'services/api/requests/notua-ii/types'
import { Color } from 'services/styles/color'
import { Space } from 'services/styles/spacing'
import { reorder } from './helpers'

interface Props {
  data: FullDefinition[]
  setData: Dispatch<SetStateAction<FullDefinition[]>>
}

interface EditableCardProps {
  item: FullDefinition
  onDelete: () => void
  onChange: (value) => void
}

const EditableCard = ({ item, onDelete, onChange }: EditableCardProps) => {
  const handleFieldChange = (field: string) => newValue => {
    onChange({ [field]: newValue })
  }
  return (
    <div className="flex flex-row justify-between items-center">
      <div className="flex flex-row justify-start gap-1">
        <StandardInput
          label="Label"
          wrapperClassname="w-56"
          defaultValue={item.label}
          onBlur={handleFieldChange('label')}
        />
        <StandardInput
          label="Display name"
          placeholder={`eg. ${item.label}`}
          defaultValue={item.name}
          onBlur={handleFieldChange('name')}
        />
        <StandardSelect
          label="Status"
          wrapperClassname="w-20"
          value={item.status}
          menu={['REQ', 'OPT', 'N/A']}
          onChange={handleFieldChange('status')}
        />
      </div>
      <Button
        onClick={onDelete}
        className="flex justify-center items-center"
        danger
        icon={<CloseOutlined />}
      />
    </div>
  )
}

const DnDArea = ({ data, setData }: Props) => {
  const grid = Space.TINY

  const getItemStyle = (isDragging, draggableStyle) => ({
    // some basic styles to make the items look a bit nicer
    userSelect: 'none',
    padding: grid * 2,
    margin: `0 0 ${grid}px 0`,
    borderRadius: Space.TINY / 2,

    // change background colour if dragging
    background: isDragging ? 'lightgreen' : 'white',

    // styles we need to apply on draggables
    ...draggableStyle
  })

  const getListStyle = isDraggingOver => ({
    background: isDraggingOver ? 'lightblue' : Color.GREY_300,
    padding: grid,
    width: '100%'
  })

  const onDragEnd = result => {
    // dropped outside the list
    if (!result.destination) {
      return
    }

    const newData: any[] = reorder(
      data,
      result.source.index,
      result.destination.index
    )

    setData(newData)
  }

  const handleAddMoreClick = () => {
    setData([...data, DefaultFullDefinition])
  }

  const handleDeleteItemClick = targetIdx => () => {
    setData(
      data.reduce(
        (acc, curr, index) => (index === targetIdx ? acc : [...acc, curr]),
        []
      )
    )
  }
  const handleEditItem = targetIdx => newValue => {
    const newData = data.map((i, index) =>
      index === targetIdx ? { ...i, ...newValue } : i
    )

    setData(newData)
  }

  return (
    <DragDropContext onDragEnd={onDragEnd}>
      <Droppable droppableId="droppable">
        {(provided, snapshot) => (
          <div
            {...provided.droppableProps}
            ref={provided.innerRef}
            style={getListStyle(snapshot.isDraggingOver)}
            placeholder="Please add more field"
          >
            {data.map((item, index) => (
              <Draggable
                key={`${item.label}_${index}`}
                draggableId={`${item.label}_${index}`}
                index={index}
              >
                {(provided, snapshot) => (
                  <div
                    ref={provided.innerRef}
                    {...provided.draggableProps}
                    {...provided.dragHandleProps}
                    style={getItemStyle(
                      snapshot.isDragging,
                      provided.draggableProps.style
                    )}
                  >
                    <EditableCard
                      item={item}
                      onDelete={handleDeleteItemClick(index)}
                      onChange={handleEditItem(index)}
                    />
                  </div>
                )}
              </Draggable>
            ))}
            {provided.placeholder}
          </div>
        )}
      </Droppable>
      <div className="flex justify-end w-full mt-2">
        <Button
          onClick={handleAddMoreClick}
          size="large"
          type="primary"
          ghost
          className="w-44 text-blue-400 rounded shadow"
        >
          + Add field
        </Button>
      </div>
    </DragDropContext>
  )
}

export default DnDArea
