import React, { ReactNode } from 'react'
import './style.less'

interface Props {
  text1: ReactNode
  text2?: ReactNode
  text3?: ReactNode
}

const MultipleTextsDivider = ({ text1, text2, text3 }: Props) => {
  return (
    <div className="container">
      <div className="short-border" />
      <span className="content">{text1}</span>
      <div className="border" />
      {text2 && (
        <>
          <div className="border" />
          <div className="border" />
          <span>{text2}</span>
        </>
      )}
      {text3 && (
        <>
          <div className="border" />
          <span>{text3}</span>
        </>
      )}
      {!text3 ? <div className="border" /> : <div className="short-border" />}
    </div>
  )
}
export default MultipleTextsDivider
