import axios from 'axios'
import config from 'services/config'
import { toTitleCase } from 'services/helpers/canonicalization'
import {
  axiosReqInterceptor,
  axiosResInterceptor
} from 'services/integrations/axios'

const SurveyCreationNoctua2Client = axios.create({
  baseURL: config.baseUrl + 'noctua-ii/survey-creation/'
})

SurveyCreationNoctua2Client.interceptors.request.use(axiosReqInterceptor)
SurveyCreationNoctua2Client.interceptors.response.use(axiosResInterceptor)

interface surveyCreationMessage {
  messageHeader: string
  surveyUrl: string
  surveyThumbnail: string
  surveyRequest: object
  surveyCreation: object
  rawJSON: object
}

export const postSurveyCreationMessage = async (
  props: surveyCreationMessage
) => {
  const slackMessage = toSlackMessage(props)

  const { data } = await SurveyCreationNoctua2Client.post(
    'submit',
    slackMessage
  )

  return data
}

const toSlackMessage = ({
  messageHeader,
  surveyUrl,
  surveyThumbnail,
  surveyRequest,
  surveyCreation,
  rawJSON
}) => {
  const surveyRequestSection = Object.entries(surveyRequest).reduce(
    (acc, [currKey, currValue]) => {
      return acc + `*${toTitleCase(currKey)}:* ${currValue}\n `
    },
    ''
  )

  // reserve created_by
  return {
    attachments: [
      {
        color: '#f2c744',
        blocks: [
          {
            type: 'header',
            text: {
              type: 'plain_text',
              text: messageHeader
            }
          },
          {
            type: 'section',
            text: {
              type: 'mrkdwn',
              text:
                surveyRequestSection +
                `*Survey url:* <${surveyUrl}|Link> \n` +
                '*Created by:* created_by \n'
            },
            accessory: {
              type: 'image',
              image_url: surveyThumbnail,
              alt_text: 'survey thumbnail'
            }
          },
          {
            type: 'rich_text',
            elements: [
              {
                type: 'rich_text_section',
                elements: [
                  {
                    type: 'text',
                    text: 'Survey creation JSON:',
                    style: {
                      bold: true
                    }
                  },
                  {
                    type: 'text',
                    text: '\n\n'
                  }
                ]
              },
              {
                type: 'rich_text_preformatted',
                border: 0,
                elements: [
                  {
                    type: 'text',
                    text: JSON.stringify(surveyCreation)
                  }
                ]
              }
            ]
          }
        ]
      }
    ]
  }
}
