import { Layout, Modal } from 'antd'
import EmptyComponent from 'components/empty-component'
import SplashScreen from 'components/splash'
import { DefinitionActions } from 'pages/app/annotation-tool/reducers/definition/actions'
import TaskFooter from 'pages/app/annotation-tool/task/footer'
import React, { useReducer } from 'react'
import { useQuery } from 'react-query'
import { useHistory, useParams } from 'react-router'
import { AppSubRoutes } from 'routes/app/routes'
import { Noctua2LabelApi } from 'services/api/requests/notua-ii'
import { DBReceiptAnnotation } from 'services/api/requests/types'
import {
  EXTRACTION_SIDER_WIDTH,
  FOOTER_HEIGHT,
  HEADER_HEIGHT
} from 'services/styles/layout'
import { combinedReducers, initialState } from '../reducers'
import { RecordActions } from '../reducers/record/actions'
import TaskContent from './content'
import { TaskContext } from './context'
import TaskSider from './sider'

const { Content, Sider, Footer } = Layout

const AnnotationToolTask = () => {
  const history = useHistory()

  const { retailerFilter, countryCode } = useParams<{
    retailerFilter: string
    countryCode: string
  }>()

  const [state, dispatchTask] = useReducer(combinedReducers, initialState)

  const resetWorkingState = () => {
    dispatchTask({ type: RecordActions.RESET_WORKING_STATE })
  }
  const handleOnDataLoaded = async (data: DBReceiptAnnotation) => {
    resetWorkingState()
    if (data) {
      const { complete, target, merchant } = data.metadata
      if (complete >= target) {
        Modal.success({
          title: `Congrats! We achieved our target for ${merchant} in ${countryCode}`,
          content: 'Please select another merchant to work on.'
        })
        return history.push(AppSubRoutes.ANNOTATION.HOME.pathname)
      }

      dispatchTask({
        type: RecordActions.SET_RECORD,
        payload: data
      })

      const { definition } = await Noctua2LabelApi.getRetailerDefinition(
        countryCode,
        merchant
      )

      dispatchTask({
        type: DefinitionActions.SET_DEFINITION,
        payload: definition
      })
    }
  }

  const handleOnLoadDataFailed = () => {
    Modal.error({
      title: 'Fetch Error!',
      content: (
        <>
          <p>Something went wrong while getting this receipt.</p>
          <p>Please contact support.</p>
        </>
      )
    })
  }

  const { isLoading, isFetching } = useQuery(
    [countryCode, retailerFilter, 'receipt-label', state.record],
    ({
      queryKey
    }: {
      queryKey: [string, string | undefined, string, string]
    }) => Noctua2LabelApi.getReceiptAnnotation(queryKey[0], queryKey?.[1]),
    {
      enabled: !state.record,
      onSuccess: handleOnDataLoaded,
      onError: handleOnLoadDataFailed
    }
  )

  return (
    <TaskContext.Provider value={{ state, dispatchTask }}>
      <Layout>
        <Layout
          style={{
            height: `calc(100vh - ${HEADER_HEIGHT}px - ${FOOTER_HEIGHT}px)`
          }}
        >
          {isLoading || isFetching ? (
            <SplashScreen />
          ) : (
            <>
              <Sider
                width={EXTRACTION_SIDER_WIDTH}
                className="z-20 overflow-hidden"
              >
                <TaskSider />
              </Sider>
              <Content className="bg-white z-30 sticky right-0 overflow-scroll">
                {state.record ? (
                  <TaskContent />
                ) : (
                  <div className="h-screen flex justify-center items-center">
                    <EmptyComponent
                      description={
                        <>
                          <strong>No more receipts to show</strong>
                          <p>Change your retailer filter to load more</p>
                        </>
                      }
                    />
                  </div>
                )}
              </Content>
            </>
          )}
        </Layout>

        <Footer
          className="flex items-center justify-between z-40 py-0 px-4"
          style={{ height: FOOTER_HEIGHT }}
        >
          <TaskFooter />
        </Footer>
      </Layout>
    </TaskContext.Provider>
  )
}

export default AnnotationToolTask
