import { Image as AntImage, message } from 'antd'
import AutoFocusToggle from 'components/auto-focus'
import Pagination from 'components/pagination'
import ZoomToolbar from 'components/zoom-toolbar'
import LinkLine from 'pages/app/annotation-tool/task/sider/components/link-line'
import SelectableArea from 'pages/app/annotation-tool/task/sider/components/selectable-area'
import DropdownToggle from 'pages/app/annotation-tool/task/sider/components/toggle-dropdown'
import React, { useContext, useRef, useState } from 'react'
import { TransformComponent, TransformWrapper } from 'react-zoom-pan-pinch'
import { Field, Word } from 'services/api/requests/types'
import { preloadImage } from 'services/helpers/misc'
import { useEventListener } from 'services/hooks/use-event-listener'
import { Color } from 'services/styles/color'
import { FOOTER_HEIGHT, HEADER_HEIGHT } from 'services/styles/layout'
import { Space } from 'services/styles/spacing'
import { CurrentPageActions } from '../../reducers/current-page/actions'
import { ModeActions } from '../../reducers/mode/actions'
import { MouseEventActions } from '../../reducers/mouse-event/actions'
import { RecordActions } from '../../reducers/record/actions'
import { SelectionActions } from '../../reducers/selection/actions'
import { TaskContext } from '../context'
import { mergeBoundingBoxes, verticesToId } from '../helpers'
import {
  AnnotationLabelEnum,
  getLinkableFields,
  HotKeyToLabelEnum,
  SelectableAreaEnum
} from '../types'
import FieldBox from './components/field-box'
import WordBox from './components/word-box'

const TaskSider = () => {
  const { state: parentState, dispatchTask: parentDispatcher } = useContext(
    TaskContext
  )
  const wrapperRef = useRef(null)
  const zoomWrapperRef = useRef(null)

  const {
    record,
    mode,
    mouseEvent,
    currentPage,
    selection,
    merchantDefinition
  } = parentState
  const { target, source } = selection
  const { popupList } = mouseEvent
  const { editingText, isPanningMode, autoFocus, isDropdownEnabled } = mode
  const [isImageLoaded, setIsImageLoaded] = useState(false)
  const [selectableTargets, setSelectableTargets] = useState<
    SelectableAreaEnum[]
  >([SelectableAreaEnum.TOKEN])

  const totalPages = record?.pages?.length || 0

  const handleDispatchTask = (action, value) =>
    parentDispatcher({ type: action, payload: value })

  // linking
  const openLinkModeMessage = () =>
    message.open({
      key: 'link-mode',
      type: 'info',
      content: 'Select box to link/ unlink',
      duration: 0
    })
  const closeLinkModeMessage = () => message.destroy('link-mode')
  const handleOnLinkModeChange = (checked: boolean) => {
    if (checked) {
      openLinkModeMessage()
    } else {
      closeLinkModeMessage()
      handleDispatchTask(SelectionActions.SET_TARGET_ID, null)
    }

    handleDispatchTask(ModeActions.SET_LINK_MODE, checked)
  }
  // zoom actions
  const handleZoomIn = () => zoomWrapperRef?.current?.zoomIn()
  const handleZoomOut = () => zoomWrapperRef?.current?.zoomOut()
  const handleResetZoom = () => zoomWrapperRef?.current?.resetTransform()
  // pagination actions
  const handlePrevPageClick = () =>
    handleDispatchTask(CurrentPageActions.SET_CURRENT_PAGE, currentPage - 1)
  const handleNextPageClick = () =>
    handleDispatchTask(CurrentPageActions.SET_CURRENT_PAGE, currentPage + 1)
  // labeling text
  const handleResetMouseEvents = () => {
    handleDispatchTask(MouseEventActions.SET_POPUP_LIST, [])
  }
  const handleLabelText = (key: AnnotationLabelEnum) => {
    if (!record || !popupList.length) return null
    const selectedBoxes = record.pages[currentPage].words.reduce(
      (acc: any, curr: Word, index: number) =>
        popupList[index] !== null && popupList[index] !== undefined
          ? {
              text: acc.text + ' ' + curr.text,
              bounding: [...acc.bounding, curr.boundingPoly],
              fieldElements: [...acc.fieldElements, curr.id]
            }
          : acc,
      { text: '', bounding: [], fieldElements: [] }
    )

    const newBoxBoundingPoly = mergeBoundingBoxes(selectedBoxes.bounding)
    const newId = verticesToId(newBoxBoundingPoly.vertices)

    const newField: Field = {
      id: newId,
      text: selectedBoxes.text,
      boundingPoly: newBoxBoundingPoly,
      fieldElements: selectedBoxes.fieldElements,
      label: key,
      links: []
    }

    handleDispatchTask(RecordActions.ADD_FIELD, newField)

    const targetField = record.pages[currentPage].fields.find(
      i => i.id === target
    )
    const linkableFields =
      targetField && getLinkableFields(targetField.label, key)
    if (target && linkableFields) {
      handleDispatchTask(RecordActions.ADD_LINK, {
        id: target,
        targetId: newId
      })
      !autoFocus && handleDispatchTask(SelectionActions.SET_TARGET_ID, null)
    }
    handleResetMouseEvents()
    return newId
  }
  const handleDeleteTokens = () => {
    const tokens = Array.from(
      document.querySelectorAll(`${SelectableAreaEnum.TOKEN}.selected`)
    )

    if (tokens.length) {
      const selectedBoxes = (record.pages[currentPage].words || []).filter(
        (highlight, index) =>
          popupList[index] !== null && popupList[index] !== undefined
      )

      selectedBoxes.map(token => {
        const isLabeled = currentPageData.fields.find(i =>
          i.fieldElements.includes(token.id)
        )
        return (
          !isLabeled && handleDispatchTask(RecordActions.REMOVE_OCR, token.id)
        )
      })

      handleResetMouseEvents()
    }
  }

  const handleDeleteHighLights = () => {
    const highlights = Array.from(
      document.querySelectorAll(`${SelectableAreaEnum.HIGHLIGHT}.selected`)
    )

    if (highlights.length) {
      const selectedBoxes = (record.pages[currentPage].fields || []).filter(
        (highlight, index) =>
          popupList[index] !== null && popupList[index] !== undefined
      )

      selectedBoxes.map(highlight =>
        handleDispatchTask(RecordActions.REMOVE_FIELD, highlight.id)
      )

      handleResetMouseEvents()
    }
  }
  const handleDeleteField = () => {
    if (source) {
      handleDispatchTask(RecordActions.REMOVE_FIELD, source)
      handleDispatchTask(SelectionActions.SET_SOURCE_ID, null)
    }
    if (target) {
      handleDispatchTask(RecordActions.REMOVE_FIELD, target)
      handleDispatchTask(SelectionActions.SET_TARGET_ID, null)
    }
  }

  const keydownEventHandler = ({ key }) => {
    const keydown = key.toLowerCase()
    // labeling events
    const isLabelTextAvailable =
      popupList.includes(true) &&
      popupList?.length === words.length &&
      !editingText
    const label = HotKeyToLabelEnum(merchantDefinition)[keydown]

    if (isLabelTextAvailable && label) handleLabelText(label)
    // delete multiple highlights
    if (['backspace', 'delete'].includes(keydown) && !editingText) {
      handleDeleteHighLights()
      handleDeleteField()
      handleDeleteTokens()
    }

    // linking event
    if (keydown === 'c' && target && !editingText) {
      handleOnLinkModeChange(true)
    }
    //  transform events: zoom/ moving
    if (keydown === ' ' && !editingText) {
      handleDispatchTask(ModeActions.SET_PANNING_MODE, true)
    }
    const _isNextDisabled = currentPage >= totalPages - 1
    const _isPrevDisabled = currentPage <= 0
    if (keydown === 'arrowleft' && !_isPrevDisabled && !editingText) {
      handlePrevPageClick()
    }
    if (keydown === 'arrowright' && !_isNextDisabled && !editingText) {
      handleNextPageClick()
    }
    if (['+', '='].includes(keydown) && !editingText) {
      handleZoomIn()
    }
    if (keydown === '-' && !editingText) {
      handleZoomOut()
    }
  }

  const keyupEventHandler = ({ key }) => {
    const keyup = key.toLowerCase()
    if (keyup === ' ') {
      handleDispatchTask(ModeActions.SET_PANNING_MODE, false)
    }

    // linking event
    if (keyup === 'c' && target && !editingText) {
      handleOnLinkModeChange(false)
    }
  }
  useEventListener('keydown', keydownEventHandler)
  useEventListener('keyup', keyupEventHandler)

  if (!record) return null
  const currentPageData = record.pages[currentPage]
  try {
    record.pages.map(i => preloadImage(i.image_url))
  } catch (e) {}

  const { words } = currentPageData
  // renders
  const renderBoxes = () =>
    currentPageData.words.map((word: Word, index) => (
      <WordBox
        key={`${word.id}_${index}`}
        word={word}
        visible={
          isDropdownEnabled &&
          popupList?.length === words.length &&
          popupList?.[index] === true
        }
      />
    ))
  const renderLabeledBoxes = () =>
    currentPageData.fields.map((field, index) => {
      return <FieldBox key={`${field.id}_${index}`} field={field} />
    })
  const renderLinks = () =>
    currentPageData.fields.map((field, index) => (
      <LinkLine key={`${field.id}_${index}`} field={field} />
    ))

  return (
    <div ref={wrapperRef}>
      <SelectableArea
        dragContainer=".draggable-area"
        zoomState={zoomWrapperRef?.current?.state}
        selectableTargets={selectableTargets}
        setSelectableTargets={setSelectableTargets}
      />
      <TransformWrapper
        key={'annotation-transform-wrapper_'}
        centerOnInit
        ref={zoomWrapperRef}
        initialScale={0.25}
        minScale={0.1}
        maxScale={5}
        doubleClick={{ disabled: true }}
        wheel={{ disabled: true }}
        panning={{ activationKeys: [' '] }}
        centerZoomedOut
      >
        <TransformComponent
          wrapperStyle={{
            backgroundColor: Color.GREY_300,
            width: '100%',
            height: `calc(100vh - ${HEADER_HEIGHT}px - ${FOOTER_HEIGHT}px)`
          }}
        >
          <div style={{ cursor: isPanningMode ? 'move' : 'default' }}>
            <AntImage
              key={currentPageData.image_url}
              {...currentPageData.size}
              alt="receipt"
              onLoad={() => setIsImageLoaded(true)}
              className="relative"
              src={currentPageData.image_url}
              preview={false}
            />
          </div>
          <svg
            className="absolute draggable-area "
            viewBox={`0 0 ${currentPageData.size.width} ${currentPageData.size.height}`}
          >
            {isImageLoaded ? renderBoxes() : null}
            {isImageLoaded ? renderLabeledBoxes() : null}
            {isImageLoaded ? renderLinks() : null}
          </svg>
        </TransformComponent>

        <Pagination>
          <Pagination.LeftArrow
            onClick={handlePrevPageClick}
            disabled={currentPage <= 0}
            style={{ left: Space.SMALL }}
          />
          <Pagination.RightArrow
            onClick={handleNextPageClick}
            disabled={currentPage >= totalPages - 1}
          />
        </Pagination>

        <ZoomToolbar>
          <ZoomToolbar.ZoomIn onClick={handleZoomIn} />
          <ZoomToolbar.ZoomOut onClick={handleZoomOut} />
          <ZoomToolbar.Reset onClick={handleResetZoom} />
        </ZoomToolbar>
        <DropdownToggle
          value={isDropdownEnabled}
          onValueChange={newValue =>
            handleDispatchTask(ModeActions.SET_TOKEN_DROPDOWN_MODE, newValue)
          }
        />
        <AutoFocusToggle
          value={autoFocus}
          onValueChange={newValue =>
            handleDispatchTask(ModeActions.SET_AUTO_FOCUS, newValue)
          }
        />
      </TransformWrapper>
    </div>
  )
}

export default TaskSider
