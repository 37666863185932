import { ApplicationReducerState } from 'data/reducers'
import { createSelector } from 'reselect'

export const visualizerState = (state: ApplicationReducerState) =>
  state.visualizer

export const receiptFilter = createSelector(
  visualizerState,
  audit => audit.receiptFilter
)
