import BigNumber from 'bignumber.js'
import { BoxLabelEnum, LINKABLE_FIELDS, REGULAR_FIELDS } from 'data/box'
import { MerchantDefinition } from 'pages/app/extraction/version-2/task/definitions/types'
import {
  LabelToSectionTitle,
  SectionEnum
} from 'pages/app/extraction/version-2/types'
import { BoundingPoly, Field, Page, Vertex } from 'services/api/requests/types'
import { traverseLinks } from 'services/helpers/graph'
import { parseCurrency, parseCurrencyStrict } from 'services/helpers/number'

function arrayMin(arr: any[]) {
  return arr.reduce(function (p, v) {
    return p < v ? p : v
  })
}

function arrayMax(arr: any[]) {
  return arr.reduce(function (p, v) {
    return p > v ? p : v
  })
}

export const mergeBoundingBoxes = (
  targetBoxes: BoundingPoly[]
): { vertices: Vertex[] } => {
  const maxX = arrayMax(
    targetBoxes.map(box => arrayMax(box.vertices.map(vertex => vertex.x)))
  )
  const maxY = arrayMax(
    targetBoxes.map(box => arrayMax(box.vertices.map(vertex => vertex.y)))
  )
  const minX = arrayMin(
    targetBoxes.map(box => arrayMin(box.vertices.map(vertex => vertex.x)))
  )
  const minY = arrayMin(
    targetBoxes.map(box => arrayMin(box.vertices.map(vertex => vertex.y)))
  )

  const VertexTopLeft = { x: minX, y: minY }
  const VertexTopRight = { x: maxX, y: minY }
  const VertexBottomRight = { x: maxX, y: maxY }
  const VertexBottomLeft = { x: minX, y: maxY }

  return {
    vertices: [
      VertexTopLeft,
      VertexTopRight,
      VertexBottomRight,
      VertexBottomLeft
    ]
  }
}

export const verticesToId = (vertices: Vertex[]) =>
  vertices.reduce(
    (acc, curr, index) =>
      index === vertices.length - 1
        ? acc + `${curr.x * 10}-${curr.y * 10}`
        : acc + `${curr.x * 10}-${curr.y * 10}_`,
    ''
  )

export const pathToId = (dAttribute: string) =>
  verticesToId(
    dAttribute.split('L').map(token => {
      const [cleanX, cleanY] = token.replace(/M|z/g, '').split(',')
      return { x: Number(cleanX), y: Number(cleanY) }
    })
  )

// TODO type for return value
export const filterHighLightsProperties = (
  highlights: Field[],
  conditions: BoxLabelEnum[]
) =>
  highlights
    .filter(box => conditions.find(condition => condition === box.label))
    .reduce<any>((acc, lineItem) => {
      const linkedProps = traverseLinks(highlights, lineItem.links)

      const linkKeys = linkedProps
        ? Object.values<any>(linkedProps).map(item => item?.id)
        : []

      const historyKey = acc.flatMap(item => item.key)
      const lineKey = Array.from(new Set([lineItem.id, ...linkKeys]))

      return lineKey.every(val => historyKey.includes(val))
        ? acc
        : [
            ...acc,
            {
              type: LabelToSectionTitle[lineItem.label],
              key: lineKey,
              yPosition: lineItem.boundingPoly.vertices[0].y,
              [lineItem.label]: lineItem,
              ...linkedProps
            }
          ]
    }, [])

// TODO type
export const sortHighLightsByPosition = (highlights: any[]) =>
  highlights.slice().sort((b, a) => b.yPosition - a.yPosition)

type SummaryType = {
  [BoxLabelEnum.ITEM_VALUE]?: number
  [BoxLabelEnum.RECEIPT_TOTAL_TAX]?: number
  [BoxLabelEnum.ITEM_PROMO_PRICE]?: number
  [BoxLabelEnum.RECEIPT_SUBTOTAL_VALUE]?: number
  [BoxLabelEnum.RECEIPT_TOTAL_VALUE]?: number
}

export const getIncompleteLineItems = (
  pages: Page[],
  merchantDefinition: MerchantDefinition
) => {
  const requiredLineFieldsInMerchant = LINKABLE_FIELDS.filter(
    field => merchantDefinition[field]?.required
  )
  return getPagedLineItems(pages).map(i =>
    i
      .map((field, index) => ({
        ...field,
        lineNumber: index + 1,
        errors: requiredLineFieldsInMerchant.filter(i => !(i in field))
      }))
      .filter(i => i.type === SectionEnum.LINE_ITEM)
      .filter(i => i.errors.length)
  )
}

export const getIncompleteReceiptFields = (
  pages: Page[],
  merchantDefinition: MerchantDefinition
) => {
  const merchantRequiredFields = REGULAR_FIELDS.filter(
    field => merchantDefinition[field]?.required
  )
  const result = pages.reduce((pagesAcc, page) => {
    const regularFieldsInPage = page.fields.reduce((pageAcc, field) => {
      if (REGULAR_FIELDS.includes(field.label)) {
        return [...pageAcc, field.label]
      }
      return pageAcc
    }, [])
    return [...pagesAcc, ...regularFieldsInPage]
  }, [])
  return merchantRequiredFields.filter(i => !result.includes(i))
}

export const getSinglePageImportantFields = (fields: Field[]) =>
  fields.reduce((acc, curr) => {
    if (REGULAR_FIELDS.includes(curr.label)) {
      return { ...acc, [curr.label]: curr }
    }
    return acc
  }, {})

export const getSinglePageSummary = (fields: Field[]): SummaryType =>
  fields.reduce((acc, curr) => {
    if (curr.label === BoxLabelEnum.ITEM_VALUE) {
      return {
        ...acc,
        [curr.label]: (acc?.[curr.label] || 0) + parseCurrency(curr.text)
      }
    }
    if (
      curr.label === BoxLabelEnum.RECEIPT_TOTAL_TAX ||
      curr.label === BoxLabelEnum.RECEIPT_TIP ||
      curr.label === BoxLabelEnum.ITEM_PROMO_PRICE
    ) {
      return {
        ...acc,
        [curr.label]: (acc?.[curr.label] || 0) + parseCurrencyStrict(curr.text)
      }
    } else if (
      curr.label === BoxLabelEnum.RECEIPT_SUBTOTAL_VALUE ||
      curr.label === BoxLabelEnum.RECEIPT_TOTAL_VALUE
    ) {
      return {
        ...acc,
        [curr.label]: curr.text
      }
    }
    return acc
  }, {})

export const getSinglePageTax = (fields: Field[]) =>
  Math.round(
    fields.reduce((acc, curr) => {
      if (curr.label === BoxLabelEnum.RECEIPT_TOTAL_TAX) {
        return acc + parseCurrencyStrict(curr.text)
      }
      return acc
    }, 0) * 100
  ) / 100

export const getReceiptFields = (pages: Page[]) =>
  pages
    .map(p => p.fields)
    .flat()
    .filter(f => REGULAR_FIELDS.includes(f.label))
    .reduce((acc, field) => {
      acc[field.label] = field
      return acc
    }, {})

export const getPagedLineItems = (pages: Page[]): any[][] =>
  pages.map((page, pageNumber) => {
    const { fields } = page
    const lineItemEntries = filterHighLightsProperties(fields, [
      BoxLabelEnum.ITEM_DESCRIPTION,
      BoxLabelEnum.ITEM_VALUE,
      BoxLabelEnum.ITEM_QUANTITY,
      BoxLabelEnum.ITEM_UNIT_TYPE,
      BoxLabelEnum.ITEM_CODE
    ])

    const promoEntries = filterHighLightsProperties(fields, [
      BoxLabelEnum.ITEM_PROMO_NAME,
      BoxLabelEnum.ITEM_PROMO_PRICE
    ])

    return sortHighLightsByPosition([...lineItemEntries, ...promoEntries])
  })

export const getSummedTotal = (pages: Page[]) =>
  getPagedLineItems(pages)
    .flat()
    .reduce((acc, field) => {
      if (field.type === SectionEnum.LINE_ITEM) {
        return new BigNumber(acc)
          .plus(new BigNumber(parseCurrency(field?.ItemValue?.text)))
          .toNumber()
      }
      if (field.type === SectionEnum.PROMOTION) {
        if (
          field.ItemPromoPrice?.text.includes('(') ||
          field.ItemPromoPrice?.text.includes(')') ||
          field.ItemPromoPrice?.text.includes('-')
        ) {
          return new BigNumber(acc)
            .plus(new BigNumber(parseCurrency(field.ItemPromoPrice?.text)))
            .toNumber()
        }
      }
      return acc
    }, 0)
