import { AutoLinkingOffIcon, AutoLinkingOnIcon } from 'assets/icons'
import { ExtractionActions, ExtractionSelectors } from 'data/extraction'
import React from 'react'
import { useDispatch, useSelector } from 'react-redux'

const AutoLinkingToggle = () => {
  const dispatch = useDispatch()
  const settings = useSelector(ExtractionSelectors.settings)

  const toggle = () => {
    const newCheckedList = { ...settings, autoFocus: !settings.autoFocus }
    dispatch(ExtractionActions.setSettings(newCheckedList))
  }

  return (
    <div
      className={
        'absolute bottom-2 ml-2 right-2 flex items-center justify-between cursor-pointer'
      }
    >
      {settings.autoFocus ? (
        <AutoLinkingOnIcon onClick={toggle} />
      ) : (
        <AutoLinkingOffIcon onClick={toggle} />
      )}
    </div>
  )
}

export default AutoLinkingToggle
