import { Select } from 'antd'
import React, { ReactNode } from 'react'

const { Option } = Select

interface Props {
  label?: ReactNode
  defaultValue?: string
  value?: string
  placeholder?: string
  wrapperClassname?: string
  menu: string[]
  onChange?: (value) => void
}

const StandardSelect = (props: Props) => {
  const { label, wrapperClassname, menu, onChange, ...selectProps } = props
  const handleChange = value => {
    onChange && onChange(value)
  }

  return (
    <div className={wrapperClassname}>
      {label && <div>{label}</div>}
      <Select
        {...selectProps}
        style={{ width: '100%' }}
        onChange={handleChange}
        getPopupContainer={triggerNode => triggerNode.parentNode}
      >
        {menu.map(i => (
          <Option key={i} value={i}>
            {i}
          </Option>
        ))}
      </Select>
    </div>
  )
}

export default StandardSelect
