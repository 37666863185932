import { Button, Dropdown } from 'antd'
import { DialogKeys } from 'components/modals/types'
import LoadingRows from 'components/skeleton/loading-rows'
import StandardBreadcrumb from 'components/standard-breadcrumb'
import { OverlayActions } from 'data/actions'
import DefinitionsTable from 'pages/app/admin/merchant-definition/definitions-table'
import CreateDefinitionModal from 'pages/app/admin/merchant-definition/modals/create-definition-modal'
import React, { ReactNode } from 'react'
import { useQuery } from 'react-query'
import { useDispatch } from 'react-redux'
import { Noctua2DefinitionApi } from 'services/api/requests/notua-ii'
import { DBDefinitionOverview } from 'services/api/requests/notua-ii/types'

interface MenuItemProps {
  onClick: () => void
  text?: ReactNode | string
}

const MenuItem = ({ onClick, text }: MenuItemProps) => (
  <Button
    onClick={onClick}
    size="middle"
    type="text"
    className="px-4 w-full h-full"
  >
    {text}
  </Button>
)

const MerchantDefinitionOverview = () => {
  const dispatch = useDispatch()
  const { isFetching, data } = useQuery<DBDefinitionOverview>(
    'definition-all',
    Noctua2DefinitionApi.getDefinitionOverview,
    { initialData: { definitions: [] } }
  )

  const handleAddMoreClick = (type?: string) => {
    dispatch(
      OverlayActions.showDialog({
        key: DialogKeys.CREATE_DEFINITION,
        component: <CreateDefinitionModal type={type} />
      })
    )
  }

  const menu = (
    <div className={'rounded-lg bg-white'}>
      <div className={'py-2'}>
        <MenuItem text={'Offline'} onClick={() => handleAddMoreClick()} />
        <MenuItem
          text={'Online'}
          onClick={() => handleAddMoreClick('online')}
        />
      </div>
    </div>
  )
  return (
    <div className="p-10">
      <StandardBreadcrumb />
      <div className="flex flex-row justify-between">
        <h3 className="mb-4">Merchant Definitions</h3>
        <Dropdown overlay={menu} trigger={['click']}>
          <Button
            onClick={e => e.preventDefault()}
            size="large"
            type="primary"
            ghost
            className="w-44 text-blue-400 rounded shadow"
          >
            + Add more
          </Button>
        </Dropdown>
      </div>
      {!isFetching ? <DefinitionsTable data={data} /> : <LoadingRows />}
    </div>
  )
}

export default MerchantDefinitionOverview
