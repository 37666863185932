import { PendingReceiptType } from 'services/api/requests/notua-ii/approval'
import handleActionsImmer from 'services/integrations/immer'
import { RecordActions } from './actions'

export interface CombinedStates {
  record?: PendingReceiptType
}

export const initialState: CombinedStates = {
  record: null
}

export const combinedReducers = handleActionsImmer<CombinedStates>(
  {
    // record reducer
    [RecordActions.SET_RECORD]: (
      state: CombinedStates,
      newRecord: PendingReceiptType
    ) => {
      state.record = newRecord
    },
    [RecordActions.EDIT_PURCHASE_TIME]: (
      state: CombinedStates,
      newPurchaseTime: string | null
    ) => {
      state.record.purchaseTime = newPurchaseTime
    },
    [RecordActions.EDIT_PURCHASE_DATE]: (
      state: CombinedStates,
      newPurchaseDate: string | null
    ) => {
      state.record.purchaseDate = newPurchaseDate
    },
    [RecordActions.EDIT_TOTAL]: (
      state: CombinedStates,
      newTotal: number | null
    ) => {
      state.record.total = newTotal
    },
    [RecordActions.EDIT_REJECTION_REASON]: (
      state: CombinedStates,
      newReason: string | null
    ) => {
      state.record.rejectionReason = newReason
    },
    [RecordActions.EDIT_VENDOR_NAME]: (
      state: CombinedStates,
      newVendorValue: string | null
    ) => {
      state.record.vendorName = newVendorValue
    },
    //  reset
    [RecordActions.RESET_WORKING_STATE]: (state: CombinedStates) => {
      state.record = null
    }
  },
  initialState
)
