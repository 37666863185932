import { BoxActions } from 'data/box'
import Field from 'pages/app/extraction/version-1/task/components/field'
import Section from 'pages/app/extraction/version-1/task/components/section'
import {
  LineItemHeader,
  SectionEnum
} from 'pages/app/extraction/version-1/types'
import React from 'react'
import { useDispatch } from 'react-redux'

const MerchantSection = ({ mergedImportantFields }) => {
  const dispatch = useDispatch()

  const merchantInfo = LineItemHeader[SectionEnum.MERCHANT].map(header => ({
    ...mergedImportantFields[header],
    label: header
  }))

  const isAllCleared = merchantInfo.every(
    item => !!item?.text || item.isNotRequired
  )

  const handleSetActiveId = (id?: string) =>
    dispatch(BoxActions.setActiveId(id))

  const renderRow = (headers, data) => (
    <>
      {headers.map((header, index) => {
        const content = data.find(content => content.label === header)
        return (
          <Field
            key={`${header}_${content?.id}_${index}`}
            id={content?.id}
            wrapperClassName={`w-1/${headers.length}`}
            label={content?.label}
            value={content?.text || '\u00A0'}
            onClick={() => handleSetActiveId(content.id)}
            isCleared={!!content?.text || content.isNotRequired}
          />
        )
      })}
    </>
  )

  return (
    <Section title="Merchant" isCleared={isAllCleared}>
      <div className="flex gap-2">
        {renderRow(
          LineItemHeader[SectionEnum.MERCHANT].slice(0, 2),
          merchantInfo
        )}
      </div>
      {renderRow(LineItemHeader[SectionEnum.MERCHANT].slice(-1), merchantInfo)}
    </Section>
  )
}

export default MerchantSection
