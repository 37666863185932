import axios from 'axios'
import { BoxLabelEnum } from 'data/box'
import { DBReceiptOverview } from 'services/api/requests/types'
import config from 'services/config'
import {
  axiosReqInterceptor,
  axiosResInterceptor
} from 'services/integrations/axios'

const ReceiptApiClient = axios.create({
  baseURL: config.baseUrl + 'noctua/receipt/'
})

type Dimensions = { width: number; height: number }
type Vertex = { x: number; y: number }
export type ResponseReceiptAnnotationType = {
  // @ts-ignore
  metadata: {
    content: string[]
    id: number | string
    size_list: Dimensions[]
    us_pending: number
    total: string
    minerva_id: number
  }
  [key: string]: {
    image_url: string
    fields: {
      id: string
      text: string
      boundingPoly: {
        vertices: Vertex[]
      }
      label: BoxLabelEnum
      links: string[]
    }[]
    annotations: {
      boundingPoly: { vertices: Vertex[] }
      text: string
    }[]
    size: Dimensions
  }
}

ReceiptApiClient.interceptors.request.use(axiosReqInterceptor)
ReceiptApiClient.interceptors.response.use(axiosResInterceptor)

export const getReceiptAnnotation = async (
  country: string,
  retailerFilter?: string
) => {
  const fallbackData = {
    metadata: {},
    0: {}
  }

  const res = await ReceiptApiClient.get<ResponseReceiptAnnotationType>(
    `annotate/${country}${
      retailerFilter ? `/?merchant=${encodeURIComponent(retailerFilter)}` : ''
    }`
  )
  return res.status === 204 ? { ...res, data: fallbackData } : res
}

export const getReceiptOverview = async () => {
  const { data } = await ReceiptApiClient.get<DBReceiptOverview>('overview')
  return data
}

type updateProps = {
  id: string | number
  putBody: ResponseReceiptAnnotationType
}
export const updateReceiptAnnotation = async ({ id, putBody }: updateProps) => {
  const { data } = await ReceiptApiClient.put<ResponseReceiptAnnotationType>(
    `annotate/${id}`,
    putBody
  )
  return data
}

export const skipReceiptAnnotation = async (id: string | number) => {
  const { data } = await ReceiptApiClient.get<ResponseReceiptAnnotationType>(
    `skip/${id}`
  )
  return data
}

type rejectProps = {
  id: string | number
  reason: string
}
export const rejectReceiptAnnotation = async ({ id, reason }: rejectProps) => {
  const { data } = await ReceiptApiClient.post<ResponseReceiptAnnotationType>(
    `reject/${id}`,
    { reason }
  )
  return data
}

export const unlockReceipt = async () => {
  const { data } = await ReceiptApiClient.post('unlock')
  return data
}
