import { Button } from 'antd'
import { DialogKeys } from 'components/modals/types'
import RejectModal from 'components/reject-modal'
import ValidateModal from 'components/validate-modal'
import { OverlayActions } from 'data/actions'
import React, { useContext } from 'react'
import { useDispatch } from 'react-redux'
import { Color } from 'services/styles/color'
import { RecordActions } from '../../reducers/record/actions'
import { TaskContext } from '../context'
import { getIncompleteLineItems, getIncompleteReceiptFields } from '../helpers'
import {
  useHandleRejectAnnotation,
  useHandleSkipAnnotation,
  useHandleSubmitAnnotation
} from './hooks'
import TaskDetails from './task-details'

const TaskFooter = () => {
  const dispatch = useDispatch()
  const { state, dispatchTask } = useContext(TaskContext)
  const { record, merchantDefinition } = state

  const [isRejecting, handleReject] = useHandleRejectAnnotation()
  const [isSubmitting, handleSubmit] = useHandleSubmitAnnotation()
  const [isSkipping, handleSkip] = useHandleSkipAnnotation()

  const handleRejectClick = async e => {
    e.currentTarget.blur()

    const onReject = async reason => {
      await handleReject(record.metadata.id, reason)
      dispatchTask({ type: RecordActions.RESET_WORKING_STATE })
    }

    dispatch(
      OverlayActions.showDialog({
        key: DialogKeys.REJECT_RECEIPT,
        component: <RejectModal onOk={onReject} />
      })
    )
  }

  const handleSkipClick = async e => {
    e.currentTarget.blur()

    await handleSkip(record.metadata.id)
    // load next receipt annotation
    dispatchTask({ type: RecordActions.RESET_WORKING_STATE })
  }

  const handleApproveClick = async e => {
    e.currentTarget.blur()

    const incompleteLineItems = getIncompleteLineItems(
      record.pages,
      merchantDefinition
    )

    const incompleteReceiptFields = getIncompleteReceiptFields(
      record.pages,
      merchantDefinition
    )

    const _onSubmit = async putBody => {
      await handleSubmit(record.metadata.id, putBody)
      dispatchTask({ type: RecordActions.RESET_WORKING_STATE })
    }
    const onBypassCheck = async bypassCheck => {
      const formattedData = {
        ...record,
        metadata: { ...record.metadata, bypassCheck }
      }

      await _onSubmit(formattedData)
    }

    // byPassTotal totals check
    if (
      incompleteLineItems.filter(i => i.length).length ||
      incompleteReceiptFields.length
    ) {
      dispatch(
        OverlayActions.showDialog({
          key: DialogKeys.VALIDATE_RECEIPT,
          component: (
            <ValidateModal
              onOk={onBypassCheck}
              isTotalMatch={true}
              incompleteLineItems={incompleteLineItems}
              incompleteReceiptFields={incompleteReceiptFields}
            />
          )
        })
      )
    } else {
      await _onSubmit(record)
    }
  }

  return (
    <>
      <TaskDetails />
      <div className="flex flex-grow items-center">
        <Button
          loading={isSubmitting}
          onClick={handleApproveClick}
          size="large"
          className="text-white hover:text-white w-28 rounded"
          style={{ backgroundColor: Color.SUCCESS }}
        >
          Approve
        </Button>
        <span className="mx-2 text-xl">|</span>
        <Button
          loading={isRejecting}
          onClick={handleRejectClick}
          size="large"
          danger
          className="w-20 rounded mr-2"
          type="primary"
        >
          Reject
        </Button>
        <Button
          loading={isSkipping}
          onClick={handleSkipClick}
          size="large"
          className="text-white w-20 rounded bg-black"
        >
          Skip
        </Button>
      </div>
    </>
  )
}

export default TaskFooter
