import { Button } from 'antd'
import BigNumber from 'bignumber.js'
import { DialogKeys } from 'components/modals/types'
import RejectModal from 'components/reject-modal'
import ValidateModal from 'components/validate-modal'
import { OverlayActions } from 'data/actions'
import { BoxLabelEnum } from 'data/box'
import { CombinedStates } from 'pages/app/extraction/version-2/reducers'
import { RecordActions } from 'pages/app/extraction/version-2/reducers/record/actions'
import getMerchantDefinition from 'pages/app/extraction/version-2/task/definitions'
import TaskDetails from 'pages/app/extraction/version-2/task/footer/details'
import {
  useHandleRejectAnnotation,
  useHandleSkipAnnotation,
  useHandleSubmitAnnotation
} from 'pages/app/extraction/version-2/task/footer/hooks'
import {
  getIncompleteLineItems,
  getIncompleteReceiptFields,
  getReceiptFields,
  getSummedTotal
} from 'pages/app/extraction/version-2/task/helpers'
import React, { Dispatch } from 'react'
import { useDispatch } from 'react-redux'
import { useParams } from 'react-router'
import { parseCurrency } from 'services/helpers/number'
import { Color } from 'services/styles/color'

interface Props {
  parentState: CombinedStates
  parentDispatcher: Dispatch<any>
}

const TaskFooter = ({ parentState, parentDispatcher }: Props) => {
  const { countryCode } = useParams<{ countryCode: string }>()

  const dispatch = useDispatch()
  const {
    record,
    loaders,
    mode,
    mouseEvent,
    currentPage,
    selection
  } = parentState

  const { target, source } = selection
  const { popupList, userActions } = mouseEvent
  const { editingText, isPanningMode, linkMode, autoFocus, isUnitPrice } = mode
  const { isImageLoaded } = loaders

  const [isSubmitting, handleSubmit] = useHandleSubmitAnnotation()
  const [isSkipping, handleSkip] = useHandleSkipAnnotation()
  const [isRejecting, handleReject] = useHandleRejectAnnotation()

  if (!record) return null
  const currentPageData = record.pages[currentPage]
  const totalPages = record.pages.length
  const predictedMerchant = record.metadata.merchant
  const merchantDefinition = getMerchantDefinition(
    countryCode,
    predictedMerchant
  )
  const handleRejectClick = async e => {
    e.currentTarget.blur()

    const onReject = async reason => {
      await handleReject(record.metadata.id, reason)
      parentDispatcher({ type: RecordActions.RESET_WORKING_STATE })
    }

    dispatch(
      OverlayActions.showDialog({
        key: DialogKeys.REJECT_RECEIPT,
        component: <RejectModal onOk={onReject} />
      })
    )
  }

  const handleSkipClick = async e => {
    e.currentTarget.blur()

    await handleSkip(record.metadata.id)
    // load next receipt annotation
    parentDispatcher({ type: RecordActions.RESET_WORKING_STATE })
  }

  const handleApproveClick = async e => {
    e.currentTarget.blur()

    const incompleteLineItems = getIncompleteLineItems(
      record.pages,
      merchantDefinition
    )

    const incompleteReceiptFields = getIncompleteReceiptFields(
      record.pages,
      merchantDefinition
    )

    const receiptFields = getReceiptFields(record.pages)
    const receiptTotal = parseCurrency(
      receiptFields[BoxLabelEnum.RECEIPT_TOTAL_VALUE]?.text
    )
    const summedTotal = getSummedTotal(record.pages)

    const isTotalMatch = new BigNumber(receiptTotal).isEqualTo(
      new BigNumber(summedTotal)
    )

    const onSubmit = async putBody => {
      await handleSubmit(record.metadata.id, putBody)
      parentDispatcher({ type: RecordActions.RESET_WORKING_STATE })
    }

    const onBypassCheck = async bypassCheck => {
      const formattedData = {
        ...record,
        metadata: { ...record.metadata, bypassCheck }
      }

      await onSubmit(formattedData)
    }

    if (
      incompleteLineItems.filter(i => i.length).length ||
      incompleteReceiptFields.length ||
      !isTotalMatch
    ) {
      dispatch(
        OverlayActions.showDialog({
          key: DialogKeys.VALIDATE_RECEIPT,
          component: (
            <ValidateModal
              onOk={onBypassCheck}
              isTotalMatch={isTotalMatch}
              incompleteLineItems={incompleteLineItems}
              incompleteReceiptFields={incompleteReceiptFields}
            />
          )
        })
      )
    } else {
      await onSubmit(record)
    }
  }

  return (
    <>
      <TaskDetails data={record} />
      <div className="flex flex-grow items-center">
        <Button
          loading={false}
          onClick={handleApproveClick}
          size="large"
          className="text-white hover:text-white w-28 rounded"
          style={{ backgroundColor: Color.SUCCESS }}
        >
          Approve
        </Button>
        <span className="mx-2 text-xl">|</span>
        <Button
          onClick={handleRejectClick}
          size="large"
          danger
          className="w-20 rounded mr-2"
          type="primary"
        >
          Reject
        </Button>
        <Button
          loading={false}
          onClick={handleSkipClick}
          size="large"
          className="text-white w-20 rounded bg-black"
        >
          Skip
        </Button>
      </div>
    </>
  )
}

export default TaskFooter
