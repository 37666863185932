import axios from 'axios'
import config from 'services/config'
import {
  axiosReqInterceptor,
  axiosResInterceptor
} from 'services/integrations/axios'

const AdminApiClient = axios.create({
  baseURL: config.baseUrl + 'noctua/admin/'
})

AdminApiClient.interceptors.request.use(axiosReqInterceptor)
AdminApiClient.interceptors.response.use(axiosResInterceptor)

export type ResponseUsersOverviewType = {
  user: {
    email: string
    user_id: number
  }[]
}

export const getUsersOverview = async () => {
  const { data } = await AdminApiClient.get<ResponseUsersOverviewType>('audit')
  return data
}

export type ResponseUserExtractedReceiptsType = {
  user_id: number
  email: string
  latest_receipts: number[]
}

export const getUserExtractedReceipts = async (id: string | number) => {
  const { data } = await AdminApiClient.post<ResponseUserExtractedReceiptsType>(
    `audit/${id}`
  )
  return data
}

type AuditDataType = {
  content: string[]
  parsed: {
    line_items: { [key: string]: string | number }[]
    store: {
      name?: string
      address?: string
      phone_number?: string
    }
    receipt: {
      total_cents: string
      subtotal_cents: string
      transaction_date: string
      transaction_time: string
    }
    receipt_promotions: []
    tax_items: []
  }[]
  annotation: any // old annotation type
}
// fallback because backend might not return error
export const getReceiptsAnalytics = async (id?: number) => {
  const fallbackData = {
    content: [],
    annotation: {},
    parsed: []
  } as AuditDataType
  if (!id) {
    return fallbackData
  }
  const res = await AdminApiClient.get<AuditDataType>(`analytics/${id}`)

  return res.status === 204 ? { ...res, data: fallbackData } : res
}
