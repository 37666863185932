import { Button, Progress, Tooltip } from 'antd'
import Table from 'components/table'
import { AppActions } from 'data/actions'
import { AppSelectors } from 'data/selectors'
import React from 'react'
import { useMutation } from 'react-query'
import { useDispatch, useSelector } from 'react-redux'
import { useHistory } from 'react-router'
import { AppSubRoutes } from 'routes/app/routes'
import { Noctua2PiiRedactionApi } from 'services/api/requests/notua-ii'
import {
  RedactStep,
  ResPiiRedactionOverview
} from 'services/api/requests/notua-ii/pii-redaction'
import { Color } from 'services/styles/color'
import { Space } from 'services/styles/spacing'

interface Props {
  data: ResPiiRedactionOverview[]
}

const TasksTable = ({ data }: Props) => {
  const dispatch = useDispatch()
  const history = useHistory()
  const isSiderOpen = useSelector(AppSelectors.siderState)

  const { mutateAsync, isLoading } = useMutation(
    Noctua2PiiRedactionApi.unlockPiiRedactionData
  )

  const handleNavigationClick = (
    step: RedactStep,
    merchant_id: number
  ) => async () => {
    await mutateAsync()

    if (isSiderOpen) dispatch(AppActions.toggleSider())
    history.push(
      AppSubRoutes.PII_REDACTION.HOME.pathname +
        `/${merchant_id}` +
        `?step=${step}`
    )
  }

  return (
    <Table>
      <Table.Header
        titles={[
          'Merchant',
          'To be redacted',
          'To be validated',
          'Total Completed',
          ' ',
          ' ',
          ' '
        ]}
      />
      <tbody>
        {data.map(
          ({
            merchant,
            pending_count,
            redacted_count,
            redaction_in_progress_count,
            validated_count,
            validation_in_progress_count
          }) => {
            const toBeRedacted =
              merchant.target - validated_count - redacted_count

            const totalProgress =
              (validated_count / (merchant?.target || 1)) * 100

            const hitTarget = validated_count >= merchant.target
            return (
              <Table.Row key={merchant.id}>
                <Table.Cell label={merchant.name} />
                <Table.Cell label={toBeRedacted.toString()} />
                <Table.Cell label={redacted_count.toString()} />
                <Table.Cell
                  cellStyle={{ paddingRight: Space.LARGE, width: '15%' }}
                  render={
                    <Tooltip
                      title={`${validated_count} / ${merchant?.target || 1}`}
                    >
                      <Progress
                        percent={totalProgress}
                        showInfo={false}
                        strokeWidth={Space.SMALL}
                        strokeColor={Color.SUCCESS}
                      />
                    </Tooltip>
                  }
                />
                <Table.Cell
                  render={
                    <Button
                      disabled={hitTarget}
                      loading={isLoading}
                      onClick={handleNavigationClick('validate', merchant.id)}
                      size="large"
                      className="w-full text-white rounded bg-blue-400 text-center"
                    >
                      Validate
                    </Button>
                  }
                />
                <Table.Cell
                  render={
                    <Button
                      disabled={hitTarget}
                      loading={isLoading}
                      onClick={handleNavigationClick('redact', merchant.id)}
                      size="large"
                      className="w-full text-white rounded text-center"
                      style={{ backgroundColor: Color.SUCCESS }}
                    >
                      Redact
                    </Button>
                  }
                />
                <Table.Cell visible={false} label={'>'} />{' '}
              </Table.Row>
            )
          }
        )}
      </tbody>
    </Table>
  )
}

export default TasksTable
