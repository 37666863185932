import { debounce } from 'lodash'
import { useEffect, useMemo } from 'react'

const useDebounceEvent = (eventHandler, deps) => {
  const debouncedSearchVendor = useMemo(() => debounce(eventHandler, 300), deps)

  useEffect(() => {
    return () => {
      debouncedSearchVendor.cancel()
    }
  }, deps)

  return debouncedSearchVendor
}

export default useDebounceEvent
