import { Button } from 'antd'
import Table from 'components/table'
import { AppActions } from 'data/actions'
import { AppSelectors } from 'data/selectors'
import React from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { useHistory } from 'react-router'
import { AppSubRoutes } from 'routes/app/routes'

const VersionTable = () => {
  const dispatch = useDispatch()

  const history = useHistory()

  const isSiderOpen = useSelector(AppSelectors.siderState)

  const handleNavigationClick = (version: string) => () => {
    if (isSiderOpen) dispatch(AppActions.toggleSider())
    history.push(
      AppSubRoutes.VISUALIZER.HOME +
        `/${version}${version === 'merchant' ? '/US' : ''}`
    )
  }

  const versionList = ['v1', 'v2', 'merchant']

  return (
    <Table>
      <Table.Header titles={['Version', '', '', '']} />
      <tbody>
        {versionList.map(code => (
          <Table.Row key={code}>
            <Table.Cell label={`Visualizer ${code}`} />
            <Table.Cell label={' '} />
            <Table.Cell
              render={
                <Button
                  onClick={handleNavigationClick(code)}
                  size="large"
                  className="w-11/12 text-white rounded bg-green-400"
                >
                  Start
                </Button>
              }
            />
          </Table.Row>
        ))}
      </tbody>
    </Table>
  )
}

export default VersionTable
