import { message } from 'antd'
import { ExtractionActions } from 'data/actions'
import { AppSelectors } from 'data/app'
import { ExtractionSelectors } from 'data/selectors'
import React, { Dispatch, SetStateAction, useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import Selecto, { OnSelect } from 'react-selecto'
import { ResponseReceiptAnnotationType } from 'services/api/requests/annotation'
import { useEventListener } from 'services/hooks/use-event-listener'
import useGetDimensions from 'services/hooks/use-get-dimensions'
import {
  EXTRACTION_SIDER_WIDTH,
  HEADER_HEIGHT,
  SIDER_WIDTH
} from 'services/styles/layout'

type ZoomState = {
  positionX?: number
  positionY?: number
  previousScale?: number
  scale?: number
}

interface Props {
  editingText: boolean
  zoomState?: ZoomState
  dragContainer: string
  setPopupList: Dispatch<SetStateAction<any[]>>
}

const SelectableArea = ({
  editingText,
  zoomState,
  dragContainer,
  setPopupList
}: Props) => {
  const { width } = useGetDimensions()
  const EXTRACTION_CONTENT_WIDTH = width - EXTRACTION_SIDER_WIDTH
  const initialTargetsList = ['.text-box']

  const dispatch = useDispatch()
  const data = useSelector(ExtractionSelectors.data)
  const isSiderOpen = useSelector(AppSelectors.siderState)
  const currentPage = useSelector(ExtractionSelectors.currentPage)

  const [selectableTargets, setSelectableTargets] = useState<string[]>(
    initialTargetsList
  )
  const [draggable, setDraggable] = useState(true)
  const [createMode, setCreateMode] = useState(false)

  const handleUpdatePopupList = () => {
    const textBoxList = Array.from(
      document.querySelectorAll(selectableTargets[0])
    )
    const newPopupList = textBoxList.map(item => {
      if (item.classList.contains('last-box')) {
        return true
      }
      if (item.classList.contains('selected')) {
        return false
      }
      return null
    })
    setPopupList(newPopupList)
  }

  const handleOnStart = (e: OnSelect) =>
    e.currentTarget.getSelectableElements().forEach(el => {
      el.classList.remove('last-box')
    })

  const handleOnEnd = (e: OnSelect) => {
    if (createMode) {
      const { rect } = e
      const { positionX, positionY, scale } = zoomState

      const accountForSider = isSiderOpen ? SIDER_WIDTH : 0

      const newBox = {
        boundingPoly: {
          vertices: [
            {
              x: (rect.left - positionX - accountForSider) / scale,
              y: (rect.top - positionY - HEADER_HEIGHT) / scale
            },
            {
              x: (rect.right - positionX - accountForSider) / scale,
              y: (rect.bottom - positionY - HEADER_HEIGHT) / scale
            }
          ]
        },
        text: ''
      }

      const currentExtractedData = {
        ...data,
        [currentPage]: {
          ...data[currentPage],
          annotations: [...(data[currentPage]?.annotations || []), newBox]
        }
      } as ResponseReceiptAnnotationType

      dispatch(ExtractionActions.setRawData(currentExtractedData))
    } else {
      e.added.forEach((el, index) => {
        if (index === e.added.length - 1) {
          el.classList.add('last-box')
        }
      })

      handleUpdatePopupList()
    }
  }

  // add selected class which change color of boxes
  const handleOnSelect = (e: OnSelect) => {
    e.added.forEach(el => {
      el.classList.add('selected')
    })
    e.removed.forEach(el => {
      el.classList.remove('selected')
    })
  }

  // prevent drag when in move cursor mode.
  const keydownEventHandler = ({ key }: { key: string }) => {
    if (String(key) === '+' || String(key) === '-' || String(key) === ' ') {
      setDraggable(false)
    }

    if (String(key) === '`' && !editingText) {
      setCreateMode(true)
    }

    if (String(key).toLowerCase() === 'o' && !editingText) {
      setSelectableTargets(['.highlight'])
    }
  }

  const keyupEventHandler = () => {
    setDraggable(true)
    setCreateMode(false)
    setSelectableTargets(initialTargetsList)
  }

  useEffect(() => {
    if (createMode) {
      message.open({
        key: 'create-mode',
        type: 'info',
        content: 'Drag your mouse to create a new box',
        duration: 0
      })
    } else {
      message.destroy('create-mode')
    }
  }, [createMode])

  useEventListener('keydown', keydownEventHandler)
  useEventListener('keyup', keyupEventHandler)

  return (
    <Selecto
      dragCondition={() => draggable}
      dragContainer={dragContainer}
      selectableTargets={selectableTargets}
      hitRate={2}
      selectByClick
      selectFromInside
      ratio={0}
      onSelectStart={handleOnStart}
      onSelect={handleOnSelect}
      onSelectEnd={handleOnEnd}
    />
  )
}

export default SelectableArea
