import { Tooltip } from 'antd'
import { TaskContext } from 'pages/app/pii-redaction/context'
import Hotkeys from 'pages/app/pii-redaction/footer/hotkeys'
import React, { useContext } from 'react'

const TaskDetails = () => {
  const { state } = useContext(TaskContext)
  const { data } = state

  return (
    <div className="flex items-center justify-between w-full mr-2">
      <div className="flex items-center">
        <Tooltip title={Hotkeys} overlayStyle={{ maxWidth: '500px' }}>
          <span className="underline">Hotkeys</span>
        </Tooltip>{' '}
        <span className="mx-2">|</span>
        <span>
          File ID: <span className="font-bold">{data?.file_id}</span>
        </span>
      </div>
    </div>
  )
}

export default TaskDetails
