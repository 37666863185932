import { BoxLabelEnum } from 'data/box'

export const columnsStyle = {
  [BoxLabelEnum.ITEM_DESCRIPTION]: 'w-4/12 flex',
  [BoxLabelEnum.ITEM_CODE]: 'w-1/12 flex',
  [BoxLabelEnum.ITEM_UNIT_PRICE]: 'w-1/12 flex',
  [BoxLabelEnum.ITEM_QUANTITY]: 'w-1/12 flex',
  [BoxLabelEnum.ITEM_VALUE]: 'w-1/12 flex flex-col justify-end',
  [BoxLabelEnum.ITEM_PROMO_NAME]: 'w-10/12',
  [BoxLabelEnum.ITEM_PROMO_PRICE]: 'w-2/12'
}
