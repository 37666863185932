import { Table } from 'antd'
import { TableProps } from 'antd/lib/table/Table'
import React from 'react'

const TableStripedRows = (props: TableProps<any>) => (
  <Table
    defaultExpandAllRows
    bordered
    rowClassName={(_, index) =>
      index % 2 === 0 ? 'table-row-light' : 'table-row-dark'
    }
    pagination={false}
    {...props}
  />
)

export default TableStripedRows
