export const parseCurrency = (currency: string | number) =>
  Number(
    String(currency)
      .replace(',', '.')
      .replace(/[^0-9\.-]+/g, '')
  )

export const parseCurrencyStrict = (currency: string | number) =>
  Number(
    String(currency)
      .replace(',', '.')
      .replace(/[^0-9\.]+/g, '')
  )

export const formatNumber = (number: number, minDigit = 2) =>
  number.toLocaleString('en-US', {
    minimumIntegerDigits: minDigit,
    useGrouping: false
  })
