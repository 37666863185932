import TimeoutModal from 'components/idle-modal'
import { DialogKeys } from 'components/modals/types'
import { AppActions, OverlayActions } from 'data/actions'
import { AppSelectors } from 'data/selectors'
import React, { useEffect } from 'react'
import { useIdleTimer, workerTimers } from 'react-idle-timer'
import { useDispatch, useSelector } from 'react-redux'
import { useFirebase } from 'react-redux-firebase'
import { diffSeconds } from 'services/helpers/time'
import { TimerMessage } from './constants'

const TIMEOUT_IN_MINUTE = 15

const PROMPT_TIME_IN_MINUTE = 5

const useCustomIdleTimer = () => {
  const dispatch = useDispatch()
  const appLastActiveTime = useSelector(AppSelectors.lastActiveTime)

  const firebase = useFirebase()

  const broadcastMessage = (action: TimerMessage, emitOnSelf: boolean) =>
    message({ action }, emitOnSelf)

  const handleLogout = async () => {
    await firebase.logout()
    broadcastMessage(TimerMessage.RESET_LAST_ACTIVE_TIME, true)
  }

  // Check if last active time is expired
  useEffect(() => {
    if (appLastActiveTime) {
      const currentTime = new Date()
      const diff = diffSeconds(currentTime, new Date(appLastActiveTime))

      if (diff > TIMEOUT_IN_MINUTE * 60 + PROMPT_TIME_IN_MINUTE * 60) {
        ;(async () => await handleLogout())()
        broadcastMessage(TimerMessage.NOTIFY_AUTO_LOGOUT, true)
      }
    } else {
      broadcastMessage(TimerMessage.SET_LAST_ACTIVE_TIME, true)
    }
  }, [])

  const onPrompt = () =>
    dispatch(
      OverlayActions.showDialog({
        key: DialogKeys.PROMPT_IDLE,
        component: (
          <TimeoutModal.PromptIdle
            isPrompted={isPrompted}
            getRemainingTime={getRemainingTime}
            onOk={handleIdleYes}
            onCancel={handleIdleNo}
          />
        )
      })
    )

  const onIdle = async () => {
    await handleLogout()
    broadcastMessage(TimerMessage.CLOSE_FOLLOWERS_MODAL, true)
    broadcastMessage(TimerMessage.NOTIFY_AUTO_LOGOUT, true)
  }

  // Listen to broadcast message
  const onMessage = data => {
    switch (data.action) {
      case TimerMessage.SET_LAST_ACTIVE_TIME:
        dispatch(AppActions.setLastActiveTime(new Date()))
        break
      case TimerMessage.RESET_LAST_ACTIVE_TIME:
        dispatch(AppActions.setLastActiveTime(null))
        break
      case TimerMessage.CLOSE_FOLLOWERS_MODAL:
        dispatch(OverlayActions.hideDialog({ key: DialogKeys.PROMPT_IDLE }))
        break
      case TimerMessage.NOTIFY_AUTO_LOGOUT:
        dispatch(
          OverlayActions.showDialog({
            key: DialogKeys.SESSION_TIMEOUT,
            component: <TimeoutModal.SessionTimeout />
          })
        )
        break
      default:
      // no op
    }
  }

  const onAction = () =>
    broadcastMessage(TimerMessage.SET_LAST_ACTIVE_TIME, true)

  const { activate, getRemainingTime, isPrompted, message } = useIdleTimer({
    onPrompt,
    onIdle,
    onAction,
    onMessage,
    timeout: 1000 * 60 * TIMEOUT_IN_MINUTE,
    promptTimeout: 1000 * 60 * PROMPT_TIME_IN_MINUTE,
    events: [
      'mousemove',
      'keydown',
      'wheel',
      'DOMMouseScroll',
      'mousewheel',
      'mousedown',
      'touchstart',
      'touchmove'
    ],
    immediateEvents: [],
    debounce: 0,
    throttle: 1000,
    eventsThrottle: 200,
    element: document,
    startOnMount: true,
    startManually: false,
    stopOnIdle: true,
    crossTab: true,
    name: 'idle-timer',
    syncTimers: 1000 * 5,
    leaderElection: false,
    timers: workerTimers
  })

  const handleIdleYes = () => {
    activate()
    broadcastMessage(TimerMessage.CLOSE_FOLLOWERS_MODAL, false)
  }

  const handleIdleNo = async () => {
    await handleLogout()
    broadcastMessage(TimerMessage.CLOSE_FOLLOWERS_MODAL, false)
  }
}

export default useCustomIdleTimer
