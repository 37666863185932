import { BoxLabelEnum } from 'data/box'
import Field from 'pages/app/extraction/version-2/task/content/components/field'
import { columnsStyle } from 'pages/app/extraction/version-2/task/content/line-section/styles'
import React from 'react'

const LineItemsHeader = ({
  subRequirement,
  isUnitPrice,
  handleSubRequirementsChange,
  handleModeChange
}) => {
  const handleSubReqChange = (checked: boolean, option: string) =>
    handleSubRequirementsChange({ [option]: checked })

  // const handleUnitPriceModeChange = (checked: boolean) =>
  //   handleModeChange(checked)

  const isSubRequirement = label =>
    [BoxLabelEnum.ITEM_CODE, BoxLabelEnum.ITEM_QUANTITY].includes(label)

  const settingsProps = {
    [BoxLabelEnum.ITEM_CODE]: 'barcode',
    [BoxLabelEnum.ITEM_QUANTITY]: 'quantity',
    [BoxLabelEnum.ITEM_UNIT_PRICE]: 'unitPrice'
  }

  return (
    <div className="flex gap-2">
      <div style={{ width: '1.25rem' }} />
      {Object.entries(columnsStyle)
        .slice(0, 5)
        .map(([label, style]) => (
          <div key={label} className={`flex-grow ${style}`}>
            <Field label={label} wrapperClassName={'flex-grow-0 justify-end'} />
          </div>
        ))}
    </div>
  )
}

export default LineItemsHeader
