import { encode } from 'base-64'

export const sleep = ms => new Promise(resolve => setTimeout(resolve, ms))

export const formatSqlValue = (object: any) => {
  let newObject = {}

  Object.entries(object || {}).forEach(([key, value]) => {
    const valueType = typeof value

    // valueType checks
    if (!value && valueType !== 'string') {
      newObject = {
        ...newObject,
        [key]: value
      }
      return
    }
    if (valueType === 'number') {
      newObject = {
        ...newObject,
        [key]: value
      }
      return
    }

    if (Array.isArray(value)) {
      newObject = {
        ...newObject,
        [key]: `$AdminServiceFrontend$\{${value}}$AdminServiceFrontend$`
      }
      return
    }
    if (valueType === 'object') {
      newObject = {
        ...newObject,
        [key]: `$AdminServiceFrontend$${JSON.stringify(
          value
        )}$AdminServiceFrontend$`
      }
      return
    }

    // key checks
    if (key.includes('timestamp')) {
      newObject = {
        ...newObject,
        [key]: !value
          ? value
          : `$AdminServiceFrontend$${value}$AdminServiceFrontend$`
      }
      return
    }
    if (
      valueType === 'string' ||
      key.toLowerCase().includes('date') ||
      key.toLowerCase().includes('time')
    ) {
      // Escape quotes to avoid SQL error/injection
      newObject = {
        ...newObject,
        [key]: `$AdminServiceFrontend$${value
          .toString()
          .replace(/\$AdminServiceFrontend\$/g, '')}$AdminServiceFrontend$`
      }
      return
    }

    newObject = {
      ...newObject,
      [key]: value
    }
  })

  return newObject
}

export const preloadImage = (src: string) =>
  new Promise((resolve, reject) => {
    const img = new Image()
    img.onload = function () {
      resolve(img)
    }
    img.onerror = img.onabort = function () {
      reject(src)
    }
    img.src = src
  })

export const reduceImageQuality = (
  url: string,
  quality: number = 20,
  shouldEscape = true
) => {
  const escapedUrl = shouldEscape ? escape(url) : url
  return `https://lambda.unocart.com/resize-image/${encode(
    escapedUrl
  )}?quality=${quality}&autoRotate=true`
}
