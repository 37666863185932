type vertex = {
  x: number
  y: number
}

export type BoxProps = {
  id: string | number
  label: string
  text: string
  boundingPoly: {
    vertices: vertex[]
  }
  links: (string | number)[]
}

export interface BoxState {
  boxes: BoxProps[]
  activeId?: string | number // link source and pop up
  targetId?: string | number // link target
}

export enum BoxLabelEnum {
  ITEM_DESCRIPTION = 'ItemDescription',
  ITEM_VALUE = 'ItemValue',
  ITEM_UNIT_PRICE = 'ItemUnitPrice',
  ITEM_QUANTITY = 'ItemQuantity',
  ITEM_CODE = 'ItemCode',
  ITEM_PROMO_NAME = 'ItemPromoName',
  ITEM_PROMO_PRICE = 'ItemPromoPrice',
  RECEIPT_SUBTOTAL_VALUE = 'ReceiptSubtotalValue',
  RECEIPT_TOTAL_VALUE = 'ReceiptTotalValue',
  RECEIPT_TOTAL_TAX = 'ReceiptTotalTax',
  RECEIPT_TIP = 'ReceiptTip',
  RECEIPT_MERCHANT_NAME = 'ReceiptMerchantName',
  RECEIPT_STORE_ADDRESS = 'ReceiptStoreAddress',
  RECEIPT_STORE_PHONE = 'ReceiptStorePhone',
  RECEIPT_PURCHASE_DATE = 'ReceiptPurchaseDate',
  RECEIPT_PURCHASE_TIME = 'ReceiptPurchaseTime',
  OTHER = 'Other',

  ITEM_UNIT_TYPE = 'ItemUnitType',
  RECEIPT_STORE_ID = 'ReceiptStoreId',
  RECEIPT_STORE_NAME = 'ReceiptStoreName',
  RECEIPT_TOTAL_DISCOUNT = 'ReceiptTotalDiscount',
  RECEIPT_CASH_REGISTER_ID = 'ReceiptCashRegisterId',
  RECEIPT_TRANSACTION_ID = 'ReceiptTransactionId',

  //  New Fields
  ITEM_OTHER = 'ItemOther'
}

export const prettyBoxLabel = {
  [BoxLabelEnum.ITEM_DESCRIPTION]: 'Name',
  [BoxLabelEnum.ITEM_VALUE]: 'Total Price',
  [BoxLabelEnum.ITEM_UNIT_PRICE]: 'Unit Price',
  [BoxLabelEnum.ITEM_QUANTITY]: 'Qty',
  [BoxLabelEnum.ITEM_CODE]: 'Product ID',
  [BoxLabelEnum.ITEM_PROMO_NAME]: 'PromoName',
  [BoxLabelEnum.ITEM_PROMO_PRICE]: 'PromoPrice',
  [BoxLabelEnum.RECEIPT_SUBTOTAL_VALUE]: 'Subtotal',
  [BoxLabelEnum.RECEIPT_TOTAL_VALUE]: 'Total',
  [BoxLabelEnum.RECEIPT_TOTAL_TAX]: 'Tax',
  [BoxLabelEnum.RECEIPT_TIP]: 'Tip',
  [BoxLabelEnum.RECEIPT_MERCHANT_NAME]: 'Name',
  [BoxLabelEnum.RECEIPT_STORE_ADDRESS]: 'Address',
  [BoxLabelEnum.RECEIPT_STORE_PHONE]: 'Phone',
  [BoxLabelEnum.RECEIPT_PURCHASE_DATE]: 'Date',
  [BoxLabelEnum.RECEIPT_PURCHASE_TIME]: 'Time',
  [BoxLabelEnum.OTHER]: 'Other',

  [BoxLabelEnum.ITEM_UNIT_TYPE]: 'UnitType',
  [BoxLabelEnum.RECEIPT_STORE_ID]: 'Store Number',
  [BoxLabelEnum.RECEIPT_STORE_NAME]: 'Store Name',
  [BoxLabelEnum.RECEIPT_TOTAL_DISCOUNT]: 'Total Discount',
  [BoxLabelEnum.RECEIPT_CASH_REGISTER_ID]: 'Register Id',
  [BoxLabelEnum.RECEIPT_TRANSACTION_ID]: 'Transaction Number',

  //  new Fields
  [BoxLabelEnum.ITEM_OTHER]: 'Item Other'
}

// priority order: most use label appear at top
export const boxLabelsList = [
  BoxLabelEnum.ITEM_DESCRIPTION,
  BoxLabelEnum.ITEM_VALUE,
  BoxLabelEnum.ITEM_UNIT_PRICE,
  BoxLabelEnum.ITEM_QUANTITY,
  BoxLabelEnum.ITEM_CODE,
  BoxLabelEnum.ITEM_PROMO_NAME,
  BoxLabelEnum.ITEM_PROMO_PRICE,
  BoxLabelEnum.RECEIPT_MERCHANT_NAME,
  BoxLabelEnum.RECEIPT_STORE_ADDRESS,
  BoxLabelEnum.RECEIPT_STORE_PHONE,
  BoxLabelEnum.RECEIPT_TOTAL_VALUE,
  BoxLabelEnum.RECEIPT_SUBTOTAL_VALUE,
  BoxLabelEnum.RECEIPT_TOTAL_TAX,
  BoxLabelEnum.RECEIPT_TIP,
  BoxLabelEnum.RECEIPT_PURCHASE_DATE,
  BoxLabelEnum.RECEIPT_PURCHASE_TIME,
  BoxLabelEnum.OTHER,

  BoxLabelEnum.ITEM_UNIT_TYPE,
  BoxLabelEnum.RECEIPT_STORE_ID,
  BoxLabelEnum.RECEIPT_STORE_NAME,
  BoxLabelEnum.RECEIPT_TOTAL_DISCOUNT,
  BoxLabelEnum.RECEIPT_CASH_REGISTER_ID,
  BoxLabelEnum.RECEIPT_TRANSACTION_ID,

  //  new fields
  BoxLabelEnum.ITEM_OTHER
]

export const LINKABLE_FIELDS = [
  BoxLabelEnum.ITEM_DESCRIPTION,
  BoxLabelEnum.ITEM_VALUE,
  BoxLabelEnum.ITEM_UNIT_PRICE,
  BoxLabelEnum.ITEM_QUANTITY,
  BoxLabelEnum.ITEM_CODE,
  BoxLabelEnum.ITEM_PROMO_NAME,
  BoxLabelEnum.ITEM_PROMO_PRICE
]

export const REGULAR_FIELDS = boxLabelsList.filter(
  i => !LINKABLE_FIELDS.includes(i)
)

export const IMPORTANT_FIELDS = [
  BoxLabelEnum.RECEIPT_MERCHANT_NAME,
  BoxLabelEnum.RECEIPT_STORE_ADDRESS,
  BoxLabelEnum.RECEIPT_STORE_PHONE,
  BoxLabelEnum.RECEIPT_PURCHASE_DATE,
  BoxLabelEnum.RECEIPT_PURCHASE_TIME,
  BoxLabelEnum.RECEIPT_TOTAL_VALUE,
  BoxLabelEnum.RECEIPT_SUBTOTAL_VALUE,

  //  new fields
  BoxLabelEnum.RECEIPT_STORE_NAME,
  BoxLabelEnum.RECEIPT_STORE_ID,
  BoxLabelEnum.RECEIPT_TOTAL_DISCOUNT,
  BoxLabelEnum.RECEIPT_CASH_REGISTER_ID,
  BoxLabelEnum.RECEIPT_TRANSACTION_ID
]
