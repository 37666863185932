import { TaskContext } from 'pages/app/annotation-tool/task/context'
import React, { useContext } from 'react'
import { Field } from 'services/api/requests/types'

interface Props {
  field: Field
}

const LinkLine = ({ field }: Props) => {
  const { state: parentState } = useContext(TaskContext)
  const { record, currentPage } = parentState
  const currentPageData = record.pages[currentPage]
  const { fields } = currentPageData

  if (!field?.links?.length) return null

  const [
    { x: startX0, y: startY0 },
    ,
    { x: startX1, y: startY1 }
  ] = field.boundingPoly.vertices

  return (
    <g>
      {field.links.map(link => {
        const targetBox = fields.find(item => item.id === link)
        if (!targetBox) return null

        const [
          { x: endX0, y: endY0 },
          ,
          { x: endX1, y: endY1 }
        ] = targetBox.boundingPoly.vertices

        const startCenter = {
          x: (startX0 + startX1) / 2,
          y: (startY0 + startY1) / 2
        }
        const endCenter = {
          x: (endX0 + endX1) / 2,
          y: (endY0 + endY1) / 2
        }

        return (
          <line
            style={{ opacity: 1 }}
            key={`${field.id}-${link}-${startCenter.x}-${endCenter.x}`}
            x1={startCenter.x}
            y1={startCenter.y}
            x2={endCenter.x}
            y2={endCenter.y}
            stroke={'red'}
          />
        )
      })}
    </g>
  )
}

export default LinkLine
