import { PermissionEnum } from 'data/reducers'
import { ForbiddenPage } from 'pages/app'
import {
  AdminAnnotationOverview,
  AdminHome,
  MerchantDefinitionOverview,
  UsersOverview
} from 'pages/app/admin'
import RelabelReceiptPage from 'pages/app/admin/relabel-receipt'
import { ReceiptHistory } from 'pages/app/receipt-history'
import React from 'react'
import { AppRoute, AppSubRoutes } from 'routes/app/routes'
import VisualizerRoutesNavigator from 'routes/app/visualizer'
import useHandleRequirePermissions from 'services/hooks/use-handle-require-permission'
import SentryRoute from 'services/sentry/sentry-route'

const AdminRoutesNavigator = () => {
  const hasPermission = useHandleRequirePermissions([PermissionEnum.ADMIN])
  return (
    <>
      <SentryRoute
        exact
        path={AppRoute.ADMIN.pathname}
        component={hasPermission ? AdminHome : ForbiddenPage}
      />
      <SentryRoute
        exact
        path={AppSubRoutes.ADMIN.COMPLETED_MERCHANTS.pathname}
        component={hasPermission ? AdminAnnotationOverview : ForbiddenPage}
      />
      <SentryRoute
        exact
        path={AppSubRoutes.ADMIN.MERCHANT_DEFINITION.pathname}
        component={hasPermission ? MerchantDefinitionOverview : ForbiddenPage}
      />
      <SentryRoute
        exact
        path={AppSubRoutes.ADMIN.PROCESSING_HISTORY.pathname}
        component={hasPermission ? ReceiptHistory : ForbiddenPage}
      />
      <SentryRoute
        exact
        path={AppSubRoutes.ADMIN.USERS.pathname}
        component={hasPermission ? UsersOverview : ForbiddenPage}
      />
      <SentryRoute
        exact
        path={AppSubRoutes.ADMIN.RELABEL_RECEIPT.pathname}
        component={hasPermission ? RelabelReceiptPage : ForbiddenPage}
      />
      <VisualizerRoutesNavigator />
    </>
  )
}

export default AdminRoutesNavigator
