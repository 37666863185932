export const traverseLinks = (graph: any, links: (string | number)[]) => {
  const visited = []
  const _findAllLinkedBox = (graph: any, links: (string | number)[]): any =>
    links.reduce((acc, link) => {
      const linkedBox = graph.find(box => box.id === link)
      const result = linkedBox ? { ...acc, [linkedBox.label]: linkedBox } : acc
      if (!visited.includes(linkedBox?.id)) {
        visited.push(linkedBox?.id)
        if (linkedBox?.links?.length) {
          const deeplink = _findAllLinkedBox(graph, linkedBox?.links)
          return { ...result, ...deeplink }
        }
      }
      return result
    }, {})

  return _findAllLinkedBox(graph, links)
}
