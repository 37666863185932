import { Button, Form, Image, Input } from 'antd'
import Logo from 'assets/misc/logo-with-text.png'
import { AuthSelectors } from 'data/selectors'
import React, { useState } from 'react'
import { useSelector } from 'react-redux'
import { useFirebase } from 'react-redux-firebase'
import { useHistory } from 'react-router'
import { AuthRoute } from 'routes/auth/routes'
import {
  confirmPasswordValidationRules,
  emailValidationRules,
  passwordValidationRules
} from 'services/form-rules'

const formItemLayout = {
  labelCol: { span: 24, style: { margin: 0, padding: 0 } },
  wrapperCol: { span: 24, style: { margin: 0 } }
}

export default () => {
  const firebase = useFirebase()
  const authError = useSelector(AuthSelectors.firebaseAuthError)
  const history = useHistory()
  const [isLoading, setIsLoading] = useState<boolean>(false)

  const handleRegister = async ({ email, password, firstName, lastName }) => {
    try {
      setIsLoading(true)
      await firebase.createUser(
        { email, password },
        {
          username: `${firstName} ${lastName}`,
          email,
          role: 'editor',
          permissions: []
        }
      )
    } finally {
      setIsLoading(false)
    }
  }

  const handleNavigationClick = () => history.push(AuthRoute.LOGIN)

  return (
    <div
      className="justify-center items-center flex flex-col"
      style={{ width: 350 }}
    >
      <Image preview={false} src={Logo} style={{ height: '100%' }} alt="logo" />

      <Form
        {...formItemLayout}
        className="w-full mt-6 no-gap-form"
        key={'register-form'}
        name="basic"
        labelAlign="left"
        scrollToFirstError
        onFinish={handleRegister}
      >
        <Form.Item
          key={'register-firstname'}
          name="firstName"
          label={'First name'}
        >
          <Input
            className="h-12 rounded-md"
            autoComplete="off"
            placeholder="e.g John"
          />
        </Form.Item>
        <Form.Item
          key={'register-firstname'}
          name="lastName"
          label={'Last name'}
        >
          <Input
            className="h-12 rounded-md"
            autoComplete="off"
            placeholder="e.g Smith"
          />
        </Form.Item>
        <Form.Item
          key={'register-email'}
          name="email"
          label={'Email'}
          rules={emailValidationRules}
        >
          <Input
            className="h-12 rounded-md"
            autoComplete="off"
            placeholder="e.g johnsmith@gmail.com"
          />
        </Form.Item>
        <Form.Item
          key={'register-password'}
          name="password"
          label={'Password'}
          validateFirst
          rules={passwordValidationRules}
        >
          <Input.Password
            className="h-12 rounded-md"
            autoComplete={'new-password'}
            placeholder="Password"
          />
        </Form.Item>
        <Form.Item
          key={'confirm-password'}
          name="confirmPassword"
          label={'Confirm password'}
          dependencies={['password']}
          rules={confirmPasswordValidationRules}
        >
          <Input.Password
            className="h-12 rounded-md mb-2"
            autoComplete={'new-password'}
            placeholder="********"
          />
        </Form.Item>

        <Form.Item noStyle>
          <Button
            loading={isLoading}
            className="rounded-md h-12 mt-4 w-full bg-black text-white hover:bg-black focus:bg-black"
            htmlType="submit"
          >
            Sign Up
          </Button>
        </Form.Item>
        <div className="text-red-500">{authError?.message || ''}</div>
      </Form>

      <span className="mt-6">
        Already have an account?
        <span
          className="underline cursor-pointer text-blue-400"
          onClick={handleNavigationClick}
        >
          {' '}
          Sign In
        </span>
      </span>
    </div>
  )
}
