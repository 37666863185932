import { BoxLabelEnum } from 'data/box'

const merchant = {
  [BoxLabelEnum.RECEIPT_MERCHANT_NAME]: 'E',
  [BoxLabelEnum.RECEIPT_STORE_ADDRESS]: 'R',
  [BoxLabelEnum.RECEIPT_STORE_PHONE]: 'T',
  [BoxLabelEnum.RECEIPT_STORE_ID]: 'Y'
}

const lineItem = {
  [BoxLabelEnum.ITEM_DESCRIPTION]: '1',
  [BoxLabelEnum.ITEM_QUANTITY]: '2',
  [BoxLabelEnum.ITEM_UNIT_PRICE]: '3',
  [BoxLabelEnum.ITEM_VALUE]: '4',
  [BoxLabelEnum.ITEM_CODE]: '5'
}

const promotion = {
  [BoxLabelEnum.ITEM_PROMO_NAME]: 'Q',
  [BoxLabelEnum.ITEM_PROMO_PRICE]: 'W'
}

const other = {
  [BoxLabelEnum.RECEIPT_CASH_REGISTER_ID]: 'D',
  [BoxLabelEnum.RECEIPT_TRANSACTION_ID]: 'F'
}

const total = {
  [BoxLabelEnum.RECEIPT_TOTAL_DISCOUNT]: 'A',
  [BoxLabelEnum.RECEIPT_SUBTOTAL_VALUE]: 'C',
  [BoxLabelEnum.RECEIPT_TOTAL_TAX]: 'V',
  [BoxLabelEnum.RECEIPT_TOTAL_VALUE]: 'B',
  [BoxLabelEnum.RECEIPT_TIP]: 'S' // not being used
}

const time = {
  [BoxLabelEnum.RECEIPT_PURCHASE_DATE]: 'Z',
  [BoxLabelEnum.RECEIPT_PURCHASE_TIME]: 'X'
}

const Hotkeys = {
  ...merchant,
  ...lineItem,
  ...promotion,
  ...total,
  ...time,
  ...other
}

export default Hotkeys
