import { message } from 'antd'
import { AppSelectors } from 'data/app'

import { UserAction } from 'pages/app/extraction/version-2/reducers'
import React, { Dispatch, SetStateAction, useEffect, useState } from 'react'
import { useSelector } from 'react-redux'
import Selecto, { OnSelect } from 'react-selecto'
import { DBReceiptAnnotation } from 'services/api/requests/types'
import { useEventListener } from 'services/hooks/use-event-listener'
import useGetDimensions from 'services/hooks/use-get-dimensions'
import {
  EXTRACTION_SIDER_WIDTH,
  HEADER_HEIGHT,
  SIDER_WIDTH
} from 'services/styles/layout'
import { pathToId, verticesToId } from '../helpers'

type ZoomState = {
  positionX?: number
  positionY?: number
  previousScale?: number
  scale?: number
}

// TODO type handleDataChange
interface Props {
  data: DBReceiptAnnotation
  handleDataChange: (newValue) => void
  handleUserActionsChange: (newValue) => void
  currentPage: number
  editingText: boolean
  zoomState?: ZoomState
  dragContainer: string
  setPopupList: Dispatch<SetStateAction<any[]>>
}

const SelectableArea = ({
  data,
  handleDataChange,
  handleUserActionsChange,
  currentPage,
  editingText,
  zoomState,
  dragContainer,
  setPopupList
}: Props) => {
  const { width } = useGetDimensions()
  const EXTRACTION_CONTENT_WIDTH = width - EXTRACTION_SIDER_WIDTH
  const initialTargetsList = ['.text-box']

  const isSiderOpen = useSelector(AppSelectors.siderState)

  const [selectableTargets, setSelectableTargets] = useState<string[]>(
    initialTargetsList
  )
  const [draggable, setDraggable] = useState(true)
  const [createMode, setCreateMode] = useState(false)

  const handleUpdatePopupList = () => {
    const textBoxList = Array.from(
      document.querySelectorAll(selectableTargets[0])
    )
    const newPopupList = textBoxList.map(item => {
      if (item.classList.contains('last-box')) {
        return true
      }
      if (item.classList.contains('selected')) {
        return false
      }
      return null
    })
    setPopupList(newPopupList)
  }

  const handleOnStart = (e: OnSelect) =>
    e.currentTarget.getSelectableElements().forEach(el => {
      el.classList.remove('last-box')
    })

  const handleOnEnd = (e: OnSelect) => {
    const { rect } = e
    const { positionX, positionY, scale } = zoomState
    const accountForSider = isSiderOpen ? SIDER_WIDTH : 0

    const newBoxVertices = [
      {
        x: (rect.left - positionX - accountForSider) / scale,
        y: (rect.top - positionY - HEADER_HEIGHT) / scale
      },
      {
        x: (rect.right - positionX - accountForSider) / scale,
        y: (rect.top - positionY - HEADER_HEIGHT) / scale
      },
      {
        x: (rect.right - positionX - accountForSider) / scale,
        y: (rect.bottom - positionY - HEADER_HEIGHT) / scale
      },
      {
        x: (rect.left - positionX - accountForSider) / scale,
        y: (rect.bottom - positionY - HEADER_HEIGHT) / scale
      }
    ]
    const newBox = {
      id: verticesToId(newBoxVertices),
      boundingPoly: {
        vertices: newBoxVertices
      },
      text: ''
    }
    if (createMode) {
      const updatedPage = data.pages.map((page, index) =>
        index === currentPage
          ? {
              ...page,
              words: [...page.words, newBox]
            }
          : page
      )

      const currentExtractedData = {
        ...data,
        pages: updatedPage
      } as DBReceiptAnnotation

      handleDataChange(currentExtractedData)
    } else {
      e.added.forEach((el, index) => {
        if (index === e.added.length - 1) {
          el.classList.add('last-box')
        }
      })

      handleUpdatePopupList()

      // !this will not track if user remove selection
      if (e.added.length > 0 && selectableTargets.includes('.text-box')) {
        let addedIds = []
        e.added.forEach(ele => {
          const wordId = pathToId(ele.getAttribute('d'))
          addedIds = [...addedIds, wordId]
        })
        const newAction: UserAction = {
          type: e.added.length > 1 ? 'drag' : 'click',
          boundingPoly: newBox.boundingPoly,
          fieldElements: addedIds
        }
        handleUserActionsChange([newAction])
      }
    }
  }

  // add selected class which change color of boxes
  const handleOnSelect = (e: OnSelect) => {
    e.added.forEach(el => {
      el.classList.add('selected')
    })
    e.removed.forEach(el => {
      el.classList.remove('selected')
    })
  }

  // prevent drag when in move cursor mode.
  const keydownEventHandler = ({ key }: { key: string }) => {
    if (String(key) === '+' || String(key) === '-' || String(key) === ' ') {
      setDraggable(false)
    }

    if (String(key) === '`' && !editingText) {
      setCreateMode(true)
    }

    if (String(key).toLowerCase() === 'o' && !editingText) {
      setSelectableTargets(['.highlight'])
    }
  }

  const keyupEventHandler = () => {
    setDraggable(true)
    setCreateMode(false)
    setSelectableTargets(initialTargetsList)
  }

  useEffect(() => {
    if (createMode) {
      message.open({
        key: 'create-mode',
        type: 'info',
        content: 'Drag your mouse to create a new box',
        duration: 0
      })
    } else {
      message.destroy('create-mode')
    }
  }, [createMode])

  useEventListener('keydown', keydownEventHandler)
  useEventListener('keyup', keyupEventHandler)

  return (
    <Selecto
      dragCondition={() => draggable}
      dragContainer={dragContainer}
      selectableTargets={selectableTargets}
      hitRate={2}
      selectByClick
      selectFromInside
      toggleContinueSelect={'shift'}
      ratio={0}
      onSelectStart={handleOnStart}
      onSelect={handleOnSelect}
      onSelectEnd={handleOnEnd}
    />
  )
}

export default SelectableArea
