import { Layout, Modal } from 'antd'
import EmptyComponent from 'components/empty-component'
import SplashScreen from 'components/splash'
import { AuditActions } from 'data/audit'
import { AuditSelectors } from 'data/selectors'
import AuditContent from 'pages/app/audit/user/content'
import AuditFooter from 'pages/app/audit/user/footer'
import GenericAuditSider from 'pages/app/audit/user/sider'
import React, { useEffect, useState } from 'react'
import { useQuery } from 'react-query'
import { useDispatch, useSelector } from 'react-redux'
import { useLocation } from 'react-router'
import {
  Noctua2AdminApi,
  Noctua2LabelApi
} from 'services/api/requests/notua-ii'
import {
  AUDIT_SIDER_WIDTH,
  FOOTER_HEIGHT,
  HEADER_HEIGHT
} from 'services/styles/layout'

const { Content, Sider, Footer } = Layout

const AuditUserPage = () => {
  const { pathname } = useLocation()
  const userId = pathname.replace(/^.*[\\\/]/, '')
  const dispatch = useDispatch()
  const receiptFilter = useSelector(AuditSelectors.receiptFilter)
  const currentIndex = useSelector(AuditSelectors.currentIndex)
  const receiptIdsList = useSelector(AuditSelectors.receiptIdsList)
  const labelsIdsList = useSelector(AuditSelectors.labelsIdsList)

  const [currPage, setCurrPage] = useState<number>(0)
  const [receiptType, setReceiptType] = useState<'EXTRACTION' | 'LABEL'>(
    'EXTRACTION'
  )

  const handleOnDataLoaded = async ({ data }) => {
    const { merchant, countryCode, source, annotations } = data
    setCurrPage(0)
    setReceiptType(source)
    let formattedData = annotations
    if (data.source === 'LABEL') {
      const { definition } = await Noctua2LabelApi.getRetailerDefinition(
        countryCode,
        merchant
      )
      formattedData = {
        ...annotations,
        definition
      }
    }

    dispatch(AuditActions.setReceiptData(formattedData))
  }

  const handleLoadDataFailed = errResponse => {
    Modal.error({
      title: 'Load Error!',
      content: (
        <>
          <p>Something went wrong while getting extracted receipt.</p>
          <p>{errResponse}</p>
        </>
      )
    })
  }

  const handleLoadUserFailed = errResponse => {
    Modal.error({
      title: 'Load Error!',
      content: (
        <>
          <p>Something went wrong while getting user details.</p>
          <p>{errResponse}</p>
        </>
      )
    })
  }

  const handleOnUserLoaded = data => {
    dispatch(AuditActions.setReceiptIds(data.latest_receipts))
    dispatch(AuditActions.setLabelIds(data.latest_labels))
  }

  const { isLoading: isLoadingUser } = useQuery(
    userId,
    ({ queryKey }) => Noctua2AdminApi.getUserExtractedReceipts(queryKey?.[0]),
    {
      onSuccess: handleOnUserLoaded,
      onError: handleLoadUserFailed
    }
  )

  const currentList = receiptType === 'LABEL' ? labelsIdsList : receiptIdsList

  const { isLoading, data } = useQuery(
    [userId, receiptFilter || currentList?.[currentIndex]],
    ({ queryKey }: { queryKey: [string, number] }) =>
      Noctua2AdminApi.getReceiptsAnalytics(queryKey?.[1]),
    {
      enabled: !!receiptFilter || !!currentList?.[currentIndex],
      onSuccess: handleOnDataLoaded,
      onError: handleLoadDataFailed
    }
  )

  useEffect(() => {
    if (currentIndex >= currentList.length - 1 && currentList.length) {
      dispatch(AuditActions.setCurrentIndex(currentList.length - 1))
    }
  }, [currentList.length, currentIndex])

  // @ts-ignore
  const status = data?.status

  return (
    <Layout>
      <Layout
        style={{
          height: `calc(100vh - ${HEADER_HEIGHT}px - ${FOOTER_HEIGHT}px)`
        }}
      >
        {isLoadingUser || isLoading ? (
          <SplashScreen />
        ) : (
          <>
            <Content className="z-20 overflow-hidden">
              {status === 204 ? (
                <div className="h-screen flex justify-center items-center">
                  <EmptyComponent
                    description={
                      <>
                        <strong>No more receipts to show</strong>
                        <p>Change your receipt filter to load more</p>
                      </>
                    }
                  />
                </div>
              ) : (
                <AuditContent currPage={currPage} setCurrPage={setCurrPage} />
              )}
            </Content>
            <Sider
              width={AUDIT_SIDER_WIDTH}
              className="bg-white z-30 sticky right-0 overflow-scroll"
            >
              <GenericAuditSider type={receiptType} currPage={currPage} />
            </Sider>
          </>
        )}
      </Layout>

      <Footer
        className="flex items-center justify-between z-40 py-0 px-4 bg-white"
        style={{ height: FOOTER_HEIGHT }}
      >
        <AuditFooter
          type={receiptType}
          setType={setReceiptType}
          isLoading={isLoading}
        />
      </Footer>
    </Layout>
  )
}

export default AuditUserPage
