import { VisualizerState } from 'data/visualizer/types'
import handleActionsImmer from 'services/integrations/immer'
import AuditConstants from './constants'

const initialState = {
  receiptFilter: null
}

const visualizer = handleActionsImmer<VisualizerState>(
  {
    [AuditConstants.SET_RECEIPT_FILTER]: (
      state: VisualizerState,
      id: string
    ) => {
      state.receiptFilter = id
    }
  },
  initialState
)

export default visualizer
