import React from 'react'
import VersionTable from './components/version-table'

const ExtractionVersionPage = () => {
  return (
    <div className="p-10">
      <h3 className="mb-4">Extraction Tool</h3>
      <VersionTable />
    </div>
  )
}

export default ExtractionVersionPage
