import AppConstants from 'data/app/constants'
import { AppState } from 'data/app/types'
import handleActionsImmer from 'services/integrations/immer'

const initialState = {
  isSiderOpen: true,
  appVersion: '1.0.0',
  lastActiveTime: null
}

const app = handleActionsImmer<AppState>(
  {
    [AppConstants.TOGGLE_SIDER]: (state: AppState) => {
      state.isSiderOpen = !state.isSiderOpen
    },
    [AppConstants.APP_VERSION]: (state: AppState, newVersion: string) => {
      state.appVersion = newVersion
    },
    [AppConstants.SET_LAST_ACTIVE_TIME]: (
      state: AppState,
      lastActiveTime: Date | null
    ) => {
      state.lastActiveTime = lastActiveTime
    }
  },
  initialState
)

export default app
