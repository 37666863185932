import { Button, Modal } from 'antd'
import { DialogKeys } from 'components/modals/types'
import { OverlayActions } from 'data/actions'
import React, { useEffect, useState } from 'react'
import { useDispatch } from 'react-redux'
import { convertMilliSecondToSecond, seconds2time } from 'services/helpers/time'

const TimeoutModal = ({ children }) => <>{children}</>

interface Props {
  onOk: () => void
  onCancel: () => Promise<void>
  getRemainingTime: () => number
  isPrompted: () => boolean
}

const IdleModal = ({ getRemainingTime, isPrompted, onOk, onCancel }: Props) => {
  const dispatch = useDispatch()

  const [remainTime, setRemainTime] = useState<number>(
    convertMilliSecondToSecond(getRemainingTime())
  )

  const handleCloseModal = () =>
    dispatch(OverlayActions.hideDialog({ key: DialogKeys.PROMPT_IDLE }))

  const handleExtendActiveTime = () => {
    onOk()
    handleCloseModal()
  }
  const handleLogout = async () => {
    await onCancel()
    handleCloseModal()
  }

  useEffect(() => {
    const interval = setInterval(() => {
      if (isPrompted()) {
        setRemainTime(convertMilliSecondToSecond(getRemainingTime()))
      }
    }, 1000)
    return () => {
      clearInterval(interval)
    }
  }, [getRemainingTime, isPrompted])

  return (
    <Modal
      title="Are you still here?"
      visible
      onOk={handleLogout}
      onCancel={handleExtendActiveTime}
      footer={[
        <Button key="back" type="ghost" danger onClick={handleLogout}>
          Log out
        </Button>,
        <Button key="submit" type="primary" onClick={handleExtendActiveTime}>
          Keep me logged in
        </Button>
      ]}
    >
      <div className="h-5/6 py-4">
        <h5 className="text-red-400">{`Your current session will expire in ${seconds2time(
          remainTime
        )}`}</h5>
        <p className="text-danger">
          In order to keep your account safe, we periodically check to see if
          you're still here. You can stay logged in by clicking below
        </p>
      </div>
    </Modal>
  )
}

const SessionTimeoutModal = () => {
  const dispatch = useDispatch()

  const handleCloseModal = () =>
    dispatch(OverlayActions.hideDialog({ key: DialogKeys.SESSION_TIMEOUT }))

  return (
    <div className="absolute top-4 right-4 rounded-md shadow p-4">
      <h4 className="text-red-400 mb-4">Session Timeout</h4>
      <p className="text-black px-2 pb-2">
        You have been logged out due to inactivity. <br /> Please login again.
      </p>
      <div className={'flex w-full justify-end'}>
        <Button
          onClick={handleCloseModal}
          size="large"
          className="text-white rounded bg-blue-400 mr-4"
        >
          Dismiss
        </Button>
      </div>
    </div>
  )
}

TimeoutModal.PromptIdle = IdleModal
TimeoutModal.SessionTimeout = SessionTimeoutModal

export default TimeoutModal
