import { Typography } from 'antd'
import React from 'react'
import { useHistory } from 'react-router'
import { AppSubRoutes } from 'routes/app/routes'
import { toStartCase } from 'services/helpers/canonicalization'

const { Text } = Typography

const AdminHome = () => {
  const history = useHistory()

  const workflows = Object.entries(AppSubRoutes.ADMIN)

  const handleNavigationClick = (pathname: string) => () => {
    history.push(pathname)
  }

  return (
    <div className="p-10">
      <h3 className="mb-4">Admin workflows</h3>
      <div className="grid grid-cols-1 md:grid-cols-4 sm:grid-cols-2 gap-4">
        {workflows.map(([name, { pathname }]) => (
          <div
            key={pathname}
            className="bg-white px-3 py-5 rounded shadow hover:cursor-pointer hover:scale-110 ease-in-out duration-300"
            onClick={handleNavigationClick(pathname)}
          >
            <Text strong>{toStartCase(name)}</Text>
          </div>
        ))}
      </div>
    </div>
  )
}

export default AdminHome
