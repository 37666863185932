import { createAction } from 'redux-actions'
import { DBReceiptAnnotation } from 'services/api/requests/types'
import AuditConstants from './constants'

export const setReceiptIds = (idsList: number[]) =>
  createAction(AuditConstants.SET_RECEIPTS_LIST)(idsList)

export const setLabelIds = (idsList: number[]) =>
  createAction(AuditConstants.SET_LABELS_LIST)(idsList)

export const setCurrentIndex = (index: number) =>
  createAction(AuditConstants.SET_CURRENT_INDEX)(index)

export const setReceiptData = (data: DBReceiptAnnotation) =>
  createAction(AuditConstants.SET_RECEIPT_DATA)(data)

export const setReceiptFilter = (id: string) =>
  createAction(AuditConstants.SET_RECEIPT_FILTER)(id)

export const resetAuditState = () =>
  createAction(AuditConstants.RESET_AUDIT_STATE)()
