import { LeftCircleOutlined, RightCircleOutlined } from '@ant-design/icons'
import {
  Button,
  Carousel,
  DatePicker,
  Form,
  Input,
  Layout,
  Select,
  Tag
} from 'antd'
import { Content, Footer } from 'antd/es/layout/layout'
import { DialogKeys } from 'components/modals/types'
import StandardBreadcrumb from 'components/standard-breadcrumb'
import { OverlayActions } from 'data/actions'
import { isArray } from 'lodash'
import moment from 'moment'
import {
  availableEnglishLocales,
  availableFrenchLocales,
  availableSpanishLocales
} from 'pages/app/survey-creation/constants'
import SurveyCreationPreview from 'pages/app/survey-creation/modals/survey-creation-preview'
import { WorkflowActions } from 'pages/app/survey-creation/reducers/actions'
import React, { useReducer, useState } from 'react'
import { useDispatch } from 'react-redux'
import { toTitleCase } from 'services/helpers/canonicalization'
import { FOOTER_HEIGHT, HEADER_HEIGHT } from 'services/styles/layout'
import { WorkflowContext } from './context'
import { baseSurveyDetails, initialState, reducer } from './reducers'

const formItemLayout = {
  labelCol: {
    span: 5
  },
  wrapperCol: {
    span: 17
  }
}

const tagRender = (props: any) => {
  const { label, closable, onClose } = props
  const onPreventMouseDown = (event: React.MouseEvent<HTMLSpanElement>) => {
    event.preventDefault()
    event.stopPropagation()
  }
  return (
    <Tag
      color={'blue'}
      onMouseDown={onPreventMouseDown}
      closable={closable}
      onClose={onClose}
      style={{ marginRight: 3 }}
    >
      {label}
    </Tag>
  )
}

const SurveyCreationHome = () => {
  const dispatch = useDispatch()

  const [state, dispatchTask] = useReducer(reducer, initialState)

  const { data: initialData, localeOptions } = state

  const initialLocales = initialData.surveyCreation.surveyDetails.map(
    details => details.locale
  )
  const [selectedLocales, setSelectedLocales] = useState(initialLocales)

  const formattedInitialData = Object.entries(initialData).reduce(
    (acc, [objectKey, objectValue]) => {
      const objectValueEntries = Object.entries(objectValue)
      let result = acc
      for (const [nestedObjectKey, nestedObjectValue] of objectValueEntries) {
        result = {
          ...result,
          [`${objectKey}.${nestedObjectKey}`]: nestedObjectValue
        }

        // format date string to date type
        if (nestedObjectKey.includes('Date')) {
          result = {
            ...result,
            [`${objectKey}.${nestedObjectKey}`]: moment(nestedObjectValue)
          }
        }

        // flatten nested array
        if (isArray(nestedObjectValue)) {
          for (const item of nestedObjectValue) {
            const itemEntries = Object.entries(item)
            const currentLocale = item?.locale
            for (const [nestedItemKey, nestedItemValue] of itemEntries) {
              if (nestedItemKey !== 'locale') {
                const newKey = currentLocale
                  ? `${objectKey}.${nestedObjectKey}.${currentLocale}.${nestedItemKey}`
                  : `${objectKey}.${nestedObjectKey}.${nestedItemKey}`
                result = {
                  ...result,
                  [newKey]: nestedItemValue
                }
              }
            }
          }
        }
      }

      return {
        ...acc,
        ...result
      }
    },
    {}
  )
  const [form] = Form.useForm()

  const onFinish = async (values: any) => {
    const formattedObject = Object.entries(values).reduce(
      (acc, curr) => {
        const [objectKey, objectValue] = curr
        const nestedObjectKeys = objectKey.split('.')

        if (nestedObjectKeys[1] === 'surveyDetails') {
          const allSurveyDetails = acc[nestedObjectKeys[0]][nestedObjectKeys[1]]
          const currSurveyDetailsLocale = nestedObjectKeys[2]
          const currSurveyDetailsKey = nestedObjectKeys[3]
          const foundSurveyDetails = allSurveyDetails.find(
            details => details?.locale === currSurveyDetailsLocale
          )

          const accSurveyDetails = foundSurveyDetails
            ? allSurveyDetails.map(detail =>
                detail.locale === currSurveyDetailsLocale
                  ? {
                      ...foundSurveyDetails,
                      [currSurveyDetailsKey]: objectValue
                    }
                  : detail
              )
            : [
                ...allSurveyDetails,
                {
                  locale: currSurveyDetailsLocale,
                  [currSurveyDetailsKey]: objectValue
                }
              ]

          return {
            ...acc,
            [nestedObjectKeys[0]]: {
              ...acc[nestedObjectKeys[0]],
              [nestedObjectKeys[1]]: accSurveyDetails
            }
          }
        }

        // format date type to date string
        if (nestedObjectKeys[1].includes('Date')) {
          return {
            ...acc,
            [nestedObjectKeys[0]]: {
              ...acc[nestedObjectKeys[0]],
              [nestedObjectKeys[1]]: moment.utc(objectValue).format()
            }
          }
        }

        return {
          ...acc,
          [nestedObjectKeys[0]]: {
            ...acc[nestedObjectKeys[0]],
            [nestedObjectKeys[1]]: objectValue
          }
        }
      },
      {
        surveyCreation: {
          imageUrl: null,
          surveyDetails: []
        },
        surveyRequest: {}
      }
    )

    const enSurveyDetails = formattedObject.surveyCreation?.surveyDetails.find(
      i => i.locale.includes('en')
    )
    const esSurveyDetails = formattedObject.surveyCreation?.surveyDetails.find(
      i => i.locale.includes('es')
    )
    const frSurveyDetails = formattedObject.surveyCreation?.surveyDetails.find(
      i => i.locale.includes('fr')
    )

    // generate english,spanish,french versions
    const otherEnglishVersionsSurvey = enSurveyDetails
      ? availableEnglishLocales.map(enLocale => ({
          ...enSurveyDetails,
          locale: enLocale
        }))
      : []
    const otherSpanishVersionsSurvey = esSurveyDetails
      ? availableSpanishLocales.map(esLocale => ({
          ...esSurveyDetails,
          locale: esLocale
        }))
      : []
    const otherFrenchVersionsSurvey = frSurveyDetails
      ? availableFrenchLocales.map(frLocale => ({
          ...frSurveyDetails,
          locale: frLocale
        }))
      : []

    const surveyCreationWithGeneratedVersions = {
      ...formattedObject.surveyCreation,
      surveyDetails: [
        ...formattedObject.surveyCreation.surveyDetails,
        ...otherEnglishVersionsSurvey,
        ...otherSpanishVersionsSurvey,
        ...otherFrenchVersionsSurvey
      ]
    }

    dispatch(
      OverlayActions.showDialog({
        key: DialogKeys.SURVEY_CREATION_PREVIEW,
        component: (
          <SurveyCreationPreview
            surveyRequestData={formattedObject.surveyRequest}
            surveyCreationData={surveyCreationWithGeneratedVersions}
          />
        )
      })
    )
  }

  const handleSubmitButtonClick = () => {
    form.submit()
  }

  const handleLocalesSelectorChange = (value: string[]) => {
    setSelectedLocales(value)

    let newSurveyDetailsList = []
    // remove
    if (value.length < initialData.surveyCreation.surveyDetails.length) {
      newSurveyDetailsList = initialData.surveyCreation.surveyDetails.filter(
        survey => value.includes(survey.locale)
      )
    } else {
      // add
      const newLocale = value.filter(
        locale =>
          !initialData.surveyCreation.surveyDetails.some(
            survey => survey.locale === locale
          )
      )
      newSurveyDetailsList = [
        ...initialData.surveyCreation.surveyDetails,
        { locale: newLocale[0], ...baseSurveyDetails }
      ]
    }

    // remove/add surveyDetail
    dispatchTask({
      type: WorkflowActions.SET_SURVEY_DETAILS,
      payload: newSurveyDetailsList
    })
  }

  // fields that are not required and prefilled (still need to add form items)
  const invisibleFields = [
    'type',
    'isActive',
    'isPublic',
    'isUnlisted',
    'earlyExitShakes',
    'earlyExitPoints'
  ]
  return (
    <WorkflowContext.Provider value={{ state, dispatchTask }}>
      <Layout>
        <Layout
          style={{
            height: `calc(100vh - ${HEADER_HEIGHT}px - ${FOOTER_HEIGHT}px)`,
            overflowY: 'scroll'
          }}
        >
          <Content>
            <div className="py-2 px-4">
              <StandardBreadcrumb />
            </div>
            <div className="w-full p-6">
              <div className="border border-solid border-gray-300 rounded p-8 w-full">
                <Form
                  {...formItemLayout}
                  form={form}
                  name="survey-creation-workflow"
                  onFinish={onFinish}
                  initialValues={formattedInitialData}
                  scrollToFirstError
                >
                  <Form.Item
                    name="surveyRequest.countryCode"
                    label="Country code"
                    tooltip="Where this survey should be live"
                    rules={[
                      {
                        required: true,
                        message: 'Please enter country code'
                      }
                    ]}
                  >
                    <Select
                      options={[
                        { value: 'US' },
                        { value: 'CA' },
                        { value: 'AU' },
                        { value: 'BR' }
                      ]}
                    />
                  </Form.Item>
                  <Form.Item
                    name="surveyRequest.expiryDate"
                    label="Expiry date"
                    tooltip="When this survey should be expired"
                    rules={[
                      {
                        required: true,
                        message: 'Please enter expiry date'
                      }
                    ]}
                  >
                    <DatePicker showTime />
                  </Form.Item>
                  <Form.Item
                    name="surveyRequest.releaseDate"
                    label="Release date"
                    tooltip="When this survey should be live"
                    rules={[
                      {
                        required: true,
                        message: 'Please enter release date'
                      }
                    ]}
                  >
                    <DatePicker showTime />
                  </Form.Item>
                  <Form.Item
                    name="surveyRequest.targetUsers"
                    label="Target users"
                    tooltip="Who should receive this survey"
                    rules={[
                      {
                        required: true,
                        message: 'Please enter target users'
                      }
                    ]}
                  >
                    <Select options={[{ value: 'All active users' }]} />
                  </Form.Item>
                  <Form.Item
                    name="surveyCreation.imageUrl"
                    label="Thumbnail imageUrl"
                    tooltip="What is thumbnail of this survey"
                    rules={[
                      {
                        required: true,
                        message: 'Please enter thumbnail url'
                      }
                    ]}
                  >
                    <Input />
                  </Form.Item>
                  <Form.Item
                    name="surveyCreation.points"
                    label="Points"
                    tooltip="How many reward points for this survey"
                    rules={[
                      {
                        required: true,
                        message: 'Please enter points'
                      }
                    ]}
                  >
                    <Input />
                  </Form.Item>
                  <Form.Item
                    name="surveyCreation.globalMaxNumberOfResponses"
                    label="Max responses"
                    tooltip="What is expected number of responses?"
                    rules={[
                      {
                        required: true,
                        message: 'Please enter max responses'
                      }
                    ]}
                  >
                    <Input />
                  </Form.Item>
                  {invisibleFields.map(invisibleField => (
                    <Form.Item
                      style={{ display: 'none' }}
                      key={invisibleField}
                      name={`surveyCreation.${invisibleField}`}
                    />
                  ))}
                  <Form.Item
                    name="other.locales"
                    label="locales"
                    tooltip="Survey locales"
                  >
                    <Select
                      mode="multiple"
                      showArrow
                      tagRender={tagRender}
                      defaultValue={selectedLocales}
                      value={selectedLocales}
                      style={{ width: '100%' }}
                      options={localeOptions}
                      onChange={handleLocalesSelectorChange}
                    />
                  </Form.Item>

                  <Carousel
                    arrows
                    nextArrow={<RightCircleOutlined />}
                    prevArrow={<LeftCircleOutlined />}
                    className="relative border border-solid border-gray-300 rounded p-8 w-full"
                  >
                    {formattedInitialData['surveyCreation.surveyDetails'].map(
                      surveyDetail => {
                        const surveyDetailKeys = Object.keys(surveyDetail)

                        return (
                          <div
                            key={`surveyCreation.surveyDetails.${surveyDetail.locale}`}
                          >
                            <div className="font-bold text-center self-center">
                              {surveyDetail.locale}
                            </div>
                            {surveyDetailKeys.map(surveyFieldName => {
                              const label = toTitleCase(surveyFieldName)
                              const reservedFields = ['locale']
                              const notRequiredFields = [
                                'earlyExitNotificationText'
                              ]
                              if (reservedFields.includes(surveyFieldName)) {
                                return null
                              }
                              return (
                                <Form.Item
                                  key={`surveyCreation.surveyDetails.${surveyDetail.locale}.${surveyFieldName}`}
                                  name={`surveyCreation.surveyDetails.${surveyDetail.locale}.${surveyFieldName}`}
                                  label={label}
                                  tooltip={'???'}
                                  rules={[
                                    {
                                      required: !notRequiredFields.includes(
                                        surveyFieldName
                                      ),
                                      message: `Please enter survey ${surveyFieldName}`
                                    }
                                  ]}
                                >
                                  <Input />
                                </Form.Item>
                              )
                            })}
                          </div>
                        )
                      }
                    )}
                  </Carousel>
                </Form>
              </div>
            </div>
          </Content>
        </Layout>
        <Footer
          className="flex items-center justify-between z-40 py-0 px-4"
          style={{ height: FOOTER_HEIGHT }}
        >
          <div className="flex flex-grow items-center justify-end">
            <Button type="primary" onClick={handleSubmitButtonClick}>
              Preview
            </Button>
          </div>
        </Footer>
      </Layout>
    </WorkflowContext.Provider>
  )
}

export default SurveyCreationHome
