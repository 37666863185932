import produce from 'immer'

const handleActionsImmer = <S, V = any>(
  actionsMap: { [x: string]: any },
  defaultState: S
) => (state = defaultState, { type, payload }: any) =>
  produce<S, V>(state, draft => {
    const action = actionsMap[type]
    action && action(draft, payload)
  })

export default handleActionsImmer
