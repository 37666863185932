import { VerticalAlignBottomOutlined } from '@ant-design/icons'
import { Button, Form, Input, message } from 'antd'
import { ButtonProps } from 'antd/lib/button/button'
import StaticUrls from 'assets/links'
import {
  AppActions,
  AuditActions,
  ExtractionActions,
  VisualizerActions
} from 'data/actions'
import { HistoryActions, HistorySelectors } from 'data/history'
import {
  AppSelectors,
  AuditSelectors,
  AuthSelectors,
  ExtractionSelectors,
  VisualizerSelectors
} from 'data/selectors'
import { useHandleSkipAnnotation } from 'pages/app/extraction/version-1/task/hooks'
import React from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { useFirebase } from 'react-redux-firebase'
import { useHistory, useLocation } from 'react-router'
import { AppRoute, AppSubRoutes } from 'routes/app/routes'
import { ReceiptApi } from 'services/api/requests'
import { toStartCase } from 'services/helpers/canonicalization'
import { cleanTrailingComma } from 'services/helpers/string'

const NavButton = ({
  children,
  className,
  active,
  ...props
}: { active?: boolean } & ButtonProps) => (
  <Button type="text" className={`px-2 h-full ${className || ''}`} {...props}>
    <span className={`${active ? 'font-bold' : ''} hover:underline`}>
      {children}
    </span>
  </Button>
)

const BoldText = ({ children }) => (
  <span className="font-bold px-2">{children}</span>
)

interface FilterInputProps {
  field: string
  onFinish: any
  initialValue: string
  label: string
  placeHolder: string
  isLoading?: boolean
}

const FilterInput = ({
  field,
  onFinish,
  initialValue,
  label,
  placeHolder,
  isLoading
}: FilterInputProps) => (
  <>
    <Form className="flex items-center" onFinish={onFinish}>
      <Form.Item
        className="m-0"
        label={label}
        name={field}
        initialValue={initialValue}
      >
        <Input className="w-full" placeholder={placeHolder} />
      </Form.Item>
      <Button
        onClick={e => e.currentTarget.blur()}
        loading={isLoading}
        className="mr-2"
        type="primary"
        ghost
        htmlType="submit"
      >
        Apply
      </Button>
    </Form>
    <span> | </span>
  </>
)

const RootHeader = () => {
  const dispatch = useDispatch()
  const firebase = useFirebase()
  const history = useHistory()
  const { pathname } = useLocation()
  const isSiderOpen = useSelector(AppSelectors.siderState)
  const retailerFilter = useSelector(ExtractionSelectors.retailerFilter)
  const receiptFilter = useSelector(AuditSelectors.receiptFilter)
  const visualizerFilter = useSelector(VisualizerSelectors.receiptFilter)
  const receiptId = useSelector(ExtractionSelectors.receiptId)
  const historyReceiptId = useSelector(HistorySelectors.receiptIdSearch)
  const { username, email } = useSelector(AuthSelectors.firebaseProfile)
  const [isSkipping, handleSkip] = useHandleSkipAnnotation()

  const handleLogout = async () => {
    try {
      await ReceiptApi.unlockReceipt()
    } catch (e) {}
    await firebase.logout()
  }

  const handleNavigationClick = (pathname: string) => () =>
    history.push(pathname)

  const handleExpandSiderClick = () => dispatch(AppActions.toggleSider())

  const _handleUpdateRetailerFilter = async (retailer: string) => {
    dispatch(ExtractionActions.setRetailerFilter(cleanTrailingComma(retailer)))

    if (receiptId) {
      await handleSkip(receiptId)
      // load next receipt annotation
      dispatch(ExtractionActions.setReceiptId(null))
      dispatch(ExtractionActions.approveReceipt())
    }
    message.success('Retailer filter applied successfully.')
  }

  const _handleUpdateReceiptFilter = async (id: string) => {
    dispatch(AuditActions.setReceiptFilter(id))

    message.success('Receipt filter applied successfully.')
  }

  const _handleUpdateVisualizerFilter = async (id: string) => {
    dispatch(VisualizerActions.setReceiptFilter(id.trim()))

    message.success('Receipt filter applied successfully.')
  }

  const _handleUpdateReceiptHistorySearch = async (id: string) => {
    dispatch(HistoryActions.setReceiptHistorySearch(id))
  }

  const onReceiptFilterApplyClick = ({ receiptFilter }) =>
    _handleUpdateReceiptFilter(receiptFilter)

  const onFilterApplyClick = ({ retailerFilter }) =>
    _handleUpdateRetailerFilter(retailerFilter)

  const onVisualizerFilterApplyClick = ({ visualizerId }) =>
    _handleUpdateVisualizerFilter(visualizerId)

  const onReceiptHistorySearchApplyClick = ({ receiptIdSearch }) =>
    _handleUpdateReceiptHistorySearch(receiptIdSearch)

  const handleTutorialClick = () => window.open(StaticUrls.TUTORIAL)

  const navTabs = Object.entries(AppRoute).slice(0, 1)

  return (
    <div className="flex justify-between px-2 border-8">
      <div>
        {!isSiderOpen && (
          <NavButton
            onClick={handleExpandSiderClick}
            icon={
              <VerticalAlignBottomOutlined className="text-2xl transform -rotate-90" />
            }
          />
        )}
        <BoldText>Noctua</BoldText>
      </div>

      <div className="flex items-center">
        {(pathname.includes(AppRoute.EXTRACTION.pathname) ||
          pathname.includes('merchant')) && (
          <FilterInput
            isLoading={isSkipping}
            field="retailerFilter"
            initialValue={retailerFilter}
            label="Retailer : "
            placeHolder="Retailer filter..."
            onFinish={onFilterApplyClick}
          />
        )}
        {pathname.includes('/audit/') && (
          <FilterInput
            field="receiptFilter"
            initialValue={receiptFilter}
            label="Receipt ID :"
            placeHolder="Receipt filter..."
            onFinish={onReceiptFilterApplyClick}
          />
        )}
        {pathname.includes(AppSubRoutes.ADMIN.VISUALIZER.pathname) &&
          !pathname.includes('merchant') && (
            <FilterInput
              field="visualizerId"
              initialValue={visualizerFilter}
              label="Receipt ID : "
              placeHolder="Receipt filter..."
              onFinish={onVisualizerFilterApplyClick}
            />
          )}
        {pathname.includes(AppSubRoutes.ADMIN.PROCESSING_HISTORY.pathname) && (
          <FilterInput
            field="receiptIdSearch"
            initialValue={historyReceiptId}
            label="Receipt ID :"
            placeHolder="Search by ID..."
            onFinish={onReceiptHistorySearchApplyClick}
          />
        )}
        <BoldText>{username || email}</BoldText>
        <span>|</span>
        {navTabs.map(([key, value]) => (
          <NavButton
            key={key}
            active={pathname === value.pathname}
            onClick={handleNavigationClick(value.pathname)}
          >
            {toStartCase(key)}
          </NavButton>
        ))}
        <NavButton onClick={handleTutorialClick}>Tutorial</NavButton>
        <NavButton onClick={handleLogout}>Sign out</NavButton>
      </div>
    </div>
  )
}

export default RootHeader
