import { Input, Modal, Radio, Space } from 'antd'
import { rejectionReasons } from 'components/modals/reject/types'
import { DialogKeys } from 'components/modals/types'
import { OverlayActions } from 'data/actions'
import React, { useEffect, useState } from 'react'
import { useDispatch } from 'react-redux'

const { TextArea } = Input

interface Props {
  onOk: (reason) => Promise<void>
  reasonsList?: string[]
}

const RejectModal = ({ onOk, reasonsList }: Props) => {
  const dispatch = useDispatch()

  const rejectList = reasonsList || rejectionReasons

  const [selectedOption, setSelectedOption] = useState(0)
  const [reason, setReason] = useState(rejectList[selectedOption])
  const [error, setError] = useState('')
  const [isLoading, setIsLoading] = useState(false)

  const handleCancel = () =>
    dispatch(OverlayActions.hideDialog({ key: DialogKeys.REJECT_RECEIPT }))

  const handleOkClick = async () => {
    if (reason.length) {
      setIsLoading(true)
      await onOk(reason)
      setIsLoading(false)
      handleCancel()
    } else {
      setError('Reason cannot be blank')
    }
  }

  const handleOnTextAreaChange = ({ target: { value } }) => setReason(value)

  const handleOnSelectedOptionChange = ({ target }) => {
    const newValue = target?.value || 0
    setSelectedOption(newValue)
    newValue < rejectList.length - 1
      ? setReason(rejectList[newValue])
      : setReason('')
  }

  useEffect(() => {
    reason.length && setError('')
  }, [reason.length])

  return (
    <Modal
      title={'Reject Receipt'}
      visible
      confirmLoading={isLoading}
      onOk={handleOkClick}
      onCancel={handleCancel}
      okButtonProps={{
        style: { width: '50%', backgroundColor: 'red', borderRadius: 6 }
      }}
      cancelButtonProps={{ type: 'text' }}
      okText={'Continue'}
      width={450}
    >
      <Radio.Group
        onChange={handleOnSelectedOptionChange}
        value={selectedOption}
        className="w-full"
      >
        <Space direction="vertical" className="w-full">
          {rejectList.map((reason, index) => (
            <Radio value={index} key={index}>
              {reason}
            </Radio>
          ))}
          {selectedOption === rejectList.length - 1 && (
            <>
              <p style={{ fontSize: 18 }}>
                Please type in <strong>reason</strong> to reject
              </p>
              <TextArea
                value={reason}
                onChange={handleOnTextAreaChange}
                placeholder="Type in reject reason..."
                autoSize={{ minRows: 3, maxRows: 5 }}
              />
            </>
          )}
        </Space>
      </Radio.Group>
      <p style={{ color: 'red' }}>{error}</p>
    </Modal>
  )
}

export default RejectModal
