import { Modal } from 'antd'
import React from 'react'
import { useMutation } from 'react-query'
import { Noctua2DefinitionApi } from 'services/api/requests/notua-ii'
import queryClient from 'services/react-query'

const useUpdateDefinition = (): [boolean, (id, putBody) => Promise<any>] => {
  const { mutateAsync, isLoading } = useMutation(
    Noctua2DefinitionApi.updateDefinition
  )

  const handleUpdateDefinition = async (id, putBody) => {
    await mutateAsync(
      { id, putBody },
      {
        onError: () => {
          Modal.error({
            title: 'Update Definition Error!',
            content: 'Something went wrong while updating definition.'
          })
        },
        onSuccess: () => queryClient.invalidateQueries('definition-all')
      }
    )
  }

  return [isLoading, handleUpdateDefinition]
}

export default useUpdateDefinition
