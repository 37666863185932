import { Image } from 'antd'
import LoadingRectangular from 'components/skeleton/loading-rectangular'
import { BoxActions, ExtractionActions } from 'data/actions'
import { BoxLabelEnum, IMPORTANT_FIELDS } from 'data/box'
import { BoxSelectors, ExtractionSelectors } from 'data/selectors'
import { isNaN } from 'lodash'
import AutoLinkingToggle from 'pages/app/extraction/version-1/task/components/autolinking-toggle'
import DropdownAddBox from 'pages/app/extraction/version-1/task/components/dropdown-add-box'
import DropdownEditBox from 'pages/app/extraction/version-1/task/components/dropdown-edit-box'
import Pagination from 'pages/app/extraction/version-1/task/components/pagination'
import SelectableArea from 'pages/app/extraction/version-1/task/components/selectable-area'
import ZoomToolbar from 'pages/app/extraction/version-1/task/components/zoom-toolbar'
import { mergeBoundingBoxes } from 'pages/app/extraction/version-1/task/helpers'
import { MenuInfo } from 'rc-menu/lib/interface'
import React, { useEffect, useRef, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { TransformComponent, TransformWrapper } from 'react-zoom-pan-pinch'
import { ResponseReceiptAnnotationType } from 'services/api/requests/annotation'
import { parseCurrencyStrict } from 'services/helpers/number'
import { useEventListener } from 'services/hooks/use-event-listener'
import useGetDimensions from 'services/hooks/use-get-dimensions'
import { Color } from 'services/styles/color'
import { FOOTER_HEIGHT, HEADER_HEIGHT } from 'services/styles/layout'
import { Space } from 'services/styles/spacing'
import { v4 as uuidv4 } from 'uuid'

const TaskContent = () => {
  const dispatch = useDispatch()
  const labeledBoxes = useSelector(BoxSelectors.boxes)
  const targetBoxId = useSelector(BoxSelectors.targetId)
  const data = useSelector(ExtractionSelectors.data)
  const currentPage = String(useSelector(ExtractionSelectors.currentPage))
  const totalPages = useSelector(ExtractionSelectors.totalReceiptPage)

  const zoomWrapperRef = useRef(null)
  const wrapperRef = useRef(null)

  const [isImageLoaded, setIsImageLoaded] = useState(false)
  const [editingText, setEditingText] = useState(false)

  const { width: windowWidth } = useGetDimensions()

  const [isPanningMode, setIsPanningMode] = useState<boolean>(false)
  const [popupList, setPopupList] = useState<(boolean | null)[]>([])

  useEffect(() => {
    if (isImageLoaded) {
      zoomWrapperRef.current.centerView()
    }
  }, [isImageLoaded, windowWidth])

  useEffect(() => {
    setIsImageLoaded(false)
  }, [data?.[currentPage]?.image_url])

  const handleProcessToNextPage = (current, next) => {
    // save current state
    const currentExtractedData = {
      ...data,
      [current]: { ...data[current], fields: labeledBoxes }
    } as ResponseReceiptAnnotationType
    dispatch(ExtractionActions.setRawData(currentExtractedData))

    // load next page annotation
    dispatch(BoxActions.resetBoxState())
    data[String(next)]?.fields.map(
      i =>
        !labeledBoxes.find(j => j?.id === i?.id) &&
        dispatch(BoxActions.addBox(i))
    )

    dispatch(ExtractionActions.setCurrentPage(next))
  }

  const handleZoomIn = () => zoomWrapperRef?.current?.zoomIn()
  const handleZoomOut = () => zoomWrapperRef?.current?.zoomOut()
  const handleResetZoom = () => {
    zoomWrapperRef?.current?.resetTransform()
  }

  const handlePrevPageClick = () => {
    handleProcessToNextPage(currentPage, Number(currentPage) - 1)
  }
  const handleNextPageClick = () => {
    handleProcessToNextPage(currentPage, Number(currentPage) + 1)
  }
  // reset dropdown popup
  const handleResetPopupList = () => setPopupList(popupList.map(() => null))

  const handleLabelText = (info: Partial<MenuInfo> & { key: BoxLabelEnum }) => {
    const selectedBoxes = (data[currentPage]?.annotations || []).reduce(
      (acc: any, curr: any, index: number) =>
        popupList[index] !== null
          ? {
              text: acc.text + ' ' + curr.text,
              bounding: [...acc.bounding, curr.boundingPoly]
            }
          : acc,
      { text: '', bounding: [] }
    )

    const newBoxBoundingPoly = mergeBoundingBoxes(selectedBoxes.bounding)

    // clean promoPrice i.e: -$10 => 10
    const newId = uuidv4()

    dispatch(
      BoxActions.addBox({
        id: newId,
        text:
          info.key === BoxLabelEnum.ITEM_PROMO_PRICE
            ? parseCurrencyStrict(selectedBoxes.text).toString()
            : selectedBoxes.text,
        boundingPoly: newBoxBoundingPoly,
        label: info.key,
        links: []
      })
    )

    const noLinkFields = IMPORTANT_FIELDS.includes(info.key)

    targetBoxId &&
      !noLinkFields &&
      dispatch(BoxActions.addLink({ id: targetBoxId, targetId: newId }))

    handleResetPopupList()
    return newId
  }

  const handleDeleteHighLights = () => {
    // TODO change selectable target to extraction state
    const highlights = Array.from(
      document.querySelectorAll('.highlight.selected')
    )

    if (highlights.length) {
      const selectedBoxes = (labeledBoxes || []).filter(
        (highlight, index) => popupList[index] !== null
      )

      selectedBoxes.map(highlight =>
        dispatch(BoxActions.removeBox(highlight.id))
      )

      handleResetPopupList()
    }
  }

  const renderBoxes = (textAnnotations: any[]) =>
    textAnnotations.map((textBox: any, index: number) => {
      const { text, boundingPoly } = textBox
      const [{ x: x0, y: y0 }, { x: x3, y: y3 }] = boundingPoly.vertices

      const boxWidth = x3 - x0
      const boxHeight = y3 - y0

      return (
        <DropdownAddBox
          key={text + index + y0 + x0}
          handleLabelText={handleLabelText}
          visible={popupList[index] === true}
          y={y0}
          x={x0}
          boxHeight={boxHeight}
          boxWidth={boxWidth}
        />
      )
    })

  const renderLabeledBoxes = () =>
    labeledBoxes?.map(box => {
      const { id, label, text, boundingPoly, links } = box
      const [{ x: x0, y: y0 }, { x: x1, y: y1 }] = boundingPoly.vertices

      const boxWidth = x1 - x0
      const boxHeight = y1 - y0

      return (
        <DropdownEditBox
          key={id + ''}
          editingText={editingText}
          setEditingText={setEditingText}
          id={id}
          text={text}
          label={label}
          links={links}
          y={y0}
          x={x0}
          boxHeight={boxHeight}
          boxWidth={boxWidth}
        />
      )
    })

  const renderLinks = () => (
    <svg
      key={'parent-links'}
      className={'absolute inset-0 z-50'}
      style={{ pointerEvents: 'none' }}
      {...data?.[currentPage]?.size}
    >
      {labeledBoxes.map(box => {
        if (box?.links?.length) {
          const [
            { x: startX0, y: startY0 },
            { x: startX1, y: startY1 }
          ] = box.boundingPoly.vertices

          return (
            <g key={box.id}>
              {box.links.map(link => {
                const targetBox = labeledBoxes.find(item => item.id === link)

                if (targetBox) {
                  const [
                    { x: endX0, y: endY0 },
                    { x: endX1, y: endY1 }
                  ] = targetBox.boundingPoly.vertices

                  const startCenter = {
                    x: (startX0 + startX1) / 2,
                    y: (startY0 + startY1) / 2
                  }
                  const endCenter = {
                    x: (endX0 + endX1) / 2,
                    y: (endY0 + endY1) / 2
                  }

                  return (
                    <line
                      style={{ opacity: 1 }}
                      key={`${box.id}-${link}-${startCenter.x}-${endCenter.x}`}
                      x1={startCenter.x}
                      y1={startCenter.y}
                      x2={endCenter.x}
                      y2={endCenter.y}
                      stroke={'red'}
                    />
                  )
                }
                return null
              })}
            </g>
          )
        }
        return null
      })}
    </svg>
  )

  const keydownEventHandler = ({ keyCode }) => {
    const _isNextDisabled = isNaN(parseInt(currentPage))
      ? true
      : parseInt(currentPage) >= totalPages - 1
    const _isPrevDisabled = isNaN(parseInt(currentPage))
      ? true
      : parseInt(currentPage) <= 0

    if (keyCode === 37 && !_isPrevDisabled && !editingText) {
      handlePrevPageClick()
    }
    if (keyCode === 39 && !_isNextDisabled && !editingText) {
      handleNextPageClick()
    }
    if ((keyCode === 107 || keyCode === 187) && !editingText) {
      handleZoomIn()
    }
    if ((keyCode === 109 || keyCode === 189) && !editingText) {
      handleZoomOut()
    }

    // delete multiple highlights
    if ((keyCode === 46 || keyCode === 8) && !editingText) {
      handleDeleteHighLights()
    }

    if (keyCode === 32 && !editingText) {
      setIsPanningMode(true)
    }
  }

  const keyupEventHandler = ({ keyCode }) => {
    if (keyCode === 32) {
      setIsPanningMode(false)
    }
  }

  useEventListener('keydown', keydownEventHandler)
  useEventListener('keyup', keyupEventHandler)

  return (
    <div ref={wrapperRef}>
      <SelectableArea
        editingText={editingText}
        zoomState={zoomWrapperRef?.current?.state}
        setPopupList={setPopupList}
        dragContainer=".draggable-area"
      />
      <TransformWrapper
        key={'extraction-transform-wrapper_'}
        centerOnInit
        ref={zoomWrapperRef}
        initialScale={0.25}
        minScale={0.1}
        maxScale={5}
        doubleClick={{ disabled: true }}
        wheel={{ disabled: true }}
        panning={{ activationKeys: [' '] }}
        centerZoomedOut
      >
        <TransformComponent
          wrapperStyle={{
            backgroundColor: Color.GREY_300,
            width: '100%',
            height: `calc(100vh - ${HEADER_HEIGHT}px - ${FOOTER_HEIGHT}px)`
          }}
        >
          <div
            className="draggable-area"
            style={{ cursor: isPanningMode ? 'move' : 'default' }}
          >
            <Image
              {...data?.[currentPage]?.size}
              alt="receipt"
              onLoad={() => setIsImageLoaded(true)}
              className="relative"
              src={data?.[currentPage]?.image_url}
              preview={false}
            />
            {!isImageLoaded ? (
              <div className="absolute inset-0 z-50">
                <LoadingRectangular {...data?.[currentPage]?.size} />
              </div>
            ) : null}
            {isImageLoaded
              ? renderBoxes(data?.[currentPage]?.annotations || [])
              : null}
            {isImageLoaded ? renderLabeledBoxes() : null}
            {isImageLoaded ? renderLinks() : null}
          </div>
        </TransformComponent>
      </TransformWrapper>

      <Pagination>
        <Pagination.LeftArrow
          onClick={handlePrevPageClick}
          disabled={
            isNaN(parseInt(currentPage)) ? true : parseInt(currentPage) <= 0
          }
          style={{ left: Space.SMALL }}
        />
        <Pagination.RightArrow
          onClick={handleNextPageClick}
          disabled={
            isNaN(parseInt(currentPage))
              ? true
              : parseInt(currentPage) >= totalPages - 1
          }
        />
      </Pagination>

      <ZoomToolbar>
        <ZoomToolbar.ZoomIn onClick={handleZoomIn} />
        <ZoomToolbar.ZoomOut onClick={handleZoomOut} />
        <ZoomToolbar.Reset onClick={handleResetZoom} />
      </ZoomToolbar>

      <AutoLinkingToggle />
    </div>
  )
}

export default TaskContent
