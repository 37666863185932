import { Button, message } from 'antd'
import React, { useContext } from 'react'
import { useMutation } from 'react-query'
import { Noctua2ApprovalApi } from 'services/api/requests/notua-ii'
import { ReceiptStatusEnum } from 'services/api/requests/notua-ii/approval'
import { TaskContext } from '../context'
import { RecordActions } from '../reducers/actions'

const TaskFooter = () => {
  const { state: parentState, dispatchTask: parentDispatcher } = useContext(
    TaskContext
  )
  const { record } = parentState

  const {
    total,
    vendorName,
    rejectionReason,
    status,
    id,
    notes,
    purchaseDate,
    purchaseTime
  } = record

  const handleDispatchTask = (action, value = null) =>
    parentDispatcher({ type: action, payload: value })

  const resetWorkingState = () =>
    handleDispatchTask(RecordActions.RESET_WORKING_STATE)

  const {
    mutateAsync: mutateReceipt,
    isLoading: isPatchingReceipt
  } = useMutation(Noctua2ApprovalApi.updatePhysicalReceipt)

  const handleApproveClick = async () => {
    if (!total && total !== 0) return
    if (!purchaseDate) return
    if (purchaseTime === '00:00') return
    if (!vendorName) return

    const updateRecordStatus = {
      ...record,
      reviewed: true,
      status: ReceiptStatusEnum.APPROVED
    }
    await mutateReceipt(updateRecordStatus)
    resetWorkingState()
  }
  const handleRejectClick = async () => {
    if (!rejectionReason) {
      return message.error('Missing rejection reason')
    }
    const updateRecordStatus = {
      ...record,
      reviewed: true,
      status: ReceiptStatusEnum.REJECTED
    }
    await mutateReceipt(updateRecordStatus)
    resetWorkingState()
  }

  return (
    <div className="flex items-center self-end">
      <CTAButtons
        isApproving={isPatchingReceipt}
        isRejecting={isPatchingReceipt}
        onApproveClick={handleApproveClick}
        onRejectClick={handleRejectClick}
      />
    </div>
  )
}

const CTAButtons = ({
  isApproving,
  isRejecting,
  onApproveClick,
  onRejectClick
}) => (
  <>
    <Button
      className="w-56 mx-2"
      loading={isApproving}
      type="primary"
      onClick={async () => await onApproveClick()}
      block={true}
      size="large"
    >
      Approve
    </Button>

    <Button
      className="w-56 mx-2"
      loading={isRejecting}
      type="ghost"
      danger
      onClick={async () => await onRejectClick()}
      block={true}
      size="large"
    >
      Reject
    </Button>
  </>
)

export default TaskFooter
