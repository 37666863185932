import { BoxLabelEnum } from 'data/box'
import { CombinedStates } from 'pages/app/extraction/version-2/reducers'
import { ModeActions } from 'pages/app/extraction/version-2/reducers/mode/actions'
import { SelectionActions } from 'pages/app/extraction/version-2/reducers/selection/actions'
import { SubRequirementsActions } from 'pages/app/extraction/version-2/reducers/sub-requirements/actions'
import LineItemsSection from 'pages/app/extraction/version-2/task/content/line-section'
import MerchantSection from 'pages/app/extraction/version-2/task/content/merchant-section'
import OtherInfoSection from 'pages/app/extraction/version-2/task/content/other-section'
import getMerchantDefinition from 'pages/app/extraction/version-2/task/definitions'
import {
  filterHighLightsProperties,
  getSinglePageImportantFields,
  getSinglePageSummary,
  getSinglePageTax,
  sortHighLightsByPosition
} from 'pages/app/extraction/version-2/task/helpers'
import React, { Dispatch, useEffect } from 'react'
import { useParams } from 'react-router'
import { scrollIntoView } from 'services/helpers/dom'

interface Props {
  parentState: CombinedStates
  parentDispatcher: Dispatch<any>
}

const TaskContent = ({ parentState, parentDispatcher }: Props) => {
  const handleDispatchTask = (action, value) =>
    parentDispatcher({ type: action, payload: value })
  const { record, mode, currentPage, selection, subRequirements } = parentState

  const { target } = selection
  const { linkMode, autoFocus, isUnitPrice } = mode
  const { countryCode } = useParams<{ countryCode: string }>()
  const predictedMerchant = record.metadata.merchant
  const currentPageData = record.pages[currentPage]
  const totalPages = record.pages.length
  const merchantDefinition = getMerchantDefinition(
    countryCode,
    predictedMerchant
  )

  const currentSummary = getSinglePageSummary(currentPageData.fields)
  const currentTax = getSinglePageTax(currentPageData.fields)

  // for total and subtotal
  const summaryOfPages = record.pages.reduce((wrapAcc, wrapCurr, index) => {
    return index === Number(currentPage)
      ? {
          ...wrapAcc,
          ...currentSummary
        }
      : {
          ...wrapAcc,
          ...getSinglePageSummary(record.pages[index].fields)
        }
  }, {})

  // sum tax of pages
  const sumTaxOfPages = record.pages.reduce(
    (wrapAcc, wrapCurr, index) =>
      index === Number(currentPage)
        ? wrapAcc + currentTax
        : wrapAcc + getSinglePageTax(record.pages[index].fields),
    0
  )

  // receipt fields
  const currentImportantFields = getSinglePageImportantFields(
    currentPageData.fields
  )
  const allPagesImportantFields = record.pages.reduce((wrapAcc, page) => {
    return {
      ...wrapAcc,
      ...getSinglePageImportantFields(page.fields)
    }
  }, {})

  const mergedRegularFields = {
    ...allPagesImportantFields,
    ...currentImportantFields,
    [BoxLabelEnum.RECEIPT_TOTAL_VALUE]: {
      text: summaryOfPages?.[BoxLabelEnum.RECEIPT_TOTAL_VALUE] || 0,
      id:
        currentImportantFields?.[BoxLabelEnum.RECEIPT_TOTAL_VALUE]?.id ||
        allPagesImportantFields?.[BoxLabelEnum.RECEIPT_TOTAL_VALUE]?.id
    },
    [BoxLabelEnum.RECEIPT_TOTAL_TAX]: {
      text: sumTaxOfPages,
      id: undefined
    }
  }

  const handleSetTargetId = (id: string) =>
    handleDispatchTask(SelectionActions.SET_TARGET_ID, id)
  const handleSetSourceId = (id: string) =>
    handleDispatchTask(SelectionActions.SET_SOURCE_ID, id)
  const handleSetSubRequirements = newValue =>
    handleDispatchTask(SubRequirementsActions.SET_SUB_REQUIREMENTS, newValue)
  const handleModeChange = newValue =>
    handleDispatchTask(ModeActions.SET_IS_UNIT_PRICE, newValue)

  // separate line and promo to remove duplication based on first condition
  const lineItemEntries = filterHighLightsProperties(currentPageData.fields, [
    BoxLabelEnum.ITEM_DESCRIPTION,
    BoxLabelEnum.ITEM_VALUE,
    BoxLabelEnum.ITEM_QUANTITY,
    BoxLabelEnum.ITEM_UNIT_PRICE,
    BoxLabelEnum.ITEM_CODE
  ])

  const promoEntries = filterHighLightsProperties(currentPageData.fields, [
    BoxLabelEnum.ITEM_PROMO_NAME,
    BoxLabelEnum.ITEM_PROMO_PRICE
  ])

  const mergeLineItems = sortHighLightsByPosition([
    ...lineItemEntries,
    ...promoEntries
  ])

  const missingLineItems = mergeLineItems.filter(
    item =>
      !(
        ((subRequirements.quantity
          ? BoxLabelEnum.ITEM_QUANTITY in item
          : true) &&
          (subRequirements.barcode ? BoxLabelEnum.ITEM_CODE in item : true) &&
          (item?.[BoxLabelEnum.ITEM_QUANTITY]?.text?.includes('.')
            ? BoxLabelEnum.ITEM_UNIT_PRICE in item
            : true) &&
          BoxLabelEnum.ITEM_DESCRIPTION in item &&
          (subRequirements.price ? BoxLabelEnum.ITEM_VALUE in item : true)) ||
        BoxLabelEnum.ITEM_PROMO_NAME in item
      )
  )

  // auto select missing item
  useEffect(() => {
    // set first line in missing line as active id
    if (autoFocus) {
      const newTargetId = missingLineItems?.[0]?.key[0]
      if (target !== newTargetId && !linkMode) handleSetTargetId(newTargetId)
    } else if (!linkMode) {
      if (target) handleSetTargetId(null)
    }
  }, [missingLineItems.length, autoFocus])

  useEffect(() => {
    if (target) {
      scrollIntoView(target)
    }
  }, [target])

  return (
    <div className="px-4 pb-4">
      <MerchantSection
        merchantDefinition={merchantDefinition}
        mergedRegularFields={mergedRegularFields}
        handleChangeSourceSelection={handleSetSourceId}
      />
      <LineItemsSection
        missingLineItems={missingLineItems}
        mergeLineItems={mergeLineItems}
        subRequirements={subRequirements}
        isUnitPriceMode={isUnitPrice}
        currentPage={currentPage}
        totalPages={totalPages}
        targetSelection={target}
        merchantDefinition={merchantDefinition}
        handleChangeSourceSelection={handleSetSourceId}
        handleChangeTargetSelection={handleSetTargetId}
        handleChangeSubRequirements={handleSetSubRequirements}
        handleModeChange={handleModeChange}
      />
      <OtherInfoSection
        merchantDefinition={merchantDefinition}
        handleChangeSourceSelection={handleSetSourceId}
        mergedRegularFields={mergedRegularFields}
      />
    </div>
  )
}

export default TaskContent
