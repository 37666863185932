import { Divider } from 'antd'
import React from 'react'

const BaseTable = ({ title, children }) => (
  <>
    <div className="sticky top-0 z-10 py-0.5 bg-gray-200">
      <Divider orientation="left">{title}</Divider>
    </div>
    {children}
  </>
)

export default BaseTable
