import Field from 'pages/app/extraction/version-2/task/content/components/field'
import Section from 'pages/app/extraction/version-2/task/content/components/sections'
import { MerchantDefinition } from 'pages/app/extraction/version-2/task/definitions/types'
import {
  LineItemHeader,
  SectionEnum
} from 'pages/app/extraction/version-2/types'
import React from 'react'

interface Props {
  mergedRegularFields: object
  handleChangeSourceSelection: (id) => void
  merchantDefinition: MerchantDefinition
}

const OtherInfoSection = ({
  mergedRegularFields,
  handleChangeSourceSelection,
  merchantDefinition
}: Props) => {
  const paymentHeader = LineItemHeader[SectionEnum.TOTAL]
  const dateTimeHeader = LineItemHeader[SectionEnum.TIME]
  const discountHeader = LineItemHeader[SectionEnum.RECEIPT_DISCOUNT]
  const transactionHeader = LineItemHeader[SectionEnum.TRANSACTION]

  const paymentInfo = paymentHeader.map(header => ({
    ...mergedRegularFields[header],
    label: header
  }))

  const dateTimeInfo = dateTimeHeader.map(header => ({
    ...mergedRegularFields[header],
    label: header
  }))

  const discountInfo = discountHeader.map(header => ({
    ...mergedRegularFields[header],
    label: header
  }))

  const transactionInfo = transactionHeader.map(header => ({
    ...mergedRegularFields[header],
    label: header
  }))

  const isComplete = [...paymentInfo, ...dateTimeInfo, ...discountInfo].every(
    item => !!item?.text || !merchantDefinition[item.label]?.required
  )

  const renderRow = (headers, data) => (
    <div className="flex gap-2">
      {headers.map((header, index) => {
        const content = data.find(content => content.label === header)
        const handleFieldClick = () => handleChangeSourceSelection(content.id)
        return (
          <Field
            key={`${header}_${content?.id}_${index}`}
            id={content?.id}
            wrapperClassName={`w-1/${headers.length}`}
            label={content?.label}
            value={content?.text || '\u00A0'}
            onClick={handleFieldClick}
            isError={merchantDefinition[header]?.required && !content?.text}
            isDisabled={merchantDefinition[header]?.disabled}
          />
        )
      })}
    </div>
  )

  return (
    <Section title="Other Info" isComplete={isComplete}>
      {renderRow(discountHeader, discountInfo)}
      {renderRow(paymentHeader, paymentInfo)}
      {renderRow(transactionHeader, transactionInfo)}
      {renderRow(dateTimeHeader, dateTimeInfo)}
    </Section>
  )
}

export default OtherInfoSection
