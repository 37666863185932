import { ExtractionSettingsType, ExtractionState } from 'data/extraction/types'
import { ResponseReceiptAnnotationType } from 'services/api/requests/annotation'
import handleActionsImmer from 'services/integrations/immer'
import ExtractionConstants from './constants'

const initialState = {
  activeTime: {
    startTime: new Date()
  },
  done: 0,
  currentPage: 0,
  isMissingFieldsOpen: false,
  shouldShowAnnotation: true,
  retailerFilter: '',
  settings: {
    quantity: false,
    autoFocus: false,
    price: true,
    barcode: false,
    linkMode: false
  }
}

const extraction = handleActionsImmer<ExtractionState>(
  {
    [ExtractionConstants.SET_RETAILER_FILTER]: (
      state: ExtractionState,
      retailer: string
    ) => {
      state.retailerFilter = retailer
    },
    [ExtractionConstants.SET_SETTINGS]: (
      state: ExtractionState,
      settings: ExtractionSettingsType
    ) => {
      state.settings = settings
    },
    [ExtractionConstants.SET_DONE_NUMBER]: (state: ExtractionState) => {
      state.done = state.done + 1
    },
    [ExtractionConstants.SET_RAW_DATA]: (
      state: ExtractionState,
      rawData: ResponseReceiptAnnotationType
    ) => {
      state.rawData = rawData
    },
    [ExtractionConstants.SET_RECEIPT_ID]: (
      state: ExtractionState,
      id: string | number
    ) => {
      state.receiptId = id
    },
    [ExtractionConstants.SET_CURRENT_PAGE]: (
      state: ExtractionState,
      pageNum: number
    ) => {
      state.currentPage = pageNum
    }
  },
  initialState
)

export default extraction
