import React from 'react'
import AnnotationAuditSider from './annotation'
import ExtractionAuditSider from './extraction'

interface Props {
  type: 'EXTRACTION' | 'LABEL'
  currPage: number
}

const GenericAuditSider = ({ type, ...rest }: Props) => {
  const Component =
    type === 'LABEL' ? AnnotationAuditSider : ExtractionAuditSider
  return <Component {...rest} />
}

export default GenericAuditSider
