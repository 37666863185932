import { AuthSelectors } from 'data/selectors'
import Retool from 'forked-components/react-retool'
import React from 'react'
import { useSelector } from 'react-redux'

export default () => {
  const { username, email } = useSelector(AuthSelectors.firebaseProfile)

  return (
    <div className="w-full h-full">
      <span id="noctua-retool-email" style={{ display: 'none' }}>
        {email}
      </span>
      <span id="noctua-retool-username" style={{ display: 'none' }}>
        {username}
      </span>
      <Retool url="https://theugroup.retool.com/embedded/public/0b6eeb74-fc90-4a9b-987e-04a69706c15c" />
    </div>
  )
}
