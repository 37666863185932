import { DashboardPage } from 'pages/app'
import React from 'react'
import { Route } from 'react-router'
import { AppRoute } from 'routes/app/routes'

const DashboardRoutesNavigator = () => (
  <Route exact path={AppRoute.DASHBOARD.pathname} component={DashboardPage} />
)

export default DashboardRoutesNavigator
