import { AuditSelectors } from 'data/audit'
import { BoxLabelEnum } from 'data/box'
import LineItemsSection from 'pages/app/extraction/version-2/task/content/line-section'
import MerchantSection from 'pages/app/extraction/version-2/task/content/merchant-section'
import OtherInfoSection from 'pages/app/extraction/version-2/task/content/other-section'
import { BaseMerchantDefinition } from 'pages/app/extraction/version-2/task/definitions/types'
import {
  filterHighLightsProperties,
  getSinglePageImportantFields,
  getSinglePageSummary,
  getSinglePageTax,
  sortHighLightsByPosition
} from 'pages/app/extraction/version-2/task/helpers'
import React from 'react'
import { useSelector } from 'react-redux'

export default ({ currPage }) => {
  const data = useSelector(AuditSelectors.receiptData)

  if (!data) return null
  const totalPages = data.pages?.length || 0

  const currentPageData = data.pages?.[currPage]

  const currentSummary = getSinglePageSummary(currentPageData.fields)
  const currentTax = getSinglePageTax(currentPageData.fields)

  // for total and subtotal
  const summaryOfPages = data.pages.reduce((wrapAcc, wrapCurr, index) => {
    return index === Number(currPage)
      ? {
          ...wrapAcc,
          ...currentSummary
        }
      : {
          ...wrapAcc,
          ...getSinglePageSummary(data.pages[index].fields)
        }
  }, {})

  // sum tax of pages
  const sumTaxOfPages = data.pages.reduce(
    (wrapAcc, wrapCurr, index) =>
      index === Number(currPage)
        ? wrapAcc + currentTax
        : wrapAcc + getSinglePageTax(data.pages[index].fields),
    0
  )

  // receipt fields
  const currentImportantFields = getSinglePageImportantFields(
    currentPageData.fields
  )
  const allPagesImportantFields = data.pages.reduce((wrapAcc, page) => {
    return {
      ...wrapAcc,
      ...getSinglePageImportantFields(page.fields)
    }
  }, {})

  const mergedRegularFields = {
    ...allPagesImportantFields,
    ...currentImportantFields,
    [BoxLabelEnum.RECEIPT_TOTAL_VALUE]: {
      text: summaryOfPages?.[BoxLabelEnum.RECEIPT_TOTAL_VALUE] || 0,
      id:
        currentImportantFields?.[BoxLabelEnum.RECEIPT_TOTAL_VALUE]?.id ||
        allPagesImportantFields?.[BoxLabelEnum.RECEIPT_TOTAL_VALUE]?.id
    },
    [BoxLabelEnum.RECEIPT_TOTAL_TAX]: {
      text: sumTaxOfPages,
      id: undefined
    }
  }

  // separate line and promo to remove duplication based on first condition
  const lineItemEntries = filterHighLightsProperties(currentPageData.fields, [
    BoxLabelEnum.ITEM_DESCRIPTION,
    BoxLabelEnum.ITEM_VALUE,
    BoxLabelEnum.ITEM_QUANTITY,
    BoxLabelEnum.ITEM_UNIT_PRICE,
    BoxLabelEnum.ITEM_CODE
  ])

  const promoEntries = filterHighLightsProperties(currentPageData.fields, [
    BoxLabelEnum.ITEM_PROMO_NAME,
    BoxLabelEnum.ITEM_PROMO_PRICE
  ])

  const mergeLineItems = sortHighLightsByPosition([
    ...lineItemEntries,
    ...promoEntries
  ])

  const missingLineItems = mergeLineItems.filter(
    item =>
      !(
        ((item?.[BoxLabelEnum.ITEM_QUANTITY]?.text?.includes('.')
          ? BoxLabelEnum.ITEM_UNIT_PRICE in item
          : true) &&
          BoxLabelEnum.ITEM_DESCRIPTION in item &&
          BoxLabelEnum.ITEM_VALUE in item) ||
        BoxLabelEnum.ITEM_PROMO_NAME in item
      )
  )

  const merchantDefinition = BaseMerchantDefinition

  const subRequirements = {
    quantity: false,
    price: true,
    barcode: false
  }
  return (
    <div className="px-4 pb-4">
      <MerchantSection
        merchantDefinition={merchantDefinition}
        mergedRegularFields={mergedRegularFields}
        handleChangeSourceSelection={() => {}}
      />
      <LineItemsSection
        missingLineItems={missingLineItems}
        mergeLineItems={mergeLineItems}
        subRequirements={subRequirements}
        isUnitPriceMode={false}
        currentPage={currPage}
        totalPages={totalPages}
        targetSelection={null}
        merchantDefinition={merchantDefinition}
        handleChangeSourceSelection={() => {}}
        handleChangeTargetSelection={() => {}}
        handleChangeSubRequirements={() => {}}
        handleModeChange={() => {}}
      />
      <OtherInfoSection
        merchantDefinition={merchantDefinition}
        handleChangeSourceSelection={() => {}}
        mergedRegularFields={mergedRegularFields}
      />
    </div>
  )
}
