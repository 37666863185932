import { store } from 'data'
import { createFirestoreInstance } from 'redux-firestore'
import firebase from 'services/firebase'

const rrfConfig = {
  userProfile: 'users',
  presence: 'onlineUsers',
  sessions: 'sessions',
  useFirestoreForProfile: true,
  profileFactory: (_, profileData, __) => {
    const { username, email, role } = profileData
    return {
      role,
      username,
      email
    }
  }
}

const rrfProps = {
  firebase,
  config: rrfConfig,
  dispatch: store.dispatch,
  createFirestoreInstance
}

export default rrfProps
