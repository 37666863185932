export type FullDefinition = {
  label: string
  name?: string
  status?: 'REQ' | 'OPT' | 'N/A'
}

export type DefinitionType = {
  id?: string
  country_code: string
  merchant: string
  definition: any[] // final definition
  full_definition: FullDefinition[] // definition before processing
  target: number
  active: boolean
  tutorial_url?: string
}

export const DefaultDefinitionState: DefinitionType = {
  country_code: 'AU',
  merchant: '',
  definition: [],
  full_definition: [],
  target: 1500,
  active: false,
  tutorial_url: ''
}

export const DefaultFullDefinition: FullDefinition = {
  label: 'receipt_merchant_logo_name',
  name: '',
  status: 'OPT'
}

export interface DBDefinitionOverview {
  definitions: DefinitionType[]
}

export interface DBDefinitionLabelsSuggestion {
  labels: FullDefinition[]
}

export type DBReceiptOverview = {
  countryCode: string
  merchant: string
  pending: number
  complete: number
  total: number
  target: number
  tutorial_url?: string
}[]

export enum LabelStatusEnum {
  INITIALISED = 'INITIALISED',
  INGESTED = 'INGESTED',
  PENDING_LABEL = 'PENDING_LABEL',
  LABELLED = 'LABELLED',
  MULTIPLE_REJECTED = 'MULTIPLE_REJECTED',
  PRIORITY_INGESTED = 'PRIORITY_INGESTED'
}
