import { Input, Modal, Row } from 'antd'
import { DialogKeys } from 'components/modals/types'
import StandardSelect from 'components/standard-select'
import { OverlayActions } from 'data/actions'
import React, { useEffect, useState } from 'react'
import { useQuery } from 'react-query'
import { useDispatch } from 'react-redux'
import { QueryKeys } from 'services/api/query-key'
import { Noctua2ApprovalApi } from 'services/api/requests/notua-ii'

interface Props {
  onOk: (vendor) => Promise<void>
}

// TODO vendor type is different from "online" column
const VENDOR_TYPES = ['OFFLINE', 'ONLINE']

const VendorModal = ({ onOk }: Props) => {
  const dispatch = useDispatch()

  const [isLoading, setIsLoading] = useState(false)
  const [error, setError] = useState('')

  const [vendor, setVendor] = useState<{
    name: string
    type: 'OFFLINE' | 'ONLINE'
  }>({ name: '', type: 'OFFLINE' })

  // search to check if retailer exist
  const { isFetching: isSearching, refetch: refetchSearchQuery } = useQuery(
    [QueryKeys.SearchOfflineRetailer],
    () => Noctua2ApprovalApi.searchRetailers(vendor.name),
    {
      enabled: false
    }
  )

  const handleCancel = () =>
    dispatch(OverlayActions.hideDialog({ key: DialogKeys.ADD_NEW_VENDOR }))

  const handleOkClick = async () => {
    const upperCaseReg = /[a-z]/gm
    const spacesReg = /\s/gm

    if (!vendor.name.length) {
      return setError('Vendor cannot be blank')
    }
    if (upperCaseReg.test(vendor.name) || spacesReg.test(vendor.name)) {
      return setError(
        'Vendor must be in UPPERCASE and not contain space eg: DAN_MURPHY'
      )
    }
    // check if vendor exist
    const refetchResult = await refetchSearchQuery()
    if (refetchResult?.data?.length) return setError('Vendor exists')

    if (!error.length) {
      setIsLoading(true)
      await onOk(vendor)
      setIsLoading(false)
      handleCancel()
    }
  }

  const handleOnTextChange = ({ target: { value } }) =>
    setVendor(prev => ({ ...prev, name: value }))

  const handleOnSelectedOptionChange = e =>
    setVendor(prev => ({ ...prev, type: e }))

  useEffect(() => {
    vendor.name.length && setError('')
  }, [vendor.name.length])

  return (
    <Modal
      title={'Add new vendor'}
      visible
      confirmLoading={isSearching || isLoading}
      onOk={handleOkClick}
      onCancel={handleCancel}
      okButtonProps={{
        style: {
          width: '70%',
          borderRadius: 6,
          display: 'flex',
          justifyContent: 'center',
          margin: 'auto'
        }
      }}
      cancelButtonProps={{ hidden: true }}
      okText={'Add'}
      width={450}
    >
      <div className="mb-4">
        <Row>
          <span className="font-bold">Vendor name</span>
        </Row>
        <Row>
          <Input
            className={'w-full'}
            value={vendor.name}
            onChange={handleOnTextChange}
            placeholder={'Type new vendor name...'}
          />
        </Row>
      </div>
      <StandardSelect
        label={<span className="font-bold">Type</span>}
        wrapperClassname="w-full"
        value={vendor.type}
        menu={VENDOR_TYPES}
        onChange={handleOnSelectedOptionChange}
      />
      <p style={{ color: 'red' }}>{error}</p>
    </Modal>
  )
}

export default VendorModal
