import { ApplicationReducerState } from 'data/reducers'
import { createSelector } from 'reselect'

export const app = (state: ApplicationReducerState) => state.app

export const siderState = createSelector(app, app => app.isSiderOpen)

export const appVersion = createSelector(app, app => app.appVersion)

export const lastActiveTime = createSelector(app, app => app.lastActiveTime)
