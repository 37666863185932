import { Col, Row, Skeleton } from 'antd'
import React from 'react'
import { Space } from 'services/styles/spacing'

const LoadingRectangular = ({ width = 300, height = 500 }) => (
  <Row>
    <Col span={24}>
      <div style={{ margin: `${Space.TINY}px 0px` }}>
        <Skeleton.Avatar
          className={'w-full height-full bg-gray-400'}
          style={{ height, width }}
          active
          size={'small'}
          shape="square"
        />
      </div>
    </Col>
  </Row>
)

export default LoadingRectangular
