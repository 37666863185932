import { Breadcrumb } from 'antd'
import React from 'react'
import { useLocation } from 'react-router'
import { Link } from 'react-router-dom'
import { toStartCase } from 'services/helpers/canonicalization'

const StandardBreadcrumb = () => {
  const location = useLocation()

  const breadcrumbRoutes = location.pathname
    .split('/')
    .map(i => ({ path: i, breadcrumbName: i ? toStartCase(i) : 'Home' }))

  const itemRender = (route, params, routes, paths) => {
    const last = routes.indexOf(route) === routes.length - 1
    return last ? (
      <span>{route.breadcrumbName}</span>
    ) : (
      <Link to={`/${paths.join('/')}`}>{route.breadcrumbName}</Link>
    )
  }

  return <Breadcrumb itemRender={itemRender} routes={breadcrumbRoutes} />
}

export default StandardBreadcrumb
