import { Modal } from 'antd'
import { DialogKeys } from 'components/modals/types'
import SplashScreen from 'components/splash'
import { OverlayActions } from 'data/actions'
import useCreateDefinition from 'pages/app/admin/merchant-definition/modals/create-definition-modal/hooks'
import React, { useEffect, useState } from 'react'
import { useQuery } from 'react-query'
import { useDispatch } from 'react-redux'
import { Noctua2DefinitionApi } from 'services/api/requests/notua-ii'
import {
  DBDefinitionLabelsSuggestion,
  DefaultDefinitionState,
  DefinitionType,
  FullDefinition
} from 'services/api/requests/notua-ii/types'
import { Color } from 'services/styles/color'
import DefinitionContent from '../components/definition-content'
import { DefinitionContext } from '../context'

interface Props {
  onOk?: () => void
  type?: string
}

const CreateDefinitionModal = ({ onOk, type }: Props) => {
  const dispatch = useDispatch()
  const [data, setData] = useState<DefinitionType>(DefaultDefinitionState)
  const [error, setError] = useState(false)

  const handleSuggestionLoaded = labelsData => {
    if (!data.full_definition.length) {
      const suggestLabels: FullDefinition[] = labelsData.labels.map(i => ({
        ...i,
        status: 'OPT'
      }))
      setData({ ...data, full_definition: suggestLabels })
    }
  }

  const { isFetching } = useQuery<DBDefinitionLabelsSuggestion>(
    ['create-definition-labels-suggestion', type],
    () => Noctua2DefinitionApi.getDefinitionLabelsSuggestion(type),
    {
      initialData: { labels: [] },
      retry: false,
      enabled: !data.full_definition?.length,
      keepPreviousData: true,
      refetchInterval: false,
      refetchOnReconnect: false,
      refetchOnWindowFocus: false,
      refetchIntervalInBackground: false,
      optimisticResults: true,
      onSuccess: handleSuggestionLoaded
    }
  )

  const [isLoading, handleCreateDefinition] = useCreateDefinition()

  const handleCancel = () =>
    dispatch(OverlayActions.hideDialog({ key: DialogKeys.CREATE_DEFINITION }))

  const handleOkClick = async () => {
    if (!error) {
      onOk && onOk()
      await handleCreateDefinition(data)
      handleCancel()
    }
  }

  useEffect(() => {
    // prevent too many re-render on typing
    if (!error && !data.merchant) {
      setError(true)
    }
    if (error && !!data.merchant) {
      setError(false)
    }
  }, [data.merchant])

  return (
    <Modal
      title={'Create Definition'}
      visible
      confirmLoading={false}
      onOk={handleOkClick}
      onCancel={handleCancel}
      okButtonProps={{
        disabled: error,
        loading: isLoading,
        style: {
          width: 200,
          backgroundColor: error ? Color.GREY_400 : '#60a5fa',
          borderRadius: 6,
          height: 40
        }
      }}
      cancelButtonProps={{ type: 'text' }}
      okText={'Confirm'}
      className="top-4"
      width={1000}
    >
      <DefinitionContext.Provider
        value={{ state: data, dispatchDefinition: setData }}
      >
        {isFetching ? <SplashScreen /> : <DefinitionContent />}
      </DefinitionContext.Provider>
    </Modal>
  )
}

export default CreateDefinitionModal
