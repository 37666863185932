import { QuestionCircleOutlined } from '@ant-design/icons'
import { Button, Tooltip } from 'antd'
import Divider from 'components/divider'
import RejectModal from 'components/modals/reject'
import { DialogKeys } from 'components/modals/types'
import { ExtractionActions, OverlayActions } from 'data/actions'
import { BoxLabelEnum } from 'data/box'
import { BoxSelectors, ExtractionSelectors } from 'data/selectors'
import { formatDataFields } from 'pages/app/extraction/version-1/helpers'
import {
  useHandleSkipAnnotation,
  useHandleSubmitAnnotation
} from 'pages/app/extraction/version-1/task/hooks'
import React from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { useParams } from 'react-router'
import { ResponseReceiptAnnotationType } from 'services/api/requests/annotation'
import { parseCurrencyStrict } from 'services/helpers/number'
import Hotkeys from 'services/hotkeys'
import { Color } from 'services/styles/color'

const ShortcutsTooltip = () => (
  <>
    <p style={{ borderBottom: '1px solid grey' }}>SHORTCUTS</p>
    <p>
      <span className="mr-12">+/=</span>: Zoom in
    </p>
    <p>
      <span className="mr-16">-</span>: Zoom out
    </p>
    <p>
      <span className="mr-14">C</span>: Link mode
    </p>
    <p>
      <span className="mr-14">Space</span>: Panning mode
    </p>
    <p>
      <span className="mr-14">O</span>: Select highlights
    </p>
    <p>
      <span className="mr-16">`</span>: Create box mode
    </p>
    <p>
      <span className="mr-6">Delete</span>: Delete label
    </p>
  </>
)

const HotkeysTooltip = () => (
  <>
    <p style={{ borderBottom: '1px solid grey' }}>HOTKEYS</p>
    <Divider text1={'Merchant'} />
    <p>
      <strong className="mr-16">
        {Hotkeys[BoxLabelEnum.RECEIPT_MERCHANT_NAME]}
      </strong>
      <strong className="mr-16">
        {Hotkeys[BoxLabelEnum.RECEIPT_STORE_ADDRESS]}
      </strong>
      <strong>{Hotkeys[BoxLabelEnum.RECEIPT_STORE_PHONE]}</strong>
    </p>
    <p>
      <strong className="mr-5">Name</strong>
      <strong className="mr-5">Address</strong>
      <strong>Phone</strong>
    </p>
    <Divider text1={'Line'} />
    <p>
      <strong className="mr-10">1</strong>
      <strong className="mr-10">2</strong>
      <strong className="mr-12">3</strong>
      <strong>B</strong>
    </p>
    <p>
      <strong className="mr-2">Name</strong>
      <strong className="mr-4">Qty</strong>
      <strong className="mr-6">Price</strong>
      <strong>Barcode</strong>
    </p>
    <Divider text1={'Promo'} />
    <p>
      <strong className="mr-14">Q</strong>
      <strong>W</strong>
    </p>
    <p>
      <strong className="mr-5">Name</strong>
      <strong>Price</strong>
    </p>
    <Divider text1={'Total'} />
    <p>
      <strong className="mr-14">A</strong>
      <strong className="mr-10">S</strong>
      <strong>D</strong>
    </p>
    <p>
      <strong className="mr-8">Sub</strong>
      <strong className="mr-6">Tax</strong>
      <strong>Total</strong>
    </p>
    <Divider text1={'Time'} />
    <p>
      <strong className="mr-14">
        {Hotkeys[BoxLabelEnum.RECEIPT_PURCHASE_DATE]}
      </strong>
      <strong>{Hotkeys[BoxLabelEnum.RECEIPT_PURCHASE_TIME]}</strong>
    </p>
    <p>
      <strong className="mr-8">Date</strong>
      <strong>Time</strong>
    </p>
  </>
)

const TaskDetails = ({ inCompleteTasks }) => {
  const { countryCode } = useParams<{ countryCode: string }>()

  const sumPrice = useSelector(BoxSelectors.sumPrice(countryCode === 'AU'))
  const currentPage = String(useSelector(ExtractionSelectors.currentPage))
  const summary = useSelector(BoxSelectors.summary)
  const minervaId = useSelector(ExtractionSelectors.minervaId)

  // for total and subtotal
  const summaryOfPages = useSelector(
    ExtractionSelectors.summaryOfPages(currentPage, summary)
  )

  // sum price of pages (calculated Total)
  const sumOfPages = useSelector(
    ExtractionSelectors.sumPriceOfPages(
      currentPage,
      sumPrice,
      countryCode === 'AU'
    )
  )

  const importantFields = useSelector(ExtractionSelectors.importantFields) // final
  const extractedImportantFields = useSelector(BoxSelectors.importantFields) // editing

  const mergedImportantFields = {
    ...importantFields,
    ...extractedImportantFields,
    [BoxLabelEnum.RECEIPT_TOTAL_VALUE]: {
      text: summaryOfPages?.[BoxLabelEnum.RECEIPT_TOTAL_VALUE] || 0,
      id:
        extractedImportantFields?.[BoxLabelEnum.RECEIPT_TOTAL_VALUE]?.id ||
        importantFields?.[BoxLabelEnum.RECEIPT_TOTAL_VALUE]?.id
    }
  }

  const distanceToZero = (
    sumOfPages -
    parseCurrencyStrict(
      mergedImportantFields[BoxLabelEnum.RECEIPT_TOTAL_VALUE].text
    )
  ).toFixed(2)
  const calculatedTotalText = `${sumOfPages?.toFixed(2)} (${distanceToZero})`
  const isTotalMatched = Number(distanceToZero) === 0

  return (
    <div className="flex items-center justify-between w-full mr-2">
      <div className="flex items-center">
        <Tooltip title={ShortcutsTooltip}>
          <QuestionCircleOutlined className="text-lg mr-4" />
        </Tooltip>
        <Tooltip title={HotkeysTooltip}>
          <span className="underline">Hotkeys</span>
        </Tooltip>{' '}
        <span className="mx-2">|</span>
        <span>
          Receipt ID: <span className="font-bold">{minervaId}</span>
        </span>
        <span className="mx-2">|</span>
        <span>
          <span className="font-bold">{inCompleteTasks}</span> more in queue
        </span>
        <span className="mx-2">|</span>
        <span>
          Receipt Total:{' '}
          <span className="font-bold">
            {mergedImportantFields[BoxLabelEnum.RECEIPT_TOTAL_VALUE].text}
          </span>
        </span>
      </div>
      <span className="text-right">
        Extracted Total:{' '}
        <span
          className="font-bold"
          style={{ color: isTotalMatched ? Color.SUCCESS : '#EF4444' }}
        >
          {calculatedTotalText}
        </span>
      </span>
    </div>
  )
}

const TaskFooter = () => {
  const currentReceiptId = useSelector(ExtractionSelectors.receiptId)
  const currentPage = useSelector(ExtractionSelectors.currentPage)
  const data = useSelector(ExtractionSelectors.data)
  const labeledBoxes = useSelector(BoxSelectors.boxes)
  const receiptInQueue = useSelector(ExtractionSelectors.totalReceipts)

  const dispatch = useDispatch()

  const [isSubmitting, handleSubmit] = useHandleSubmitAnnotation()
  const [isSkipping, handleSkip] = useHandleSkipAnnotation()

  const handleRejectClick = async e => {
    e.currentTarget.blur()

    dispatch(
      OverlayActions.showDialog({
        key: DialogKeys.REJECT_RECEIPT,
        component: <RejectModal />
      })
    )
  }

  const handleSkipClick = async e => {
    e.currentTarget.blur()

    await handleSkip(currentReceiptId)
    // load next receipt annotation
    dispatch(ExtractionActions.setReceiptId(null))
    dispatch(ExtractionActions.approveReceipt())
  }

  const handleApproveClick = async e => {
    e.currentTarget.blur()
    // save current state
    const currentExtractedData = {
      ...data,
      [currentPage]: { ...data[currentPage], fields: labeledBoxes }
    } as ResponseReceiptAnnotationType

    const formattedData = formatDataFields(currentExtractedData)

    await handleSubmit(currentReceiptId, formattedData)

    // load next receipt annotation
    dispatch(ExtractionActions.setReceiptId(null))
    dispatch(ExtractionActions.approveReceipt())
  }

  return (
    <>
      <TaskDetails inCompleteTasks={receiptInQueue} />
      <div className="flex flex-grow items-center">
        <Button
          loading={isSubmitting}
          onClick={handleApproveClick}
          size="large"
          className="text-white hover:text-white w-28 rounded"
          style={{ backgroundColor: Color.SUCCESS }}
        >
          Approve
        </Button>
        <span className="mx-2 text-xl">|</span>
        <Button
          onClick={handleRejectClick}
          size="large"
          danger
          className="w-20 rounded mr-2"
          type="primary"
        >
          Reject
        </Button>
        <Button
          loading={isSkipping}
          onClick={handleSkipClick}
          size="large"
          className="text-white w-20 rounded bg-black"
        >
          Skip
        </Button>
      </div>
    </>
  )
}

export default TaskFooter
