import { AuthActions } from 'data/actions'
import { AuthSelectors } from 'data/selectors'
import { LoginPage, RegisterPage, ResetPasswordPage } from 'pages/auth'
import React, { useEffect } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { useHistory, useLocation } from 'react-router'
import { AuthRoute, isOnAuthRoutes } from 'routes/auth/routes'
import SentryRoute from 'services/sentry/sentry-route'

const AuthRoutesNavigator = () => {
  const dispatch = useDispatch()
  const firebaseErrors = useSelector(AuthSelectors.firebaseErrors)
  const history = useHistory()
  const { pathname } = useLocation()

  if (!isOnAuthRoutes(pathname)) {
    history.replace(AuthRoute.LOGIN)
  }

  useEffect(() => {
    if (firebaseErrors.length) {
      dispatch(AuthActions.clearAuthError())
      dispatch(AuthActions.clearFirebaseErrors())
    }
  }, [pathname])

  return (
    <div className="flex justify-center items-center w-screen h-screen">
      <SentryRoute exact path={AuthRoute.LOGIN} component={LoginPage} />
      <SentryRoute exact path={AuthRoute.REGISTER} component={RegisterPage} />
      <SentryRoute exact path={AuthRoute.RESET} component={ResetPasswordPage} />
    </div>
  )
}

export default AuthRoutesNavigator
