import { LockFilled, VerticalAlignBottomOutlined } from '@ant-design/icons'
import { Button } from 'antd'
import { ButtonProps } from 'antd/lib/button/button'
import { AppActions } from 'data/actions'
import { AuthSelectors } from 'data/selectors'
import React from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { useHistory, useLocation } from 'react-router'
import { AppRoute, AppSubRoutes } from 'routes/app/routes'
import { toStartCase } from 'services/helpers/canonicalization'
import { checkOnePermission } from 'services/hooks/use-handle-require-permission'
import { HEADER_HEIGHT } from 'services/styles/layout'

const NavButton = ({
  children,
  className,
  active,
  blocked,
  ...props
}: { active?: boolean; blocked?: boolean } & ButtonProps) => (
  <Button
    type="text"
    disabled={blocked}
    className={`px-2 h-full text-left py-4 flex items-center ${
      className || ''
    }`}
    {...props}
  >
    <span className={`${active ? 'font-bold' : ''} hover:underline`}>
      {children}
    </span>
    {blocked ? <LockFilled className="-mt-0.5" /> : null}
  </Button>
)

const RootSider = () => {
  const history = useHistory()
  const { pathname } = useLocation()
  const dispatch = useDispatch()
  const profile = useSelector(AuthSelectors.firebaseProfile)

  const handleNavigationClick = (pathname: string) => () => {
    // temporary hide Noctua 1
    if (pathname === AppRoute.EXTRACTION.pathname) {
      return history.push(AppSubRoutes.EXTRACTION.V2.HOME)
    }
    return history.push(pathname)
  }
  const handleCollapseSiderClick = () => dispatch(AppActions.toggleSider())

  const tabs = Object.entries(AppRoute).slice(1)

  return (
    <>
      <div className="flex flex-col w-full">
        <div
          style={{ height: HEADER_HEIGHT }}
          className="flex items-center justify-between pl-4"
        >
          <span
            className="font-bold text-lg cursor-pointer"
            onClick={handleNavigationClick(AppRoute.DASHBOARD.pathname)}
          >
            Workflows
          </span>
          <NavButton
            className="pl-2"
            onClick={handleCollapseSiderClick}
            icon={
              <VerticalAlignBottomOutlined className="text-2xl transform rotate-90" />
            }
          />
        </div>
        {tabs.map(([key, value]) => (
          <NavButton
            key={key}
            className="pl-12"
            blocked={
              !checkOnePermission(
                profile?.permissions,
                value.requirePermissions
              )
            }
            active={pathname.includes(value.pathname)}
            onClick={handleNavigationClick(value.pathname)}
          >
            {toStartCase(key)}
          </NavButton>
        ))}
      </div>
    </>
  )
}

export default RootSider
