import axios from 'axios'
import config from 'services/config'
import {
  axiosReqInterceptor,
  axiosResInterceptor
} from 'services/integrations/axios'

const VisualizerApiClient = axios.create({
  baseURL: config.baseUrl + 'noctua-ii/label/'
})

VisualizerApiClient.interceptors.request.use(axiosReqInterceptor)
VisualizerApiClient.interceptors.response.use(axiosResInterceptor)

export type ReceiptType = {
  content: string[]
  size_list: { width: number; height: number }
  minerva_id: number
}

export const getReceipt = async (
  countryCode: string,
  merchantName?: string
) => {
  const { data } = await VisualizerApiClient.get<ReceiptType>(
    `training/${countryCode}${merchantName ? `?merchant=${merchantName}` : ''}`
  )

  return data
}
