import { Typography } from 'antd'
import { PromoIcon } from 'assets/icons'
import React, { CSSProperties } from 'react'
import { Color } from 'services/styles/color'

const { Text } = Typography

interface FieldProps {
  placeholder?: string
  value?: string | number
  wrapperClassName?: string
  onClick?: (x: any) => void
  isDisabled?: boolean
  isError?: boolean
  id?: string
  isPromoName?: boolean
}

const disabledStyle: CSSProperties = {
  backgroundColor: Color.GREY_300,
  border: '1px solid grey',
  cursor: 'not-allowed'
}
const FieldCell = ({
  placeholder,
  isDisabled,
  isError,
  value,
  wrapperClassName,
  onClick,
  id,
  isPromoName
}: FieldProps) => (
  <div
    id={id}
    className={`flex flex-col flex-grow ${wrapperClassName || ''}`}
    onClick={!isDisabled ? onClick : () => {}}
  >
    <div className="flex flex-grow items-center ">
      {isPromoName && <PromoIcon className="pr-2" />}
      <Text
        editable={false}
        className={`p-2 truncate text-xs border border-solid rounded w-full cursor-pointer ${
          isError ? 'bg-red-100 border-red-700' : 'bg-white border-gray-400'
        } ${!value && placeholder ? 'text-gray-400' : 'text-black'}`}
        style={isDisabled ? disabledStyle : {}}
      >
        {value || placeholder || '\u00A0'}
      </Text>
    </div>
  </div>
)

export default FieldCell
